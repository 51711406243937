import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './styles.module.scss';

const Steps = ({ current, children }) => {
    const props = [];
    let index = 0;

    React.Children.forEach(children, child => {
        if (child && child.type && (child.type.displayName || child.type.name) === 'Step') {
            props.push({
                active: child.props.name === current,
                number: index + 1,
                complete: !!current
                    ? typeof child.props.complete !== 'undefined'
                        ? child.props.complete
                        : child.props.name !== current && !props.some(stepProps => stepProps.active)
                    : false,
            });

            index++;
        }
    });

    index = -1;

    return (
        <ul className={styles.steps}>
            {React.Children.map(children, child => {
                if (child && child.type && (child.type.displayName || child.type.name) === 'Step') {
                    index++;

                    return (child && child.type && (child.type.displayName || child.type.name)) ===
                        'Step'
                        ? React.cloneElement(child, props[index])
                        : child;
                }

                return child;
            })}
        </ul>
    );
};

const Step = ({ title, active, number, complete }) => {
    return (
        <li
            className={`${styles.stepsStep} ${active ? styles.stepsStepActive : ''} ${
                complete ? styles.stepsStepComplete : ''
            }`}
        >
            <span>
                <span className={styles.stepsBadge}>
                    {complete ? <FontAwesomeIcon icon="check" /> : <span>{number}</span>}
                </span>
                {title}
            </span>
        </li>
    );
};

Step.displayName = 'Step';
Steps.Step = Step;
export default Steps;
