import React from 'react';
import Img from 'gatsby-image';

import { subcomponent } from '@helpers';

import styles from './styles.module.scss';

const Modal = ({ isOpen, title, children, onClose, width }) => {
    return isOpen ? (
        <div
            className={styles.modal}
            onClick={onClose && typeof onClose === 'function' ? () => onClose() : undefined}
        >
            <div className={styles.modalContainer}>
                <div
                    className={styles.modalCard}
                    onClick={e => e.stopPropagation()}
                    style={{ maxWidth: width }}
                >
                    {subcomponent(children, Image, true)}
                    {!!title && <h3 className={styles.modalCardTitle}>{`${title}`}</h3>}
                    {subcomponent(children, Content, true)}
                </div>
            </div>
        </div>
    ) : null;
};

const Image = ({ alt, src, height = 300 }) =>
    src ? (
        <div className={styles.modalCardImage} style={{ height }}>
            <img alt={alt} src={src} />
        </div>
    ) : null;
const Content = ({ children }) => <div className={styles.modalCardContent}>{children}</div>;

Modal.Content = Content;
Content.displayName = 'Content';

Modal.Image = Image;
Image.displayName = 'Image';

export default Modal;
