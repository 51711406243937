import React, { Fragment, useEffect, useState } from 'react';
import objectPath from 'object-path';
import * as objectPathImmutable from 'object-path';
import queryString from 'query-string';
import { navigate } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { reorder } from '@helpers';

import { HeightContext } from '@helpers/contexts';

import styles from './styles.module.scss';
import Breadcrumbs from '@components/Breadcrumbs';

import Field from './Field';
import EmptyIcon from '@images/wizard-question.svg';
import Button from '@components/Button';
import { withSnackbar } from '@components/Snackbar';

const List = ({
    id,
    label,
    create,
    path,
    min,
    max,
    optional,
    index,
    internalPath,
    paths,
    pathFields,
    fields,
    values,
    validation,
    touched,
    errors,
    formatPath,
    setFields,
    openSnackbar,
    ...props
}) => {
    const { setFieldValue } = props;
    const [editing, setEditing] = useState(false);

    // recursively patch all the paths
    // required when creating or moving fields within a list of other fields
    // always patches lower validation fields at 0 index as it doesn't exist as a field yet.
    const patchPaths = ({ fields, path, internalPath, index = 0 }) => {
        return fields.map((item, fieldIndex) => ({
            ...item,
            path: [...path, index, item.id],
            internalPath: [...internalPath, 'fields', index, fieldIndex],
            fields: item.fields
                ? item.fields.map(fields =>
                      patchPaths({
                          fields,
                          path: [...path, index, item.id],
                          internalPath: [...internalPath, 'fields', index, fieldIndex],
                      })
                  )
                : undefined,
            validation: item.validation
                ? patchPaths({
                      fields: item.validation,
                      path: [...path, index, item.id],
                      internalPath: [...internalPath, 'fields', index, fieldIndex],
                  })
                : undefined,
        }));
    };

    const patchValues = fields => {
        const newFields = {};

        fields.forEach(({ id, type }) => {
            if (type === 'list') {
                newFields[id] = [];
                return;
            }

            newFields[id] = undefined;
        });

        return newFields;
    };

    const handleCreateListField = () => {
        const newFields = patchPaths({
            fields: validation,
            path,
            internalPath,
            index: fields.length,
        });

        // update fields with new item
        setFields([...fields, newFields]);

        // update form with new empty values (so we can validate required fields correctly)
        setFieldValue(path, [...(objectPath.get(values, path) || []), patchValues(validation)]);
    };

    const handleDeleteListField = item => {
        const index = fields.indexOf(item);

        const newFields = objectPathImmutable.del(fields, [index]).map((item, index) =>
            patchPaths({
                fields: item,
                path,
                internalPath,
                index,
            })
        );

        // // update fields with new item
        setFields(newFields);

        // update form with new empty values (so we can validate required fields correctly)
        setFieldValue(path, objectPathImmutable.del(objectPath.get(values, path), [index]));
    };

    const handleReorderListField = result => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        // const newFields = reorder(fields, result.source.index, result.destination.index);

        const newFields = reorder(
            objectPathImmutable.get(fields),
            result.source.index,
            result.destination.index
        ).map((item, index) =>
            patchPaths({
                fields: item,
                path,
                internalPath,
                index,
            })
        );

        const newValues = reorder(
            objectPathImmutable.get(values, path),
            result.source.index,
            result.destination.index
        );

        setFields(newFields);
        setFieldValue(path, newValues);
    };

    const title = (
        <div className={styles.listTitle}>
            <div
                className={styles.listTitleBack}
                onClick={() => {
                    navigate(
                        `/editor?${queryString.stringify({
                            ...queryString.parse(location.search),
                            path: pathFields[1] ? pathFields[1].path : undefined,
                        })}`
                    );
                }}
            >
                <FontAwesomeIcon icon={['fad', 'angle-left']} />
            </div>

            {!!paths.length && (
                <Breadcrumbs>
                    {pathFields
                        .slice()
                        .reverse()
                        .map(({ label, path }, index) => (
                            <Breadcrumbs.Breadcrumb
                                key={path}
                                text={label}
                                link={
                                    paths.length - 1 === index
                                        ? null
                                        : `/editor?${queryString.stringify({
                                              ...queryString.parse(location.search),
                                              path,
                                          })}`
                                }
                            />
                        ))}
                </Breadcrumbs>
            )}

            <div className={styles.listTitleActions}>
                <Button
                    className={styles.listTitleActionsAction}
                    icon={['fad', 'pen']}
                    tooltip="Reorder or Delete"
                    onClick={() => setEditing(!editing)}
                />

                {!!create && (
                    <Button
                        disabled={max && fields.length >= max}
                        allowDisabledOnClick
                        className={styles.listTitleActionsAction}
                        secondary
                        icon="plus"
                        text="Create"
                        onClick={
                            max && fields.length >= max
                                ? () => {
                                      openSnackbar(`${label} can't have more than ${max} items.`);
                                  }
                                : handleCreateListField
                        }
                    />
                )}
            </div>
        </div>
    );

    return (
        <HeightContext.Consumer>
            {({ height } = {}) => (
                <div className={styles.list} style={{ height }}>
                    {title}

                    {!!fields && !!fields.length ? (
                        editing ? (
                            <DragDropContext onDragEnd={handleReorderListField}>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            className={`${styles.listFields} ${
                                                snapshot.isDraggingOver
                                                    ? styles.listFieldsDragging
                                                    : ''
                                            }`}
                                        >
                                            {fields.map((item, index) => (
                                                <Draggable
                                                    key={`fields_${path.join('.')}_${index}`}
                                                    draggableId={`draggable_fields_${path.join(
                                                        '.'
                                                    )}_${index}`}
                                                    isDragDisabled={fields.length === 1}
                                                    index={index}
                                                >
                                                    {(provided, snapshot) => (
                                                        <ListItem
                                                            editing={true}
                                                            draggable={{ provided, snapshot }}
                                                            allFields={fields}
                                                            fields={item}
                                                            min={min}
                                                            label={label}
                                                            optional={optional}
                                                            handleDeleteField={
                                                                handleDeleteListField
                                                            }
                                                            formatPath={formatPath}
                                                            values={values}
                                                            touched={touched}
                                                            errors={errors}
                                                            openSnackbar={openSnackbar}
                                                            {...props}
                                                        />
                                                    )}
                                                </Draggable>
                                            ))}

                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        ) : (
                            <div className={styles.listFields}>
                                {fields.map((item, index) => (
                                    <ListItem
                                        key={`fields_${path.join('.')}_${index}`}
                                        allFields={fields}
                                        fields={item}
                                        min={min}
                                        label={label}
                                        optional={optional}
                                        handleDeleteField={handleDeleteListField}
                                        formatPath={formatPath}
                                        values={values}
                                        touched={touched}
                                        errors={errors}
                                        openSnackbar={openSnackbar}
                                        {...props}
                                    />
                                ))}
                            </div>
                        )
                    ) : (
                        <div className={styles.listFields}>
                            <div className={styles.fieldsEmpty}>
                                <EmptyIcon className={styles.fieldsEmptyIcon} />
                                <p className={styles.fieldsEmptyTitle}>{`No ${label} yet`}</p>
                                <p
                                    className={styles.fieldsEmptyText}
                                >{`You don't seem to have any ${label} yet, hit create to get started!`}</p>
                                <div className={styles.fieldsEmptyButton}>
                                    <Button
                                        success
                                        icon="plus"
                                        text="Create"
                                        onClick={handleCreateListField}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </HeightContext.Consumer>
    );
};

const ListItem = ({
    allFields,
    fields,
    editing,
    draggable,
    min,
    label,
    optional,
    handleDeleteField,
    formatPath,
    values,
    touched,
    errors,
    openSnackbar,
    ...props
}) => (
    <div
        {...(draggable ? draggable.provided.draggableProps : {})}
        className={`${styles.fieldsContainer}`}
        ref={draggable ? draggable.provided.innerRef : undefined}
        style={draggable ? draggable.provided.draggableProps.style : undefined}
    >
        <div
            className={`${styles.fields} ${editing ? styles.fieldsHasActions : ''} ${
                draggable && draggable.snapshot.isDragging ? styles.fieldsDragging : ''
            }`}
        >
            {editing && (
                <div className={styles.fieldsActions}>
                    {/* if required and only 1 field left prevent deletion, or if there are more available fields than the minimum required */}
                    <div {...(draggable ? draggable.provided.dragHandleProps : {})}>
                        <Button
                            disabled={allFields.length === 1}
                            allowDisabledOnClick
                            onClick={
                                allFields.length === 1
                                    ? () =>
                                          openSnackbar(
                                              `${label} can't be reordered when there is only 1 item.`
                                          )
                                    : undefined
                            }
                            icon={['fad', 'bars']}
                            tooltip="Reorder"
                        />
                    </div>

                    <Button
                        disabled={
                            !(
                                optional ||
                                (!optional && allFields.length > 1) ||
                                allFields.length > min
                            )
                        }
                        allowDisabledOnClick
                        icon={['fad', 'trash']}
                        tooltip="Delete"
                        onClick={
                            !(
                                optional ||
                                (!optional && allFields.length > 1) ||
                                allFields.length > min
                            )
                                ? () =>
                                      openSnackbar(
                                          `${label} ${(min &&
                                              `can't have less than ${min} items.`) ||
                                              (!optional && `can't have less than 1 item.`)}`
                                      )
                                : () => handleDeleteField(fields)
                        }
                        danger
                    />
                </div>
            )}

            <div className={styles.fieldsContent}>
                {fields.map((field, index) => (
                    <Field
                        key={`${field.path.join('.')}_${index}`}
                        field={field}
                        {...props}
                        value={objectPath.get(values, field.path)}
                        touched={objectPath.get(touched, field.path)}
                        error={objectPath.get(errors, field.path)}
                        formatPath={formatPath}
                        name={formatPath(field?.path)}
                    />
                ))}
            </div>
        </div>
    </div>
);

export default withSnackbar(List);
