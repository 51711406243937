import React, { Fragment, useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import Card from '@components/Card';
import Fields from '@views/Forms/Form/Fields';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@components/Button';
import Stepper from '@components/Stepper';
import { Divider } from '@components/Grid';
import usePrevious from '@helpers/hooks/usePrevious';
import Text from '@components/Text';

const Section = ({
    errors,
    touched,
    section,
    index,
    isSameItem,
    refs,
    id,
    multiple,
    title,
    description,
    min,
    max,
    fields,
    focused,
    total,
    currentFocused,
    onFocus,
    onSectionDelete,
    onSectionDuplicate,
    onSectionChange,
    onQuestionReorder,
    onQuestionChange,
    onQuestionDuplicate,
    onQuestionDelete,
    onHeightChange,
    handleChange,
    setFieldValue,
}) => {
    const prevMultiple = usePrevious(multiple);
    const [minHeight, setMinHeight] = useState(null);
    const $section = useRef(null);

    useEffect(() => {
        setMinHeight(null);
    }, [currentFocused]);

    useEffect(() => {
        if (!minHeight) {
            setMinHeight($section?.current?.clientHeight);
            return;
        }

        onHeightChange();
    }, [minHeight]);

    useEffect(() => {
        if (!!prevMultiple && !multiple) {
            onSectionChange({
                ...section,
                min: undefined,
                max: undefined,
            });
        }
    }, [multiple]);

    return (
        <div className={styles.section}>
            <div className={styles.sectionMain}>
                <div className={styles.sectionControl}>
                    <div className={styles.sectionControlLabel}>{`Sections ${index +
                        1} of ${total}`}</div>
                </div>

                <div className={styles.sectionContent} ref={$section} style={{ minHeight }}>
                    <div>
                        <div
                            className={`${styles.sectionHeading} ${
                                focused ? styles.sectionHeadingFocused : ''
                            }`}
                            ref={ref => (refs[id] = ref)}
                            onClick={focused ? undefined : () => onFocus(section)}
                        >
                            <Card key={`${id}_card`}>
                                <Card.Title
                                    key={`${id}_card_title`}
                                    editable={focused}
                                    placeholder={'Untitled Section'}
                                    value={title}
                                    onChange={e =>
                                        onSectionChange({
                                            ...section,
                                            title: e.target.value,
                                        })
                                    }
                                >
                                    {title ?? (!focused && 'Untitled Section')}
                                </Card.Title>
                                <Card.Description
                                    key={`${id}_card_description`}
                                    editable={focused}
                                    placeholder="Form description"
                                    value={description}
                                    onChange={e =>
                                        onSectionChange({
                                            ...section,
                                            description: e.target.value,
                                        })
                                    }
                                >
                                    {description ?? 'Untitled Description'}
                                </Card.Description>

                                {multiple && ((!focused && (min || max)) || focused) && (
                                    <Card.Content>
                                        {focused ? (
                                            <Fragment>
                                                <Stepper
                                                    text={min}
                                                    empty={!min}
                                                    label="Min"
                                                    onClear={() => {
                                                        onSectionChange({
                                                            ...section,
                                                            min: undefined,
                                                        });
                                                    }}
                                                    onDecrement={() =>
                                                        onSectionChange({
                                                            ...section,
                                                            min: min ? min - 1 : 1,
                                                        })
                                                    }
                                                    onIncrement={() =>
                                                        onSectionChange({
                                                            ...section,
                                                            min: min ? min + 1 : 1,
                                                        })
                                                    }
                                                    decrementDisabled={!min || min === 1}
                                                    incrementDisabled={max && min && min >= max}
                                                />
                                                <Divider />
                                                <Stepper
                                                    text={max}
                                                    empty={!max}
                                                    label="Max"
                                                    onClear={() => {
                                                        onSectionChange({
                                                            ...section,
                                                            max: undefined,
                                                        });
                                                    }}
                                                    onDecrement={() =>
                                                        onSectionChange({
                                                            ...section,
                                                            max: max ? max - 1 : 1,
                                                        })
                                                    }
                                                    onIncrement={() =>
                                                        onSectionChange({
                                                            ...section,
                                                            max: max ? max + 1 : min ? min : 1,
                                                        })
                                                    }
                                                    decrementDisabled={
                                                        !max || max === 1 || (min && max <= min)
                                                    }
                                                />
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                <Text>
                                                    {!!min && `Minimum: ${min}`}
                                                    {min && max ? ` • ` : ''}
                                                    {!!max && `Maximum: ${max}`}
                                                </Text>
                                            </Fragment>
                                        )}
                                    </Card.Content>
                                )}

                                {focused && (
                                    <Card.Actions right>
                                        <Card.Actions.Switch
                                            name="multiple"
                                            label="Multiple"
                                            checked={multiple}
                                            onChange={(e, checked) => {
                                                onSectionChange({
                                                    ...section,
                                                    multiple: checked,
                                                });
                                            }}
                                        />
                                        <Card.Actions.Action
                                            tooltip="Duplicate section"
                                            icon={['fad', 'clone']}
                                            onClick={() => onSectionDuplicate(section)}
                                        />
                                        {total > 1 && (
                                            <Card.Actions.Action
                                                tooltip="Delete section"
                                                icon={['fad', 'trash']}
                                                onClick={() => onSectionDelete(section)}
                                            />
                                        )}
                                    </Card.Actions>
                                )}
                            </Card>
                        </div>
                    </div>

                    <Fields
                        name={`sections.${index}.fields`}
                        errors={errors?.sections?.[index]?.fields}
                        touched={touched?.sections?.[index]?.fields}
                        onReorder={onQuestionReorder}
                        refs={refs}
                        isSameItem={isSameItem}
                        currentFocused={currentFocused}
                        onFocus={onFocus}
                        onChange={onQuestionChange}
                        onDuplicate={onQuestionDuplicate}
                        onDelete={onQuestionDelete}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        fields={fields}
                    />
                </div>
            </div>
        </div>
    );
};

export default Section;
