import React, { Fragment, useEffect, useState } from 'react';
import { Grid, Divider } from '@components/Grid';

import List from '@components/List';
import { makeGetRequest, makePostRequest } from '@helpers/requests';
import { USER_PROFILE, EMAIL_VERIFY_RESEND } from '@helpers/api';
import { updateUser } from '@actions/user';
import Alert from '@components/Alert';
import Button from '@components/Button';
import { useDispatch } from 'react-redux';
import { withSnackbar } from '@components/Snackbar';

const Account = ({ user, openSnackbar }) => {
    const [resendingEmail, setResendingEmail] = useState(false);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                const { data: userData } = await makeGetRequest(USER_PROFILE);
                dispatch(updateUser({ user: userData }));
                setLoading(false);
            } catch (error) {
                error !== 'cancelled' &&
                    openSnackbar(
                        error?.errorMessage ?? 'An error occurred loading your user details.'
                    );
            }
        })();
    }, []);

    const handleResendEmail = async () => {
        try {
            setResendingEmail(true);
            await makePostRequest(EMAIL_VERIFY_RESEND);
            openSnackbar('Successfully sent email confirmation.');
        } catch (error) {
            error !== 'cancelled' &&
                openSnackbar(
                    error?.errorMessage ??
                        'An error occurred when attempting to send a verification email.'
                );
        } finally {
            setResendingEmail(false);
        }
    };

    return (
        <Grid small>
            {!user?.permissions?.emailVerified && (
                <Fragment>
                    <Alert
                        type="warning"
                        title="Verify your email address"
                        message={`You need to verify your email address, please check your inbox at ${user.email} for a confirmation email and click the button in the email to successfully complete verification. Be sure to check your spam/junk folders just in case!
`}
                    >
                        <Alert.Actions>
                            <Alert.Actions.Action
                                onClick={handleResendEmail}
                                submitting={resendingEmail}
                            >
                                Resend confirmation email
                            </Alert.Actions.Action>
                        </Alert.Actions>
                    </Alert>
                    <Divider />
                </Fragment>
            )}

            <List>
                <List.Item>
                    <List.Item.Column>
                        <List.Item.Title text="Id" />
                    </List.Item.Column>
                    <List.Item.Column.Right>
                        <List.Item.Title bold={false} text={user._id} />
                    </List.Item.Column.Right>
                </List.Item>
                <List.Item>
                    <List.Item.Column>
                        <List.Item.Title text="Full name" />
                    </List.Item.Column>
                    <List.Item.Column.Right>
                        <List.Item.Title bold={false} text={`${user.firstName} ${user.lastName}`} />
                    </List.Item.Column.Right>
                </List.Item>
                <List.Item>
                    <List.Item.Column>
                        <List.Item.Title text="Email address" />
                    </List.Item.Column>
                    <List.Item.Column.Right>
                        <List.Item.Title bold={false} text={user.email} />
                    </List.Item.Column.Right>
                </List.Item>
            </List>

            <Divider />

            <Button text="Edit my profile" link="/account/profile/edit" />
            <Button link="/logout" danger>
                Log out
            </Button>
        </Grid>
    );
};

export default withSnackbar(Account);
