import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { logoutUser } from '@actions/user';
import Loader from '@components/Loader';

const Logout = ({ navigate, logoutUser }) => {
    useEffect(() => {
        logoutUser();
        navigate('/');
    }, []);

    return <Loader tip="Logging out..." />;
};

const mapDispatchToProps = dispatch => bindActionCreators({ logoutUser }, dispatch);

export default connect(null, mapDispatchToProps)(Logout);
