import React, { Fragment, useEffect, useRef, useState } from 'react';
import Command from '@components/Command';
import { Col, Divider, Grid, Row } from '@components/Grid';
import { Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import Loader from '@components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';

import styles from './styles.module.scss';
import Tooltip from '@components/Tooltip';
import usePrevious from '@helpers/hooks/usePrevious';
import Section from '@views/Forms/Form/Section';
import { reorder } from '@helpers/index';
import {
    makeDeleteRequest,
    makeGetRequest,
    makePostRequest,
    makePutRequest,
} from '@helpers/requests';
import { FORM, FORMS } from '@helpers/api';
import { withSnackbar } from '@components/Snackbar';
import Card from '@components/Card';
import { useSelector } from 'react-redux';
import Alert from '@components/Alert';
import { navigate } from 'gatsby-link';
import Switch from '@components/Switch';

const Form = ({ openSnackbar, id }) => {
    const { user, website } = useSelector(({ user, website }) => ({ user, website }));
    const [loading, setLoading] = useState(true);
    const [initialForm, setInitialForm] = useState(null);
    const [creating, setCreating] = useState(!id);
    const [deleting, setDeleting] = useState(null);

    const handleDelete = async () => {
        try {
            setDeleting(true);
            await makeDeleteRequest(FORM(id));
            setDeleting(false);
            openSnackbar(`Successfully deleted ${initialForm?.name ?? 'this form'}.`);
            navigate(`/forms`);
        } catch (error) {
            if (error !== 'cancelled') {
                setDeleting(false);
                openSnackbar(
                    error?.errorMessage ??
                        `An error occurred attempting to delete ${initialForm?.name ??
                            'this form'}.`
                );
            }
        }
    };

    const handleSubmit = async ({ name, description, locked, sections }) => {
        try {
            const { data: formData } = creating
                ? await makePostRequest(FORMS, {
                      name,
                      description,
                      locked,
                      sections,
                  })
                : await makePutRequest(FORM(id), {
                      name,
                      description,
                      locked,
                      sections,
                  });

            openSnackbar(`Successfully ${creating ? 'created' : 'saved'} ${name}`);

            if (creating) {
                await navigate(`/forms/form/${formData?._id}`);
            }
        } catch (error) {
            error !== 'cancelled' &&
                openSnackbar(
                    error?.errorMessage ??
                        `An error occurred attempting to ${creating ? 'create' : 'save'} your form.`
                );
        }
    };

    useEffect(() => {
        (async () => {
            try {
                if (!id) {
                    const newSectionId = uuidv4();
                    const initialSection = {
                        id: newSectionId,
                        multiple: false,
                        title: undefined,
                        description: undefined,
                        min: undefined,
                        max: undefined,
                        fields: [
                            {
                                id: `${newSectionId}_${uuidv4()}`,
                                type: 'string',
                                label: '',
                                hint: undefined,
                                validation: undefined,
                                options: undefined,
                                placeholder: undefined,
                                optional: false,
                            },
                        ],
                    };

                    setInitialForm({
                        name: undefined,
                        description: undefined,
                        locked: false,
                        sections: [initialSection],
                    });
                    return;
                }

                const { data: formData } = await makeGetRequest(FORM(id));
                setInitialForm({
                    name: formData?.name,
                    description: formData?.description,
                    locked: formData?.locked,
                    sections: formData?.sections,
                });
            } catch (error) {
                error !== 'cancelled' &&
                    openSnackbar(
                        error?.errorMessage ?? 'An error occurred attempting to request this form.'
                    );
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    return (
        <Fragment>
            {loading && (
                <Command>
                    <Command.Breadcrumbs>
                        <Command.Breadcrumbs.Breadcrumb text="Forms" link="/forms" />
                        <Command.Breadcrumbs.Breadcrumb text="Form" />
                    </Command.Breadcrumbs>
                </Command>
            )}

            {loading ? (
                <Loader />
            ) : (
                <Formik
                    validationSchema={Yup.object().shape({
                        name: Yup.string().required('Name is required.'),
                        description: Yup.string().nullable(),
                        locked: Yup.boolean().required('Locked is required.'),
                        sections: Yup.array().of(
                            Yup.object().shape({
                                title: Yup.string().nullable(),
                                description: Yup.string().nullable(),
                                multiple: Yup.boolean().nullable(),
                                min: Yup.number().nullable(),
                                max: Yup.number().nullable(),
                                fields: Yup.array().of(
                                    Yup.object().shape({
                                        type: Yup.string()
                                            .oneOf([
                                                'string',
                                                'paragraph',
                                                'radio',
                                                'checkbox',
                                                'dropdown',
                                            ])
                                            .required('Type is required.'),
                                        label: Yup.string().required('Question is required.'),
                                        hint: Yup.string().nullable(),
                                        optional: Yup.boolean().nullable(),
                                        options: Yup.array()
                                            .of(
                                                Yup.object().shape({
                                                    label: Yup.string(),
                                                })
                                            )
                                            .nullable(),
                                        validation: Yup.mixed()
                                            .nullable()
                                            .notRequired()
                                            .when('type', {
                                                is: 'string',
                                                then: Yup.object()
                                                    .default(null)
                                                    .nullable()
                                                    .shape({
                                                        type: Yup.string()
                                                            .oneOf(['text'])
                                                            .required(
                                                                'Validation type is required.'
                                                            ),
                                                        condition: Yup.mixed()
                                                            .nullable()
                                                            .when('type', {
                                                                is: 'text',
                                                                then: Yup.string()
                                                                    .oneOf([
                                                                        'includes',
                                                                        'excludes',
                                                                        'email',
                                                                        'url',
                                                                    ])
                                                                    .required(
                                                                        'Validation condition is required.'
                                                                    ),
                                                            }),
                                                        value: Yup.mixed()
                                                            .nullable()
                                                            .when(
                                                                ['type', 'condition'],
                                                                (type, condition, schema) => {
                                                                    if (type === 'text') {
                                                                        return condition ===
                                                                            'includes' ||
                                                                            condition === 'excludes'
                                                                            ? Yup.string().required(
                                                                                  'Value is required.'
                                                                              )
                                                                            : schema;
                                                                    }

                                                                    return schema;
                                                                }
                                                            ),
                                                        error: Yup.string().required(
                                                            'Custom error text is required.'
                                                        ),
                                                    }),
                                            }),
                                    })
                                ),
                            })
                        ),
                    })}
                    onSubmit={handleSubmit}
                    initialValues={{
                        name: initialForm?.name ?? '',
                        description: initialForm?.description,
                        locked: initialForm?.locked ?? false,
                        sections: initialForm?.sections,
                    }}
                >
                    {({
                        values,
                        touched,
                        errors,
                        setFieldValue,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                    }) => {
                        const [focused, setFocused] = useState(initialForm?.sections[0]?.fields[0]);
                        const { name, description, locked, sections } = values;
                        const [detailsFocused, setDetailsFocused] = useState(null);
                        const $details = useRef(null);
                        const $items = [];
                        const [toolbarInitialised, setToolbarInitialised] = useState(false);
                        const [toolbarOffset, setToolbarOffset] = useState(null);
                        const prevToolbarOffset = usePrevious(toolbarOffset);

                        useEffect(() => {
                            if (!focused) return;
                            handleToolbarOffset();
                        }, [focused]);

                        useEffect(() => {
                            if (
                                !toolbarInitialised &&
                                prevToolbarOffset === null &&
                                toolbarOffset !== null
                            ) {
                                setToolbarInitialised(true);
                            }
                        }, [toolbarOffset]);

                        useEffect(() => {
                            if (detailsFocused) {
                                document.addEventListener('keydown', handleDetailsKeyDown);
                                document.addEventListener('mousedown', handleDetailsMouseDown);
                            }

                            if (!detailsFocused) {
                                document.removeEventListener('keydown', handleDetailsKeyDown);
                                document.removeEventListener('mousedown', handleDetailsMouseDown);
                            }

                            return () => {
                                document.removeEventListener('keydown', handleDetailsKeyDown);
                                document.removeEventListener('mousedown', handleDetailsMouseDown);
                            };
                        }, [detailsFocused]);

                        const handleDetailsKeyDown = e => {
                            const code = e.which || e.keyCode;

                            if (code === 27) {
                                setDetailsFocused(false);
                            }
                        };

                        const handleDetailsMouseDown = e => {
                            if (!$details.current) return;
                            if (
                                e.target === $details.current ||
                                $details.current.contains(e.target)
                            )
                                return;

                            setDetailsFocused(false);
                        };

                        const handleToolbarOffset = () => {
                            setToolbarOffset($items?.[focused._id || focused.id]?.offsetTop);
                        };

                        const isSameItem = (a, b) =>
                            (a?._id && b?._id && a?._id === b?._id) ||
                            (a?.id && b?.id && a?.id === b?.id);

                        const handleNewQuestion = () => {
                            setFieldValue(
                                'sections',
                                sections.map(section => {
                                    const isSectionFocused = isSameItem(section, focused);
                                    const isFieldFocused = section.fields.some(field =>
                                        isSameItem(field, focused)
                                    );

                                    if (isSectionFocused || isFieldFocused) {
                                        const newField = {
                                            id: `${section?.id}_${uuidv4()}`,
                                            type: 'string',
                                            label: '',
                                            validation: undefined,
                                            hint: undefined,
                                            options: undefined,
                                            placeholder: undefined,
                                            optional: false,
                                        };

                                        setFocused(newField);

                                        return {
                                            ...section,
                                            fields: isSectionFocused
                                                ? [newField, ...section.fields]
                                                : section.fields.reduce(
                                                      (acc, curr) =>
                                                          isSameItem(curr, focused)
                                                              ? [...acc, curr, newField]
                                                              : [...acc, curr],
                                                      []
                                                  ),
                                        };
                                    }

                                    return section;
                                })
                            );
                        };

                        const handleNewSection = () => {
                            const newSection = {
                                id: uuidv4(),
                                multiple: false,
                                title: undefined,
                                description: undefined,
                                min: undefined,
                                max: undefined,
                                fields: [],
                            };

                            setFieldValue(
                                'sections',
                                sections.reduce(
                                    (acc, curr) =>
                                        isSameItem(curr, focused) ||
                                        curr.fields.some(field => isSameItem(field, focused))
                                            ? [...acc, curr, newSection]
                                            : [...acc, curr],
                                    []
                                )
                            );
                            setFocused(newSection);
                        };

                        const handleSectionDuplicate = section => {
                            const newSection = {
                                ...section,
                                id: uuidv4(),
                                _id: undefined,
                            };

                            setFieldValue(
                                'sections',
                                sections.reduce(
                                    (acc, curr) =>
                                        isSameItem(curr, focused)
                                            ? [...acc, curr, newSection]
                                            : [...acc, curr],
                                    []
                                )
                            );

                            setFocused(newSection);
                        };

                        const handleSectionDelete = section => {
                            const newSections = sections.filter(
                                curr =>
                                    !(
                                        isSameItem(curr, section) ||
                                        curr.fields.some(field => isSameItem(field, section))
                                    )
                            );

                            setFieldValue('sections', newSections);
                            setFocused(newSections[0]);
                        };

                        const handleQuestionDelete = () => {
                            const section = sections.find(section =>
                                section.fields.some(field => isSameItem(field, focused))
                            );

                            const isFirstField = isSameItem(section.fields[0], focused);
                            let prevField = null;

                            if (!isFirstField) {
                                section.fields.forEach((field, i) => {
                                    if (isSameItem(field, focused)) {
                                        prevField = section.fields[i - 1];
                                    }
                                });
                            }

                            setFieldValue(
                                'sections',
                                sections.map(section => ({
                                    ...section,
                                    fields: [
                                        ...section.fields.filter(
                                            field => !isSameItem(field, focused)
                                        ),
                                    ],
                                }))
                            );

                            setFocused(isFirstField ? section : prevField);
                        };

                        const handleQuestionDuplicate = question => {
                            const newField = {
                                ...question,
                                id: uuidv4(),
                                _id: undefined,
                            };

                            setFieldValue(
                                'sections',
                                sections.map(section => ({
                                    ...section,
                                    fields: section.fields.reduce(
                                        (acc, curr) =>
                                            isSameItem(curr, focused)
                                                ? [...acc, curr, newField]
                                                : [...acc, curr],
                                        []
                                    ),
                                }))
                            );

                            setFocused(newField);
                        };

                        const handleQuestionChange = question => {
                            setFieldValue(
                                'sections',
                                sections.map(section => ({
                                    ...section,
                                    fields: section.fields.map(curr =>
                                        isSameItem(curr, question) ? question : curr
                                    ),
                                }))
                            );
                        };

                        const handleQuestionReorder = (questions, result) => {
                            // dropped outside the list
                            if (!result.destination) {
                                return;
                            }

                            const newFields = reorder(
                                questions,
                                result.source.index,
                                result.destination.index
                            );

                            setFieldValue(
                                'sections',
                                sections.map(section => {
                                    // work out what section contains the fields that have been reordered
                                    const isSection = section.fields.some(field =>
                                        newFields.some(newField => isSameItem(newField, field))
                                    );

                                    return isSection
                                        ? {
                                              ...section,
                                              fields: newFields,
                                          }
                                        : section;
                                })
                            );
                        };

                        const handleSectionChange = section => {
                            setFieldValue(
                                'sections',
                                sections.map(curr => (isSameItem(curr, section) ? section : curr))
                            );
                        };

                        return (
                            <Grid small>
                                <Command>
                                    <Command.Breadcrumbs>
                                        <Command.Breadcrumbs.Breadcrumb
                                            text="Forms"
                                            link="/forms"
                                        />
                                        <Command.Breadcrumbs.Breadcrumb
                                            text={initialForm?.name ?? 'Form'}
                                        />
                                    </Command.Breadcrumbs>

                                    <Command.Action
                                        icon={['fad', locked ? 'lock' : 'lock-open']}
                                        tooltip={locked ? 'Unlock' : 'Lock'}
                                        disabled={!user?.permissions?.isAdmin}
                                        onClick={
                                            !!user?.permissions?.isAdmin
                                                ? () => setFieldValue('locked', !locked)
                                                : undefined
                                        }
                                    />

                                    {!!user?.permissions?.isAdmin && !creating && (
                                        <Command.Action
                                            danger
                                            icon={['fad', 'trash']}
                                            tooltip="Delete"
                                            onClick={handleDelete}
                                        />
                                    )}

                                    <Command.Action
                                        text={creating ? 'Create' : 'Save'}
                                        icon="folder-upload"
                                        onClick={handleSubmit}
                                        submitting={isSubmitting}
                                        disabled={!user?.permissions?.isAdmin && locked}
                                    />
                                </Command>

                                <div className={styles.formGrid}>
                                    <div className={styles.form} key="form">
                                        <div>
                                            {locked && !user?.permissions?.isAdmin && (
                                                <Fragment>
                                                    <Alert
                                                        type="warning"
                                                        message={
                                                            <Fragment>
                                                                {`This form is locked, this means you can't edit this form in any way as it may cause issues with `}
                                                                <a
                                                                    href={website?.domain}
                                                                    target="_blank"
                                                                    rel="noopen noreferrer"
                                                                >
                                                                    {website?.domain}
                                                                </a>
                                                                {`, please contact our support team if you would like to make any amendments.`}
                                                            </Fragment>
                                                        }
                                                    />
                                                    <Divider />
                                                </Fragment>
                                            )}

                                            <div
                                                className={`${styles.formDetails} ${
                                                    detailsFocused ? styles.formDetailsFocused : ''
                                                }`}
                                                ref={$details}
                                                onClick={() => setDetailsFocused(true)}
                                            >
                                                <Card>
                                                    <Card.Title
                                                        name="name"
                                                        key={`${id}_card_title`}
                                                        editable={detailsFocused}
                                                        placeholder={'Untitled Form'}
                                                        value={name}
                                                        onChange={e =>
                                                            setFieldValue('name', e.target.value)
                                                        }
                                                        text={
                                                            name ||
                                                            (!detailsFocused && 'Untitled Form')
                                                        }
                                                    />
                                                    <Card.Description
                                                        key={`${id}_card_description`}
                                                        editable={detailsFocused}
                                                        placeholder="Form description"
                                                        value={description}
                                                        onChange={e =>
                                                            setFieldValue(
                                                                'description',
                                                                e.target.value
                                                            )
                                                        }
                                                        text={description || 'Untitled Description'}
                                                    />
                                                    {detailsFocused && (
                                                        <Card.Content>
                                                            <Switch
                                                                disabled={
                                                                    !user?.permissions?.isAdmin
                                                                }
                                                                label="Locked"
                                                                hint="Prevent users from editing this form"
                                                                checked={locked}
                                                                onChange={(e, checked) =>
                                                                    setFieldValue('locked', checked)
                                                                }
                                                            />
                                                        </Card.Content>
                                                    )}
                                                </Card>
                                            </div>

                                            {errors?.name && touched?.name && (
                                                <Fragment>
                                                    <Divider margin={2} />
                                                    <Alert type="error" message={errors?.name} />
                                                </Fragment>
                                            )}

                                            {errors?.description && touched?.description && (
                                                <Fragment>
                                                    <Divider margin={2} />
                                                    <Alert
                                                        type="error"
                                                        message={errors?.description}
                                                    />
                                                </Fragment>
                                            )}
                                        </div>

                                        {sections?.map((section, index) => (
                                            <Section
                                                key={section?._id || section?.id}
                                                handleChange={handleChange}
                                                setFieldValue={setFieldValue}
                                                errors={errors}
                                                touched={touched}
                                                total={sections.length}
                                                index={index}
                                                refs={$items}
                                                isSameItem={isSameItem}
                                                focused={isSameItem(section, focused)}
                                                currentFocused={focused}
                                                id={section?._id || section?.id}
                                                onSectionDuplicate={handleSectionDuplicate}
                                                onSectionDelete={handleSectionDelete}
                                                onSectionChange={handleSectionChange}
                                                onQuestionChange={handleQuestionChange}
                                                onQuestionDuplicate={handleQuestionDuplicate}
                                                onQuestionDelete={handleQuestionDelete}
                                                onQuestionReorder={handleQuestionReorder}
                                                onFocus={item => setFocused(item)}
                                                onHeightChange={handleToolbarOffset}
                                                {...section}
                                                section={section}
                                            />
                                        ))}
                                    </div>
                                    <div>
                                        {!!focused && (
                                            <ul
                                                className={`${styles.toolbar} ${
                                                    toolbarInitialised
                                                        ? styles.toolbarInitialised
                                                        : ''
                                                }`}
                                                style={{
                                                    transform: `translateY(${toolbarOffset}px)`,
                                                }}
                                            >
                                                <li
                                                    className={styles.toolbarItem}
                                                    onClick={handleNewQuestion}
                                                >
                                                    <Tooltip text="Add a question">
                                                        <FontAwesomeIcon icon={['fad', 'plus']} />
                                                    </Tooltip>
                                                </li>
                                                <li
                                                    className={styles.toolbarItem}
                                                    onClick={handleNewSection}
                                                >
                                                    <Tooltip text="Create new section">
                                                        <FontAwesomeIcon icon={['fad', 'equals']} />
                                                    </Tooltip>
                                                </li>
                                            </ul>
                                        )}
                                    </div>
                                </div>
                            </Grid>
                        );
                    }}
                </Formik>
            )}
        </Fragment>
    );
};

export default withSnackbar(Form);
