import React, { useState, Fragment } from 'react';
import { subcomponent } from '@helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from '@components/Button';

import styles from './styles.module.scss';

const Choices = ({ checkbox, radio, onChange, children, block }) => {
    const handleChange = ({ event, checked, id, ...data }) => {
        if (checkbox) handleCheckboxChange({ event, checked, id, ...data });
        if (radio) handleRadioChange({ event, id, ...data });
    };

    const handleCheckboxChange = ({ event, checked, id, ...data }) => {
        const activeCheckboxes = React.Children.map(children, child => {
            console.log({ child });
            return child.props.checked ? child.props.id : null;
        }).filter(x => !!x);

        console.log({ activeCheckboxes });

        if (checked) {
            activeCheckboxes.push(id);
        } else {
            const index = activeCheckboxes.indexOf(id);
            if (index > -1) {
                activeCheckboxes.splice(index, 1);
            }
        }

        onChange && onChange({ event, checked, id, active: activeCheckboxes, data });
    };

    const handleRadioChange = ({ event, id, ...data }) => {
        onChange && onChange({ event, id, data });
    };

    return (
        <div
            className={`${styles.choices} ${radio ? styles.choicesRadio : ''} ${
                checkbox ? styles.choicesCheckbox : ''
            } ${block ? styles.choicesBlock : ''}`}
        >
            {subcomponent(children, Choice, false, { onChange: handleChange, checkbox, radio })}
            {subcomponent(children, Create, true)}
        </div>
    );
};

const Choice = ({
    id,
    title,
    description,
    badge,
    onDelete,
    onEdit,
    checkbox,
    radio,
    checked,
    children,
    onChange,
    onClick,
    disabled,
    ...data
}) => {
    const content = subcomponent(children, Content, false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const handleClick = event => {
        onChange &&
            onChange({
                event,
                checked: radio ? true : !checked,
                id,
                title,
                description,
                badge,
                ...data,
            });
        onClick && onClick({ id, title, description, badge, ...data });
    };

    // allow consumer to plumb in array of checked ids and if this id matches set to checked automatically for them
    if (Array.isArray(checked)) {
        checked = checked.indexOf(id) > -1;
    }

    // if was not passed a boolean, but instead the checked id, we compare if this checked choice is the same as the id
    if (checked !== true && checked !== false) {
        checked = id === checked;
    }

    const choiceClasses = `${styles.choice} 
                           ${checked ? styles.choiceChecked : ''} ${
        checkbox ? styles.choiceCheckbox : ''
    } ${radio ? styles.choiceRadio : ''} 
                           ${onDelete ? styles.choiceDelete : ''} ${
        onEdit ? styles.choiceEdit : ''
    } ${disabled ? styles.choiceDisabled : ''}`;

    if (confirmDelete) {
        return (
            <div className={`${styles.choice} ${styles.choiceConfirmDelete}`}>
                <h3 className={styles.choiceTitle}>Are you sure you want to delete {title}?</h3>
                <div className={styles.choiceActions}>
                    <Action secondary onClick={() => setConfirmDelete(false)}>
                        Cancel
                    </Action>
                    <Action danger onClick={() => onDelete({ id, title, description, ...data })}>
                        Confirm
                    </Action>
                </div>
            </div>
        );
    }

    return (
        <div
            tabIndex={!disabled && (radio || checkbox) ? 0 : undefined}
            className={choiceClasses}
            onClick={!disabled && (radio || checkbox || onClick) ? handleClick : undefined}
        >
            {checkbox && checked ? <FontAwesomeIcon icon="check" /> : null}
            {radio && checked ? <FontAwesomeIcon icon="circle" /> : null}

            <div className={styles.choiceContent}>
                {badge && <span className={styles.choiceBadge}>{badge}</span>}
                {title && <h3 className={styles.choiceTitle}>{title}</h3>}
                {description && <p className={styles.choiceDescription}>{description}</p>}
                {content}
            </div>

            <div className={styles.choiceActions}>
                {subcomponent(children, Action, false, { id, ...data })}
                {onDelete && (
                    <Action danger onClick={() => setConfirmDelete(true)}>
                        Delete
                    </Action>
                )}
            </div>
        </div>
    );
};

const Content = ({ children }) => <div className={styles.choiceContentChildren}>{children}</div>;

const Create = ({ onCreate, title, ...data }) => {
    return (
        <div
            className={`${styles.choice} ${styles.choiceCreate}`}
            onClick={() => onCreate({ title, ...data })}
        >
            {<FontAwesomeIcon icon="plus" />}
            {title && <h3 className={styles.choiceTitle}>{title}</h3>}
        </div>
    );
};

const Action = ({ children, onClick, id, ...data }) => (
    <div>
        <Button
            className={styles.choiceAction}
            transparent
            onClick={
                onClick
                    ? event => {
                          event.stopPropagation();
                          onClick({ event, id, ...data });
                      }
                    : undefined
            }
            {...data}
            small
        >
            {children}
        </Button>
    </div>
);

Choice.Content = Content;
Content.displayName = 'Content';

Choices.Create = Create;
Create.displayName = 'Create';

Choices.Choice = Choice;
Choice.displayName = 'Choice';

Choice.Action = Action;
Action.displayName = 'Action';

export default Choices;
