import React from 'react';
import styles from '../styles.module.scss';

import Card from '@components/Card';
import { navigate } from 'gatsby-link';
import queryString from 'query-string';

const ListField = ({
    website,
    field: { type, label, hint, optional, min, max, id, path, multiple },
    activeField,
    value,
    touched,
    error,
    handleChange,
    setFieldValue,
    formatPath,
}) => {
    const active = path.length === 1 && activeField?.path[0] === path[0];

    const handleListClick = () => {
        let newPath;

        if (
            !activeField ||
            path.length > activeField.path.length ||
            path[0] !== activeField.path[0]
        ) {
            newPath = path;
        }

        navigate(
            `/editor?${queryString.stringify({
                ...queryString.parse(location.search),
                path: newPath,
            })}`
        );
    };

    return (
        <Card className={`${styles.fieldList} ${active ? styles.fieldListActive : ''}`}>
            <Card.List>
                <Card.List.Item title={label} description={hint} onClick={handleListClick}>
                    <Card.List.Item.Action
                        icon={
                            !!activeField && path.length === 1 && activeField.path[0] === path[0]
                                ? 'angle-left'
                                : 'angle-right'
                        }
                        iconSize={1.3}
                        onClick={handleListClick}
                    />
                </Card.List.Item>
            </Card.List>
        </Card>
    );
};

export default ListField;
