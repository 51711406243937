import React from 'react';
import { Link } from 'gatsby';
import { Location } from '@reach/router';

import styles from './styles.module.scss';
import { subcomponent } from '@helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Breadcrumbs = ({ children }) => (
    <ul className={styles.breadcrumbs}>{subcomponent(children, Breadcrumb)}</ul>
);

const Breadcrumb = ({ text, link }) => {
    return (
        <li className={styles.breadcrumbsBreadcrumb}>
            {!!link ? <Link to={link}>{text}</Link> : <span>{text}</span>}
            <FontAwesomeIcon icon="chevron-right" />
        </li>
    );
};

Breadcrumbs.Breadcrumb = Breadcrumb;
Breadcrumb.displayName = 'Breadcrumb';

export default Breadcrumbs;
