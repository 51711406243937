import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';

import Header from './DatepickerHeader';
import Title from './DatepickerTitle';
import Labels from './DatepickerLabels';
import Days from './DatepickerDays';
import Prev from './DatepickerPrev';
import Next from './DatepickerNext';

import styles from '../styles.module.scss';

class Datepicker extends React.Component {
    constructor(props) {
        super(props);
        this.handlePrevMonthDisabled = this.handlePrevMonthDisabled.bind(this);
        this.handleNextMonthDisabled = this.handleNextMonthDisabled.bind(this);

        this.state = {
            currentYear: moment(this.props.date).year(),
            currentMonth: moment(this.props.date).month(),
        };
    }

    handleChange({ day, month, year }) {
        const date = moment(`${day}-${month + 1}-${year}`, 'D-M-YYYY');

        // callback to parent component if given
        if (this.props.onChange) {
            this.props.onChange({ date });
        }
    }

    handlePrevMonth() {
        const date = this.getDate().subtract(1, 'months');
        const month = date.month();
        const year = date.year();

        this.setState({
            currentMonth: month,
            currentYear: year,
        });

        if (this.props.onPrevMonthChange) {
            this.props.onPrevMonthChange(month, year);
        }
    }

    getDate(day = null) {
        return moment(
            `${day !== null ? day : '01'}-${this.state.currentMonth + 1}-${this.state.currentYear}`,
            'D-M-YYYY'
        );
    }

    handleNextMonth() {
        const date = this.getDate().add(1, 'months');
        const month = date.month();
        const year = date.year();

        this.setState({
            currentMonth: month,
            currentYear: year,
        });

        if (this.props.onNextMonthChange) {
            this.props.onNextMonthChange(month, year);
        }
    }

    handlePrevMonthDisabled() {
        // prev month is less or equal to minDate
        if (
            this.props.minDate &&
            this.getDate()
                .subtract(1, 'months')
                .endOf('month')
                .isBefore(moment(this.props.minDate))
        )
            return true;

        return false;
    }

    handleNextMonthDisabled() {
        // prev month is less or equal to minDate
        if (
            this.props.maxDate &&
            this.getDate()
                .add(1, 'months')
                .startOf('month')
                .isAfter(moment(this.props.maxDate))
        )
            return true;

        return false;
    }

    render() {
        return (
            <div
                className={`${styles.datepicker} ${this.props.open ? styles.datepickerOpen : ''}`}
                tabIndex={0}
            >
                <Header>
                    <Prev
                        disabled={this.handlePrevMonthDisabled()}
                        onPrev={this.handlePrevMonth.bind(this)}
                    />
                    <Title year={this.state.currentYear} month={this.state.currentMonth} />
                    <Next
                        disabled={this.handleNextMonthDisabled()}
                        onNext={this.handleNextMonth.bind(this)}
                    />
                </Header>
                <Labels />
                <Days
                    onChange={this.handleChange.bind(this)}
                    onEscape={this.props.onEscape}
                    disabledDates={this.props.disabledDates}
                    active={[this.props.date]}
                    year={this.state.currentYear}
                    month={this.state.currentMonth}
                    minDate={this.props.minDate}
                    maxDate={this.props.maxDate}
                />
            </div>
        );
    }
}

Datepicker.propTypes = {
    date: momentPropTypes.momentObj,
    minDate: momentPropTypes.momentObj,
    maxDate: momentPropTypes.momentObj,
    onChange: PropTypes.func,
    onNextMonthChange: PropTypes.func,
    onPrevMonthChange: PropTypes.func,
    onEscape: PropTypes.func,
    disabledDates: PropTypes.arrayOf(
        PropTypes.oneOfType([momentPropTypes.momentObj, PropTypes.string])
    ),
};

Datepicker.defaultProps = {
    date: moment(new Date()).format('Do MMMM Y'),
};

export default Datepicker;
