import React, { useEffect } from 'react';
import { navigate } from 'gatsby-link';
import { useDispatch, useSelector } from 'react-redux';

import { updateUser, loginUser } from '@actions/user';
import { makePostRequest } from '@helpers/requests';
import { USER_EMAIL_CHANGE_COMPLETE } from '@helpers/api';
import { getUserFromToken } from '@helpers/auth';
import Loader from '@components/Loader';
import Layout from '@components/Layout';
import { withSnackbar } from '@components/Snackbar';
import queryString from 'query-string';

const ChangeEmail = ({ openSnackbar, location }) => {
    const params = queryString.parse(location.search);
    const code = params?.code;
    const userId = params?.id;
    const email = params?.email;
    const user = useSelector(({ user }) => user);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!code || !userId || !email) {
            navigate('/');
            return;
        }

        (async () => {
            try {
                const { data: tokens } = await makePostRequest(USER_EMAIL_CHANGE_COMPLETE, {
                    code,
                    id: userId,
                    currentEmail: email,
                });

                if (user) {
                    // gets user obj from new tokens, run updateUser or loginUser passing the user and tokens
                    const data = await getUserFromToken(tokens);
                    dispatch(updateUser(data));
                } else {
                    dispatch(loginUser(tokens));
                }

                navigate('/account/overview');
                openSnackbar('Successfully changed email address');
            } catch (error) {
                navigate('/');
                error !== 'cancelled' &&
                    openSnackbar(
                        error?.errorMessage ??
                            'An error occurred when attempting to change your email.'
                    );
            }
        })();
    }, []);

    return (
        <Layout>
            <Loader tip="Changing your email address..." />
        </Layout>
    );
};

export default withSnackbar(ChangeEmail);
