import React, { useEffect, useState, Fragment } from 'react';

import { makeGetRequest } from '@helpers/requests';
import { FORM_SUBMISSION } from '@helpers/api';
import Command from '@components/Command';
import { Grid, Row, Col, Divider } from '@components/Grid';
import Card from '@components/Card';
import Loader from '@components/Loader';
import { format, parseISO } from 'date-fns';
import { withSnackbar } from '@components/Snackbar';
import List from '@components/List';
import Text from '@components/Text';

const Submission = ({ id, openSnackbar }) => {
    const [loading, setLoading] = useState(true);
    const [submission, setSubmission] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                const { data: submissionData } = await makeGetRequest(FORM_SUBMISSION(id));
                setSubmission(submissionData);
                setLoading(false);
            } catch (error) {
                error !== 'cancelled' &&
                    openSnackbar(
                        error?.errorMessage ?? 'An error occurred when loading this submission.'
                    );
            }
        })();
    }, []);

    const renderValue = value => {
        if (Array.isArray(value)) {
            return (
                <ul>
                    {value.map(item => (
                        <li>{item?.label}</li>
                    ))}
                </ul>
            );
        }

        return value?.label ?? value;
    };

    const renderFields = fields => (
        <Fragment>
            {fields?.map(field => (
                <List.Item key={field?._id}>
                    <List.Item.Column>
                        <Text bold>{field?.label}</Text>
                        <Text wrap empty={typeof field?.value === 'undefined' || field?.value === null}>
                            {renderValue(field?.value)}
                        </Text>
                    </List.Item.Column>
                </List.Item>
            ))}
        </Fragment>
    );

    return (
        <Fragment>
            <Command>
                <Command.Breadcrumbs>
                    <Command.Breadcrumbs.Breadcrumb text="Forms" link="/forms" />
                    <Command.Breadcrumbs.Breadcrumb text="Submissions" link="/forms/submissions" />
                    <Command.Breadcrumbs.Breadcrumb text="Submission" />
                </Command.Breadcrumbs>
            </Command>
            {loading ? (
                <Loader />
            ) : (
                <Grid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Content>
                                    <List>
                                        <List.Item>
                                            <List.Item.Column>
                                                <Text bold>Id</Text>
                                            </List.Item.Column>
                                            <List.Item.Column.Right important>
                                                <Text>{submission?._id}</Text>
                                            </List.Item.Column.Right>
                                        </List.Item>
                                        <List.Item>
                                            <List.Item.Column>
                                                <Text bold>Date</Text>
                                            </List.Item.Column>
                                            <List.Item.Column.Right important>
                                                <Text>
                                                    {!!submission?.meta?.timestamp &&
                                                        format(
                                                            parseISO(submission?.meta?.timestamp),
                                                            'PPP p'
                                                        )}
                                                </Text>
                                            </List.Item.Column.Right>
                                        </List.Item>
                                        <List.Item>
                                            <List.Item.Column>
                                                <Text bold>Form</Text>
                                            </List.Item.Column>
                                            <List.Item.Column.Right important>
                                                <Text>{submission?.form?.name}</Text>
                                            </List.Item.Column.Right>
                                        </List.Item>
                                    </List>
                                </Card.Content>
                                <Card.Actions>
                                    <Card.Actions.Action
                                        text={`View ${submission?.form?.name}`}
                                        link={`/forms/form/${submission?.form?._id}`}
                                    />
                                </Card.Actions>
                            </Card>

                            <Divider />

                            {!!submission?.sections && <h2>Response</h2>}
                            {submission?.sections?.map((section, sectionIndex) => (
                                <Fragment key={section?._id}>
                                    {section?.multiple ? (
                                        section?.fields?.map((fields, fieldIndex) => (
                                            <Fragment>
                                                {!!sectionIndex && <Divider />}
                                                <List>
                                                    <List.Title
                                                        text={`${section?.title} #${fieldIndex +
                                                            1}`}
                                                    />
                                                    {renderFields(fields)}
                                                </List>
                                            </Fragment>
                                        ))
                                    ) : (
                                        <Fragment>
                                            {!!sectionIndex && <Divider />}
                                            <List>
                                                {!!section?.title && (
                                                    <List.Title text={section?.title} />
                                                )}
                                                {renderFields(section?.fields)}
                                            </List>
                                        </Fragment>
                                    )}
                                </Fragment>
                            ))}

                            {!!submission?.ua && (
                                <Fragment>
                                    <Divider />
                                    <Card>
                                        <Card.Title>User agent</Card.Title>
                                        <Card.Content>
                                            <List>
                                                <List.Item>
                                                    <List.Item.Column>
                                                        <List.Item.Title text="Mobile" />
                                                    </List.Item.Column>
                                                    <List.Item.Column.Right important>
                                                        <List.Item.Checked
                                                            checked={
                                                                submission?.ua.isMobile === 'true'
                                                            }
                                                        />
                                                    </List.Item.Column.Right>
                                                </List.Item>
                                                <List.Item>
                                                    <List.Item.Column>
                                                        <List.Item.Title text="Desktop" />
                                                    </List.Item.Column>
                                                    <List.Item.Column.Right important>
                                                        <List.Item.Checked
                                                            checked={
                                                                submission?.ua.isDesktop === 'true'
                                                            }
                                                        />
                                                    </List.Item.Column.Right>
                                                </List.Item>
                                                <List.Item>
                                                    <List.Item.Column>
                                                        <List.Item.Title text="Bot" />
                                                    </List.Item.Column>
                                                    <List.Item.Column.Right important>
                                                        <List.Item.Checked
                                                            checked={
                                                                submission?.ua.isBot === 'true'
                                                            }
                                                        />
                                                    </List.Item.Column.Right>
                                                </List.Item>
                                                <List.Item>
                                                    <List.Item.Column>
                                                        <List.Item.Title text="Browser" />
                                                    </List.Item.Column>
                                                    <List.Item.Column.Right important>
                                                        <List.Item.Checked
                                                            checked={
                                                                submission?.ua.browser === 'true'
                                                            }
                                                        />
                                                    </List.Item.Column.Right>
                                                </List.Item>
                                                <List.Item>
                                                    <List.Item.Column>
                                                        <List.Item.Title text="Browser Version" />
                                                    </List.Item.Column>
                                                    <List.Item.Column.Right important>
                                                        <List.Item.Title
                                                            small
                                                            bold={false}
                                                            text={submission?.ua.version}
                                                        />
                                                    </List.Item.Column.Right>
                                                </List.Item>
                                                <List.Item>
                                                    <List.Item.Column>
                                                        <List.Item.Title text="OS" />
                                                    </List.Item.Column>
                                                    <List.Item.Column.Right important>
                                                        <List.Item.Title
                                                            small
                                                            bold={false}
                                                            text={submission?.ua.os}
                                                        />
                                                    </List.Item.Column.Right>
                                                </List.Item>
                                                <List.Item>
                                                    <List.Item.Column>
                                                        <List.Item.Title text="Platform" />
                                                    </List.Item.Column>
                                                    <List.Item.Column.Right important>
                                                        <List.Item.Title
                                                            small
                                                            bold={false}
                                                            text={submission?.ua.platform}
                                                        />
                                                    </List.Item.Column.Right>
                                                </List.Item>
                                                <List.Item>
                                                    <List.Item.Column>
                                                        <List.Item.Title text="Source" />
                                                    </List.Item.Column>
                                                    <List.Item.Column.Right important>
                                                        <List.Item.Title
                                                            small
                                                            bold={false}
                                                            text={submission?.ua.source}
                                                        />
                                                    </List.Item.Column.Right>
                                                </List.Item>
                                            </List>
                                        </Card.Content>
                                    </Card>
                                </Fragment>
                            )}
                        </Col>
                    </Row>
                </Grid>
            )}
        </Fragment>
    );
};

export default withSnackbar(Submission);
