import React, { useRef, useState } from 'react';
import { Col, Grid, Row } from '@components/Grid';

import Logo from '@images/logo-white-icon.svg';
import { useSelector } from 'react-redux';
import { Link } from 'gatsby';
import styles from './styles.module.scss';
import Dropdown from '@components/Dropdown';

const Menu = ({ routes }) => {
    const $avatar = useRef(null);
    const { user } = useSelector(({ user }) => ({ user }));
    const [userDropdownOpen, setUserDropdownOpen] = useState(false);

    const routeMatches = ({ match, children }) => {
        if (match) return true;

        if (children) {
            if (children.some(routeMatches)) return true;
        }
    };

    return (
        <header className={styles.menu}>
            <Grid small>
                <Row>
                    <Col xsGrow>
                        <Link to="/websites" className={styles.menuLogo} aria-label="Logo">
                            <Logo />
                        </Link>
                    </Col>
                    {!!user && (
                        <Col xsShrink>
                            <div
                                ref={$avatar}
                                className={styles.menuAvatar}
                                onClick={() => setUserDropdownOpen(!userDropdownOpen)}
                            >
                                <img alt="Avatar" src={user?.avatar} />
                            </div>

                            {userDropdownOpen && (
                                <Dropdown
                                    target={$avatar}
                                    open={userDropdownOpen}
                                    onEscape={() => setUserDropdownOpen(false)}
                                >
                                    <Dropdown.Item
                                        text={`${user?.firstName} ${user?.lastName}`}
                                        description={user?.email}
                                    />
                                    <Dropdown.Item text="Profile" link="/account/profile" />
                                    <Dropdown.Item text="Logout" link="/logout" />
                                </Dropdown>
                            )}
                        </Col>
                    )}
                </Row>
            </Grid>

            <hr />

            {!!routes && !!routes.length && (
                <Grid small noPadding>
                    <ul className={styles.menuNavigation}>
                        {routes
                            .filter(({ hidden }) => !hidden)
                            .map(({ text, path, disabled, getProps, match, children }) => {
                                const className = `${styles.menuNavigationItemLink} ${
                                    disabled ? styles.menuNavigationItemLinkDisabled : ''
                                }`;

                                return (
                                    <li
                                        key={`${text}_${path}`}
                                        className={styles.menuNavigationItem}
                                    >
                                        <Link
                                            to={path}
                                            getProps={props => {
                                                const pathProps =
                                                    (getProps && getProps(props)) || {};

                                                return {
                                                    ...pathProps,
                                                    className: `${className} ${pathProps?.className ??
                                                        ''} ${
                                                        routeMatches({ match, children })
                                                            ? `${styles.menuNavigationItemLinkActive}`
                                                            : ''
                                                    }`,
                                                };
                                            }}
                                        >
                                            <span>{text}</span>
                                        </Link>
                                    </li>
                                );
                            })}
                    </ul>
                </Grid>
            )}
        </header>
    );
};

export default Menu;
