import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Grid } from '@components/Grid';

const Editor = ({ user, website }) => {
    const [loading, setLoading] = useState(true);
    // const [pages, setPages] = useState([]);

    useEffect(() => {
    }, []);

    // if (loading) return <Loader />;

    return (
        <Grid>
            <h1>Inbox</h1>
        </Grid>
    );
};

export default connect(({ user, website }) => ({ user, website }))(Editor);
