import React, { Fragment } from 'react';

import { HeightContext } from '@helpers/contexts';
import { Grid } from '@components/Grid';
import SEO from '@components/SEO';

const NotFoundPage = () => (
    <HeightContext.Consumer>
        {({ height } = {}) => (
            <Grid fullHeight noMargin style={{ height }}>
                <SEO title={'404'} />
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '90vh',
                        flexDirection: 'column',
                    }}
                >
                    <h1>NOT FOUND</h1>
                    <p>You just hit a route that doesn't exist... the sadness.</p>
                </div>
            </Grid>
        )}
    </HeightContext.Consumer>
);

export default NotFoundPage;
