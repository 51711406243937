import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

const Wrapper = ({ types, nested, field, onSubmit, onDelete, children }) => {
	const [collectionId] = useState(nested ? uuidv4() : undefined);

	const fieldSchema = {
		collectionId: Yup.string().nullable(),
		id: Yup.string().nullable(),
		label: Yup.string().required('Label is required.'),
		hint: Yup.string().required('Hint is required.'),
		type: Yup.string().required('Type is required.'),
		options: Yup.array(Yup.object())
			.nullable()
			.when('type', (type, schema) =>
				type === 'select' || type === 'checkbox' || type === 'radio'
					? Yup.array(
							Yup.object().shape({
								_id: Yup.string().nullable(),
								label: Yup.string(),
							})
					  ).required('Options are required.')
					: schema
			),
		multiple: Yup.boolean()
			.oneOf([false], `Multiple isn't possible with this field type.`)
			.nullable()
			.when('type', (type, schema) =>
				types?.find(({ value }) => value === type)?.supports?.multiple
					? Yup.boolean()
							.oneOf([false, true])
							.nullable()
					: schema
			),
		optional: Yup.boolean(),
		disabled: Yup.boolean(),
		...(nested
			? {}
			: {
					accountTypes: Yup.array(Yup.string())
						.nullable()
						.when('globalApplicability', {
							is: false,
							then: Yup.array(Yup.string()),
						}),
					globalApplicability: Yup.boolean(),
			  }),
	};

	return (
		<Formik
			enableReinitialize
			initialValues={{
				collectionId: nested
					? field?._id || field?.collectionId || collectionId
					: undefined,
				id: field?.id,
				label: field?.label,
				type: field?.type,
				hint: field?.hint,
				disabled: field?.disabled,
				optional: field?.optional,
				options: field?.options?.length ? field?.options : undefined,
				items: field?.items?.length ? field?.items : undefined,
				...(nested
					? {}
					: {
							accountTypes: field?.accountTypes?.map(({ _id }) => _id),
							globalApplicability: !field?.accountTypes?.length,
					  }),
				multiple: field?.multiple,
			}}
			validationSchema={Yup.object().shape({
				...fieldSchema,
				items: Yup.array(Yup.object())
					.nullable()
					.when('type', (type, schema) =>
						type === 'list'
							? Yup.array(Yup.object().shape(fieldSchema)).required(
									'Items are required.'
							  )
							: schema
					),
			})}
			onSubmit={onSubmit}
		>
			{formikProps => {
				const props = {
					field,
					nested,
					onDelete,
				};

				return children({ ...formikProps, ...props });
			}}
		</Formik>
	);
};

export default Wrapper;
