import React, { Fragment, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import moment from 'moment';

import Input from '@components/Input';
import Dropdown from '@components/Dropdown';

import Datepicker from './DatepickerComponents/DatepickerCalendar';
import DatepickerRange from './DatepickerComponents/DatepickerRangeCalendar';

const DatepickerInput = ({
    startDatePlaceholder = 'Start date',
    endDatePlaceholder = 'End Date',
    startDate,
    endDate,
    datePlaceholder = 'Date',
    date,
    minDate = new Date(),
    maxDate,
    onChange,
    onNextMonthChange,
    onPrevMonthChange,
    onEscape,
    range = false,
    minDays,
    maxDays,
    disabledDates = [],

    onRemove,
    label,
    noMarginBottom = false,
    hint,
    optional,
    fullWidth = false,
    inline = false,
    name = 'date',
    minWidth,
}) => {
    const [focused, setFocused] = useState(false);
    let $input = useRef(null);
    let datepicker = null;

    const value = range
        ? (startDate &&
              endDate &&
              `${startDate ? moment(startDate).format('D MMM') : ''} - ${
                  endDate ? moment(endDate).format('D MMM') : ''
              }`) ||
          ''
        : `${date ? moment(date).format('Do MMMM Y') : ''}`;
    const handleEscape = e => {
        if (e && e.target && (e.target === $input.current || $input.current.contains(e.target)))
            return;
        setFocused(false);

        if (onEscape) {
            onEscape();
        }
    };
    const handleChange = ({ date, startDate, endDate }) => {
        // only close the datepicker when a date is given or both a start and end date was set
        if (date || (startDate && endDate)) {
            $input.current.focus();
            setFocused(false);
        }

        if (onChange) {
            onChange({
                date: date ? moment(date).toDate() : undefined,
                startDate: startDate ? moment(startDate).toDate() : undefined,
                endDate: endDate ? moment(endDate).toDate() : undefined,
            });
        }
    };
    const handleKeyDown = e => {
        const code = e.which || e.keyCode;

        // on spacebar, open datepicker
        if (code === 32) {
            setFocused(true);
        }
    };

    if (focused) {
        // if range is true create range datepicker, otherwise if false (default) create normal datepicker
        datepicker = (
            <Dropdown width="auto" target={$input} onEscape={handleEscape}>
                {range ? (
                    <DatepickerRange
                        startDate={
                            startDate && startDate instanceof moment
                                ? startDate
                                : (startDate && moment(startDate)) || undefined
                        }
                        endDate={
                            endDate && endDate instanceof moment
                                ? endDate
                                : (endDate && moment(endDate)) || undefined
                        }
                        minDate={
                            minDate && minDate instanceof moment
                                ? minDate
                                : (minDate && moment(minDate)) || undefined
                        }
                        maxDate={
                            maxDate && maxDate instanceof moment
                                ? maxDate
                                : (maxDate && moment(maxDate)) || undefined
                        }
                        onPrevMonthChange={onPrevMonthChange}
                        onNextMonthChange={onNextMonthChange}
                        onChange={handleChange}
                        minDays={minDays}
                        maxDays={maxDays}
                        disabledDates={
                            disabledDates &&
                            Array.isArray(disabledDates) &&
                            disabledDates
                                .map(disabledDate =>
                                    disabledDate instanceof moment
                                        ? disabledDate
                                        : (disabledDate && moment(disabledDate)) || undefined
                                )
                                .filter(x => !!x)
                        }
                    />
                ) : (
                    <Datepicker
                        date={date && date instanceof moment ? date : moment(date) || undefined}
                        minDate={
                            minDate && minDate instanceof moment
                                ? minDate
                                : (minDate && moment(minDate)) || undefined
                        }
                        maxDate={
                            maxDate && maxDate instanceof moment
                                ? maxDate
                                : (maxDate && moment(maxDate)) || undefined
                        }
                        onPrevMonthChange={onPrevMonthChange}
                        onNextMonthChange={onNextMonthChange}
                        onChange={handleChange}
                        disabledDates={
                            disabledDates &&
                            Array.isArray(disabledDates) &&
                            disabledDates
                                .map(disabledDate =>
                                    disabledDate instanceof moment
                                        ? disabledDate
                                        : (disabledDate && moment(disabledDate)) || undefined
                                )
                                .filter(x => !!x)
                        }
                    />
                )}
            </Dropdown>
        );
    }

    return (
        <Fragment>
            <Input
                label={label}
                hint={hint}
                optional={optional}
                ref={$input}
                value={value}
                placeholder={
                    range ? `${startDatePlaceholder} - ${endDatePlaceholder}` : datePlaceholder
                }
                name={name}
                icon={!!date || !!startDate || !!endDate ? 'times' : 'calendar'}
                onIconClick={
                    (!!date || !!startDate || !!endDate) && !!onRemove
                        ? onRemove
                        : () => setFocused(true)
                }
                focused={focused}
                onClick={() => setFocused(true)}
                onEnter={() => setFocused(true)}
                onKeyDown={handleKeyDown}
                onEscape={() => setFocused(false)}
            />

            {datepicker}
        </Fragment>
    );
};

DatepickerInput.propTypes = {
    // datepicker props
    startDatePlaceholder: PropTypes.string,
    endDatePlaceholder: PropTypes.string,
    startDate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
        momentPropTypes.momentObj,
    ]),
    endDate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
        momentPropTypes.momentObj,
    ]),
    datePlaceholder: PropTypes.string,
    date: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
        momentPropTypes.momentObj,
    ]),
    minDate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
        momentPropTypes.momentObj,
    ]),
    maxDate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
        momentPropTypes.momentObj,
    ]),
    minDays: PropTypes.number,
    maxDays: PropTypes.number,
    onChange: PropTypes.func,
    onNextMonthChange: PropTypes.func,
    onPrevMonthChange: PropTypes.func,
    onEscape: PropTypes.func,
    range: PropTypes.bool,
    disabledDates: PropTypes.arrayOf(
        PropTypes.oneOfType([momentPropTypes.momentObj, PropTypes.string])
    ),

    // input props
    label: PropTypes.string,
    hint: PropTypes.string,
    fullWidth: PropTypes.bool,
    inline: PropTypes.bool,
    name: PropTypes.string,
    minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default DatepickerInput;
