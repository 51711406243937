import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';

import Header from './DatepickerHeader';
import Title from './DatepickerTitle';
import Days from './DatepickerDays';
import Prev from './DatepickerPrev';
import Next from './DatepickerNext';
import Labels from './DatepickerLabels';

import styles from '../styles.module.scss';

class DatepickerRange extends React.Component {
    constructor(props) {
        super(props);
        this.handlePrevMonthDisabled = this.handlePrevMonthDisabled.bind(this);
        this.handleNextMonthDisabled = this.handleNextMonthDisabled.bind(this);

        this.state = {
            currentYear: moment(this.props.startDate || new Date()).year(),
            currentMonth: moment(this.props.startDate || new Date()).month(),
            hoveredYear: null,
            hoveredMonth: null,
            hoveredDay: null,
        };
    }

    handleChange({ day, month, year }) {
        const date = moment(`${day}-${month + 1}-${year}`, 'D-M-YYYY');

        // if has startDate and the startDate is before a disabledDate and the selected date is after the disabled date
        // this prevents creating a range over a disabled date inbetween, and instead forces the new selected date as a
        // startDate instead of an endDate
        const isAfterDisabledDate =
            this.props.startDate &&
            this.props.disabledDates.some(
                disabledDate =>
                    disabledDate.isAfter(this.props.startDate) && date.isAfter(disabledDate)
            );

        if (!this.props.onChange) return;

        // if both have been declared already,
        // or startDate was declared
        // and the new selected date is before the startDate,
        // or the a disabled date is after the startDate
        // and the selected date is after the disabled date
        // reset endDate and set selected date as the start date
        if (
            !this.props.startDate ||
            (this.props.startDate && this.props.endDate) ||
            (this.props.startDate && (date.isBefore(this.props.startDate) || isAfterDisabledDate))
        ) {
            this.props.onChange({ startDate: date, endDate: null });
            return;
        }

        // if user selects same day for both start date and end date
        if (this.props.startDate && this.props.startDate.isSame(date)) {
            const endDate = date.add(1, 'days');
            // if new end date is a disabled date or past maxDate, don't conveniently set it to next day for them
            if (
                (this.props.disabledDates &&
                    Array.isArray(this.props.disabledDates) &&
                    this.props.disabledDates.some(disabledDate => disabledDate.isSame(endDate))) ||
                (this.props.maxDate && this.props.maxDate.isSame(endDate))
            ) {
                this.props.onChange({ startDate: this.props.startDate, endDate: null });
                return;
            }
            // else force end date to become next day
            this.props.onChange({ startDate: this.props.startDate, endDate: date });
            return;
        }

        // if the selected date is after the start date, set selected date as the end date
        this.props.onChange({ startDate: this.props.startDate, endDate: date });
    }

    handleHover({ day, month, year }) {
        this.setState({ hoveredDay: day, hoveredMonth: month, hoveredYear: year });
    }

    handleHoverEnd() {
        this.setState({ hoveredDay: null, hoveredMonth: null, hoveredYear: null });
    }

    // get the current month
    getStartDate(day = null) {
        return moment(
            `${day !== null ? day : '01'}-${this.state.currentMonth + 1}-${this.state.currentYear}`,
            'D-M-YYYY'
        );
    }

    // get start date and add 1 month to it, and todays day (because setting it initially would break certain months i.e. 30 in Feburary!)
    getEndDate(day = null) {
        const date = this.getStartDate();
        date.add(1, 'months');

        if (day) {
            date.set('date', day);
        }

        return date;
    }

    handlePrevMonth() {
        const date = this.getStartDate().subtract(2, 'months');
        const month = date.month();
        const year = date.year();

        this.setState(
            {
                currentMonth: month,
                currentYear: year,
            },
            () => {
                if (this.props.onPrevMonthChange) {
                    const endDate = this.getEndDate();
                    this.props.onPrevMonthChange({
                        startMonth: date.month(),
                        startYear: date.year(),
                        endMonth: endDate.month(),
                        endYear: endDate.year(),
                    });
                }
            }
        );
    }

    handleNextMonth() {
        const date = this.getStartDate().add(2, 'months');
        const month = date.month();
        const year = date.year();

        this.setState(
            {
                currentMonth: month,
                currentYear: year,
            },
            () => {
                if (this.props.onNextMonthChange) {
                    this.props.onNextMonthChange({
                        startMonth: date.month(),
                        startYear: date.year(),
                        endMonth: this.getEndDate().month(),
                        endYear: this.getEndDate().year(),
                    });
                }
            }
        );
    }

    handlePrevMonthDisabled() {
        // prev month is less or equal to minDate
        if (
            this.props.minDate &&
            this.getStartDate()
                .subtract(1, 'months')
                .endOf('month')
                .isBefore(moment(this.props.minDate))
        )
            return true;

        return false;
    }

    handleNextMonthDisabled() {
        // prev month is less or equal to minDate
        if (
            this.props.maxDate &&
            this.getEndDate()
                .add(1, 'months')
                .startOf('month')
                .isAfter(moment(this.props.maxDate))
        )
            return true;

        return false;
    }

    render() {
        const startDate = this.getStartDate();
        const endDate = this.getEndDate();

        return (
            <ul
                className={`${styles.datepicker} ${syles.datepickerRange} ${
                    this.props.open ? styles.datepickerOpen : ''
                }`}
                style={this.state.position}
                tabIndex={0}
            >
                <li>
                    <Header>
                        <Prev
                            disabled={this.handlePrevMonthDisabled()}
                            onPrev={this.handlePrevMonth.bind(this)}
                        />
                        <Title year={startDate.year()} month={startDate.month()} />
                    </Header>
                    <Labels />
                    <Days
                        onChange={this.handleChange.bind(this)}
                        onHover={this.handleHover.bind(this)}
                        onHoverEnd={this.handleHoverEnd.bind(this)}
                        disabledDates={this.props.disabledDates}
                        active={[this.props.startDate, this.props.endDate]}
                        year={startDate.year()}
                        month={startDate.month()}
                        minDate={this.props.minDate}
                        maxDate={this.props.maxDate}
                        hoveredYear={this.state.hoveredYear}
                        hoveredMonth={this.state.hoveredMonth}
                        hoveredDay={this.state.hoveredDay}
                        minDays={this.props.minDays}
                        maxDays={this.props.maxDays}
                        range
                    />
                </li>

                <li>
                    <Header>
                        <Title year={endDate.year()} month={endDate.month()} />
                        <Next
                            disabled={this.handleNextMonthDisabled()}
                            onNext={this.handleNextMonth.bind(this)}
                        />
                    </Header>
                    <Labels />
                    <Days
                        onChange={this.handleChange.bind(this)}
                        onHover={this.handleHover.bind(this)}
                        onHoverEnd={this.handleHoverEnd.bind(this)}
                        disabledDates={this.props.disabledDates}
                        active={[this.props.startDate, this.props.endDate]}
                        year={endDate.year()}
                        month={endDate.month()}
                        minDate={this.props.minDate}
                        maxDate={this.props.maxDate}
                        hoveredYear={this.state.hoveredYear}
                        hoveredMonth={this.state.hoveredMonth}
                        hoveredDay={this.state.hoveredDay}
                        minDays={this.props.minDays}
                        maxDays={this.props.maxDays}
                        range
                    />
                </li>
            </ul>
        );
    }
}

DatepickerRange.propTypes = {
    startDate: momentPropTypes.momentObj,
    endDate: momentPropTypes.momentObj,
    minDate: momentPropTypes.momentObj,
    maxDate: momentPropTypes.momentObj,
    minDays: PropTypes.number,
    maxDays: PropTypes.number,
    onChange: PropTypes.func,
    onNextMonthChange: PropTypes.func,
    onPrevMonthChange: PropTypes.func,
    disabledDates: PropTypes.arrayOf(PropTypes.oneOfType([momentPropTypes.momentObj])),
};

export default DatepickerRange;
