import React from 'react';

import styles from './styles.module.scss';

const Label = ({
    htmlFor,
    onClick,
    text = '',
    hint = false,
    optional = false,
    className = '',
    children,
    ...props
}) => {
    return (
        <label
            onClick={onClick}
            htmlFor={htmlFor}
            className={`${styles.label} ${optional ? styles.labelOptional : ''} ${
                onClick ? styles.labelClickable : ''
            } ${className}`}
            {...props}
        >
            {!!text && (
                <div className={styles.labelContent}>
                    <span className={styles.labelText}>
                        {text}
                        {!!optional && <span className={styles.labelTextOptional}>- Optional</span>}
                    </span>
                    {!!hint && <span className={styles.labelHint}>{hint}</span>}
                </div>
            )}

            {children}
        </label>
    );
};

export default Label;
