import React from 'react';

const DatepickerTitle = ({ month, year }) => {
    const monthNames = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ],
        currentMonth = monthNames[month];

    return (
        <h3>
            {currentMonth} {year}
        </h3>
    );
};

export default DatepickerTitle;
