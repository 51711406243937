import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, StaticQuery } from 'gatsby';

const SEO = (config = {}) => {
    return (
        <StaticQuery
            query={pageQuery}
            render={data => {
                const { site } = data;
                const { siteMetadata } = site;
                const {
                    title,
                    titleTemplate,
                    description,
                    url,
                    image,
                    twitterUsername,
                } = siteMetadata;

                const seo = {
                    htmlAttributes: { lang: 'en' },
                    title,
                    description,
                    image,
                    url,
                    article: false,
                    ...config,
                };

                return (
                    <Helmet title={title} titleTemplate={titleTemplate}>
                        <meta name="description" content={seo.description} />
                        <meta name="image" content={seo.image} />
                        {seo.url && <meta property="og:url" content={seo.url} />}
                        {(seo.article ? true : null) && (
                            <meta property="og:type" content="article" />
                        )}
                        {seo.title && <meta property="og:title" content={seo.title} />}
                        {seo.description && (
                            <meta property="og:description" content={seo.description} />
                        )}
                        {seo.image && <meta property="og:image" content={seo.image} />}
                        <meta name="twitter:card" content="summary_large_image" />
                        {twitterUsername && (
                            <meta name="twitter:creator" content={twitterUsername} />
                        )}
                        {seo.title && <meta name="twitter:title" content={seo.title} />}
                        {seo.description && (
                            <meta name="twitter:description" content={seo.description} />
                        )}
                        {seo.image && <meta name="twitter:image" content={seo.image} />}
                    </Helmet>
                );
            }}
        />
    );
};

export default SEO;

const pageQuery = graphql`
    query SEOQuery {
        site {
            siteMetadata {
                title
                titleTemplate
                description
                url
                image
                twitterUsername
            }
        }
    }
`;
