import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextareaAutosize from 'react-textarea-autosize';

import Label from '@components/Label';

import styles from './styles.module.scss';
import { subcomponent } from '@helpers';
import Button from '@components/Button';

class Input extends React.Component {
    constructor(props) {
        super(props);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleIconClick = this.handleIconClick.bind(this);
        this.inputWrapper = React.createRef();
        this.input = this.props.forwardedRef || React.createRef();
    }

    handleKeyDown(e) {
        const keyCode = e.which || e.keyCode;

        if (keyCode === 13 && this.props.onEnter) {
            this.props.onEnter(e);
        }

        if (keyCode === 27 && this.props.onEscape) {
            this.props.onEscape(e);
        }

        if (this.props.onKeyDown) {
            this.props.onKeyDown(e);
        }
    }

    handleChange(e) {
        if (e.target.value) {
            if (this.props.number) {
                if (this.props.number?.positive && !/^[0-9,.]*$/.test(e.target.value)) {
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                }

                if (
                    !/^[0-9,.-]*$/.test(e.target.value) ||
                    e.target.value.split('.').length > 2 ||
                    e.target.value.split('-').length > 2
                ) {
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                }

                // decimals is a number, restrict decimal places
                if (
                    parseInt(this.props.number?.decimals) &&
                    !isNaN(parseInt(this.props.number?.decimals)) &&
                    (e.target.value.split('.')[1] || []).length >
                        parseInt(this.props.number?.decimals)
                ) {
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                }

                // decimals doesn't exist, disallow any decimals (no dots)
                if (
                    !this.props.number?.decimals &&
                    !new RegExp(/^[0-9,-]\d*$/).test(e.target.value)
                ) {
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                }
            }

            if (typeof this.props.number === 'object' && this.props.number !== null) {
                if (
                    typeof this.props.number.min === 'number' &&
                    parseInt(e.target.value) < this.props.number.min
                ) {
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                }

                if (
                    typeof this.props.number.max === 'number' &&
                    parseInt(e.target.value) > this.props.number.max
                ) {
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                }
            }
        }

        if (this.props.onChange) {
            this.props.onChange(e, e.target.value);
        }
    }

    handleIconClick(e) {
        if (this.props.onIconClick) {
            e.preventDefault();

            this.props.onIconClick(e);
        }
    }

    render() {
        const icon = this.props.icon ? (
            <FontAwesomeIcon
                icon={this.props.icon}
                className={this.props.onIconClick ? styles.inputIconClickable : ''}
                onMouseDown={this.handleIconClick}
            />
        ) : null;

        const iconClass = this.props.icon ? styles.inputHasIcon : '';
        const blockClass = !this.props.autoWidth ? styles.inputBlock : '';
        const pointerClass = this.props.pointer || !!this.props.onClick ? styles.inputPointer : '';
        const labelClass = this.props.label ? styles.inputHasLabel : '';
        const inlineClass = this.props.inline ? styles.inputInline : '';
        const minimalClass = this.props.minimal ? styles.inputMinimal : '';
        const noMarginBottomClass = this.props.noMarginBottom ? styles.inputNoMarginBottom : '';
        const iconBeforeClass = this.props.iconBefore ? styles.inputIconBefore : '';
        const textareaClass = this.props.textarea ? styles.inputTextarea : '';
        const noHeightClass = this.props.height === false ? styles.inputNoHeight : '';
        let customClassName = this.props.className || '';
        const Button = subcomponent(this.props.children, InputButton, true, {
            className: styles.inputButton,
            secondary: this.props.secondary,
            inverse: this.props.inverse,
        });
        const inputHasButtonClass = !!Button ? styles.inputHasButton : '';
        const inversedClass = this.props.inverse ? styles.inputInversed : '';
        let themeClass = styles.inputPrimary;

        if (this.props.secondary) {
            themeClass = styles.inputSecondary;
        } else if (this.props.tertiary) {
            themeClass = styles.inputTertiary;
        }

        return (
            <div
                ref={this.inputWrapper}
                className={`${styles.input} ${themeClass} ${inversedClass} ${inputHasButtonClass} ${noHeightClass} ${iconBeforeClass} ${noMarginBottomClass} ${minimalClass} ${inlineClass} ${labelClass} ${pointerClass} ${blockClass} ${iconClass} ${textareaClass} ${customClassName}`}
                style={{ height: this.props.height }}
            >
                {this.props.label && (
                    <Label
                        text={this.props.label}
                        hint={this.props.hint}
                        optional={this.props.optional}
                        onClick={this.props.labelOnClick}
                    />
                )}

                <div
                    className={styles.inputHolder}
                    style={{ minWidth: this.props.minWidth, height: this.props.height }}
                >
                    {this.props.textarea ? (
                        <TextareaAutosize
                            inputRef={this.input}
                            type={this.props.type}
                            autoComplete="off"
                            disabled={this.props.disabled}
                            name={this.props.name}
                            value={this.props.value || ''}
                            onChange={this.handleChange}
                            onKeyDown={this.handleKeyDown}
                            placeholder={this.props.placeholder}
                            onClick={this.props.onClick}
                            rows={this.props.rows}
                            style={{ height: this.props.height }}
                        />
                    ) : (
                        <input
                            ref={this.input}
                            type={this.props.type}
                            autoComplete="off"
                            disabled={this.props.disabled}
                            name={this.props.name}
                            value={this.props.value || ''}
                            onChange={this.handleChange}
                            onKeyDown={this.handleKeyDown}
                            placeholder={this.props.placeholder}
                            onClick={this.props.onClick}
                            style={{ height: this.props.height }}
                            onFocus={this.props.onFocus}
                            onBlur={this.props.onBlur}
                            inputMode={this.props.number ? 'numeric' : undefined}
                            pattern={this.props.number ? '[0-9]*' : undefined}
                        />
                    )}

                    {icon}
                    {Button}
                </div>
            </div>
        );
    }
}

Input.defaultProps = {
    value: '',
    type: 'text',
    fullWidth: true,
};

Input.propTypes = {
    noMarginBottom: PropTypes.bool,
    type: PropTypes.string,
    minimal: PropTypes.bool,
    optional: PropTypes.bool,
    inline: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fullWidth: PropTypes.bool,
    autoWidth: PropTypes.bool,
    pointer: PropTypes.bool,
    number: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
            decimals: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([true])]),
        }),
    ]),
    label: PropTypes.string,
    hint: PropTypes.string,
    hintBlock: PropTypes.bool,
    icon: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    iconBefore: PropTypes.bool,
    onIconClick: PropTypes.func,
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    date: PropTypes.bool,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    onKeyDown: PropTypes.func,
    minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    textarea: PropTypes.bool,
    height: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
};

const InputWrapper = React.forwardRef((props, ref) => <Input forwardedRef={ref} {...props} />);

const InputButton = props => <Button {...props} />;
InputWrapper.Button = InputButton;
InputWrapper.Button.displayName = 'Button';

export default InputWrapper;
