import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { connect, useSelector } from 'react-redux';
import { withSnackbar } from '@components/Snackbar';

import Loader from '@components/Loader';
import { Redirect } from '@reach/router';

const PrivateRoute = ({
    component: Component,
    location,
    websiteRequired = false,
    websitePermission,
    admin,
    openSnackbar,
    ...props
}) => {
    // Extract user and website from Redux
    const { user, website } = useSelector(({ user, website }) => ({ user, website }));

    useEffect(() => {
        // Checks to see if the route is marked as admin only
        if (admin && !user?.permissions?.isAdmin) {
            // Alert user they don't have permission and navigate them away
            error !== 'cancelled' && openSnackbar("You don't have permission to view this page");
            navigate('/');
            return;
        }

        if (!user) {
            // user isn't logging out, notify them
            if (location.pathname !== '/logout') {
                openSnackbar('You need to be logged in to view that');
            }

            if (location.pathname !== '/') {
                navigate('/', { state: { from: location } });
            }

            return;
        }

        // if route requires a selected website, prevent user from accessing route, redirect back to /websites route so they can select a website first.
        if (user && websiteRequired && !website) {
            openSnackbar('You need to select a website first before viewing that');
            navigate('/websites');
            return;
        }

        // if route requires a specific permission that the selected website doesn't have, reroute the user to the upgrade plan landing page.
        if (
            user &&
            websiteRequired &&
            website &&
            websitePermission &&
            !website?.features?.[websitePermission]
        ) {
            openSnackbar(
                `Your plan for ${website.name} doesn't currently support this feature, consider upgrading.`
            );

            // TODO: Redirect to upgrade landing page
            navigate('/');
        }
    }, []);

    // Checks to see if the route is marked as admin only
    if (
        (admin && !user?.permissions?.isAdmin) ||
        !user ||
        (user && websiteRequired && !website) ||
        (user &&
            websiteRequired &&
            website &&
            websitePermission &&
            !website?.features?.[websitePermission])
    ) {
        // Return null to indicate to React/Gatsby we have nothing to render
        return null;
    }

    return <Component location={location} user={user} website={website} {...props} />;
};

export default withSnackbar(PrivateRoute);
