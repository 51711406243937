import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Col, Divider, Row } from '@components/Grid';
import Input from '@components/Input';
import styles from '@views/Forms/Form/styles.module.scss';
import Card from '@components/Card';
import Button from '@components/Button';
import Dropdown from '@components/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import usePrevious from '@helpers/hooks/usePrevious';
import Alert from '@components/Alert';
import Select from '@components/Select';

const Field = ({
    name,
    handleChange,
    setFieldValue,
    errors,
    touched,
    draggable,
    id,
    label,
    hint,
    placeholder,
    validation,
    optional,
    options,
    type,
    refs,
    field,
    total,
    onFocus,
    onChange,
    onDuplicate,
    onDelete,
    isSameItem,
    currentFocused,
}) => {
    const [focused, setFocused] = useState(isSameItem(field, currentFocused));
    const $type = useRef(null);
    const [showPlaceholder, setShowPlaceholder] = useState(!!placeholder);
    const [showHint, setShowHint] = useState(!!hint);
    const [showValidation, setShowValidation] = useState(!!validation);
    const prevShowHint = usePrevious(showHint);
    const prevShowPlaceholder = usePrevious(showPlaceholder);
    const prevShowValidation = usePrevious(showValidation);
    const prevType = usePrevious(type);
    const prevValidation = usePrevious(validation);
    const [hovering, setHovering] = useState(false);
    const [typeDropdownOpen, setTypeDropdownOpen] = useState(false);

    useEffect(() => {
        if (currentFocused) {
            setFocused(isSameItem(field, currentFocused));
        }
    }, [currentFocused]);

    // reset options to null if type changes to a singular value type
    useEffect(() => {
        if (!prevType) return;
        setShowValidation(false);

        if (type !== 'dropdown' && type !== 'checkbox' && type !== 'radio') {
            onChange({
                ...field,
                options: null,
                validation: undefined,
            });
            return;
        }

        onChange({
            ...field,
            validation: undefined,
        });
    }, [type]);

    useEffect(() => {
        if (!prevValidation) return;

        if (!!validation?.condition) {
            setFieldValue(`${name}.validation.value`, undefined);
            setFieldValue(`${name}.validation.error`, undefined);
        }
    }, [validation?.condition]);

    useEffect(() => {
        if (!!prevShowHint && !showHint) {
            setFieldValue(`${name}.hint`, undefined);
        }
    }, [showHint]);

    useEffect(() => {
        if (!!prevShowPlaceholder && !showPlaceholder) {
            setFieldValue(`${name}.placeholder`, undefined);
        }
    }, [showPlaceholder]);

    useEffect(() => {
        if (
            (prevShowValidation === true && showValidation === false) ||
            (!!prevType && prevType !== type)
        ) {
            setFieldValue(`${name}.validation`, undefined);
            return;
        }

        if (prevShowValidation === false && showValidation === true) {
            setFieldValue(`${name}.validation`, {
                type: 'text',
                condition: 'includes',
                value: '',
                error: '',
            });
        }
    }, [showValidation]);

    useEffect(() => {
        if (validation?.condition === 'email') {
            setFieldValue(`${name}.validation.error`, 'Must be an email address.');
        }

        if (validation?.condition === 'url') {
            setFieldValue(`${name}.validation.error`, 'Must be an URL.');
        }
    }, [validation?.condition]);

    const handleTypeChange = type => {
        setTypeDropdownOpen(false);
        setFieldValue(`${name}.type`, type);
    };

    const answer = !!type && (
        <Fragment>
            {type === 'string' && (
                <Row>
                    <Col xs={12} lg={6}>
                        <Input disabled value="Short answer text" />
                    </Col>
                </Row>
            )}
            {type === 'paragraph' && (
                <Row>
                    <Col xs={12} lg={10}>
                        <Input disabled value="Long answer text" />
                    </Col>
                </Row>
            )}
            {(type === 'dropdown' || type === 'checkbox' || type === 'radio') && (
                <Multiple
                    name={`${name}.options`}
                    focused={focused}
                    type={type}
                    options={options}
                    onChange={options => setFieldValue(`${name}.options`, options)}
                    setFieldValue={setFieldValue}
                />
            )}
        </Fragment>
    );

    const typeValues = {
        string: {
            text: 'Short answer',
            icon: 'comment-alt-lines',
        },
        paragraph: {
            text: 'Paragraph',
            icon: 'paragraph',
        },
        radio: { text: 'Multiple Choice', icon: 'scrubber' },
        checkbox: { text: 'Checkboxes', icon: 'check-square' },
        dropdown: { text: 'Dropdown', icon: 'chevron-down' },
    };

    return (
        <div
            {...(draggable?.provided?.draggableProps ?? {})}
            style={{
                margin: `0 0 0.9375rem 0`,
                ...(draggable?.provided?.draggableProps?.style ?? {}),
            }}
            ref={draggable?.provided?.innerRef}
        >
            <div
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => setHovering(false)}
                className={`${styles.sectionField} ${focused ? styles.sectionFieldFocused : ''} ${
                    draggable.snapshot.isDragging ? styles.sectionFieldDragging : ''
                }`}
                ref={ref => (refs[id] = ref)}
                onClick={focused ? undefined : onFocus}
                key={id}
            >
                <div
                    {...(draggable?.provided?.dragHandleProps ?? {})}
                    className={`${styles.sectionFieldDrag} ${
                        total > 1 && hovering ? styles.sectionFieldDragVisible : ''
                    }`}
                >
                    <FontAwesomeIcon icon="grip-horizontal" />
                </div>
                {!focused ? (
                    <div className={styles.sectionFieldQuestionPreview}>
                        <Card>
                            <Card.Title
                                iconStatus="error"
                                icon={!!errors?.label && !!touched?.label && 'exclamation-circle'}
                            >
                                {label || 'Question'}
                            </Card.Title>
                            {!focused && !!hint && <Card.Description>{hint}</Card.Description>}
                            <Card.Content>{answer}</Card.Content>
                        </Card>
                    </div>
                ) : (
                    <Card>
                        <Card.Content>
                            <div className={styles.sectionFieldQuestionWrapper}>
                                <Row>
                                    <Col xs={12} mdGrow>
                                        <div className={styles.sectionFieldQuestion}>
                                            <Input
                                                name={`${name}.label`}
                                                placeholder="Question"
                                                secondary
                                                value={label ?? ''}
                                                onChange={handleChange}
                                            />
                                            {errors?.label && touched?.label && (
                                                <Fragment>
                                                    <Divider margin={2} />
                                                    <Alert type="error" message={errors?.label} />
                                                </Fragment>
                                            )}

                                            {showPlaceholder && (
                                                <Fragment>
                                                    <Divider margin={2} />
                                                    <Input
                                                        name={`${name}.placeholder`}
                                                        placeholder="Placeholder"
                                                        tertiary
                                                        value={placeholder ?? ''}
                                                        onChange={e => {
                                                            setFieldValue(
                                                                `${name}.placeholder`,
                                                                !e.target.value
                                                                    ? undefined
                                                                    : e.target.value
                                                            );
                                                        }}
                                                    />
                                                </Fragment>
                                            )}
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={8} mdShrink>
                                        <div style={{ position: 'relative' }} ref={$type}>
                                            <Button
                                                secondary
                                                iconBefore
                                                text={typeValues[type].text}
                                                icon={['fad', typeValues[type].icon]}
                                                onClick={() =>
                                                    setTypeDropdownOpen(!typeDropdownOpen)
                                                }
                                            />
                                        </div>

                                        <Divider xsMargin={2} mdMargin={0} />

                                        {typeDropdownOpen && focused && (
                                            <Dropdown
                                                target={$type}
                                                onEscape={() => setTypeDropdownOpen(false)}
                                            >
                                                {Object.keys(typeValues).map(key => (
                                                    <Dropdown.Item
                                                        key={key}
                                                        icon={['fad', typeValues[key].icon]}
                                                        text={typeValues[key].text}
                                                        selected={type === key}
                                                        onClick={() => handleTypeChange(key)}
                                                    />
                                                ))}
                                            </Dropdown>
                                        )}
                                    </Col>
                                </Row>
                            </div>
                            {showHint && (
                                <Fragment>
                                    <Divider margin={2} />
                                    <Input
                                        name={`${name}.hint`}
                                        placeholder="Description"
                                        tertiary
                                        value={hint ?? ''}
                                        onChange={handleChange}
                                    />
                                </Fragment>
                            )}

                            {showValidation && (
                                <Fragment>
                                    <Divider margin={2} />
                                    {type === 'string' && (
                                        <ul className={styles.sectionFieldValidation}>
                                            <li>
                                                <Select
                                                    tertiary
                                                    placeholder="Type"
                                                    name={`${name}.validation.type`}
                                                    value={validation?.type}
                                                    onChange={handleChange}
                                                >
                                                    <Select.Option value="text" label="Text" />
                                                </Select>
                                                {touched?.validation?.type &&
                                                    errors?.validation?.type && (
                                                        <Fragment>
                                                            <Divider margin={2} />
                                                            <Alert
                                                                type="error"
                                                                message={errors?.validation?.type}
                                                            />
                                                        </Fragment>
                                                    )}
                                            </li>
                                            <li>
                                                <Select
                                                    tertiary
                                                    placeholder="Condition"
                                                    name={`${name}.validation.condition`}
                                                    value={validation?.condition}
                                                    onChange={handleChange}
                                                >
                                                    <Select.Option
                                                        value="includes"
                                                        label="Contains"
                                                    />
                                                    <Select.Option
                                                        value="excludes"
                                                        label="Doesn't contain"
                                                    />
                                                    <Select.Option
                                                        value="email"
                                                        label="Email address"
                                                    />
                                                    <Select.Option value="url" label="URL" />
                                                </Select>
                                                {touched?.validation?.condition &&
                                                    errors?.validation?.condition && (
                                                        <Fragment>
                                                            <Divider margin={2} />
                                                            <Alert
                                                                type="error"
                                                                message={
                                                                    errors?.validation?.condition
                                                                }
                                                            />
                                                        </Fragment>
                                                    )}
                                            </li>
                                            {(validation?.condition === 'includes' ||
                                                validation?.condition === 'excludes') && (
                                                <li>
                                                    <Input
                                                        placeholder="Value"
                                                        tertiary
                                                        name={`${name}.validation.value`}
                                                        value={validation?.value ?? ''}
                                                        onChange={handleChange}
                                                    />
                                                    {touched?.validation?.value &&
                                                        errors?.validation?.value && (
                                                            <Fragment>
                                                                <Divider margin={2} />
                                                                <Alert
                                                                    type="error"
                                                                    message={
                                                                        errors?.validation?.value
                                                                    }
                                                                />
                                                            </Fragment>
                                                        )}
                                                </li>
                                            )}
                                            <li>
                                                <Input
                                                    placeholder="Custom error text"
                                                    tertiary
                                                    name={`${name}.validation.error`}
                                                    value={validation?.error ?? ''}
                                                    onChange={handleChange}
                                                />
                                                {touched?.validation?.error &&
                                                    errors?.validation?.error && (
                                                        <Fragment>
                                                            <Divider margin={2} />
                                                            <Alert
                                                                type="error"
                                                                message={errors?.validation?.error}
                                                            />
                                                        </Fragment>
                                                    )}
                                            </li>
                                        </ul>
                                    )}
                                </Fragment>
                            )}
                            <Divider />

                            {answer}
                        </Card.Content>
                        <Card.Actions right>
                            <Card.Actions.Switch
                                name="optional"
                                checked={optional}
                                label="Optional"
                                onChange={(e, checked) =>
                                    setFieldValue(`${name}.optional`, checked)
                                }
                            />
                            {/*<Card.Actions.Switch*/}
                            {/*    name="hint"*/}
                            {/*    checked={showHint}*/}
                            {/*    label="Description"*/}
                            {/*    onChange={(e, checked) => setShowHint(checked)}*/}
                            {/*/>*/}
                            <Card.Actions.Action
                                icon={['fad', 'clone']}
                                tooltip="Duplicate question"
                                onClick={() => onDuplicate(field)}
                            />
                            <Card.Actions.Action
                                icon={['fad', 'trash']}
                                tooltip="Delete question"
                                onClick={onDelete}
                            />
                            <Card.Actions.Action
                                icon="ellipsis-v"
                                placement="bottom-end"
                                offset={[0, 30]}
                            >
                                {type === 'string' && (
                                    <Card.Actions.Action.Item
                                        checked={showValidation}
                                        text="Response validation"
                                        onClick={() => setShowValidation(!showValidation)}
                                    />
                                )}

                                <Card.Actions.Action.Item
                                    checked={showHint}
                                    text="Show description"
                                    onClick={() => setShowHint(!showHint)}
                                />
                                <Card.Actions.Action.Item
                                    checked={showPlaceholder}
                                    text="Show placeholder"
                                    onClick={() => setShowPlaceholder(!showPlaceholder)}
                                />
                            </Card.Actions.Action>
                        </Card.Actions>
                    </Card>
                )}
            </div>
        </div>
    );
};

const Multiple = ({ name, focused, type, options, onChange, setFieldValue }) => {
    const $options = [];
    const prevOptions = usePrevious(options);

    useEffect(() => {
        if ((!prevOptions && options) || (options && options.length > prevOptions.length)) {
            $options[options.length - 1]?.focus();
        }
    }, [options]);

    return (
        <ul
            className={`${styles.sectionFieldAnswer} ${type === 'dropdown' &&
                styles.sectionFieldAnswerDropdown} ${type === 'checkbox' &&
                styles.sectionFieldAnswerCheckbox} ${type === 'radio' &&
                styles.sectionFieldAnswerRadio}`}
        >
            {(options ?? (focused ? [{ label: 'Option 1' }] : [])).map((value, index) => (
                <li key={index}>
                    <div className={styles.sectionFieldAnswerKey}>
                        {type === 'dropdown' ? `${index + 1}.` : null}
                    </div>

                    {focused ? (
                        <Fragment>
                            <Input
                                ref={ref => ($options[index] = ref)}
                                value={value?.label}
                                placeholder={`Option ${index + 1}`}
                                onChange={e => {
                                    setFieldValue(`${name}.${index}.label`, e.target.value);
                                }}
                            />
                            <div className={styles.sectionFieldAnswerDelete}>
                                {options?.length > 1 && (
                                    <FontAwesomeIcon
                                        icon="times"
                                        onClick={() => {
                                            onChange(
                                                options.filter(
                                                    (option, currIndex) => currIndex !== index
                                                )
                                            );
                                        }}
                                    />
                                )}
                            </div>
                        </Fragment>
                    ) : (
                        <span className={styles.sectionFieldAnswerPreview}>{value?.label}</span>
                    )}
                </li>
            ))}
            {focused && (
                <li>
                    <div className={styles.sectionFieldAnswerKey}>
                        {type === 'dropdown' ? `${options ? options.length + 1 : 2}.` : null}
                    </div>
                    <Input
                        placeholder="Add option"
                        value=""
                        onClick={() => {
                            onChange([
                                ...(options || [{ label: 'Option 1' }]),
                                { label: `Option ${options ? options.length + 1 : 2}` },
                            ]);
                        }}
                    />
                </li>
            )}
        </ul>
    );
};

export default Field;
