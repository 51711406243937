import React from 'react';

import styles from './styles.module.scss';

const Avatar = ({ alt, src, size }) => {
    return (
        <div style={{ height: size, width: size }} className={styles.cardAvatar}>
            {!!src && <img alt={alt} src={src} />}
        </div>
    );
};
Avatar.displayName = 'Avatar';

export default Avatar;
