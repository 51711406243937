import React, { useEffect, useState } from 'react';

import { Grid, Row, Col } from '@components/Grid';
import { useSelector } from 'react-redux';
import Card from '@components/Card';
import { makeGetRequest } from '@helpers/requests';
import { HOSTING_DEPLOY } from '@helpers/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import ComingSoon from '@components/ComingSoon';
import Loader from '@components/Loader';

import styles from './styles.module.scss';

const Deployment = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const website = useSelector(state => state.website);
    const returnImgSrc = () =>
        data[0].screenshot_url ?? 'http://placehold.it/600x300?text=No%20Preview%20Available';

    useEffect(() => {
        (async () => {
            if (website?._id) {
                try {
                    // Retrieving hosting data from the API
                    const response = await makeGetRequest(HOSTING_DEPLOY(website._id));
                    // Make sure response.data is an array before we slice
                    if (Array.isArray(response.data)) {
                        setData(response.data.slice(0, 6));
                    }

                    // Render page contents instead of loader
                    setLoading(false);
                } catch (error) {
                    console.log(error?.response ? error.response : error);
                }
            }
        })();
    }, [website]);

    if (!website._id) {
        return <ComingSoon />;
    }

    if (loading) {
        return <Loader tip="Loading Data..." />;
    }

    return (
        <Grid>
            <Row>
                <Col xs={12}>
                    <h1>Deployment Overview</h1>
                    <h2>Most Recent Change</h2>
                </Col>
                <Col xs={12}>
                    <div className={styles.cardFull}>
                        <img src={returnImgSrc()} className={styles.cardFullPreview} />
                        <div className={styles.cardFullContent}>
                            <a
                                style={{ textDecoration: 'none' }}
                                href={website.domain}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <h3 className={styles.cardFullPreviewTitle}>{website.name}</h3>
                                <FontAwesomeIcon icon={['fad', 'external-link']} />
                            </a>
                            {data[0].id && (
                                <React.Fragment>
                                    <p>{data[0].title}</p>
                                    <hr />
                                    <p style={{ marginBottom: 0 }}>
                                        Deployed and live since
                                        {format(
                                            new Date(data[0].published_at),
                                            ' hh:mm:ss aaaa iii, io MMM, yyyy '
                                        )}
                                    </p>
                                    <p>
                                        Took {(Number(data[0].deploy_time) / 60).toFixed(0)} minutes
                                        to build.
                                    </p>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <h2 style={{ marginTop: 32 }}>Previous Updates</h2>
                </Col>
                <Col xs={12}>
                    <Card>
                        <Card.List>
                            {data.slice(1, 6).map(deployment => (
                                <Card.List.Item
                                    key={deployment.id}
                                    title={deployment.title}
                                    description={`Modified at ${format(
                                        new Date(deployment.published_at),
                                        ' hh:mm:ss aaaa iiii, io MMMM, yyyy '
                                    )}`}
                                >
                                    <Card.List.Item.Icon icon={['fad', 'browser']} />
                                    {/* <Card.List.Item.Avatar
                                        size={50}
                                        src={returnProfilePicture(user)}
                                    /> */}
                                    <Card.List.Item.Content.Right>
                                        {deployment.summary.status === 'ready' ? (
                                            <Card.List.Item.Icon
                                                icon={['fad', 'check']}
                                                color="green"
                                            />
                                        ) : (
                                            <Card.List.Item.Icon icon={['fad', 'exclamation']} />
                                        )}
                                    </Card.List.Item.Content.Right>
                                </Card.List.Item>
                            ))}
                        </Card.List>
                    </Card>
                </Col>
            </Row>
        </Grid>
    );
};

export default Deployment;
