import React, { useEffect, useState, Fragment } from 'react';

import { Divider, Grid } from '@components/Grid';
import { makeGetRequest } from '@helpers/requests';
import { USER_FIELDS, USERS } from '@helpers/api';
import Card from '@components/Card';
import Command from '@components/Command';
import Empty from '@components/Empty';
import { useSnackbar } from '@components/Snackbar';
import List from '@components/List';
import Pagination from '@components/Pagination';
import { navigate } from 'gatsby-link';
import queryString from 'query-string';

const Fields = () => {
	const params = queryString.parse(location.search, { parseNumbers: true });
	const page = params.page ?? 1;
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState(null);
	const [fields, setFields] = useState([]);
	const [openSnackbar] = useSnackbar();

	useEffect(() => {
		(async () => {
			try {
				const { data: fieldsData } = await makeGetRequest(USER_FIELDS, {
					pageNum: page,
					perPage: 10,
				});
				setTotal(fieldsData?.meta?.total);
				setFields(fieldsData?.results);
			} catch (error) {
				error !== 'cancelled' &&
					openSnackbar(
						error?.errorMessage ?? 'An error occurred when attempting to load fields.'
					);
			} finally {
				setLoading(false);
			}
		})();
	}, [page]);

	const handleUrlChange = (query = {}) => {
		navigate(
			`${location.pathname}?${queryString.stringify({
				...queryString.parse(location.search),
				page: undefined,
				...query,
			})}`
		);
	};

	return (
		<Fragment>
			<Command>
				<Command.Breadcrumbs>
					<Command.Breadcrumbs.Breadcrumb text="Users" link="/users" />
					<Command.Breadcrumbs.Breadcrumb text="Fields" />
				</Command.Breadcrumbs>

				<Command.Action text="Create" icon="list" link={`/users/fields/create`} />
			</Command>

			{loading || !!fields.length ? (
				<Grid>
					<Card>
						<Card.Content>
							<List loading={loading}>
								{fields.map(({ label, _id, accountTypes }) => (
									<List.Item key={_id} link={`/users/fields/${_id}`}>
										<List.Item.Title text={label} />
										<List.Item.Description text={_id} />
										{!!accountTypes && !!accountTypes.length && (
											<List.Item.Tags>
												{accountTypes
													?.map(({ _id, name }) => (
														<List.Item.Tags.Tag key={_id} text={name} />
													))
													.slice(0, 3)}
												{!!accountTypes.slice(3).length && (
													<List.Item.Tags.Tag
														text={`+ ${
															accountTypes.slice(3).length
														} more`}
													/>
												)}
											</List.Item.Tags>
										)}
									</List.Item>
								))}
							</List>
						</Card.Content>
					</Card>

					{!loading && (
						<Fragment>
							<Divider />
							<Pagination
								meta
								onChange={page => handleUrlChange({ page })}
								pageTotal={fields?.length}
								total={total}
								page={page}
								perPage={10}
								metaLabel="Products"
							/>
						</Fragment>
					)}
				</Grid>
			) : (
				<Empty title="Hold tight" text="You don't have any fields yet!" />
			)}
		</Fragment>
	);
};

export default Fields;
