import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import React from 'react';

import Input from '@components/Input';

const Description = ({ editable, onChange, value, placeholder, children, text, link, divider }) => (
    <div className={`${styles.cardDescription} ${!!divider ? styles.cardDescriptionDivider : ''}`}>
        {editable ? (
            <Input value={value} placeholder={placeholder} onChange={onChange} />
        ) : !!link ? (
            link?.includes('http://') || link?.includes('https://') ? (
                <a href={link}>{text || children}</a>
            ) : (
                <Link to={link}>{text || children}</Link>
            )
        ) : (
            text || children
        )}
    </div>
);
Description.displayName = 'Description';

export default Description;
