import React, { Fragment } from 'react';
import { Link } from '@reach/router';
import { subcomponent, checkLinkIsExternal } from '@helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Position from './Position';

import styles from './styles.module.scss';

const List = ({ children, title, divider = true, className = '', loading }) => {
    return (
        <ul className={`${styles.cardList} ${divider ? styles.cardListDivider : ''} ${className}`}>
            {title && <li className={styles.cardListTitle}>{title}</li>}
            {loading
                ? Array(parseInt(loading) || 10)
                      .fill()
                      .map((x, i) => i)
                      .map(i => subcomponent(children, List.Item, false, { loading }))
                : subcomponent(children, List.Item, false, { loading })}
        </ul>
    );
};

List.Item = ({
    label,
    value,
    bold,
    title,
    description,
    icon,
    children,
    hoverable,
    onClick,
    className = '',
    loading,
    active,
}) => {
    const cardTopLeft = subcomponent(children, Position.Top.Left, true, { loading });
    const cardTopRight = subcomponent(children, Position.Top.Right, true, { loading });
    const cardBottomLeft = subcomponent(children, Position.Bottom.Left, true, { loading });
    const cardBottomRight = subcomponent(children, Position.Bottom.Right, true, { loading });
    const content = subcomponent(children, List.Item.Content, true, { loading });
    const ContentRight = subcomponent(children, List.Item.Content.Right, true, { loading });
    const Avatar = subcomponent(children, List.Item.Avatar, true);
    const renderValue = () => {
        if (value === undefined) return value;

        if (value === true) {
            return <FontAwesomeIcon icon="check" />;
        }

        if (value === false) {
            return <FontAwesomeIcon icon="times" />;
        }

        return value;
    };

    return (
        <li
            onClick={onClick}
            className={`${styles.cardListItem} ${bold ? styles.cardListItemBold : ''} ${
                hoverable || onClick ? styles.cardListItemHoverable : ''
            } ${Avatar ? styles.cardListItemHasAvatar : ''} ${
                loading ? styles.cardListItemLoading : ''
            } ${className}`}
        >
            {cardTopLeft || cardTopRight ? (
                <div className={`${styles.cardPositionEnd}`}>
                    {cardTopLeft} {cardTopRight}
                </div>
            ) : null}

            <div className={styles.cardListItemContent}>
                {subcomponent(children, List.Item.Icon, true)}
                {Avatar}

                <div className={styles.cardListItemMain}>
                    <div className={styles.cardListItemMainContentWrapper}>
                        <div className={styles.cardListItemMainContent}>
                            {!title && label && (value !== undefined || content) && (
                                <ul>
                                    <li>{label}</li>
                                    <li
                                        className={`${
                                            value === true ? styles.cardListItemListItemSuccess : ''
                                        } ${
                                            value === false ? styles.cardListItemListItemDanger : ''
                                        }`}
                                    >
                                        {renderValue() || content}
                                    </li>
                                </ul>
                            )}
                            {!title && !label && value && <p>{value}</p>}
                            {!label && !value && title && (
                                <p className={styles.cardListItemTitle}>{title}</p>
                            )}
                            {!label && !value && description && (
                                <p className={styles.cardListItemDescription}>
                                    {loading ? (
                                        <Fragment>
                                            <span />
                                            <span />
                                        </Fragment>
                                    ) : (
                                        description
                                    )}
                                </p>
                            )}

                            {!label && content}
                        </div>
                        {!value && !!ContentRight && (
                            <div className={styles.cardListItemMainContentRight}>
                                {ContentRight}
                            </div>
                        )}
                    </div>

                    {subcomponent(children, List.Item.Action, true, { loading })}
                </div>
            </div>

            {cardBottomLeft || cardBottomRight ? (
                <div className={`${styles.cardPositionEnd}`}>
                    {cardBottomLeft} {cardBottomRight}
                </div>
            ) : null}
        </li>
    );
};

List.Item.Position = Position;

List.Item.Action = ({ onClick, link, icon, iconSize, loading }) => {
    const handleLinkClick = e => {
        e.stopPropagation();
        onClick && onClick(e);
    };

    return link ? (
        checkLinkIsExternal(link) ? (
            <a
                rel="noopener noreferrer"
                target="_blank"
                href={link}
                className={`${styles.cardListItemAction} ${
                    onClick || link ? styles.cardListItemActionHoverable : ''
                }`}
                onClick={handleLinkClick}
            >
                <FontAwesomeIcon
                    icon={['fad', icon || '']}
                    style={!!iconSize && { fontSize: `${iconSize}rem` }}
                />
            </a>
        ) : (
            <Link
                to={link}
                className={`${styles.cardListItemAction} ${
                    onClick || link ? styles.cardListItemActionHoverable : ''
                }`}
                onClick={handleLinkClick}
            >
                <FontAwesomeIcon
                    icon={['fad', icon || '']}
                    style={!!iconSize && { fontSize: `${iconSize}rem` }}
                />
            </Link>
        )
    ) : (
        <div
            className={`${styles.cardListItemAction} ${
                onClick || link ? styles.cardListItemActionHoverable : ''
            } ${loading ? styles.cardListItemActionLoading : ''}`}
            onClick={onClick}
        >
            {!loading && (
                <FontAwesomeIcon
                    icon={['fad', icon || '']}
                    style={!!iconSize && { fontSize: `${iconSize}rem` }}
                />
            )}
        </div>
    );
};

List.Item.Avatar = ({ src, small, center, size = null }) => (
    <div
        className={`${styles.cardListItemAvatar} ${center ? styles.cardListItemAvatarCenter : ''} ${
            small ? styles.cardListItemAvatarSmall : ''
        }`}
        style={{
            backgroundImage: src && `url(${src})`,
            width: size,
            height: size,
        }}
    />
);

List.Item.Icon = ({ icon, center, small, size, color }) => (
    <div
        className={`${styles.cardListItemIcon} ${center ? styles.cardListItemIconCenter : ''} ${
            small ? styles.cardListItemIconSmall : ''
        }`}
        style={{
            width: size,
            height: size,
        }}
    >
        {!!icon && <FontAwesomeIcon icon={icon} color={color} />}
    </div>
);

List.Item.Content = ({ children }) => {
    return <div className={styles.cardListItemMainContentChildren}>{children}</div>;
};

List.Item.Content.Right = ({ children }) => children;

List.displayName = 'List';
List.Item.displayName = 'Item';
List.Item.Action.displayName = 'Action';
List.Item.Avatar.displayName = 'Avatar';
List.Item.Icon.displayName = 'Icon';
List.Item.Content.displayName = 'Content';
List.Item.Content.Right.displayName = 'Right';

export default List;
