import React from 'react';

import styles from './styles.module.scss';

const Badge = ({ type = '', text, onClick }) => {
    const className = getStyling(type);
    return (
        <span
            onClick={onClick}
            className={`${className} ${!!onClick ? styles.badgeHoverable : ''}`}
        >
            {text}
        </span>
    );
};

function getStyling(type) {
    switch (type) {
        case 'success':
            return `${styles.badge} ${styles.badgeSuccess}`;
        case 'warning':
            return `${styles.badge} ${styles.badgeWarning}`;
        case 'danger':
            return `${styles.badge} ${styles.badgeDanger}`;
        case 'primary':
        default:
            return `${styles.badge} ${styles.badgePrimary}`;
    }
}

export default Badge;
