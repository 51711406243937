import React, { useEffect, useState, Fragment } from 'react';

import { Divider, Grid } from '@components/Grid';
import { makeDeleteRequest, makeGetRequest } from '@helpers/requests';
import { STORE_CATEGORIES, STORE_CATEGORY, STORE_PRODUCTS } from '@helpers/api';
import Card from '@components/Card';
import Command from '@components/Command';
import Empty from '@components/Empty';

import { withSnackbar } from '@components/Snackbar';
import Modal from '@components/Modal';
import { Formik } from 'formik';

import Button from '@components/Button';
import List from '@components/List';
import { Link } from 'gatsby';
import queryString from 'query-string';
import Pagination from '@components/Pagination';
import { navigate } from 'gatsby-link';

const Categories = ({ openSnackbar, location }) => {
    const params = queryString.parse(location.search, { parseNumbers: true });
    const page = params.page ?? 1;
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const { data: categoriesData } = await makeGetRequest(STORE_CATEGORIES, {
                    pageNum: page,
                    perPage: 10,
                });
                setCategories(categoriesData);
            } catch (error) {
                error !== 'cancelled' &&
                    openSnackbar(
                        error?.errorMessage ??
                            'An error occurred when attempting to load categories.'
                    );
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const handleUrlChange = (query = {}) => {
        navigate(
            `${location.pathname}?${queryString.stringify({
                ...queryString.parse(location.search),
                page: undefined,
                ...query,
            })}`
        );
    };

    return (
        <Fragment>
            <Command>
                <Command.Breadcrumbs>
                    <Command.Breadcrumbs.Breadcrumb text="Store" link="/store" />
                    <Command.Breadcrumbs.Breadcrumb text="Categories" />
                </Command.Breadcrumbs>

                <Command.Action text="Create" icon="plus" link="/store/categories/create" />
            </Command>

            {loading || !!categories?.results?.length ? (
                <Grid>
                    <Card>
                        <Card.Content>
                            <List loading={loading}>
                                {categories?.results?.map(({ name, id, _id }) => (
                                    <List.Item key={_id} link={`/store/categories/${_id}`}>
                                        <List.Item.Title text={name} />
                                        <List.Item.Description text={id} />
                                    </List.Item>
                                ))}
                            </List>
                        </Card.Content>
                    </Card>

                    {!loading && (
                        <Fragment>
                            <Divider />
                            <Pagination
                                meta
                                onChange={page => handleUrlChange({ page })}
                                pageTotal={categories?.results?.length}
                                total={categories?.meta?.total}
                                page={page}
                                perPage={10}
                                metaLabel="Categories"
                            />
                        </Fragment>
                    )}
                </Grid>
            ) : (
                <Empty title="Hold tight" text="You don't have any categories yet!" />
            )}
        </Fragment>
    );
};

export default withSnackbar(Categories);
