import React, { Fragment, useEffect, useRef, useState } from 'react';
import { makeGetRequest, makePostRequest, makePutRequest } from '@helpers/requests';
import { INVOICE, INVOICE_PAY, INVOICE_PAY_TOKEN, SUBSCRIPTION } from '@helpers/api';
import { navigate } from 'gatsby-link';
import Loader from '@components/Loader';
import { Grid, Row, Col, Divider } from '@components/Grid';
import Card from '@components/Card';
import Command from '@components/Command';
import List from '@components/List';
import { withSnackbar } from '@components/Snackbar';
import Button from '@components/Button';
import Addresses from '@components/Addresses';
import Alert from '@components/Alert';
import DropIn from 'braintree-web-drop-in-react';
import { setWebsite } from '@actions/website';

const Pay = ({ openSnackbar, id }) => {
    const braintree = useRef(null);
    const [loading, setLoading] = useState(true);
    const [invoice, setInvoice] = useState(null);
    const [submitting, setSubmitting] = useState(null);
    const [token, setToken] = useState(null);

    const handleSubmit = async () => {
        try {
            setSubmitting(true);

            const { nonce } = await braintree.current.instance.requestPaymentMethod({
                amount: (invoice?.total / 100).toFixed(2),
                billingAddress: {
                    company: invoice?.address?.name,
                    streetAddress: invoice?.address?.streetOne,
                    extendedAddress: invoice?.address?.streetTwo,
                    locality: invoice?.address?.city,
                    region: invoice?.address?.county,
                    postalCode: invoice?.address?.postcode,
                    countryCodeAlpha2: invoice?.address?.country,
                },
            });

            // Create object to send to server
            const data = {
                token: nonce,
            };

            // Create invoice payment
            await makePostRequest(INVOICE_PAY(id), data);

            openSnackbar(`You successfully paid invoice MP-${invoice?.invoiceNumber ?? ''}.`);
            navigate(`/settings/billing/invoice/${invoice?._id}`);
        } catch (error) {
            setSubmitting(false);
            // clear payment methods
            braintree.current.instance.clearSelectedPaymentMethod();
            openSnackbar(
                error?.errorMessage ?? 'An error occurred submitting your billing details.'
            );
        }
    };

    useEffect(() => {
        (async () => {
            try {
                const { data: invoice } = await makeGetRequest(INVOICE(id));
                const { data: token } = await makeGetRequest(INVOICE_PAY_TOKEN);
                setInvoice(invoice);
                setToken(token);
                setLoading(false);
            } catch (error) {
                if (error === 'cancelled') return;
                openSnackbar(error?.errorMessage ?? 'An error occurred loading this invoice.');
                navigate('/settings/billing');
            }
        })();
    }, []);

    return (
        <Fragment>
            <Command>
                <Command.Breadcrumbs>
                    <Command.Breadcrumbs.Breadcrumb text="Settings" />
                    <Command.Breadcrumbs.Breadcrumb text="Billing" link="/settings/billing" />
                    <Command.Breadcrumbs.Breadcrumb
                        text={`Invoice${
                            invoice?.invoiceNumber ? ` (MP-${invoice.invoiceNumber})` : ''
                        }`}
                        link={`/settings/billing/invoice/${invoice?._id}`}
                    />
                    <Command.Breadcrumbs.Breadcrumb text="Pay" />
                </Command.Breadcrumbs>
            </Command>

            <Grid>
                <Row>
                    <Col xs={12} lg={8}>
                        <h2>Payment details</h2>
                        <Divider />

                        {loading && <Loader height={150} />}
                        {!token ? null : (
                            <div
                                className={`braintree-dropin-wrapper ${
                                    loading ? 'braintree-dropin-loading' : ''
                                }`}
                            >
                                <DropIn
                                    options={{
                                        vaultManager: true,
                                        authorization: token,
                                        paypal: {
                                            flow: 'vault',
                                        },
                                        paypalCredit: {
                                            flow: 'checkout',
                                            amount: (invoice?.total / 100).toFixed(2),
                                            currency: 'GBP',
                                        },
                                    }}
                                    onInstance={() => setLoading(false)}
                                    ref={braintree}
                                />
                            </div>
                        )}

                        <Row end="xs">
                            <Col xsShrink>
                                <Button
                                    onClick={handleSubmit}
                                    submitting={submitting}
                                    disabled={loading || !token}
                                >
                                    {`Pay${
                                        invoice ? ` £${(invoice?.total / 100).toFixed(2)}` : ''
                                    }`}
                                </Button>
                            </Col>
                        </Row>

                        <Divider lgMargin={0} />
                    </Col>
                    <Col xs={12} lg={4}>
                        <Card loading={loading}>
                            <Card.Title>{`Pay Invoice${
                                invoice ? ` (MP-${invoice?.invoiceNumber})` : ''
                            }`}</Card.Title>
                            <Card.Content>
                                <Card.List>
                                    <Card.List.Item
                                        label="Subtotal"
                                        empty
                                        value={`£${(invoice?.subtotal / 100).toFixed(2)}`}
                                    />
                                    <Card.List.Item
                                        label="VAT"
                                        value={`£${(invoice?.vat / 100).toFixed(2)}`}
                                    />
                                    <Card.List.Item
                                        label="Total"
                                        value={`£${(invoice?.total / 100).toFixed(2)}`}
                                    />
                                </Card.List>
                            </Card.Content>
                        </Card>
                    </Col>
                </Row>
            </Grid>
        </Fragment>
    );
};

export default withSnackbar(Pay);
