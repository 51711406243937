import React from 'react';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { subcomponent } from '@helpers';

import styles from './styles.module.scss';

const ImageSlider = ({ height, children }) => {
    const LeftSliderArrow = ({ onClick }) => (
        <button onClick={onClick} className={`${styles.sliderArrow} ${styles.sliderArrowLeft}`}>
            <FontAwesomeIcon icon="chevron-left" />
        </button>
    );

    const RightSliderArrow = ({ onClick }) => (
        <button onClick={onClick} className={`${styles.sliderArrow} ${styles.sliderArrowRight}`}>
            <FontAwesomeIcon icon="chevron-right" />
        </button>
    );

    return (
        <div style={{ height }}>
            <Slider
                className={styles.slider}
                dots={false}
                infinite
                speed={500}
                slidesToShow={1}
                slidesToScroll={1}
                prevArrow={<LeftSliderArrow />}
                nextArrow={<RightSliderArrow />}
            >
                {subcomponent(children, Item)}
            </Slider>
        </div>
    );
};

const Item = ({ alt, src }) => {
    return (
        <div className={styles.sliderImage} key={src}>
            <img alt={alt} className={styles.sliderImageWrapper} src={src} />
        </div>
    );
};

ImageSlider.Item = Item;
Item.displayName = 'Item';

export default ImageSlider;
