import React, { Fragment, useEffect, useState } from 'react';
import { useSnackbar } from '@components/Snackbar';
import * as Yup from 'yup';
import {
    makeDeleteRequest,
    makeGetRequest,
    makePostRequest,
    makePutRequest,
} from '@helpers/requests';
import {
    COUNTRY_CODES,
    PERMISSIONS,
    PERMISSIONS_ROLES,
    TEAM_MEMBER,
    TEAM_MEMBERS,
} from '@helpers/api';
import Command from '@components/Command';
import { navigate } from 'gatsby-link';
import Loader from '@components/Loader';
import { Formik } from 'formik';
import Form from '@views/Settings/TeamMembers/TeamMember/Form';

const TeamMember = ({ id }) => {
    const [openSnackbar] = useSnackbar();
    const [loading, setLoading] = useState(true);
    const [teamMember, setTeamMember] = useState(null);
    const [countries, setCountries] = useState(null);
    const [roles, setRoles] = useState([]);
    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                if (id) {
                    const { data: teamMemberData } = await makeGetRequest(TEAM_MEMBER(id));
                    setTeamMember(teamMemberData);
                }

                const { data: rolesData } = await makeGetRequest(PERMISSIONS_ROLES);
                setRoles(rolesData?.standard);

                const { data: permissionsData } = await makeGetRequest(PERMISSIONS);
                setPermissions(permissionsData);

                const { data: countriesData } = await makeGetRequest(COUNTRY_CODES);
                setCountries(countriesData);
            } catch (error) {
                error !== 'cancelled' &&
                    openSnackbar(
                        error?.errorMessage ??
                            `An error occurred attempting to load ${
                                creating ? 'team member creation' : 'this team member'
                            }.`
                    );
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const handleSubmit = async ({
        firstName,
        lastName,
        type,
        locale: { countryCode, city },
        email,
    }) => {
        try {
            const data = {
                email,
                firstName,
                lastName,
                type: type || undefined,
                locale: {
                    countryCode,
                    city,
                },
            };
            const { data: teamMemberData } = creating
                ? await makePostRequest(TEAM_MEMBERS, data)
                : await makePutRequest(TEAM_MEMBER(id), data);

            openSnackbar('Team member changes successfully saved.');

            if (creating) {
                navigate(`/settings/team-members/team-member/${teamMemberData?._id}`);
            }
        } catch (error) {
            error !== 'cancelled' &&
                openSnackbar(
                    error?.errorMessage ??
                        'An error occurred attempting to save your team member changes.'
                );
        }
    };

    return (
        <Fragment>
            <Command>
                <Command.Breadcrumbs>
                    <Command.Breadcrumbs.Breadcrumb
                        text="Team Members"
                        link="/settings/team-members"
                    />
                    <Command.Breadcrumbs.Breadcrumb
                        text={
                            !!teamMember
                                ? `${teamMember?.firstName ?? ''} ${teamMember?.lastName ?? ''}`
                                : 'Team Member'
                        }
                    />
                </Command.Breadcrumbs>
            </Command>

            {loading ? (
                <Loader />
            ) : (
                <Formik
                    initialValues={{
                        firstName: teamMember?.firstName,
                        lastName: teamMember?.lastName,
                        email: teamMember?.email,
                        type: teamMember?.type?._id,
                        locale: {
                            city: teamMember?.locale?.city || '',
                            countryCode: teamMember?.locale?.countryCode || '',
                        },
                        role: teamMember?.role?._id,
                        isCustomRole: teamMember?.role?.custom,
                    }}
                    validationSchema={Yup.object().shape({
                        firstName: Yup.string().required('First name is required.'),
                        lastName: Yup.string().required('Last name is required.'),
                        email: Yup.string()
                            .email('Must be an email address.')
                            .required('Email is required.'),
                        type: Yup.string().nullable(),
                        locale: Yup.object().shape({
                            city: Yup.string().required('City is required.'),
                            countryCode: Yup.string().required('Country is required.'),
                        }),
                        role: Yup.string()
                            .required('Role is required')
                            .when('isCustomRole', {
                                is: true,
                                then: Yup.string().required(
                                    'Role is required, please create your custom role or change to an existing role.'
                                ),
                            }),
                        isCustomRole: Yup.boolean().nullable(),
                    })}
                    onSubmit={handleSubmit}
                >
                    {props => (
                        <Form
                            permission={permissions}
                            teamMember={teamMember}
                            roles={roles}
                            countries={countries}
                            creating={!id}
                            {...props}
                        />
                    )}
                </Formik>
            )}
        </Fragment>
    );
};

export default TeamMember;
