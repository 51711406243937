import React, { Fragment, useState } from 'react';
import Empty from '@components/Empty';
import Input from '@components/Input';
import { Col, Divider, Row } from '@components/Grid';
import Alert from '@components/Alert';
import Label from '@components/Label';
import Choices from '@components/Choices';
import fuzzysearch from 'fuzzysearch';
import Button from '@components/Button';
import { makeDeleteRequest } from '@helpers/requests';
import { PERMISSIONS_ROLE } from '@helpers/api';
import { navigate } from 'gatsby-link';
import Modal from '@components/Modal';
import { useSnackbar } from '@components/Snackbar';

const Form = ({
    values,
    touched,
    errors,
    handleChange,
    isSubmitting,
    handleSubmit,
    permissions,
    setFieldValue,
    role,
}) => {
    const [openSnackbar] = useSnackbar();
    const [permissionsQuery, setPermissionsQuery] = useState('');
    const { name, description } = values;
    const [deleting, setDeleting] = useState(false);
    const [processingDeletion, setProcessingDeletion] = useState(false);

    const handlePermissionChange = ({ active, ...props }) => {
        console.log({ active, props });
        setFieldValue('permissions', active);
    };

    const handleDelete = async () => {
        try {
            setProcessingDeletion(true);
            await makeDeleteRequest(PERMISSIONS_ROLE(id));
            setDeleting(false);
            openSnackbar('Your discount has been successfully deleted.');
            navigate('/store/discounts');
        } catch (error) {
            error !== 'cancelled' &&
                openSnackbar(error?.errorMessage ?? 'An error occurred deleting this discount.');
        }
    };

    return (
        <Fragment>
            {role?.locked ? (
                <Empty
                    fullHeight
                    title="Default role"
                    text="You can't alter a default role, however feel free to look around."
                >
                    <Empty.Action
                        text="Create"
                        icon="plus"
                        link={`/settings/permissions/role/create`}
                    />
                </Empty>
            ) : (
                <Fragment>
                    <Input
                        name="name"
                        label="Name"
                        hint="What should this role be called?"
                        value={name}
                        onChange={handleChange}
                    />
                    {touched.name && errors.name && (
                        <Fragment>
                            <Divider margin={2} />
                            <Alert type="error" message={errors.name} />
                        </Fragment>
                    )}
                    <Divider />

                    <Input
                        name="description"
                        label="Description"
                        hint="What can this role do?"
                        value={description}
                        onChange={handleChange}
                        textarea
                    />
                    {touched.description && errors.description && (
                        <Fragment>
                            <Divider margin={2} />
                            <Alert type="error" message={errors.description} />
                        </Fragment>
                    )}
                    <Divider />

                    <Label text="Permissions" hint="Assign specific permissions to this role" />
                    <Divider margin={1} />
                    <Input
                        placeholder="Filter permissions..."
                        icon="search"
                        secondary
                        onChange={e => setPermissionsQuery(e.target.value)}
                        value={permissionsQuery}
                    />
                    <Divider margin={2} />

                    <Choices checkbox onChange={handlePermissionChange}>
                        {permissions
                            ?.filter(permission =>
                                !!permissionsQuery
                                    ? fuzzysearch(permissionsQuery, permission)
                                    : true
                            )
                            ?.map(permission => (
                                <Choices.Choice
                                    id={permission}
                                    key={permission}
                                    title="Name"
                                    description="Maecenas sed diam eget risus varius blandit sit amet non magna."
                                    checked={values.permissions.indexOf(permission) > -1}
                                />
                            ))}
                    </Choices>
                    {touched.permissions && errors.permissions && (
                        <Fragment>
                            <Divider margin={2} />
                            <Alert type="error" message={errors.permissions} />
                        </Fragment>
                    )}

                    <Divider />
                    <Row end="xs">
                        <Col xs={12}>
                            {!!role?._id && (
                                <Button
                                    loading={processingDeletion}
                                    danger
                                    onClick={() => setDeleting(true)}
                                >
                                    Delete
                                </Button>
                            )}
                            <Button loading={isSubmitting} success onClick={handleSubmit}>
                                {!!role?._id ? 'Save' : 'Create'}
                            </Button>
                        </Col>
                    </Row>
                </Fragment>
            )}

            {!!role && (
                <Modal isOpen={deleting} onClose={() => setDeleting(false)}>
                    <Modal.Content>
                        <p>{`Are you sure you want to delete ""?`}</p>
                        <Button onClick={() => setDeleting(false)}>Cancel</Button>
                        <Button loading={processingDeletion} danger onClick={handleDelete}>
                            Delete
                        </Button>
                    </Modal.Content>
                </Modal>
            )}
        </Fragment>
    );
};

export default Form;
