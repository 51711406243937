import React, { useState, useEffect, Fragment, useRef } from 'react';
import ReactDOM from 'react-dom';

import { LayoutContext } from '@helpers/contexts';
import { subcomponent } from '@helpers';
import Button from '@components/Button';
import Dropdown from '@components/Dropdown';
import Input from '@components/Input';
import Breadcrumbs from '@components/Breadcrumbs';

import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMatch } from '@reach/router';

const Command = ({ title, children }) => {
    const Breadcrumbs = subcomponent(children, Command.Breadcrumbs, true);
    const Action = subcomponent(children, Command.Action);
    const Actions = subcomponent(children, Command.Actions, true);

    return (
        <LayoutContext.Consumer>
            {({ $app, $command, scrolled } = {}) => {
                return (
                    $app.current &&
                    ReactDOM.createPortal(
                        <div
                            className={`${styles.command} ${scrolled ? styles.commandShadow : ''}`}
                            ref={$command}
                        >
                            <div className={styles.commandContent}>
                                {title && <h1 className={styles.commandTitle}>{title}</h1>}
                                {!title && Breadcrumbs}

                                {/*{(!!Action.length || Actions) && (*/}
                                <div className={styles.commandActions}>
                                    {!!Action.length ? Action : Actions}
                                    <Command.Help />
                                </div>
                                {/*)}*/}
                            </div>

                            {subcomponent(children, Command.Filters, true)}
                        </div>,
                        $app.current
                    )
                );
            }}
        </LayoutContext.Consumer>
    );
};

Command.Breadcrumbs = props => <Breadcrumbs {...props} />;
Command.Breadcrumbs.displayName = 'Breadcrumbs';

Command.Breadcrumbs.Breadcrumb = props => <Breadcrumbs.Breadcrumb {...props} />;
Command.Breadcrumbs.Breadcrumb.displayName = 'Breadcrumb';

Command.Action = props => (
    <div className={styles.commandAction}>
        <Button {...props} />
    </div>
);
Command.Action.displayName = 'Action';

Command.Actions = ({ children, ...props }) => {
    const $button = useRef(null);
    const [open, setOpen] = useState(false);

    return (
        <div className={styles.commandActionsDropdown}>
            <div ref={$button}>
                <Button
                    onClick={() => setOpen(!open)}
                    text="Actions"
                    icon={['fad', `chevron-${open ? 'up' : 'down'}`]}
                    {...props}
                />
            </div>
            <Dropdown target={$button} open={open} onEscape={() => setOpen(false)}>
                {subcomponent(children, Command.Actions.Action)}
            </Dropdown>
        </div>
    );
};
Command.Actions.displayName = 'Actions';

Command.Actions.Action = Dropdown.Item;
Command.Actions.Action.displayName = 'Actions';

Command.Filters = ({ children }) => (
    <div className={styles.commandFilters}>
        <ul>{subcomponent(children, Command.Filters.Filter)}</ul>
        {subcomponent(children, Command.Filters.Search, true)}
    </div>
);
Command.Filters.displayName = 'Filters';

Command.Filters.Search = ({ onChange, placeholder = 'Search...', value = '' }) => {
    return (
        <div className={styles.commandFiltersSearch}>
            <Input
                height="100%"
                tertiary
                placeholder={placeholder}
                icon={['fas', 'search']}
                border={false}
                onChange={onChange}
                value={value}
            />
        </div>
    );
};
Command.Filters.Search.displayName = 'Search';

Command.Filters.Filter = ({ text, children, onChange, onCancel, active }) => {
    const $tag = useRef(null);
    const [open, setOpen] = useState(false);

    const handleCancel = () => {
        setOpen(false);
        onCancel && onCancel();
    };

    const handleChange = () => {
        setOpen(false);
        onChange && onChange();
    };

    return (
        <li
            className={`${styles.commandFiltersFilter} ${
                active ? styles.commandFiltersFilterActive : ''
            }`}
        >
            <span
                ref={$tag}
                className={styles.commandFiltersFilterName}
                onClick={() => setOpen(!open)}
            >
                {text}
            </span>
            <Dropdown target={$tag} open={open} onEscape={() => setOpen(false)}>
                <div className={styles.commandFiltersFilterDropdownContent}>{children}</div>
                {(!!onChange || !!onCancel) && (
                    <div className={styles.commandFiltersFilterDropdownActions}>
                        {!!onCancel && (
                            <button
                                className={styles.commandFiltersFilterDropdownActionsAction}
                                onClick={handleCancel}
                            >
                                Cancel
                            </button>
                        )}
                        {!!onChange && (
                            <button
                                className={styles.commandFiltersFilterDropdownActionsAction}
                                onClick={handleChange}
                            >
                                Save
                            </button>
                        )}
                    </div>
                )}
            </Dropdown>
        </li>
    );
};
Command.Filters.Filter.displayName = 'Filter';

Command.Help = () => {
    const $help = useRef(null);
    const [open, setOpen] = useState(false);
    const help = useSelector(({ help }) => help);

    if (help?.data) {
        const item = help?.data?.find(item => item.paths.some(path => !!useMatch(path)));
        const getIcon = () => {
            let icon;

            switch (item?.system) {
                case 'store': {
                    icon = 'shopping-basket';
                    break;
                }
                case 'editor': {
                    icon = 'draw-square';
                    break;
                }
                case 'analytics': {
                    icon = 'chart-pie';
                    break;
                }
                case 'dns': {
                    icon = 'server';
                    break;
                }
                case 'emails': {
                    icon = 'emails';
                    break;
                }
                case 'forms': {
                    icon = 'mailbox';
                    break;
                }
                case 'chat': {
                    icon = 'comment';
                    break;
                }
            }

            return ['fad', icon ?? 'question'];
        };

        return (
            <Fragment>
                <div className={styles.commandHelp} onClick={() => setOpen(!open)}>
                    <div ref={$help} className={styles.commandHelpIcon}>
                        <FontAwesomeIcon icon={['fad', 'question']} />
                    </div>

                    <Dropdown open={open} target={$help} onEscape={() => setOpen(false)}>
                        {!!item && (
                            <Fragment>
                                <Dropdown.Item
                                    key={item?.name}
                                    icon={getIcon()}
                                    text={item?.name}
                                    description={item?.description}
                                    link={item?.learnMore}
                                >
                                    <Dropdown.Item.Link link={item?.learnMore} text="Learn more" />
                                </Dropdown.Item>
                                <Dropdown.Item
                                    key="faq"
                                    link={item?.faq}
                                    icon={['fad', 'list']}
                                    text="FAQ"
                                    description="See commonly asked questions and reported issues about this specific area of the panel. View no-nonsense answers straight away to help you get on with your day."
                                >
                                    <Dropdown.Item.Link link={item?.faq} text="Read FAQ" />
                                </Dropdown.Item>
                            </Fragment>
                        )}
                        <Dropdown.Item
                            link="https://merlinpanel.com/contact"
                            key="support"
                            icon={['fad', 'user-headset']}
                            text="Premium Support"
                            description="Your package includes premium support via live chat, email, phone calls and a dedicated Slack channel. Whichever way you prefer to get in touch, we're ready and waiting to help."
                        >
                            <Dropdown.Item.Link
                                link="https://merlinpanel.com/contact"
                                text="Contact us"
                            />
                        </Dropdown.Item>
                    </Dropdown>
                </div>
            </Fragment>
        );
    }

    return null;
};

export default Command;
