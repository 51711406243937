export const getApiServer = () => {
	if (process.env.GATSBY_API_URL) return process.env.GATSBY_API_URL;

	if (process.env.NODE_ENV === 'development') {
		return 'http://localhost:3001';
	}

	return 'https://api.merlinpanel.com/v1';
};

export const API_ROUTE = getApiServer();

export const ADDRESS = API_ROUTE + '/address';
export const ADDRESS_SET_DEFAULT = id => `${API_ROUTE}/address/${id}/default`;

export const ADMIN_USERS = `${API_ROUTE}/admin/users`;
export const ADMIN_WEBSITES = `${API_ROUTE}/admin/websites`;
export const ADMIN_WEBSITE = id => `${API_ROUTE}/admin/websites/${id}`;
export const ADMIN_USER = id => `${API_ROUTE}/admin/users/${id}`;
export const ADMIN_WEBSITE_NETLIFY_LINK = id => `${API_ROUTE}/admin/websites/${id}/netlifyLink`;
export const ADMIN_WEBSITE_NETLIFY_WEBSITES = `${API_ROUTE}/admin/websites/netlifySites`;

export const COUNTRY_CODES = `${API_ROUTE}/lists/countries`;
export const HELP = `${API_ROUTE}/lists/help`;

export const EMAIL_VERIFY = `${API_ROUTE}/auth/email/verify`;
export const EMAIL_VERIFY_RESEND = `${API_ROUTE}/auth/email/resendVerify`;

export const USER_LOGIN = `${API_ROUTE}/auth/login`;
// export const USER_REGISTER = `${API_ROUTE}/auth/register`;
export const USER_REFRESH = `${API_ROUTE}/auth/refresh`;
export const USER_PROFILE = `${API_ROUTE}/account`;
export const USER_AVATAR = `${API_ROUTE}/account/avatar`;
export const USER_PASSWORD_RESET = `${API_ROUTE}/auth/password/reset/request`;
export const USER_PASSWORD_RESET_COMPLETE = `${API_ROUTE}/auth/password/reset/complete`;
export const USER_PASSWORD_REPLACE = `${API_ROUTE}/auth/password/replace`;
export const USER_EMAIL_CHANGE = `${API_ROUTE}/auth/email/change/request`;
export const USER_EMAIL_CHANGE_COMPLETE = `${API_ROUTE}/auth/email/change/complete`;

export const USER_REQUESTS = `${API_ROUTE}/user/type/request`;
export const USER_REQUEST = id => `${API_ROUTE}/user/type/request/${id}`;
export const USER_TYPES = `${API_ROUTE}/user/type`;
export const USER_TYPE = id => `${API_ROUTE}/user/type/${id}`;
export const USERS = `${API_ROUTE}/user`;
export const USER = id => `${API_ROUTE}/user/${id}`;
export const USER_FIELDS = `${API_ROUTE}/user/customField`;
export const USER_FIELD = id => `${API_ROUTE}/user/customField/${id}`;

export const WEBSITES = `${API_ROUTE}/website`;
export const WEBSITE = id => `${API_ROUTE}/website/${id}`;

export const HOSTING_DEPLOY = `${API_ROUTE}/hosting/deploy`;

export const STORE_PRODUCTS = `${API_ROUTE}/store/product`;
export const STORE_PRODUCT = id => `${API_ROUTE}/store/product/${id}`;
export const STORE_PRODUCT_NEW = `${API_ROUTE}/store/product`;
export const STORE_CATEGORIES = `${API_ROUTE}/store/category`;
export const STORE_CATEGORY = id => `${API_ROUTE}/store/category/${id}`;
export const STORE_CHECKOUTS = `${API_ROUTE}/store/checkout`;
export const STORE_CHECKOUTS_CHECKOUT = id => `${API_ROUTE}/store/checkout/${id}`;
export const STORE_ORDERS = `${API_ROUTE}/store/order`;
export const STORE_ORDERS_ORDER = id => `${API_ROUTE}/store/order/${id}`;
export const STORE_SALE_STATS = id => `${API_ROUTE}/store/stats/sales?websiteId=${id}`;
export const STORE_FIELDS = `${API_ROUTE}/store/customField`;
export const STORE_FIELD = id => `${API_ROUTE}/store/customField/${id}`;
export const STORE_STATS_SALES = `${API_ROUTE}/store/stats/sales`;
export const STORE_DISCOUNTS = `${API_ROUTE}/store/discount`;
export const STORE_DISCOUNT = id => `${API_ROUTE}/store/discount/${id}`;

export const FORMS = `${API_ROUTE}/form`;
export const FORM = id => `${API_ROUTE}/form/${id}`;
export const FORM_SUBMISSIONS = `${API_ROUTE}/form/submission`;
export const FORM_SUBMISSION = id => `${API_ROUTE}/form/submission/${id}`;
export const FORM_NEWSLETTERS = `${API_ROUTE}/form/newsletter`;
export const FORM_NEWSLETTER = id => `${API_ROUTE}/form/newsletter/${id}`;
export const FORM_CONTACTS = `${API_ROUTE}/form/contact`;
export const FORM_CONTACT = id => `${API_ROUTE}/form/contact/${id}`;

export const UPLOAD_IMAGE = `${API_ROUTE}/upload/image`;

export const EDITOR_NAVIGATION = `${API_ROUTE}/editor/navigation`;
export const EDITOR_EXPLORER = `${API_ROUTE}/editor/explorer`;
export const EDITOR_EXPLORER_FILE = `${API_ROUTE}/editor/updateFile`;

export const MEDIA = `${API_ROUTE}/media`;
export const MEDIA_ITEM = id => `${API_ROUTE}/media/${id}`;
export const MEDIA_UPLOAD = `${API_ROUTE}/media`;

export const SETTINGS_WEBSITE = id => `${API_ROUTE}/settings/website/${id}`;
export const SETTINGS_STORE = id => `${API_ROUTE}/settings/store/${id}`;

export const PERMISSIONS = `${API_ROUTE}/permissions/meta/permissions`;
export const PERMISSIONS_ROLES = `${API_ROUTE}/permissions/role`;
export const PERMISSIONS_ROLE = id => `${API_ROUTE}/permissions/role/${id}`;

export const TEAM_MEMBERS = `${API_ROUTE}/teamMember`;
export const TEAM_MEMBER = id => `${API_ROUTE}/teamMember/${id}`;

export const SUBSCRIPTION = `${API_ROUTE}/subscription`;
export const SUBSCRIPTION_PLANS = `${API_ROUTE}/subscription/plans`;
export const SUBSCRIPTION_BRAINTREE_TOKEN = `${API_ROUTE}/subscription/braintreeToken`;

export const INVOICES = `${API_ROUTE}/invoice`;
export const INVOICE = id => `${API_ROUTE}/invoice/${id}`;
export const INVOICE_PAY_TOKEN = `${API_ROUTE}/invoice/pay/braintreeToken`;
export const INVOICE_PAY = id => `${API_ROUTE}/invoice/pay/${id}`;

export const CDN_LINK = 'https://merlin-panel.s3.eu-west-2.amazonaws.com';
