import React, { useEffect, useState, Fragment } from 'react';

import { makeGetRequest } from '@helpers/requests';
import { FORM_NEWSLETTER } from '@helpers/api';
import Command from '@components/Command';
import { Grid, Row, Col, Divider } from '@components/Grid';
import Card from '@components/Card';
import Loader from '@components/Loader';
import { format, parseISO } from 'date-fns';
import { withSnackbar } from '@components/Snackbar';
import List from '@components/List';

const subscriber = ({ id, openSnackbar }) => {
    const [loading, setLoading] = useState(true);
    const [subscriber, setsubscriber] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                const { data: subscriber } = await makeGetRequest(FORM_NEWSLETTER(id));
                setsubscriber(subscriber);
                setLoading(false);
            } catch (error) {
                error !== 'cancelled' &&
                    openSnackbar(
                        error?.errorMessage ?? 'An error occurred when loading subscriptions.'
                    );
            }
        })();
    }, []);

    return (
        <Fragment>
            <Command>
                <Command.Breadcrumbs>
                    <Command.Breadcrumbs.Breadcrumb text="Forms" link="/forms" />
                    <Command.Breadcrumbs.Breadcrumb text="Newsletters" link="/forms/newsletters" />
                    <Command.Breadcrumbs.Breadcrumb text="Subscriber" />
                </Command.Breadcrumbs>
            </Command>
            {loading ? (
                <Loader />
            ) : (
                <Grid>
                    <Row>
                        <Col xs={12}>
                            <List>
                                <List.Item>
                                    <List.Item.Column>
                                        <List.Item.Title text="Id" />
                                    </List.Item.Column>
                                    <List.Item.Column.Right>
                                        <List.Item.Title
                                            small
                                            bold={false}
                                            text={subscriber?._id}
                                        />
                                    </List.Item.Column.Right>
                                </List.Item>
                                <List.Item>
                                    <List.Item.Column>
                                        <List.Item.Title text="Date" />
                                    </List.Item.Column>
                                    <List.Item.Column.Right>
                                        <List.Item.Title
                                            small
                                            bold={false}
                                            text={
                                                !!subscriber?.timestamp &&
                                                format(parseISO(subscriber?.timestamp), 'PPP p')
                                            }
                                        />
                                    </List.Item.Column.Right>
                                </List.Item>
                                <List.Item>
                                    <List.Item.Column>
                                        <List.Item.Title text="Email" />
                                    </List.Item.Column>
                                    <List.Item.Column.Right>
                                        <List.Item.Title
                                            small
                                            bold={false}
                                            text={subscriber?.email}
                                        />
                                    </List.Item.Column.Right>
                                </List.Item>
                            </List>

                            {!!subscriber?.ua && (
                                <Fragment>
                                    <Divider />
                                    <Card>
                                        <Card.Title>User agent</Card.Title>
                                        <Card.Content>
                                            <List>
                                                <List.Item>
                                                    <List.Item.Column>
                                                        <List.Item.Title text="Mobile" />
                                                    </List.Item.Column>
                                                    <List.Item.Column.Right important>
                                                        <List.Item.Checked
                                                            checked={
                                                                subscriber?.ua.isMobile === 'true'
                                                            }
                                                        />
                                                    </List.Item.Column.Right>
                                                </List.Item>
                                                <List.Item>
                                                    <List.Item.Column>
                                                        <List.Item.Title text="Desktop" />
                                                    </List.Item.Column>
                                                    <List.Item.Column.Right important>
                                                        <List.Item.Checked
                                                            checked={
                                                                subscriber?.ua.isDesktop === 'true'
                                                            }
                                                        />
                                                    </List.Item.Column.Right>
                                                </List.Item>
                                                <List.Item>
                                                    <List.Item.Column>
                                                        <List.Item.Title text="Bot" />
                                                    </List.Item.Column>
                                                    <List.Item.Column.Right important>
                                                        <List.Item.Checked
                                                            checked={
                                                                subscriber?.ua.isBot === 'true'
                                                            }
                                                        />
                                                    </List.Item.Column.Right>
                                                </List.Item>
                                                <List.Item>
                                                    <List.Item.Column>
                                                        <List.Item.Title text="Browser" />
                                                    </List.Item.Column>
                                                    <List.Item.Column.Right important>
                                                        <List.Item.Checked
                                                            checked={
                                                                subscriber?.ua.browser === 'true'
                                                            }
                                                        />
                                                    </List.Item.Column.Right>
                                                </List.Item>
                                                <List.Item>
                                                    <List.Item.Column>
                                                        <List.Item.Title text="Browser Version" />
                                                    </List.Item.Column>
                                                    <List.Item.Column.Right important>
                                                        <List.Item.Title
                                                            small
                                                            bold={false}
                                                            text={subscriber?.ua.version}
                                                        />
                                                    </List.Item.Column.Right>
                                                </List.Item>
                                                <List.Item>
                                                    <List.Item.Column>
                                                        <List.Item.Title text="OS" />
                                                    </List.Item.Column>
                                                    <List.Item.Column.Right important>
                                                        <List.Item.Title
                                                            small
                                                            bold={false}
                                                            text={subscriber?.ua.os}
                                                        />
                                                    </List.Item.Column.Right>
                                                </List.Item>
                                                <List.Item>
                                                    <List.Item.Column>
                                                        <List.Item.Title text="Platform" />
                                                    </List.Item.Column>
                                                    <List.Item.Column.Right important>
                                                        <List.Item.Title
                                                            small
                                                            bold={false}
                                                            text={subscriber?.ua.platform}
                                                        />
                                                    </List.Item.Column.Right>
                                                </List.Item>
                                                <List.Item>
                                                    <List.Item.Column>
                                                        <List.Item.Title text="Source" />
                                                    </List.Item.Column>
                                                    <List.Item.Column.Right important>
                                                        <List.Item.Title
                                                            small
                                                            bold={false}
                                                            text={subscriber?.ua.source}
                                                        />
                                                    </List.Item.Column.Right>
                                                </List.Item>
                                            </List>
                                        </Card.Content>
                                    </Card>
                                </Fragment>
                            )}
                        </Col>
                    </Row>
                </Grid>
            )}
        </Fragment>
    );
};

export default withSnackbar(subscriber);
