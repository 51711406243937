import { useSelector } from 'react-redux';
import usePrevious from '@helpers/hooks/usePrevious';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { makeDeleteRequest, makeGetRequest } from '@helpers/requests';
import { STORE_CATEGORIES, STORE_DISCOUNT, STORE_PRODUCTS, USER_TYPES } from '@helpers/api';
import { navigate } from 'gatsby-link';
import Panes from '@components/Panes';
import Switch from '@components/Switch';
import { Col, Divider, Row } from '@components/Grid';
import Alert from '@components/Alert';
import Input from '@components/Input';
import Select from '@components/Select';
import Datepicker from '@components/Datepicker';
import { formatISO, parseISO } from 'date-fns';
import Label from '@components/Label';
import Dropdown from '@components/Dropdown';
import Text from '@components/Text';
import List from '@components/List';
import Button from '@components/Button';
import Modal from '@components/Modal';
import { useSnackbar } from '@components/Snackbar';

const Form = ({
	values,
	errors,
	touched,
	setFieldValue,
	handleChange,
	handleSubmit,
	isSubmitting,
	handleBlur,
	discount,
	categories,
	products,
	accountTypes,
	onAccountTypesChange,
	onProductsChange,
	onCategoriesChange,
	calcProductPriceDiscount,
}) => {
	const [openSnackbar] = useSnackbar();
	const website = useSelector(({ website }) => website);
	const { name, code, active, type, amount, limit, startDate, endDate } = values;
	const [deleting, setDeleting] = useState(false);
	const [processingDeletion, setProcessingDeletion] = useState(false);

	const [categoriesApplicable, setCategoriesApplicable] = useState(!!categories.length);
	const [productsApplicable, setProductsApplicable] = useState(!!products.length);
	const [accountTypesApplicable, setAccountTypesApplicable] = useState(!!accountTypes.length);

	const $products = useRef(null);
	const [searchedProducts, setSearchedProducts] = useState(null);
	const [productsQuery, setProductsQuery] = useState('');
	const prevProductsQuery = usePrevious(productsQuery);
	const [productsQueryTimeout, setProductsQueryTimeout] = useState(null);
	const [productsFocused, setProductsFocused] = useState(false);

	const $categories = useRef(null);
	const [searchedCategories, setSearchedCategories] = useState(null);
	const [categoriesQuery, setCategoriesQuery] = useState('');
	const prevCategoriesQuery = usePrevious(categoriesQuery);
	const [categoriesQueryTimeout, setCategoriesQueryTimeout] = useState(null);
	const [categoriesFocused, setCategoriesFocused] = useState(false);

	const $accountTypes = useRef(null);
	const [searchedAccountTypes, setSearchedAccountTypes] = useState(null);
	const [accountTypesQuery, setAccountTypesQuery] = useState('');
	const prevAccountTypesQuery = usePrevious(accountTypesQuery);
	const [accountTypesQueryTimeout, setAccountTypesQueryTimeout] = useState(null);
	const [accountTypesFocused, setAccountTypesFocused] = useState(false);

	useEffect(() => {
		(async () => {
			await handleCategories();
			await handleAccountTypes();
			await handleProducts();
		})();

		return () => {
			clearTimeout(categoriesQueryTimeout);
			clearTimeout(productsQueryTimeout);
			clearTimeout(accountTypesApplicable);
		};
	}, []);

	useEffect(() => {
		if (!prevProductsQuery || prevProductsQuery === productsQuery) return;
		clearTimeout(productsQueryTimeout);
		setProductsQueryTimeout(
			setTimeout(async () => {
				await handleProducts();
			}, 750)
		);
	}, [productsQuery]);

	useEffect(() => {
		if (!prevCategoriesQuery || prevCategoriesQuery === categoriesQuery) return;
		clearTimeout(categoriesQueryTimeout);
		setCategoriesQueryTimeout(
			setTimeout(async () => {
				await handleCategories();
			}, 750)
		);
	}, [categoriesQuery]);

	useEffect(() => {
		if (!prevAccountTypesQuery || prevAccountTypesQuery === accountTypesQuery) return;
		clearTimeout(accountTypesQueryTimeout);
		setCategoriesQueryTimeout(
			setTimeout(async () => {
				await handleAccountTypes();
			}, 750)
		);
	}, [accountTypesQuery]);

	const handleProducts = async () => {
		try {
			const { data: categoriesData } = await makeGetRequest(STORE_PRODUCTS, {
				perPage: 5,
				pageNum: 1,
				search: productsQuery || undefined,
			});

			setSearchedProducts(categoriesData?.results);
		} catch (error) {
			error !== 'cancelled' &&
				openSnackbar(
					error?.errorMessage ?? 'An error occurred when attempting to load categories.'
				);
		}
	};

	const handleCategories = async () => {
		try {
			const { data: categoriesData } = await makeGetRequest(STORE_CATEGORIES, {
				perPage: 5,
				pageNum: 1,
				search: categoriesQuery || undefined,
			});

			setSearchedCategories(categoriesData?.results);
		} catch (error) {
			error !== 'cancelled' &&
				openSnackbar(
					error?.errorMessage ?? 'An error occurred when attempting to load categories.'
				);
		}
	};

	const handleAccountTypes = async () => {
		// users are only supported on external dbs
		if (!website?.database?.active) return;

		try {
			const { data: accountTypesData } = await makeGetRequest(USER_TYPES, {
				perPage: 5,
				pageNum: 1,
				search: accountTypesQuery || undefined,
			});

			setSearchedAccountTypes(accountTypesData?.results);
		} catch (error) {
			error !== 'cancelled' &&
				openSnackbar(
					error?.errorMessage ?? 'An error occurred when attempting to load categories.'
				);
		}
	};

	const handleDelete = async () => {
		try {
			setProcessingDeletion(true);
			await makeDeleteRequest(STORE_DISCOUNT(discount._id));
			setDeleting(false);
			openSnackbar('Your discount has been successfully deleted.');
			navigate('/store/discounts');
		} catch (error) {
			error !== 'cancelled' &&
				openSnackbar(error?.errorMessage ?? 'An error occurred deleting this discount.');
		}
	};

	useEffect(() => {
		// prevent using newAmount type when a discount has categories applicability
		if (!!categories.length && type === 'newAmount') {
			setFieldValue('type', undefined);
		}

		// update formik categories
		setFieldValue(
			'categories',
			categories?.map(({ _id }) => _id)
		);
	}, [categories]);

	useEffect(() => {
		// update formik products
		setFieldValue(
			'products',
			products?.map(({ _id }) => _id)
		);
	}, [products]);

	useEffect(() => {
		// update formik account types
		setFieldValue(
			'accountTypes',
			accountTypes?.map(({ _id }) => _id)
		);
	}, [accountTypes]);

	useEffect(() => {
		if (!categoriesApplicable) {
			onCategoriesChange([]);
		}
	}, [categoriesApplicable]);

	useEffect(() => {
		if (!productsApplicable) {
			onProductsChange([]);
		}
	}, [productsApplicable]);

	useEffect(() => {
		if (!accountTypesApplicable) {
			onAccountTypesChange([]);
		}
	}, [accountTypesApplicable]);

	console.log('FORM', { accountTypes, 'values.accountTypes': values?.accountTypes });

	return (
		<Fragment>
			<Switch
				label="Active"
				hint="Discount won't be live if inactive"
				checked={active}
				onChange={(e, checked) => setFieldValue('active', checked)}
			/>
			{touched.active && errors.active && (
				<Fragment>
					<Divider margin={2} />
					<Alert type="error" message={errors.active} />
				</Fragment>
			)}
			<Divider />

			<Input
				label="Name"
				hint="Displayed to the user during checkout"
				name="name"
				value={name}
				onChange={handleChange}
				onBlur={handleBlur}
			/>
			{touched.name && errors.name && (
				<Fragment>
					<Divider margin={2} />
					<Alert type="error" message={errors.name} />
				</Fragment>
			)}
			<Divider />

			<Input
				label="Code"
				hint="Only allow discount if a valid coupon code is passed, letters and numbers only"
				name="code"
				optional
				value={code}
				onChange={e =>
					setFieldValue(
						'code',
						e.target.value
							.split(' ')
							.join('')
							.toUpperCase()
					)
				}
				onBlur={handleBlur}
			/>
			{touched.code && errors.code && (
				<Fragment>
					<Divider margin={2} />
					<Alert type="error" message={errors.code} />
				</Fragment>
			)}
			<Divider />

			<Select
				placeholder="Choose a type"
				label="Type"
				name="type"
				value={type}
				onChange={e => setFieldValue('type', e.target.value)}
			>
				<Select.Option label="Percentage off" value="percentageOff" />
				<Select.Option label="Amount off" value="amountOff" />
				<Select.Option label="New amount" value="newAmount" />
			</Select>
			{touched.type && errors.type && (
				<Fragment>
					<Divider margin={2} />
					<Alert type="error" message={errors.type} />
				</Fragment>
			)}
			<Divider />

			<Input
				label="Amount"
				hint={
					type === 'percentageOff'
						? 'How much percentage off should the discount offer? Must be between 0 and 1.'
						: type === 'amountOff'
						? `How much ${website?.store?.currency?.code ?? ''} (${website?.store
								?.currency?.symbol ?? ''}) off should the discount offer?`
						: type === 'newAmount'
						? `What new ${website?.store?.currency?.code ?? ''} (${website?.store
								?.currency?.symbol ??
								''}) price would you like the products to become?`
						: ''
				}
				name="amount"
				value={amount}
				onChange={handleChange}
				number={{
					max: type === 'percentageOff' ? 1 : null,
					decimals: type === 'percentageOff' ? 4 : 2,
				}}
			/>
			{touched.amount && errors.amount && (
				<Fragment>
					<Divider margin={2} />
					<Alert type="error" message={errors.amount} />
				</Fragment>
			)}
			<Divider />

			<Input
				number
				label="Limit"
				hint="Prevent the discount from being used by a specific number of times"
				optional
				name="limit"
				value={limit}
				onChange={handleChange}
				onBlur={handleBlur}
			/>
			{touched.limit && errors.limit && (
				<Fragment>
					<Divider margin={2} />
					<Alert type="error" message={errors.limit} />
				</Fragment>
			)}
			<Divider />

			<Datepicker
				label="Start date"
				hint="Activate the discount after the start date"
				optional
				date={!!startDate ? parseISO(startDate) : undefined}
				maxDate={!!endDate ? parseISO(endDate) : undefined}
				onChange={({ date }) => setFieldValue('startDate', formatISO(date))}
				onRemove={() => setFieldValue('startDate', undefined)}
			/>
			{touched.startDate && errors.startDate && (
				<Fragment>
					<Divider margin={2} />
					<Alert type="error" message={errors.startDate} />
				</Fragment>
			)}
			<Divider />
			<Datepicker
				label="End date"
				hint="Expire the discount after the end date"
				optional
				date={!!endDate ? parseISO(endDate) : undefined}
				minDate={!!startDate ? parseISO(startDate) : undefined}
				onChange={({ date }) => setFieldValue('endDate', formatISO(date))}
				onRemove={() => setFieldValue('endDate', undefined)}
			/>
			{touched.endDate && errors.endDate && (
				<Fragment>
					<Divider margin={2} />
					<Alert type="error" message={errors.endDate} />
				</Fragment>
			)}
			<Divider />

			<Switch
				label="Category Applicability"
				hint="Should this discount be applicable to selected categories?"
				checked={categoriesApplicable}
				onChange={(e, checked) => setCategoriesApplicable(checked)}
			/>
			<Divider />

			{categoriesApplicable && (
				<Fragment>
					<Label
						text="Categories"
						hint="Search categories and select to make them applicable to this discount"
						optional
						onClick={() => navigate(`/store/categories`)}
					/>

					<Input
						ref={$categories}
						secondary
						icon="search"
						placeholder="Search a category"
						value={categoriesQuery}
						onChange={e => setCategoriesQuery(e.target.value)}
						onFocus={() => setCategoriesFocused(true)}
					/>
					{categoriesFocused && !!searchedCategories?.length && (
						<Dropdown target={$categories} onEscape={() => setCategoriesFocused(false)}>
							{searchedCategories?.map(category => {
								const selected = categories.some(
									({ _id }) => _id === category?._id
								);

								return (
									<Dropdown.Item
										key={category?._id}
										onClick={() =>
											onCategoriesChange(
												selected
													? categories?.filter(
															({ _id }) => _id !== category?._id
													  )
													: [category, ...(categories ?? [])]
											)
										}
										checked={selected}
									>
										<Text bold>{category?.name}</Text>
										<Text faded>{category?._id}</Text>
									</Dropdown.Item>
								);
							})}
						</Dropdown>
					)}
					{!!categories.length && (
						<Fragment>
							<Divider margin={2} />
							<List>
								{categories.map(({ _id, name }) => (
									<List.Item key={_id} link={`/store/categories/${_id}`}>
										<List.Item.Column>
											<Text bold>{name}</Text>
											<Text faded>{_id}</Text>
										</List.Item.Column>
										<List.Item.Actions>
											<List.Item.Actions.Action
												icon="trash"
												onClick={() =>
													onCategoriesChange(
														categories.filter(
															({ _id: categoryId }) =>
																categoryId !== _id
														)
													)
												}
											/>
										</List.Item.Actions>
									</List.Item>
								))}
							</List>
						</Fragment>
					)}
					{touched.categories && errors.categories && (
						<Fragment>
							<Divider margin={2} />
							<Alert type="error" message={errors.categories} />
						</Fragment>
					)}
					<Divider />
				</Fragment>
			)}

			<Switch
				label="Product Applicability"
				hint="Should this discount be applicable to selected products?"
				checked={productsApplicable}
				onChange={(e, checked) => setProductsApplicable(checked)}
			/>
			<Divider />

			{productsApplicable && (
				<Fragment>
					<Label
						text="Products"
						hint="Search products and select to make them applicable to this discount"
						optional
						onClick={() => navigate(`/store/products`)}
					/>

					<Input
						ref={$products}
						secondary
						icon="search"
						placeholder="Search a product"
						value={productsQuery}
						onChange={e => setProductsQuery(e.target.value)}
						onFocus={() => setProductsFocused(true)}
					/>
					{productsFocused && !!searchedProducts?.length && (
						<Dropdown target={$products} onEscape={() => setProductsFocused(false)}>
							{searchedProducts?.map(product => {
								const selected = products.some(({ _id }) => _id === product?._id);

								return (
									<Dropdown.Item
										key={product?._id}
										onClick={() =>
											onProductsChange(
												selected
													? products?.filter(
															({ _id }) => _id !== product?._id
													  )
													: [product, ...(products ?? [])]
											)
										}
										checked={selected}
									>
										<Text bold>{product?.name}</Text>
										<Text>
											{`${product?.category?.name} • ${
												website?.store?.currency?.symbol
											}${(product?.price / 100).toFixed(2)}`}
										</Text>
									</Dropdown.Item>
								);
							})}
						</Dropdown>
					)}
					{!!products.length && (
						<Fragment>
							<Divider margin={2} />
							<List>
								{products.map(({ _id, name, category, images, price }) => (
									<List.Item key={_id} link={`/store/products/${_id}`}>
										{console.log({ price })}
										<List.Item.Avatar alt={name} src={images[0]?.url} />
										<List.Item.Column>
											<Text bold>{name}</Text>
											<Text>{`${category?.name} • ${
												website?.store?.currency?.symbol
											}${(price / 100).toFixed(2)}${
												!!amount
													? ` • ${calcProductPriceDiscount(
															price
													  )} after discount`
													: ''
											}`}</Text>
											<Text faded>{_id}</Text>
										</List.Item.Column>
										<List.Item.Actions>
											<List.Item.Actions.Action
												icon="trash"
												onClick={() =>
													onProductsChange(
														products.filter(
															({ _id: productId }) =>
																productId !== _id
														)
													)
												}
											/>
										</List.Item.Actions>
									</List.Item>
								))}
							</List>
						</Fragment>
					)}
					{touched.products && errors.products && (
						<Fragment>
							<Divider margin={2} />
							<Alert type="error" message={errors.products} />
						</Fragment>
					)}
					<Divider />
				</Fragment>
			)}

			{website?.database?.active && (
				<Fragment>
					<Switch
						label="User Types Applicability"
						hint="Should this discount be applicable to selected user types?"
						checked={accountTypesApplicable}
						onChange={(e, checked) => setAccountTypesApplicable(checked)}
					/>
					<Divider />

					{accountTypesApplicable && (
						<Fragment>
							<Label
								text="User Types"
								hint="Search user types and select to make them applicable to this discount"
								optional
								onClick={() => navigate(`/users/types`)}
							/>

							<Input
								ref={$accountTypes}
								secondary
								icon="search"
								placeholder="Search a user type"
								value={accountTypesQuery}
								onChange={e => setAccountTypesQuery(e.target.value)}
								onFocus={() => setAccountTypesFocused(true)}
							/>
							{accountTypesFocused && !!searchedAccountTypes?.length && (
								<Dropdown
									target={$accountTypes}
									onEscape={() => setAccountTypesFocused(false)}
								>
									{searchedAccountTypes?.map(accountType => {
										const selected = accountTypes.some(
											({ _id }) => _id === accountType?._id
										);

										return (
											<Dropdown.Item
												key={accountType?._id}
												onClick={() =>
													onAccountTypesChange(
														selected
															? accountTypes?.filter(
																	({ _id }) =>
																		_id !== accountType?._id
															  )
															: [accountType, ...(accountTypes ?? [])]
													)
												}
												checked={selected}
											>
												<Text bold>{accountType?.name}</Text>
												<Text faded>{accountType?._id}</Text>
											</Dropdown.Item>
										);
									})}
								</Dropdown>
							)}
							{!!accountTypes.length && (
								<Fragment>
									<Divider margin={2} />
									<List>
										{accountTypes.map(({ _id, name }) => (
											<List.Item key={_id} link={`/users/types/${_id}`}>
												<List.Item.Column>
													<Text bold>{name}</Text>
													<Text faded>{_id}</Text>
												</List.Item.Column>
												<List.Item.Actions>
													<List.Item.Actions.Action
														icon="trash"
														onClick={() =>
															onAccountTypesChange(
																accountTypes.filter(
																	({ _id: accountTypeId }) =>
																		accountTypeId !== _id
																)
															)
														}
													/>
												</List.Item.Actions>
											</List.Item>
										))}
									</List>
								</Fragment>
							)}
							{touched.accountTypes && errors.accountTypes && (
								<Fragment>
									<Divider margin={2} />
									<Alert type="error" message={errors.accountTypes} />
								</Fragment>
							)}
							<Divider />
						</Fragment>
					)}
				</Fragment>
			)}

			<Row end="xs">
				<Col xs={12}>
					{!!discount?._id && (
						<Button
							loading={processingDeletion}
							danger
							onClick={() => setDeleting(true)}
						>
							Delete
						</Button>
					)}
					<Button loading={isSubmitting} success onClick={handleSubmit}>
						{!!discount?._id ? 'Save' : 'Create'}
					</Button>
				</Col>
			</Row>

			{discount && (
				<Modal isOpen={deleting} onClose={() => setDeleting(false)}>
					<Modal.Content>
						<p>{`Are you sure you want to delete "${name}"?`}</p>
						<Button onClick={() => setDeleting(false)}>Cancel</Button>
						<Button loading={processingDeletion} danger onClick={handleDelete}>
							Delete
						</Button>
					</Modal.Content>
				</Modal>
			)}
		</Fragment>
	);
};

export default Form;
