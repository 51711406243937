import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from '../styles.module.scss';

export default ({ onPrev, disabled }) => {
    const handleKeyUp = e => {
        if (!disabled && e.keyCode === 13 && onPrev) {
            onPrev();
        }
    };

    return !onPrev ? null : (
        <div
            className={`${styles.datepickerHeaderPrev} ${
                disabled ? styles.datepickerHeaderPrevDisabled : ''
            }`}
            onClick={disabled ? undefined : onPrev}
        >
            <FontAwesomeIcon tabIndex="0" onKeyUp={handleKeyUp} icon="chevron-left" />
        </div>
    );
};
