import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '@reach/router';

import { Grid } from '@components/Grid';
import { makeGetRequest } from '@helpers/requests';
import { WEBSITES } from '@helpers/api';
import { setWebsite, removeWebsite } from '@actions/website';
import { capitalise } from '@helpers';
import { withSnackbar } from '@components/Snackbar';
import List from '@components/List';
import Loader from '@components/Loader';

import styles from './styles.module.scss';
import usePrevious from '@helpers/hooks/usePrevious';

const Websites = ({ openSnackbar }) => {
    const { user, website } = useSelector(({ user, website }) => ({ user, website }));
    const dispatch = useDispatch();
    const prevWebsite = usePrevious(website);
    const [loading, setLoading] = useState(true);
    const [websites, setWebsites] = useState([]);
    const [selectedWebsite, setSelectedWebsite] = useState(false);

    const handleWebsiteClick = website => {
        dispatch(setWebsite(website));
        navigate('/dashboard');
    };

    useEffect(() => {
        dispatch(removeWebsite());

        (async () => {
            try {
                // get websites, if 1 found, set website by default and go to dashboard immeditately
                const { data: websites } = await makeGetRequest(WEBSITES, {
                    navigator: true,
                });

                // if user doesn't yet have a website already selected, and there was only 1 website to choose from,
                // put the user at ease and automatically redirect them to the dashboard for there website.
                // if (!website && websites.length === 1) {
                //     dispatch(setWebsite(websites[0]));
                //     setSelectedWebsite(true);
                //     return;
                // }

                setWebsites(websites);
                setLoading(false);
            } catch (error) {
                error !== 'cancelled' && openSnackbar(error?.errorMessage ?? 'An error occurred loading websites.');
            }
        })();
    }, []);

    return (
        <Grid small>
            <h1 className={styles.heading}>Websites</h1>

            {loading || !!websites.length ? (
                <List loading={loading}>
                    {websites.map(website => (
                        <List.Item
                            key={website._id}
                            title={website.name}
                            description={`${capitalise(website.meta.type)} • ${website.domain}`}
                            onClick={() => handleWebsiteClick(website)}
                        >
                            <List.Item.Avatar alt={website.name} src={website.avatar} />
                            <List.Item.Column>
                                <List.Item.Title text={website.name} />
                                <List.Item.Description
                                    text={capitalise(website.meta.type)}
                                />
                            </List.Item.Column>

                            <List.Item.Column.Right>
                                <List.Item.Avatars>
                                    {website?.meta?.usersData
                                        .filter(user => true)
                                        .map(data => (
                                            <List.Item.Avatars.Avatar
                                                key={`${website._id}_${data._id}`}
                                                alt={data.firstName}
                                                tooltip={data.firstName}
                                                src={data.avatar}
                                            />
                                        ))}
                                </List.Item.Avatars>
                            </List.Item.Column.Right>

                            <List.Item.Actions>
                                <List.Item.Actions.Action
                                    icon="browser"
                                    tooltip="View Website"
                                    link={website.domain}
                                />
                            </List.Item.Actions>
                        </List.Item>
                    ))}
                </List>
            ) : (
                <p>Sorry, you don't currently have any websites available!</p>
            )}
        </Grid>
    );
};

export default withSnackbar(Websites);
