import React, { Fragment, useState } from 'react';
import queryString from 'query-string';
import ReactDOM from 'react-dom';
import styles from '../styles.module.scss';

import { makePostRequest } from '@helpers/requests';
import { MEDIA, MEDIA_UPLOAD } from '@helpers/api';
import Dropzone from '@components/Dropzone';
import MediaLibrary from '@components/MediaLibrary';
import { LayoutContext } from '@helpers/contexts';
import { useSelector } from 'react-redux';

const ImageField = ({
    field: { type, label, hint, optional, min, max, id, path, multiple },
    activeField,
    value,
    touched,
    error,
    handleChange,
    setFieldValue,
    setFieldTouched,
    name,
}) => {
    const website = useSelector(({website}) => website);
    const [mediaLibraryOpen, setMediaLibraryOpen] = useState(false);

    const formatMediaPath = media =>
        `${website?.paths?.media ?? MEDIA}/preview/${media}?${queryString.stringify({
            websiteId: website._id,
            token: website.api.key,
        })}`;

    const handleMediaSelect = media => {
        console.log('media selected', { media });
        if (multiple) {
            setFieldValue(name, [...(value || []), media.id]);
        } else {
            setFieldValue(name, media.id);
        }

        setMediaLibraryOpen(false);
    };

    const handleMediaReorder = newMedias => {
        setFieldValue(name, newMedias);
    };

    const handleMediaRemove = newMedias => {
        setFieldValue(name, !!multiple ? [...newMedias] : '');
    };

    const handleMediaUpload = async newMedia => {
        if (multiple) {
            const media = await Promise.all(
                newMedia.map(async newMediaItem => {
                    const formData = new FormData();
                    formData.append('media', newMediaItem);
                    const { data: media } = await makePostRequest(MEDIA_UPLOAD, formData, null, {
                        'Content-Type': 'multipart/form-data',
                    });
                    return media;
                })
            );

            setFieldValue(name, ...(value || []), ...media.map(item => item.id));
        } else {
            const formData = new FormData();
            formData.append('media', newMedia);
            const { data: media } = await makePostRequest(MEDIA_UPLOAD, formData, null, {
                'Content-Type': 'multipart/form-data',
            });
            setFieldValue(name, media.id);
        }
    };

    return (
        <LayoutContext.Consumer>
            {({ $main, $panes } = {}) => (
                <Fragment>
                    <Dropzone
                        name={name}
                        label={label}
                        hint={hint}
                        min={min}
                        max={max}
                        optional={optional}
                        image={value}
                        upload={false}
                        multiple={multiple}
                        dropPlacholder={
                            (!multiple &&
                                value &&
                                'Drag and drop an image or click to browse in order to replace the existing image') ||
                            (!multiple && 'Drag and drop an image or click to browse') ||
                            'Drag and drop imagery or click to browse'
                        }
                        onClick={() => setMediaLibraryOpen(true)}
                        onRemove={handleMediaRemove}
                        onUpload={handleMediaUpload}
                        onReorder={handleMediaReorder}
                        formatMediaPath={formatMediaPath}
                    />

                    {!!mediaLibraryOpen &&
                        ReactDOM.createPortal(
                            <div className={styles.mediaLibraryWrapper}>
                                <MediaLibrary onSelect={handleMediaSelect} multiple={!!multiple} />
                            </div>,
                            $panes?.current ?? $main?.current
                        )}
                </Fragment>
            )}
        </LayoutContext.Consumer>
    );
};

export default ImageField;
