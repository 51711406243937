import React, { Fragment, isValidElement } from 'react';

import md5 from 'md5';
import { CDN_LINK } from './api';
import { navigate } from 'gatsby-link';
import queryString from 'query-string';

export const handleUrlChange = (location, query = {}) => {
    if (!location) return;

    navigate(
        `${location.pathname}?${queryString.stringify({
            ...queryString.parse(location.search),
            page: undefined,
            ...query,
        })}`
    );
};

// a little function to help us with reordering the result
export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const toFixed = (x, d) => {
    if (!d) return x.toFixed(d); // don't go wrong if no decimal
    return x.toFixed(d).replace(/\.?0+$/, '');
};

export const isArrayBuffer = value => {
    return (
        typeof ArrayBuffer === 'function' &&
        (value instanceof ArrayBuffer || toString.call(value) === '[object ArrayBuffer]')
    );
};

export const checkLinkIsExternal = link => {
    return link.includes('https://') || link.includes('http://');
};

export const formatDistanceInWordsStrict = (date1, date2) => {
    const str = formatDistanceStrict(date1, date2);
    const items = str.split(' ');
    return `${items[0]}${items[1].charAt(0)}`;
};

export function shuffleArray(array) {
    // immutable
    const shuffledArray = array.slice();

    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }

    return shuffledArray;
}

export function returnProfilePicture(user) {
    if (user.avatar && !!user.avatar) {
        // If our string includes https it's a social avatar so we should display that instead
        return user.avatar.includes('https') ? user.avatar : `${CDN_LINK}/avatar/${user.avatar}`;
    }

    if (user.instagramAvatar && !!user.instagramAvatar) {
        // If our string includes https it's a social avatar so we should display that instead
        return `${CDN_LINK}/instagram/${user.instagramAvatar}`;
    }

    if (user.gravatar) {
        return `https://secure.gravatar.com/avatar/${user.gravatar}?d=identicon&s=256`;
    }

    if (user.email) {
        return `https://www.gravatar.com/avatar/${md5(user.email)}?d=identicon&s=256`;
    }

    return `https://www.gravatar.com/avatar/null?d=identicon&s=256`;
}

// capitalised the first letter of a sentence, useful for Formik form errors
export const capitalise = str => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
export const camelCase = str => str.replace(/-([a-z])/g, g => g[1].toUpperCase());
export const pascalCase = str => str.replace(/(^\w|-\w)/g, g => g.replace(/-/, '').toUpperCase());

// Common methods
export function isValidJSON(string) {
    return /^[\],:{}\s]*$/.test(
        string
            .replace(/\\["\\\/bfnrtu]/g, '@')
            .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']')
            .replace(/(?:^|:|,)(?:\s*\[)+/g, '')
    );
}

export function isValidStringify(value) {
    return (typeof value === 'object' && value !== null) || Array.isArray(value);
}

export function filterInternalChildren(children, components = []) {
    if (!Array.isArray(components)) components = [components];
    const results = [];
    const componentDisplayNames = components.map(
        component => component.displayName || component.name
    );

    const renderSubComponentChildren = children => {
        React.Children.forEach(children, child => {
            const childType = child && child.type && (child.type.displayName || child.type.name);

            // support sub components inside fragments (recursively loop the same method to add results)
            if (child?.type === Fragment && child?.props?.children) {
                renderSubComponentChildren(child.props.children);
                return;
            }

            if (!componentDisplayNames.includes(childType)) {
                results.push(child);
            }
        });
    };

    renderSubComponentChildren(children);

    if (!results.length) return null;

    /* Then we go through each React children, if one of matches the name of the sub-component we’re looking for we put it in the result array */
    return results;
}

// For unique sub-components (can only render one of type
export function subcomponent(children, components, singular = false, props = {}) {
    if (!Array.isArray(components)) components = [components];
    const results = [];

    const renderSubComponentChildren = children => {
        React.Children.forEach(children, (child, index) => {
            if (!isValidElement(child)) return;

            components.forEach(component => {
                /* We can store the actual name of the component through the displayName or name property of our sub-component */
                const childType = child?.type?.displayName || child?.type?.name;
                const componentType = component?.displayName || component?.name;
                const isComponent = componentType === childType;

                // support sub components inside fragments (recursively loop the same method to add results)
                if (child?.type === Fragment && child?.props?.children) {
                    renderSubComponentChildren(child.props.children);
                    return;
                }

                if (isComponent) {
                    const key =
                        child?.key ||
                        child?.props?.id ||
                        child?.props?.value ||
                        child?.props?.name ||
                        index;

                    results.push(
                        React.cloneElement(child, {
                            index,
                            id: key,
                            key,
                            ...props,
                        })
                    );
                }
            });
        });
    };

    renderSubComponentChildren(children);

    if (singular) {
        return results[0];
    }

    /* Then we go through each React children, if one of matches the name of the sub-component we’re looking for we put it in the result array */
    return results;
}
