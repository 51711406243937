import React, { Fragment, useState } from 'react';
import { navigate } from 'gatsby-link';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';

import Alert from '@components/Alert';
import Input from '@components/Input';
import { Grid, Row, Col, Divider } from '@components/Grid';
import Button from '@components/Button';
import { makePostRequest } from '@helpers/requests';
import { USER_PASSWORD_RESET } from '@helpers/api';
import Layout from '@components/Layout';
import SEO from '@components/SEO';
import { withSnackbar } from '@components/Snackbar';
import Card from '@components/Card';

const ForgotPassword = ({ openSnackbar }) => {
    const user = useSelector(({ user }) => user);
    const [passwordResetSent, setPasswordResetSent] = useState(false);

    const handlePasswordReset = async values => {
        try {
            await makePostRequest(USER_PASSWORD_RESET, values);
            setPasswordResetSent(true);
        } catch (error) {
            error !== 'cancelled' &&
                openSnackbar(
                    error?.errorMessage ??
                        'An error occurred when attempting to reset your password.'
                );
        }
    };

    return (
        <Layout>
            <SEO title={'Resetting password'} />

            <Grid>
                <h1>Forgot Password</h1>
                <Formik
                    validationSchema={Yup.object().shape({
                        email: Yup.string()
                            .email('Must be an email address.')
                            .required('Email is required.'),
                    })}
                    initialValues={{ email: (user && user.email) || '' }}
                    onSubmit={handlePasswordReset}
                >
                    {({ handleSubmit, values, errors, touched, isSubmitting, handleChange }) => (
                        <Fragment>
                            <Row>
                                <Col xs={12} lg={6}>
                                    {!passwordResetSent && (
                                        <Card>
                                            <Card.Content>
                                                <Input
                                                    label="Email address"
                                                    name="email"
                                                    type="email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                />
                                                {touched.email && errors.email && (
                                                    <Alert type="error" message={errors.email} />
                                                )}
                                            </Card.Content>
                                            <Card.Actions>
                                                <Card.Actions.Action
                                                    text="Request password reset"
                                                    onClick={handleSubmit}
                                                    submitting={isSubmitting}
                                                />
                                            </Card.Actions>
                                        </Card>
                                    )}

                                    {passwordResetSent && (
                                        <Fragment>
                                            <Alert
                                                type="success"
                                                message="Check your email for a reset password confirmation"
                                            />
                                        </Fragment>
                                    )}
                                </Col>
                            </Row>
                        </Fragment>
                    )}
                </Formik>
            </Grid>
        </Layout>
    );
};

export default withSnackbar(ForgotPassword);
