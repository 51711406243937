import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';

const Wrapper = ({ discount, onSubmit, children }) => {
	const website = useSelector(({ website }) => website);

	return (
		<Formik
			enableReinitialize
			validationSchema={Yup.object().shape({
				name: Yup.string().required('Name is required'),
				code: Yup.string()
					.uppercase('Code must be uppercase.')
					.matches(/^[0-9a-zA-Z]+$/, {
						message: 'Code can only contain letters and numbers.',
					})
					.nullable(),
				type: Yup.string()
					.oneOf(['amountOff', 'percentageOff', 'newAmount'])
					.required('Type is required.'),
				active: Yup.boolean().nullable(),
				amount: Yup.number()
					.positive('Must be a positive number.')
					.required('Amount is required.')
					.when('type', {
						is: 'percentageOff',
						then: Yup.number()
							.positive('Must be a positive number.')
							.max(
								1,
								'Amount must be between 0 and 1 when type is "Percentage off" (e.g. 0.25 is 25%).'
							)
							.required('Amount is required.'),
					}),
				limit: Yup.number().positive('Must be a positive number.'),
				products: Yup.array()
					.of(Yup.string())
					.nullable(),
				categories: Yup.array()
					.of(Yup.string())
					.when('type', {
						is: 'newAmount',
						then: Yup.array()
							.max(
								0,
								`Can't use type "New amount" when categories are selected, either remove category applicability or change type.`
							)
							.nullable(),
					}),
				startDate: Yup.date()
					.min(new Date())
					.nullable(),
				endDate: Yup.date()
					.nullable()
					.when('startDate', (startDate, schema) =>
						!!startDate ? schema.min(startDate) : schema
					),
				accountTypes: Yup.array(Yup.string()).nullable(),
			})}
			initialValues={{
				name: discount?.name,
				code: discount?.code,
				type: discount?.type,
				active: discount?.active ?? true,
				limit: discount?.limit,
				amount:
					discount?.amount && discount?.type !== 'percentageOff'
						? discount?.amount / 100
						: discount?.amount,
				products: discount?.products?.map(({ _id }) => _id),
				categories: discount?.categories?.map(({ _id }) => _id),
				startDate: discount?.startDate,
				endDate: discount?.endDate,
				accountTypes: discount?.accountTypes,
			}}
			onSubmit={onSubmit}
		>
			{formikProps => {
				const {
					values: { amount, type },
				} = formikProps;
				const [categories, setCategories] = useState(discount?.categories ?? []);
				const [products, setProducts] = useState(discount?.products ?? []);
				const [accountTypes, setAccountTypes] = useState(discount?.accountTypes ?? []);

				const calcProductPriceDiscount = price => {
					let value;

					switch (type) {
						case 'percentageOff': {
							value = parseFloat(price - price * amount);
							break;
						}
						case 'amountOff': {
							value = parseFloat(price - amount * 100);
							break;
						}
						case 'newAmount': {
							value = parseFloat(amount);
							break;
						}
					}

					return value <= 0
						? 'Free'
						: `${website?.store?.currency?.symbol}${(type === 'newAmount'
								? value
								: value / 100
						  )?.toFixed(2)}`;
				};

				const props = {
					categories,
					products,
					accountTypes,
					discount,
					onCategoriesChange: setCategories,
					onProductsChange: setProducts,
					onAccountTypesChange: setAccountTypes,
					calcProductPriceDiscount,
				};

				return children({ ...formikProps, ...props });
			}}
		</Formik>
	);
};

export default Wrapper;
