import React, { useEffect } from 'react';

import { EMAIL_VERIFY } from '@helpers/api';
import { makePostRequest } from '@helpers/requests';
import { updateUser } from '@actions/user';
import { navigate } from 'gatsby-link';
import queryString from 'query-string';
import Layout from '@components/Layout';
import SEO from '@components/SEO';
import { getUserFromToken } from '@helpers/auth';
import Loader from '@components/Loader';
import { withSnackbar } from '@components/Snackbar';
import { useDispatch, useSelector } from 'react-redux';

const VerifyEmail = ({ openSnackbar, location }) => {
    const params = queryString.parse(location.search);
    const id = params?.id;
    const code = params?.code;
    const user = useSelector(({ user }) => user);
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            try {
                if (!id || !code) {
                    return throw null;
                }

                const { data } = await makePostRequest(EMAIL_VERIFY, { id, code });
                const userData = await getUserFromToken(data);
                openSnackbar('Email successfully verified!');

                if (user) {
                    dispatch(updateUser(userData));
                    await navigate('/account');
                }
            } catch (error) {
                if (error !== 'cancelled') {
                    openSnackbar(
                        error?.errorMessage ?? 'An error occurred verifying your email address'
                    );
                    await navigate('/');
                }
            }
        })();
    }, []);

    return (
        <Layout>
            <SEO title="Verify Email" />
            <Loader />
        </Layout>
    );
};

export default withSnackbar(VerifyEmail);
