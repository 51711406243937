import React, { Fragment, useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { navigate } from 'gatsby';

// import { Button } from '@element-softworks/ui';
import Button from '@components/Button';
import { Grid, Row, Col, Divider } from '@components/Grid';
import { makePostRequest } from '@helpers/requests';
import { USER_LOGIN } from '@helpers/api';
import { loginUser, logoutUser } from '@actions/user';
import Alert from '@components/Alert';
import Input from '@components/Input';
import Card from '@components/Card';
import Logo from '@images/logo.svg';

import styles from './styles.module.scss';
import { Redirect } from '@reach/router';
import { withSnackbar } from '@components/Snackbar';

const Login = ({ login, user, website, openSnackbar, location }) => {
    const [loading, setLoading] = useState(true);
    const [redirectPath, setRedirectPath] = useState(
        location?.state?.from?.pathname ?? website ? '/dashboard' : '/websites'
    );

    // useEffect(() => {
    //     setLoading(false);
    // }, []);

    const handleSubmit = async (values, actions) => {
        try {
            const { data } = await makePostRequest(USER_LOGIN, {
                ...values,
                email: String(values.email).toLowerCase(),
            });

            await login(data);
        } catch (error) {
            error !== 'cancelled' &&
                openSnackbar(error?.errorMessage ?? 'An error occurred when attempting login.');
        }
    };

    useEffect(() => {
        if (user) {
            navigate(redirectPath);
        }
    }, [user]);

    // // If our user is an admin and they haven't already selected a website
    // if (user?.permissions?.isAdmin && !website) {
    //     // Redirect to the admin overview page
    //     return <Redirect to="/admin/dashboard" noThrow />;
    // }

    // user already exists, don't show login
    // if (user) {
    //     // // website already selected previously, go straight to dashboard
    //     // if (website) {
    //     //     // navigate('/dashboard');
    //     //     return <Redirect to="/dashboard" noThrow />;
    //     //     // return null;
    //     // }
    //
    //     return <Redirect to="/websites" noThrow />;
    //     // website not yet selected, show website selection
    //     // navigate('/websites');
    //     // return null;
    // }

    // if (loading) return <Loader />;

    return (
        <Fragment>
            <div className={styles.login}>
                <Grid>
                    <Row center="xs" middle="xs">
                        <Col xs={12} md={6} lg={4}>
                            <Logo className={styles.logo} />

                            <Row start="xs">
                                <Col xs={12}>
                                    <Card>
                                        <Card.Content>
                                            <Formik
                                                validationSchema={yupObjectSchema}
                                                initialValues={{ email: '', password: '' }}
                                                onSubmit={handleSubmit}
                                            >
                                                {({
                                                    handleSubmit,
                                                    values,
                                                    errors,
                                                    touched,
                                                    isSubmitting,
                                                    handleChange,
                                                }) => (
                                                    <Fragment>
                                                        <Row>
                                                            <Col xs={12}>
                                                                <Input
                                                                    secondary
                                                                    label="Email Address"
                                                                    name="email"
                                                                    type="email"
                                                                    value={values.email}
                                                                    onChange={handleChange}
                                                                />
                                                                {touched.email && errors.email && (
                                                                    <Fragment>
                                                                        <Divider margin={1} />
                                                                        <Alert
                                                                            type="error"
                                                                            message={errors.email}
                                                                        />
                                                                    </Fragment>
                                                                )}
                                                            </Col>
                                                        </Row>

                                                        <Divider margin={2} />

                                                        <Row>
                                                            <Col xs={12}>
                                                                <Input
                                                                    secondary
                                                                    label="Password"
                                                                    name="password"
                                                                    type="password"
                                                                    value={values.password}
                                                                    onChange={handleChange}
                                                                />
                                                                {touched.password &&
                                                                    errors.password && (
                                                                        <Fragment>
                                                                            <Divider margin={1} />
                                                                            <Alert
                                                                                type="error"
                                                                                message={
                                                                                    errors.password
                                                                                }
                                                                            />
                                                                        </Fragment>
                                                                    )}
                                                            </Col>
                                                        </Row>

                                                        <Divider margin={2} />

                                                        <Button
                                                            color="primary"
                                                            onClick={handleSubmit}
                                                            submitting={isSubmitting}
                                                            fullWidth={false}
                                                        >
                                                            Login
                                                        </Button>
                                                    </Fragment>
                                                )}
                                            </Formik>
                                        </Card.Content>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Grid>
            </div>
        </Fragment>
    );
};

const yupObjectSchema = Yup.object().shape({
    email: Yup.string()
        .email()
        .lowercase()
        .required('Email Address is required'),
    password: Yup.string().required('Password is required'),
});

const mapStateToProps = ({ user, website }) => ({ user, website });
const mapDispatchToProps = dispatch =>
    bindActionCreators({ login: loginUser, logout: logoutUser }, dispatch);

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(Login));
