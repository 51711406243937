import React, { Fragment, useEffect, useState } from 'react';
import { withSnackbar } from '@components/Snackbar';
import * as Yup from 'yup';
import {
	makeDeleteRequest,
	makeGetRequest,
	makePostRequest,
	makePutRequest,
} from '@helpers/requests';
import { COUNTRY_CODES, USER, USER_TYPES, USERS } from '@helpers/api';
import Command from '@components/Command';
import { navigate } from 'gatsby-link';
import { Col, Divider, Grid, Row } from '@components/Grid';
import List from '@components/List';
import { format, parseISO } from 'date-fns';
import Loader from '@components/Loader';
import Panes from '@components/Panes';
import Select from '@components/Select';
import { Formik } from 'formik';
import Button from '@components/Button';
import Input from '@components/Input';
import Alert from '@components/Alert';
import objectPath from 'object-path';
import Modal from '@components/Modal';
import { Link } from 'gatsby';

const User = ({ openSnackbar, id }) => {
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState(null);
	const [types, setTypes] = useState(null);
	const [creating, setCreating] = useState(!id);
	const [countries, setCountries] = useState(null);
	const [deletingUser, setDeletingUser] = useState(false);

	useEffect(() => {
		(async () => {
			try {
				if (id) {
					const { data: userData } = await makeGetRequest(USER(id));
					setUser(userData);
				}

				const { data: countriesData } = await makeGetRequest(COUNTRY_CODES);
				setCountries(countriesData);

				const { data: typesData } = await makeGetRequest(USER_TYPES, {
					perPage: 1000,
					pageNum: 1,
				});

				setTypes(typesData?.results);
			} catch (error) {
				error !== 'cancelled' &&
					openSnackbar(
						error?.errorMessage ??
							`An error occurred attempting to load ${
								creating ? 'user creation' : 'this user'
							}.`
					);
			} finally {
				setLoading(false);
			}
		})();
	}, []);

	const handleDelete = async () => {
		try {
			await makeDeleteRequest(USER(id));
			openSnackbar(`Successfully deleted user - ${user?.firstName} ${user?.lastName}.`);
			navigate('/users/user');
		} catch (error) {
			error !== 'cancelled' &&
				openSnackbar(
					error?.errorMessage ??
						`An error occurred attempting to delete user - ${user?.firstName} ${user?.lastName}.`
				);
		}
	};

	const handleSubmit = async ({
		firstName,
		lastName,
		type,
		locale: { countryCode, city },
		customFields,
		email,
	}) => {
		try {
			const customFieldsData = [];

			for (let key in customFields) {
				if (customFields.hasOwnProperty(key) && !!customFields[key]) {
					customFieldsData.push({
						_id: key,
						value: customFields[key],
					});
				}
			}

			const { data: userData } = creating
				? await makePostRequest(USERS, {
						email,
						firstName,
						lastName,
						type: type || undefined,
						locale: {
							countryCode,
							city,
						},
						customFields: !!customFieldsData.length ? customFieldsData : undefined,
				  })
				: await makePutRequest(USER(id), {
						firstName,
						lastName,
						locale: {
							countryCode,
							city,
						},
						type: type || undefined,
						customFields: !!customFieldsData.length ? customFieldsData : undefined,
				  });

			openSnackbar('User changes successfully saved.');

			if (creating) {
				navigate(`/users/user/${userData?._id}`);
			}
		} catch (error) {
			error !== 'cancelled' &&
				openSnackbar(
					error?.errorMessage ?? 'An error occurred attempting to save your user changes.'
				);
		}
	};

	const renderStringValidation = ({ label, multiple, optional, min, max }) => {
		if (multiple) {
			let value = Yup.array().of(Yup.string());
			if (max) value = value.max(max, `${label} can't have more than ${max} inputs.`);
			if (min) value = value.min(min, `${label} can't have less than ${min} inputs.`);
			if (!optional) value = value.required(`${label} is required.`);
			return value;
		}

		let value = Yup.string();
		if (max) value = value.max(max, `${label} can't be longer than ${max} characters.`);
		if (min) value = value.min(min, `${label} can't be less than ${min} characters.`);
		if (!optional) value = value.required(`${label} is required.`);
		return value;
	};

	const renderNumberValidation = ({ label, optional, min, max, positive, decimals }) => {
		let value = Yup.number();
		if (max) value = value.max(max, `${label} can't be higher than ${max}.`);
		if (min) value = value.min(min, `${label} can't be less than ${min}.`);
		if (positive) value = value.positive(`${label} must be a positive number.`);
		if (!decimals) value = value.integer(`${label} can't have decimals.`);
		if (!optional) value = value.required(`${label} is required.`);
		return value;
	};

	const renderValidationImage = ({ id, multiple, min, max, label, optional }) => {
		let value;

		if (multiple) {
			let media = Yup.string();
			value = Yup.array().of(media);
			if (max) value = value.max(max, `${label} can't have more than ${max} images.`);
			if (min) value = value.min(min, `${label} can't have less than ${min} images.`);
			if (!optional) media = media.required(`${label} is required.`);
		} else {
			value = Yup.string();
		}

		if (!optional) value = value.required(`${label} is required.`);
		return value;
	};

	const renderValidationList = ({ validation, label, optional, max, min }) => {
		const validationFields = {};

		validation.forEach(validationItem => {
			validationFields[validationItem.id] = renderValidationField(validationItem);
		});

		let value = Yup.array().of(Yup.object().shape(validationFields));
		if (max) value = value.max(max, `${label} can't have more than ${max} items.`);
		if (min) value = value.min(min, `${label} can't have less than ${min} items.`);
		if (!optional) value = value.required(`${label} is required.`);
		return value;
	};

	const renderValidationField = ({ type, ...field }) => {
		if (type === 'string' || type === 'text') return renderStringValidation(field);
		if (type === 'number') return renderNumberValidation(field);
		if (type === 'list') return renderValidationList(field);
		if (type === 'image') return renderValidationImage(field);

		return undefined;
	};

	const renderCustomFieldsValidation = (currentFields = user?.customFields ?? {}) => {
		const schema = {};

		Object.keys(currentFields).forEach(key => {
			schema[currentFields[key]._id] = renderValidationField(currentFields[key]);
		});

		return Yup.lazy(() => Yup.object().shape(schema));
	};

	const getCustomFields = (currentFields = user?.customFields, acc = {}) => {
		if (creating) return acc;

		currentFields.forEach(({ value, _id }) => {
			objectPath.set(acc, _id, value);
		});

		return acc;
	};

	return (
		<Fragment>
			<Command>
				<Command.Breadcrumbs>
					<Command.Breadcrumbs.Breadcrumb text="Users" link="/users" />
					<Command.Breadcrumbs.Breadcrumb
						text={!!user ? `${user?.firstName ?? ''} ${user?.lastName ?? ''}` : 'User'}
					/>
				</Command.Breadcrumbs>
			</Command>

			{loading ? (
				<Loader />
			) : (
				<Formik
					initialValues={{
						firstName: user?.firstName,
						lastName: user?.lastName,
						email: user?.email,
						type: user?.type?._id,
						locale: {
							city: user?.locale?.city || '',
							countryCode: user?.locale?.countryCode || '',
						},
						customFields: getCustomFields(),
					}}
					validationSchema={Yup.object().shape({
						firstName: Yup.string().required('First name is required.'),
						lastName: Yup.string().required('Last name is required.'),
						email: Yup.string()
							.email('Must be an email address.')
							.required('Email is required.'),
						type: Yup.string().nullable(),
						locale: Yup.object().shape({
							city: Yup.string().required('City is required.'),
							countryCode: Yup.string().required('Country is required.'),
						}),
						customFields: renderCustomFieldsValidation(),
					})}
					onSubmit={handleSubmit}
				>
					{({
						values,
						errors,
						touched,
						handleSubmit,
						isSubmitting,
						handleChange,
						setFieldValue,
					}) => (
						<Panes>
							<Panes.Left>
								{creating && (
									<Fragment>
										<Alert
											type="info"
											message="When you create a user an email will be sent out to the applicable email address, the user will be activated once they have set up a password from their setup email."
										/>
										<Divider />
									</Fragment>
								)}
								<Input
									name="email"
									label="Email"
									value={values.email}
									onChange={handleChange}
									disabled={!creating}
								/>
								{touched.email && errors.email && (
									<Fragment>
										<Divider />
										<Alert type="error" message={errors.email} />
									</Fragment>
								)}
								<Divider />

								<Input
									name="firstName"
									label="First name"
									value={values.firstName}
									onChange={handleChange}
								/>
								{touched.firstName && errors.firstName && (
									<Fragment>
										<Divider />
										<Alert type="error" message={errors.firstName} />
									</Fragment>
								)}
								<Divider />

								<Input
									name="lastName"
									label="Last name"
									value={values.lastName}
									onChange={handleChange}
								/>
								{touched.lastName && errors.lastName && (
									<Fragment>
										<Divider />
										<Alert type="error" message={errors.lastName} />
									</Fragment>
								)}
								<Divider />

								<Select
									name="type"
									label="Type"
									placeholder="Select a user type..."
									onChange={e => {
										setFieldValue(
											'type',
											e.target.value === 'standard' ? '' : e.target.value
										);
									}}
									value={values.type || 'standard'}
								>
									<Select.Option label="Standard" value="standard" />
									{types.map(({ _id, name }) => (
										<Select.Option key={_id} label={name} value={_id} />
									))}
								</Select>
								<Divider />

								<Input
									name="locale.city"
									label="City"
									value={values.locale?.city}
									onChange={handleChange}
								/>
								{touched.locale?.city && errors.locale?.city && (
									<Fragment>
										<Divider />
										<Alert type="error" message={errors.locale?.city} />
									</Fragment>
								)}
								<Divider />

								<Select
									name="locale.countryCode"
									label="Country"
									placeholder="Select a country..."
									onChange={handleChange}
									value={values.locale?.countryCode}
								>
									{countries.map(({ code, name }) => (
										<Select.Option key={code} label={name} value={code} />
									))}
								</Select>
								{touched.locale?.countryCode && errors.locale?.countryCode && (
									<Fragment>
										<Divider />
										<Alert type="error" message={errors.locale?.countryCode} />
									</Fragment>
								)}

								{!!user?.customFields && !!user?.customFields?.length && (
									<Fragment>
										{user?.customFields?.map(
											({ _id, label, hint, optional }) => (
												<Fragment key={_id}>
													<Divider />
													<Input
														name={`customFields.${_id}`}
														label={label}
														labelOnClick={() =>
															navigate(`/users/fields/${_id}`)
														}
														hint={hint}
														optional={optional}
														value={values.customFields[_id]}
														onChange={handleChange}
													/>
													{touched?.customFields?.[_id] &&
														errors?.customFields?.[_id] && (
															<Fragment>
																<Divider />
																<Alert
																	type="error"
																	message={
																		errors?.customFields?.[_id]
																	}
																/>
															</Fragment>
														)}
												</Fragment>
											)
										)}
									</Fragment>
								)}
								<Divider />

								<Row end="xs">
									<Col xs={12}>
										{!creating && (
											<Button
												text="Delete"
												danger
												onClick={() => setDeletingUser(true)}
												submitting={deletingUser}
											/>
										)}

										<Button
											submitting={isSubmitting}
											success
											text={creating ? 'Create' : 'Save'}
											onClick={handleSubmit}
										/>
									</Col>
								</Row>
							</Panes.Left>
							<Panes.Right>
								<List>
									{!creating && (
										<Fragment>
											<List.Item key="id">
												<List.Item.Column>
													<List.Item.Title text="Id" />
												</List.Item.Column>
												<List.Item.Column.Right important>
													<List.Item.Title
														small
														bold={false}
														text={user?._id}
													/>
												</List.Item.Column.Right>
											</List.Item>
											<List.Item key="last-logged-in">
												<List.Item.Column>
													<List.Item.Title text="Last logged in" />
												</List.Item.Column>
												<List.Item.Column.Right important>
													<List.Item.Title
														small
														bold={false}
														text={
															!!user?.lastLogin &&
															format(
																parseISO(user?.lastLogin),
																'PPP p'
															)
														}
													/>
												</List.Item.Column.Right>
											</List.Item>
										</Fragment>
									)}
									<List.Item key="email">
										<List.Item.Column>
											<List.Item.Title text="Email" />
										</List.Item.Column>
										<List.Item.Column.Right important>
											<List.Item.Title
												small
												bold={false}
												text={values.email}
											/>
										</List.Item.Column.Right>
									</List.Item>
									<List.Item key="first-name">
										<List.Item.Column>
											<List.Item.Title text="First name" />
										</List.Item.Column>
										<List.Item.Column.Right important>
											<List.Item.Title
												small
												bold={false}
												text={values.firstName}
											/>
										</List.Item.Column.Right>
									</List.Item>
									<List.Item key="last-name">
										<List.Item.Column>
											<List.Item.Title text="Last name" />
										</List.Item.Column>
										<List.Item.Column.Right important>
											<List.Item.Title
												small
												bold={false}
												text={values.lastName}
											/>
										</List.Item.Column.Right>
									</List.Item>
									<List.Item key="type">
										<List.Item.Column>
											<List.Item.Title text="Type" />
										</List.Item.Column>
										<List.Item.Column.Right important>
											<List.Item.Title
												small
												bold={false}
												text={
													types?.find(type => type?._id === values.type)
														?.name ?? 'Standard'
												}
											/>
										</List.Item.Column.Right>
									</List.Item>
									<List.Item key="city">
										<List.Item.Column>
											<List.Item.Title text="City" />
										</List.Item.Column>
										<List.Item.Column.Right important>
											<List.Item.Title
												small
												bold={false}
												text={values?.locale?.city}
											/>
										</List.Item.Column.Right>
									</List.Item>
									<List.Item key="country">
										<List.Item.Column>
											<List.Item.Title text="Country" />
										</List.Item.Column>
										<List.Item.Column.Right important>
											<List.Item.Title
												small
												bold={false}
												text={
													countries.find(
														({ code }) =>
															code === values?.locale?.countryCode
													)?.name
												}
											/>
										</List.Item.Column.Right>
									</List.Item>
								</List>
							</Panes.Right>
						</Panes>
					)}
				</Formik>
			)}

			<Modal
				isOpen={deletingUser}
				title={`Deleting user - ${user?.firstName} ${user?.lastName}`}
				onClose={() => {
					setDeletingUser(false);
				}}
			>
				<Modal.Content>
					<Formik initialValues={{}} onSubmit={handleDelete}>
						{({ handleSubmit, isSubmitting }) => (
							<Fragment>
								<p>Are you sure?</p>
								<Divider />
								<Button
									text="Delete"
									danger
									onClick={handleSubmit}
									submitting={isSubmitting}
								/>
								<Button
									text="Cancel"
									onClick={() => {
										setDeletingUser(false);
									}}
								/>
							</Fragment>
						)}
					</Formik>
				</Modal.Content>
			</Modal>
		</Fragment>
	);
};

export default withSnackbar(User);
