import React from 'react';
import { Link } from 'gatsby';
import { Grid } from '@components/Grid';

const Dashboard = () => {
    return (
        <Grid>
            <div>
                <p>TODO: Finish this page</p>
                <p>
                    <Link to="/admin/users">User Overview</Link>
                </p>
                <p>
                    <Link to="/admin/websites">Website Overview</Link>
                </p>
            </div>
        </Grid>
    );
};

export default Dashboard;
