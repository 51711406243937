import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from '../styles.module.scss';

export default ({ onNext, disabled }) => {
    const handleKeyUp = e => {
        if (!disabled && e.keyCode === 13 && onNext) {
            onNext();
        }
    };

    return !onNext ? null : (
        <div
            className={`${styles.datepickerHeaderNext} ${
                disabled ? styles.datepickerHeaderNextDisabled : ''
            }`}
            onClick={disabled ? undefined : onNext}
        >
            <FontAwesomeIcon tabIndex="0" onKeyUp={handleKeyUp} icon="chevron-right" />
        </div>
    );
};
