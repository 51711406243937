import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { usePopper } from 'react-popper';
import maxSize from 'popper-max-size-modifier';

import { subcomponent } from '@helpers';
import { LayoutContext } from '@helpers/contexts';
import { detectOverflow } from '@popperjs/core';

import styles from './styles.module.scss';
import { navigate } from 'gatsby-link';
import usePrevious from '@helpers/hooks/usePrevious';
import { filterInternalChildren } from '@helpers/index';

const getApplyMaxSize = boundary => ({
    name: 'applyMaxSize',
    enabled: true,
    phase: 'beforeWrite',
    requires: ['maxSize'],
    fn({ state }) {
        const { height } = state.modifiersData.maxSize;
        state.styles.popper.maxHeight = `${Math.max(100, height - 20)}px`;
    },
    options: {
        boundary,
    },
});

const Dropdown = ({
    $main,
    $content,
    $app,
    className = '',
    text,
    children,
    onEscape,
    open = true,
    target,
    offset = [0, 0],
    width,
    placement,
    ...props
}) => {
    if (!target?.current) return null;

    // const referenceElement = useRef(null);
    const $dropdown = useRef(null);
    const [itemsFocusIndex, setItemsFocusIndex] = useState(-1);
    const [popperElement, setPopperElement] = useState(null);
    const applyMaxSize = useMemo(() => getApplyMaxSize($content?.current), [$content]);

    const { instance, styles: popperStyles, attributes } = usePopper(
        target?.current,
        popperElement,
        {
            strategy: 'fixed',
            placement: placement ?? 'bottom-start',
            modifiers: [
                {
                    name: 'preventOverflow',
                    options: {
                        boundary: $app?.current,
                        // altBoundary: true,
                    },
                },
                {
                    name: 'flip',
                    options: {
                        boundary: $app?.current,
                    },
                },
                {
                    name: 'offset',
                    options: {
                        offset: [0, 10],
                    },
                },
                maxSize,
                applyMaxSize,
            ],
        }
    );
    const $items = [];
    const items = subcomponent(children, Item, false, {
        $items,
        onEscape,
    });
    const openClass = open ? styles.dropdownOpen : '';
    const hasChildrenClass = !items.length && children ? styles.dropdownHasChildren : '';
    const prevOpen = usePrevious(open);

    const handleMouseDown = e => {
        if (!$dropdown.current) return;
        if (e.target === $dropdown.current || $dropdown.current.contains(e.target)) return;

        if (onEscape) {
            onEscape(e);
        }
    };

    const handleFocus = e => {
        const code = e.which || e.keyCode;

        // shiftkey was held, go backward!
        if (e.shiftKey || code === 38) {
            setItemsFocusIndex(itemsFocusIndex <= 0 ? items.length - 1 : itemsFocusIndex - 1);
            return;
        }

        setItemsFocusIndex(items.length - 1 === itemsFocusIndex ? 0 : itemsFocusIndex + 1);
    };

    const handleKeyDown = e => {
        const code = e.which || e.keyCode;

        if (code === 9 || code === 38 || code === 40) {
            handleFocus(e);
            return;
        }

        if (code === 27 && onEscape) {
            onEscape(e);
        }
    };

    useEffect(() => {
        if (!prevOpen && open) {
            document.addEventListener('keydown', handleKeyDown);
            document.addEventListener('mousedown', handleMouseDown);
        }

        if (prevOpen && !open) {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('mousedown', handleMouseDown);
            setItemsFocusIndex(-1);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('mousedown', handleMouseDown);
        };
    }, [open]);

    return (
        <Fragment>
            {open &&
                ReactDOM.createPortal(
                    <div
                        ref={setPopperElement}
                        style={{
                            ...popperStyles.popper,
                            width: width ? width : popperStyles?.popper?.width,
                            marginLeft: offset[1],
                            marginTop: offset[0],
                        }}
                        {...attributes.popper}
                        className={styles.dropdownWrapper}
                    >
                        <ul
                            ref={$dropdown}
                            className={`${
                                styles.dropdown
                            } ${openClass} ${hasChildrenClass} ${className ?? ''}`}
                            aria-expanded={open}
                        >
                            {items}
                            {(!items || (items && Array.isArray(items) && !items.length)) &&
                            children ? (
                                <li>{children}</li>
                            ) : null}
                        </ul>
                    </div>,
                    $content?.current
                )}
        </Fragment>
    );
};

const Item = ({
    icon,
    $items,
    text,
    description,
    link,
    index,
    onClick,
    selected,
    disabled,
    onEscape,
    children,
    checked,
    ...props
}) => {
    const allProps = { $items, text, link, index, onClick, ...props };

    const handleItemClick = (keypress, e) => {
        if (disabled) return;

        if (onClick) {
            onClick(allProps);
        }

        if (keypress) {
            e.preventDefault();
        }

        // once a click/link has been handled, run onEscape to tell the handler to escape the dropdown
        if (onEscape) {
            onEscape(allProps);
        }

        // // if entered by keypress we need to manually trigger react-router
        // if (link && keypress) {
        //     navigate(link);
        // }
    };

    const content = (
        <Fragment>
            {!!icon && <FontAwesomeIcon icon={icon} />}
            <div className={styles.dropdownItemContent}>
                <span className={styles.dropdownItemLinkTitle}>{text}</span>
                {!!description && (
                    <span className={styles.dropdownItemLinkDescription}>{description}</span>
                )}
                {filterInternalChildren(children, [Item.Link])}
                {subcomponent(children, Item.Link, true, { parentIsLink: !!link })}
            </div>
            {checked && (
                <span className={styles.dropdownItemCheck}>
                    <FontAwesomeIcon icon="check" />
                </span>
            )}
        </Fragment>
    );

    if (link) {
        return (
            <li
                className={`${styles.dropdownItem} ${styles.dropdownItemHasLink} ${
                    styles.dropdownItemClickable
                } ${disabled ? styles.dropdownItemDisabled : ''}`}
                ref={instance => ($items[text] = instance)}
                onKeyDown={e => e.keyCode === 13 && handleItemClick(true, e)}
            >
                {/^((http|https|tel|mailto):)/.test(link) ? (
                    <a
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={e => handleItemClick(false, e)}
                        className={styles.dropdownItemLink}
                    >
                        {content}
                    </a>
                ) : (
                    <Link
                        activeClassName={styles.dropdownItemLinkActive}
                        to={link}
                        onClick={e => handleItemClick(false, e)}
                        className={styles.dropdownItemLink}
                    >
                        {content}
                    </Link>
                )}
            </li>
        );
    }

    return (
        <li
            tabIndex={!link && onClick ? 0 : undefined}
            ref={instance => ($items[index] = instance)}
            onClick={e => handleItemClick(false, e)}
            onKeyDown={e => e.keyCode === 13 && handleItemClick(true, e)}
            className={`${styles.dropdownItem} ${
                checked || selected ? styles.dropdownItemActive : ''
            } ${disabled ? styles.dropdownItemDisabled : ''} ${
                link ? styles.dropdownItemHasLink : ''
            } ${link || onClick ? styles.dropdownItemClickable : ''}`}
        >
            <div
                className={`${styles.dropdownItemLink} ${
                    selected || checked ? styles.dropdownItemLinkActive : ''
                }`}
            >
                {content}
            </div>
        </li>
    );
};
Item.displayName = 'Item';

Item.Link = ({ link, text, parentIsLink }) => {
    const handleLinkClick = () => {
        if (/^((http|https|tel|mailto):)/.test(link)) {
            window.open(link, '_blank', 'noopener');
            return;
        }

        navigate(link);
    };

    return (
        <div
            className={styles.dropdownItemLinkAnchor}
            onClick={e => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            {parentIsLink ? (
                <span onClick={handleLinkClick}>
                    {text}
                    <FontAwesomeIcon icon={['fad', 'mouse-pointer']} />
                </span>
            ) : /^((http|https|tel|mailto):)/.test(link) ? (
                <a href={link} target="_blank" rel="noopener noreferrer">
                    {text}
                    <FontAwesomeIcon icon={['fad', 'mouse-pointer']} />
                </a>
            ) : (
                <Link to={link}>
                    {text}
                    <FontAwesomeIcon icon={['fad', 'mouse-pointer']} />
                </Link>
            )}
        </div>
    );
};
Item.Link.displayName = 'Link';

Dropdown.propTypes = {
    width: PropTypes.number,
    className: PropTypes.string,
    value: PropTypes.string,
    target: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    open: PropTypes.bool,
    onDropdownItemClick: PropTypes.func,
    onEscape: PropTypes.func,
    offset: PropTypes.arrayOf(PropTypes.number),
};

const DropdownWrapper = ({ children, ...props }) => (
    <LayoutContext.Consumer>
        {(contexts = {}) => {
            return (
                <Dropdown {...props} {...contexts}>
                    {children}
                </Dropdown>
            );
        }}
    </LayoutContext.Consumer>
);
DropdownWrapper.Item = Item;

export default DropdownWrapper;
