import React from 'react';

import EmptyIcon from '@images/wizard-question.svg';
import { HeightContext } from '@helpers/contexts';
import Button from '@components/Button';

import styles from './styles.module.scss';
import { navigate } from '@reach/router';
import { subcomponent } from '@helpers/index';

const Empty = ({ children, text, title = 'Whoops', refresh = true, fullHeight = false }) => {
    const Actions = subcomponent(children, Empty.Action);

    return (
        <HeightContext.Consumer>
            {({ height } = {}) => (
                <div className={styles.empty} style={{ minHeight: fullHeight ? `100%` : height }}>
                    <EmptyIcon className={styles.emptyIcon} />
                    <h1>{title}</h1>
                    <p>{text}</p>
                    <div className={styles.emptyButton}>
                        {!!Actions.length ? (
                            Actions
                        ) : (
                            <Button text="Go back" onClick={() => navigate(-1)} />
                        )}
                    </div>
                </div>
            )}
        </HeightContext.Consumer>
    );
};

Empty.Action = props => <Button {...props} />;
Empty.Action.displayName = 'Action';

export default Empty;
