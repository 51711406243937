import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { subcomponent } from '@helpers';
import Button from '../Button';
import styles from './styles.module.scss';
import Switch from '@components/Switch';
import Title from '@components/Card/Title';
import Dropdown from '@components/Dropdown';
import { filterInternalChildren } from '@helpers/index';

const Actions = ({ divider = true, center, right, children, onBack }) => {
    return (
        <div
            className={`${styles.cardActions} ${divider ? styles.cardActionsDivider : ''} ${
                center ? styles.cardActionsCenter : ''
            } ${right ? styles.cardActionsRight : ''}`}
        >
            {onBack ? (
                <Actions.Action
                    className={styles.cardActionsActionBack}
                    onClick={onBack}
                    inverse
                    secondary
                    small
                    text="Back"
                />
            ) : null}
            {subcomponent(children, [Actions.Action, Actions.Switch])}
        </div>
    );
};
Actions.displayName = 'Actions';

Actions.propTypes = {
    divider: PropTypes.bool,
    center: PropTypes.bool,
    right: PropTypes.bool,
    onBack: PropTypes.func,
};

Actions.Action = ({ children, text, icon, placement, offset, onClick, ...buttonProps }) => {
    const $button = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const DropdownItem = subcomponent(children, Actions.Action.Item);
    const filteredChildren = filterInternalChildren(children, [Actions.Action.Item]);

    return (
        <div
            ref={$button}
            className={`${styles.cardActionsAction} ${
                !text && !filteredChildren && icon ? styles.cardActionsActionIconOnly : ''
            }`}
        >
            <Button
                text={text}
                icon={icon}
                onClick={e => {
                    e.stopPropagation();
                    if (!!DropdownItem.length) setIsOpen(!isOpen);
                    if (onClick) onClick(e);
                }}
                {...buttonProps}
            />

            {!!DropdownItem.length && (
                <Dropdown
                    offset={offset}
                    placement={placement}
                    target={$button}
                    open={isOpen}
                    onEscape={() => setIsOpen(false)}
                >
                    {DropdownItem}
                </Dropdown>
            )}
        </div>
    );
};
Actions.Action.displayName = 'Action';
Actions.Action.Item = Dropdown.Item;

Actions.Switch = ({ label, ...props }) => {
    return (
        <div className={styles.cardActionsSwitch}>
            {!!label && <label className={styles.cardActionsSwitchLabel}>{label}</label>}
            <Switch {...props} label={null} hint={null} optional={null} />
        </div>
    );
};
Actions.Switch.displayName = 'Switch';

export default Actions;
