import React, { Fragment, useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withSnackbar } from '@components/Snackbar';

import { makeGetRequest } from '@helpers/requests';
import { COUNTRY_CODES } from '@helpers/api';
import Loader from '@components/Loader';
import Button from '@components/Button';
import Input from '@components/Input';
import Select from '@components/Select';
import { Row, Col, Divider } from '@components/Grid';
import Alert from '@components/Alert';

const Address = ({
    _id,
    streetOne,
    streetTwo,
    city,
    county,
    country,
    postcode,
    onSubmit,
    onCancel,
    openSnackbar,
}) => {
    const existingAddress = !!_id;
    const [loading, setLoading] = useState(true);
    const [countries, setCountries] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                let { data } = await makeGetRequest(COUNTRY_CODES);
                let options = data
                    .map(({ name, code }) => ({ label: name, value: code }))
                    .sort((a, b) => (a.label < b.label ? -1 : 1));

                setCountries(options);
                setLoading(false);
            } catch (error) {
                error !== 'cancelled' && openSnackbar(`An error occurred loading address data.`);
                onCancel();
            }
        })();
    }, []);

    if (loading) return <Loader />;

    return (
        <Formik
            validationSchema={yupAddressSchema}
            initialValues={{ streetOne, streetTwo, city, county, country, postcode }}
            onSubmit={onSubmit}
            render={props => (
                <Fragment>
                    <h2>{existingAddress ? 'Edit your address' : 'Add a new address'}</h2>
                    <AddressFormikForm {...props} countries={countries} />

                    <Divider />
                    <Row>
                        <Col xsGrow>
                            <Button transparent danger onClick={onCancel}>
                                Cancel
                            </Button>
                        </Col>
                        <Col xsShrink>
                            <Button
                                type="submit"
                                onClick={props.handleSubmit}
                                submitting={props.isSubmitting}
                            >
                                {existingAddress ? 'Update' : 'Create'}
                            </Button>
                        </Col>
                    </Row>
                </Fragment>
            )}
        />
    );
};

const AddressFormikForm = ({
    values,
    touched,
    errors,
    countries,
    handleChange,
    handleBlur,
    setFieldValue,
}) => {
    const onSelectCountryAddress = e => {
        setFieldValue('country', countries.filter(item => item.value === e.target.value)[0].value);
    };

    const country = values.country && countries.filter(item => item.value === values.country)[0];

    return (
        <Fragment>
            <Row>
                <Col xs={12} lg={6}>
                    <Input
                        secondary
                        fullWidth
                        label="Address line one"
                        required
                        name="streetOne"
                        value={values.streetOne}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {touched.streetOne && errors.streetOne && (
                        <Fragment>
                            <Divider margin={2} />
                            <Alert message={errors.streetOne} type="error" />
                        </Fragment>
                    )}
                    <Divider margin={2} />
                </Col>
                <Col xs={12} lg={6}>
                    <Input
                        secondary
                        fullWidth
                        label="Address line two"
                        name="streetTwo"
                        value={values.streetTwo}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <Divider margin={2} />
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={6}>
                    <Input
                        secondary
                        fullWidth
                        label="City/Town"
                        required
                        name="city"
                        value={values.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {touched.city && errors.city && (
                        <Fragment>
                            <Divider margin={2} />
                            <Alert message={errors.city} type="error" />
                        </Fragment>
                    )}
                    <Divider margin={2} />
                </Col>
                <Col xs={12} lg={6}>
                    <Input
                        secondary
                        fullWidth
                        label="State/County"
                        required
                        name="county"
                        value={values.county}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {touched.county && errors.county && (
                        <Fragment>
                            <Divider margin={2} />
                            <Alert message={errors.county} type="error" />
                        </Fragment>
                    )}
                    <Divider margin={2} />
                </Col>
                <Col xs={12} lg={6}>
                    <Input
                        secondary
                        fullWidth
                        label="ZIP/Postal Code"
                        required
                        name="postcode"
                        value={values.postcode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {touched.postcode && errors.postcode && (
                        <Fragment>
                            <Divider margin={2} />
                            <Alert message={errors.postcode} type="error" />
                        </Fragment>
                    )}
                    <Divider margin={2} />
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={6}>
                    <Select
                        secondary
                        fullWidth
                        label="Country"
                        required
                        name="country"
                        onChange={onSelectCountryAddress}
                        options={countries}
                        value={country && country.value}
                        placeholder="Please select a country..."
                    >
                        {countries.map(({ label, value }) => (
                            <Select.Option key={`${label}_${value}`} label={label} value={value} />
                        ))}
                    </Select>
                    {touched.country && errors.country && (
                        <Fragment>
                            <Divider margin={2} />
                            <Alert message={errors.country} type="error" />
                        </Fragment>
                    )}
                    <Divider margin={2} />
                </Col>
            </Row>
        </Fragment>
    );
};

const yupAddressSchema = Yup.object().shape({
    streetOne: Yup.string().required('Street line one is required'),
    streetTwo: Yup.string(),
    city: Yup.string().required('City/Town is required'),
    county: Yup.string().required('State/County is required'),
    country: Yup.string().required('Country is required'),
    postcode: Yup.string().required('ZIP/Postal Code is required'),
});

export default withSnackbar(Address);
