import React, { Fragment, useEffect, useState } from 'react';
import { useSnackbar } from '@components/Snackbar';
import { makeGetRequest } from '@helpers/requests';
import { PERMISSIONS_ROLES, TEAM_MEMBERS } from '@helpers/api';
import { Divider, Grid } from '@components/Grid';
import Command from '@components/Command';
import List from '@components/List';
import Text from '@components/Text';

const Permissions = () => {
    const [openSnackbar] = useSnackbar();
    const [teamMembers, setTeamMembers] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                const { data: teamMembersData } = await makeGetRequest(TEAM_MEMBERS);

                setTeamMembers(teamMembersData);
                setLoading(false);
            } catch (error) {
                error !== 'cancelled' &&
                    openSnackbar(
                        error?.errorMessage ??
                            'An error occurred attempting to load your permissions.'
                    );
            }
        })();
    }, []);

    console.log({ teamMembers });
    return (
        <Fragment>
            <Command>
                <Command.Breadcrumbs>
                    <Command.Breadcrumbs.Breadcrumb text="Settings" />
                    <Command.Breadcrumbs.Breadcrumb
                        text="Team Members"
                        link="/settings/team-members"
                    />
                </Command.Breadcrumbs>

                <Command.Action
                    icon="plus"
                    text="Create"
                    link="/settings/team-members/team-member/create"
                />
            </Command>

            <Grid>
                <h1>Team Members</h1>
                <List loading={loading}>
                    {teamMembers?.members?.map(({ _id, firstName, lastName, email, avatar }) => (
                        <List.Item key={_id} link={`/settings/team-members/team-member/${_id}`}>
                            <List.Item.Avatar
                                alt={`${firstName} ${lastName}'s Avatar`}
                                src={avatar}
                            />
                            <List.Item.Column>
                                <Text bold>{`${firstName} ${lastName}`}</Text>
                                <Text>{email}</Text>
                            </List.Item.Column>
                        </List.Item>
                    ))}
                </List>
                <Divider />
            </Grid>
        </Fragment>
    );
};

export default Permissions;
