import React, { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { navigate } from 'gatsby-link';
import { format, parseISO, isAfter, isBefore, differenceInDays } from 'date-fns';

import { Grid, Divider } from '@components/Grid';
import { makeGetRequest } from '@helpers/requests';
import { STORE_DISCOUNTS } from '@helpers/api';
import Card from '@components/Card';
import Pagination from '@components/Pagination';
import Command from '@components/Command';
import usePrevious from '@helpers/hooks/usePrevious';
import Empty from '@components/Empty';
import { useSnackbar } from '@components/Snackbar';
import List from '@components/List';
import Text from '@components/Text';

const Discounts = ({ location }) => {
	const [openSnackbar] = useSnackbar();
	const { website } = useSelector(({ user, website }) => ({ user, website }));
	const params = queryString.parse(location.search, { parseNumbers: true });
	const page = params.page ?? 1;
	const query = params.query || '';
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState(null);
	const [discounts, setDiscounts] = useState([]);
	const [queryTimeout, setQueryTimeout] = useState(null);
	const prevPage = usePrevious(page);
	const prevQuery = usePrevious(query);

	const setNewDiscounts = async () => {
		try {
			if (!loading) setLoading(true);
			const { data: discountsData } = await makeGetRequest(STORE_DISCOUNTS, {
				perPage: 10,
				pageNum: page,
				search: query ? query : undefined,
			});
			setTotal(discountsData?.meta?.total);
			setDiscounts(discountsData?.results);
			setLoading(false);
		} catch (error) {
			error !== 'cancelled' &&
				openSnackbar(error?.errorMessage ?? 'An error occurred when loading discounts.');
		}
	};

	useEffect(() => {
		(async () => await setNewDiscounts())();
		return () => {
			clearTimeout(queryTimeout);
		};
	}, []);

	useEffect(() => {
		if (!prevPage || prevPage === page) return;
		setNewDiscounts();
	}, [page]);

	useEffect(() => {
		if (!prevQuery || prevQuery === query) return;
		clearTimeout(queryTimeout);
		setQueryTimeout(
			setTimeout(async () => {
				await setNewDiscounts();
			}, 750)
		);
	}, [query]);

	const handleUrlChange = (query = {}) => {
		navigate(
			`${location.pathname}?${queryString.stringify({
				...queryString.parse(location.search),
				page: undefined,
				...query,
			})}`
		);
	};

	return (
		<Fragment>
			<Command>
				<Command.Breadcrumbs>
					<Command.Breadcrumbs.Breadcrumb text="Store" link="/store" />
					<Command.Breadcrumbs.Breadcrumb text="Discounts" />
				</Command.Breadcrumbs>

				<Command.Action
					disabled={total >= website?.meta?.discountsLimit}
					text="Create"
					icon="plus"
					link="/store/discounts/create"
					allowDisabledOnClick
					onClick={
						total >= website?.meta?.discountsLimit
							? () =>
									openSnackbar(
										'Maximum discount limit reached, please contact support.'
									)
							: undefined
					}
				/>

				<Command.Filters>
					<Command.Filters.Search
						key="search"
						onChange={e => handleUrlChange({ query: e.target.value })}
						value={query}
					/>
				</Command.Filters>
			</Command>

			{loading || !!discounts?.length ? (
				<Grid>
					<Card>
						<Card.Content>
							<List loading={loading}>
								{discounts?.map(
									({
										_id,
										name,
										code,
										type,
										amount,
										limit,
										startDate,
										endDate,
										meta: { amountUsed = 0 },
									}) => (
										<List.Item
											key={_id}
											title={name}
											link={`/store/discounts/${_id}`}
										>
											<List.Item.Column>
												<Text bold>{name}</Text>
												{!!code && <Text faded>{code?.toUpperCase()}</Text>}
											</List.Item.Column>
											<List.Item.Column>
												<Text>
													{type === 'percentageOff' &&
														`${(amount * 100).toFixed(2)}%`}
													{type === 'amountOff' &&
														`-${(amount / 100).toFixed(2)}`}
													{type === 'newAmount' &&
														(amount / 100).toFixed(2)}
												</Text>
												<Text>
													{`Used ${amountUsed} ${
														amountUsed === 1 ? 'time' : 'times'
													}`}
													{` / `}
													{limit ? `${limit} limit` : 'Unlimited'}
												</Text>
											</List.Item.Column>
											<List.Item.Column>
												{(!!startDate || !!endDate) && (
													<Fragment>
														<Text>
															{!!startDate &&
																(isBefore(
																	parseISO(startDate),
																	new Date()
																)
																	? `Starts in ${differenceInDays(
																			parseISO(startDate),
																			new Date()
																	  )} days`
																	: `${
																			isAfter(
																				parseISO(startDate),
																				new Date()
																			)
																				? 'Starts '
																				: 'Started '
																	  }${format(
																			parseISO(startDate),
																			'PPP'
																	  )}`)}
														</Text>
														<Text>
															{!!endDate &&
																(isAfter(
																	new Date(),
																	parseISO(endDate)
																)
																	? `Expired ${differenceInDays(
																			new Date(),
																			parseISO(endDate)
																	  )} days ago`
																	: `${
																			isBefore(
																				parseISO(endDate),
																				new Date()
																			)
																				? 'Ended '
																				: 'Ends '
																	  }${format(
																			parseISO(endDate),
																			'PPP'
																	  )}`)}
														</Text>
													</Fragment>
												)}
											</List.Item.Column>
											)}
										</List.Item>
									)
								)}
							</List>
						</Card.Content>
					</Card>

					{!loading && (
						<Fragment>
							<Divider />
							<Pagination
								meta
								onChange={page => handleUrlChange({ page })}
								pageTotal={discounts?.length}
								total={total}
								page={page}
								perPage={10}
								metaLabel="Discounts"
							/>
						</Fragment>
					)}
				</Grid>
			) : !!query.length || queryTimeout ? (
				<Empty title="Nothing here" text="Your filters didn't seem to find anything." />
			) : (
				<Empty title="Hold tight" text="You don't have any discounts yet!" />
			)}
		</Fragment>
	);
};

export default Discounts;
