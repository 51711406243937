import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Input from '@components/Input';
import Button from '@components/Button';
import { Divider } from '@components/Grid';

import styles from './styles.module.scss';

// return dynamic input entries ['', ...]
const Inputs = ({ values, onChange }) => {
	const $inputs = [];

	return (
		<Fragment>
			<ul className={styles.fieldInput}>
				{(!!values?.length ? values : ['']).map((value, index) => (
					<li key={index}>
						<Input
							ref={ref => ($inputs[index] = ref)}
							value={value}
							placeholder={`Input ${index + 1}`}
							onChange={e => {
								onChange(
									values?.map((option, currIndex) =>
										currIndex !== index ? option : e.target.value
									) ?? [e.target.value]
								);
							}}
						/>
						{values?.length > 1 && (
							<div className={styles.fieldInputDelete}>
								<FontAwesomeIcon
									icon="times"
									onClick={() => {
										onChange(
											values.filter(
												(option, currIndex) => currIndex !== index
											)
										);
									}}
								/>
							</div>
						)}
					</li>
				))}
			</ul>
			<Divider margin={2} />
			<Button
				icon="plus"
				text="Add option"
				onClick={() => {
					onChange([
						...(!!values?.length ? values : ['Input 1']),
						`Input ${values ? values.length + 1 : 2}`,
					]);
				}}
			/>
		</Fragment>
	);
};

export default Inputs;
