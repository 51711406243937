import React, { Fragment, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListManager } from 'react-beautiful-dnd-grid';

import styles from './styles.module.scss';
import { makePostRequest } from '@helpers/requests';
import { UPLOAD_IMAGE } from '@helpers/api';
import Spinner from '@components/Spinner/Ellipsis';
import Label from '@components/Label';
import { withSnackbar } from '@components/Snackbar';

const Dropzone = ({
    name,
    min,
    max,
    label,
    optional,
    hint,
    image,
    reorder = true,
    multiple = false,
    onChange,
    folder = '',
    onUpload,
    onUploading,
    onUploaded,
    onReorder,
    upload = true,
    dropPlacholder,
    dropFocusedPlaceholder,
    onClick,
    onRemove,
    formatMediaPath,
    openSnackbar,
}) => {
    const [uploading, setUploading] = useState(false);
    const [rejected, setRejected] = useState(false);
    const [rejectedTimeout, setRejectedTimeout] = useState(null);

    useEffect(() => {
        return () => {
            clearTimeout(rejectedTimeout);
        };
    }, []);

    const uploadFile = async file => {
        if (upload) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('folder', folder);

            const { data: imageData } = await makePostRequest(UPLOAD_IMAGE, formData, null, {
                'Content-Type': 'multipart/form-data',
            });

            return imageData;
        } else {
            // const encoding = await new Promise((resolve, reject) => {
            //     const reader = new FileReader();
            //
            //     reader.onabort = () => reject('File reading was aborted');
            //     reader.onerror = () => reject('File reading has failed');
            //     reader.onload = () => resolve(reader.result);
            //     reader.readAsArrayBuffer(file);
            // });

            return file;
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        disabled:
            multiple &&
            ((max && (image?.length ?? 0) >= max) || (min && (image?.length ?? 0) <= min)),
        accept: 'image/*',
        maxSize: 5242880,
        noClick: !!onClick,
        multiple,
        onDrop: async files => {
            try {
                // prevent upload when no files passed, this is caused by dropzone rejection
                if (!files.length) return;

                setUploading(true);
                onUploading && onUploading(files);

                const newImage = !!multiple
                    ? await Promise.all(files.map(uploadFile))
                    : await uploadFile(files[0]);

                setUploading(false);

                if (multiple) {
                    onUpload && (await onUpload(newImage, [...(image || []), ...newImage]));

                    onChange && (await onChange([...(image || []), ...newImage], 'upload'));
                    onUploaded && (await onUploaded(newImage, [...(image || []), ...newImage]));
                } else {
                    onUpload && (await onUpload(newImage));

                    onChange && (await onChange(newImage, 'upload'));
                    onUploaded && (await onUploaded(newImage));
                }
            } catch (error) {
                setUploading(false);
                error !== 'cancelled' &&
                    openSnackbar(
                        error?.errorMessage ?? 'An error occurred when attempting uploading.'
                    );
            }
        },
        onDropRejected: () => {
            setRejected(true);
            setRejectedTimeout(
                setTimeout(() => {
                    setRejected(false);
                }, 5000)
            );
        },
    });

    const removeImage = removedImage => {
        if (multiple) {
            const newImages = image.slice();
            const index = newImages.indexOf(removedImage);
            newImages.splice(index, 1);

            onChange && onChange(newImages, 'remove');
            onRemove && onRemove(newImages);
            return;
        }

        onChange && onChange(null, 'remove');
        onRemove && onRemove();
    };

    const renderImage = (image, index = '') => {
        if (!image) return null;

        return (
            <div
                tabIndex={0}
                key={`${image.url || image}${`${index}` ? `_${index}` : ''}`}
                className={`${styles.dropzoneImagesImage}`}
            >
                {reorder && multiple ? (
                    <div className={styles.dropzoneImagesImageOverlay}>
                        <div
                            className={styles.dropzoneImagesImageOverlayItem}
                            onClick={() => removeImage(image)}
                            onKeyDown={e => e.key === 'Enter' && removeImage(image)}
                        >
                            <FontAwesomeIcon icon={['fas', 'times']} />
                        </div>
                        <div
                            className={`${styles.dropzoneImagesImageOverlayItem} ${
                                image.length <= 1
                                    ? styles.dropzoneImagesImageOverlayItemDisabled
                                    : ''
                            }`}
                        >
                            <FontAwesomeIcon icon={['fad', 'bars']} />
                        </div>
                    </div>
                ) : (
                    <div
                        className={styles.dropzoneImagesImageOverlay}
                        onClick={() => removeImage(image)}
                        onKeyDown={e => e.key === 'Enter' && removeImage(image)}
                    >
                        <FontAwesomeIcon icon={['fas', 'times']} />
                    </div>
                )}
                <img
                    alt={name}
                    src={
                        !!formatMediaPath ? formatMediaPath(image.url || image) : image.url || image
                    }
                />
            </div>
        );
    };

    // a little function to help us with reordering the result
    const handleReorder = (startIndex, endIndex) => {
        const result = Array.from(image);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    return (
        <Fragment>
            {!!label && <Label text={label} optional={optional} hint={hint} />}
            <div
                className={`${styles.dropzone} ${uploading ? styles.dropzoneUploading : ''} ${
                    !uploading && !isDragActive && rejected ? styles.dropzoneRejected : ''
                } ${(!!multiple && !image?.length) || !image ? styles.dropzoneEmpty : ''} ${
                    multiple &&
                    ((max && (image?.length ?? 0) >= max) || (min && (image?.length ?? 0) <= min))
                        ? styles.dropzoneDisabled
                        : ''
                } ${reorder && multiple ? styles.dropzoneReorderable : ''}`}
            >
                <div {...getRootProps()}>
                    <div
                        onClick={
                            multiple && max && (image?.length ?? 0) >= max
                                ? () => openSnackbar(`${label} can't have more than ${max} images.`)
                                : onClick
                        }
                        className={styles.dropzoneArea}
                    >
                        <input name={name} {...getInputProps()} />

                        {uploading && <Spinner />}

                        {!uploading && isDragActive ? (
                            <p>
                                {dropFocusedPlaceholder ||
                                    `Drop your image${multiple ? 's' : ''} right here...`}
                            </p>
                        ) : rejected ? (
                            <p>Sorry, the maximum file size is 5MB</p>
                        ) : (
                            <p>
                                {dropPlacholder ||
                                    (!multiple &&
                                        image &&
                                        'Drag and drop an image or click to select an image to replace the existing image') ||
                                    (!multiple &&
                                        'Drag and drop an image or click to select an image') ||
                                    'Drag and drop imagery or click to select images'}
                            </p>
                        )}
                    </div>
                </div>

                {!!image &&
                    (!!multiple && Array.isArray(image) && !!image?.length && !!reorder ? (
                        <div className={`${styles.dropzoneImages}`}>
                            <ListManager
                                items={image}
                                direction="horizontal"
                                maxItems={4}
                                render={renderImage}
                                onDragEnd={async (startIndex, endIndex) => {
                                    const newMedia = handleReorder(startIndex, endIndex);

                                    onChange && (await onChange(newMedia, 'reorder'));
                                    onReorder && (await onReorder(newMedia));
                                }}
                            />
                        </div>
                    ) : (
                        ((!multiple && image) ||
                            (!!multiple && Array.isArray(image) && !!image?.length)) && (
                            <div className={styles.dropzoneImages}>
                                {!!multiple && Array.isArray(image) && !!image?.length
                                    ? image?.map(renderImage)
                                    : renderImage(image)}
                            </div>
                        )
                    ))}
            </div>
        </Fragment>
    );
};

export default withSnackbar(Dropzone);
