import React from 'react';
import { globalHistory, Router, Redirect } from '@reach/router';
import { connect } from 'react-redux';

import Layout from '@components/Layout';
import PrivateRoute from '@components/PrivateRoute';
// const Login = loadable(() => import('@views/Login'));
// const Logout = loadable(() => import('@views/logout'));
// const Dashboard = loadable(() => import('@views/Dashboard'));
// const Websites = loadable(() => import('@views/Websites'));
// const Store = loadable(() => import('@views/Store'));
// const StoreProducts = loadable(() => import('@views/Store/Products'));
// const StoreProduct = loadable(() => import('@views/store/Product'));
// const EditorPages = loadable(() => import('@views/Editor/Pages'));
// const EmailsInbox = loadable(() => import('@views/Emails/Inbox'));

import Deployment from '@views/Deployment';
import Dashboard from '@views/Dashboard';

import AccountBilling from '@views/Account/Billing';
import AccountProfile from '@views/Account/Profile';
import AccountProfileEdit from '@views/Account/Profile/Edit';
import AccountProfileChangeEmail from '@views/Account/Profile/ChangeEmail';
import AccountProfileVerifyEmail from '@views/Account/Profile/VerifyEmail';
import AccountProfileForgotPassword from '@views/Account/Profile/ForgotPassword';
import AccountProfileForgotPasswordConfirm from '@views/Account/Profile/ForgotPasswordConfirm';

import Store from '@views/Store';
import StoreProducts from '@views/Store/Products';
import StoreProduct from '@views/Store/Products/Product';
import StoreCategories from '@views/Store/Categories';
import StoreCategoriesCategory from '@views/Store/Categories/Category';
import StoreCheckouts from '@views/Store/Checkouts';
import StoreCheckoutsCheckout from '@views/Store/Checkouts/Checkout';
import StoreOrders from '@views/Store/Orders';
import StoreOrdersOrder from '@views/Store/Orders/Order';
import StoreFields from '@views/Store/Fields';
import StoreFieldsField from '@views/Store/Fields/Field';
import StoreDiscounts from '@views/Store/Discounts';
import StoreDiscountsDiscount from '@views/Store/Discounts/Discount';

import Editor from '@views/Editor';
import EmailsInbox from '@views/Mail/Inbox';

import Websites from '@views/Websites';

import Forms from '@views/Forms';
import FormsForm from '@views/Forms/Form';
import FormsSubmissions from '@views/Forms/Submissions';
import FormsSubmissionsSubmission from '@views/Forms/Submissions/Submission';
import FormsNewsletters from '@views/Forms/Newsletters';
import FormsNewslettersSubscriber from '@views/Forms/Newsletters/Subscriber';

import Users from '@views/Users';
import UsersUser from '@views/Users/User';
import UsersTypes from '@views/Users/Types';
import UsersTypesType from '@views/Users/Types/Type';
import UsersFields from '@views/Users/Fields';
import UsersFieldsField from '@views/Users/Fields/Field';
import UsersRequests from '@views/Users/Requests';
import UsersRequestsRequest from '@views/Users/Requests/Request';

import SettingsWebsite from '@views/Settings/Website';
import SettingsStore from '@views/Settings/Store';
import SettingsBilling from '@views/Settings/Billing';
import SettingsBillingCheckout from '@views/Settings/Billing/Checkout';
import SettingsBillingInvoice from '@views/Settings/Billing/Invoice';
import SettingsBillingInvoicePay from '@views/Settings/Billing/Invoice/Pay';
import SettingsPermissions from '@views/Settings/Permissions';
import SettingsPermissionsRole from '@views/Settings/Permissions/Role';
import SettingsTeamMembers from '@views/Settings/TeamMembers';
import SettingsTeamMembersTeamMember from '@views/Settings/TeamMembers/TeamMember';

import PayInvoice from '@views/PayInvoice';

import ComingSoon from '@components/ComingSoon';

import AdminDashboard from '@views/Admin/Dashboard';
import AdminWebsites from '@views/Admin/Websites';
import AdminUsers from '@views/Admin/Users';
import AdminWebsitesWebsite from '@views/Admin/Websites/Website';
import AdminUserId from '@views/Admin/Users/Id';

import Login from '@views/Login';
import Logout from '@views/Logout';
import Error404 from './404';

// app.js wraps around client-only routes
// static pages and dynamic pages built through the createPage API are not passed into the Router
const App = () => (
    <Layout>
        <Router>
            <Login path="/" />
            <PayInvoice path="/pay-invoice/:id" />
            <PrivateRoute path="/account/billing" component={AccountBilling} />
            <PrivateRoute path="/account/profile" component={AccountProfile} />
            <PrivateRoute path="/account/profile/edit" component={AccountProfileEdit} />
            <AccountProfileChangeEmail path="/account/profile/change-email" />
            <AccountProfileVerifyEmail path="/account/profile/verify-email" />
            <AccountProfileForgotPassword path="/account/profile/forgot-password" />
            <AccountProfileForgotPasswordConfirm path="/account/profile/forgot-password-confirm" />

            <PrivateRoute
                path="/analytics"
                component={ComingSoon}
                websiteRequired
                websitePermission="analytics"
            />
            <PrivateRoute
                path="/dns"
                component={ComingSoon}
                websiteRequired
                websitePermission="dns"
            />
            <Redirect from="/emails" to="/emails/inbox" noThrow />
            <PrivateRoute
                path="/emails/inbox"
                component={ComingSoon}
                websiteRequired
                websitePermission="email"
            />
            <PrivateRoute
                path="/emails/accounts"
                component={ComingSoon}
                websiteRequired
                websitePermission="email"
            />
            <PrivateRoute
                path="/chat"
                component={ComingSoon}
                websiteRequired
                websitePermission="chat"
            />
            <PrivateRoute
                path="/chat/conversations"
                component={ComingSoon}
                websiteRequired
                websitePermission="chat"
            />
            <Redirect from="/store" to="/store/products" noThrow />
            <PrivateRoute
                path="/store/overview"
                component={ComingSoon}
                websiteRequired
                websitePermission="store"
            />
            <PrivateRoute
                path="/store/products"
                component={StoreProducts}
                websiteRequired
                websitePermission="store"
            />
            <PrivateRoute
                path="/store/products/:id"
                component={StoreProduct}
                websiteRequired={['professional', 'commercial']}
                websitePermission="store"
            />
            <PrivateRoute
                path="/store/products/create"
                component={StoreProduct}
                websiteRequired={['professional', 'commercial']}
                websitePermission="store"
            />
            <PrivateRoute
                path="/store/categories"
                component={StoreCategories}
                websiteRequired={['professional', 'commercial']}
                websitePermission="store"
            />
            <PrivateRoute
                path="/store/categories/create"
                component={StoreCategoriesCategory}
                websiteRequired={['professional', 'commercial']}
                websitePermission="store"
            />
            <PrivateRoute
                path="/store/categories/:id"
                component={StoreCategoriesCategory}
                websiteRequired={['professional', 'commercial']}
                websitePermission="store"
            />
            <PrivateRoute
                path="/store/checkouts"
                component={StoreCheckouts}
                websiteRequired
                websitePermission="store"
            />
            <PrivateRoute
                path="/store/checkouts/:id"
                component={StoreCheckoutsCheckout}
                websiteRequired
                websitePermission="store"
            />
            <PrivateRoute
                path="/store/orders"
                component={StoreOrders}
                websiteRequired
                websitePermission="store"
            />
            <PrivateRoute
                path="/store/orders/:id"
                component={StoreOrdersOrder}
                websiteRequired
                websitePermission="store"
            />
            <PrivateRoute
                path="/store/fields"
                component={StoreFields}
                websiteRequired
                websitePermission="store"
            />
            <PrivateRoute
                path="/store/fields/create"
                component={StoreFieldsField}
                websiteRequired
                websitePermission="store"
            />
            <PrivateRoute
                path="/store/fields/:id"
                component={StoreFieldsField}
                websiteRequired
                websitePermission="store"
            />
            <PrivateRoute
                path="/store/discounts"
                component={StoreDiscounts}
                websiteRequired
                websitePermission="store"
            />
            <PrivateRoute
                path="/store/discounts/create"
                component={StoreDiscountsDiscount}
                websiteRequired
                websitePermission="store"
            />
            <PrivateRoute
                path="/store/discounts/:id"
                component={StoreDiscountsDiscount}
                websiteRequired
                websitePermission="store"
            />
            <PrivateRoute
                path="/store/inventory"
                component={ComingSoon}
                websiteRequired
                websitePermission="store"
            />
            <PrivateRoute
                path="/editor"
                component={Editor}
                websiteRequired
                websitePermission="editor"
            />
            {/*<Redirect from="/forms" to="/forms/overview" noThrow />*/}
            <PrivateRoute
                path="/forms/overview"
                component={ComingSoon}
                websiteRequired
                websitePermission="enquiries"
            />
            <PrivateRoute
                path="/forms"
                component={Forms}
                websiteRequired
                websitePermission="enquiries"
            />
            <PrivateRoute
                path="/forms/form/create"
                component={FormsForm}
                websiteRequired
                websitePermission="enquiries"
            />
            <PrivateRoute
                path="/forms/form/:id"
                component={FormsForm}
                websiteRequired
                websitePermission="enquiries"
            />
            <PrivateRoute
                path="/forms/submissions"
                component={FormsSubmissions}
                websiteRequired
                websitePermission="enquiries"
            />
            <PrivateRoute
                path="/forms/submissions/:id"
                component={FormsSubmissionsSubmission}
                websiteRequired
                websitePermission="enquiries"
            />
            <PrivateRoute
                path="/forms/newsletters"
                component={FormsNewsletters}
                websiteRequired
                websitePermission="enquiries"
            />
            <PrivateRoute
                path="/forms/newsletters/:id"
                component={FormsNewslettersSubscriber}
                websiteRequired
                websitePermission="enquiries"
            />
            <PrivateRoute
                path="/users"
                component={Users}
                websiteRequired
                websitePermission="users"
            />
            <PrivateRoute
                path="/users/user/create"
                component={UsersUser}
                websiteRequired
                websitePermission="users"
            />
            <PrivateRoute
                path="/users/user/:id"
                component={UsersUser}
                websiteRequired
                websitePermission="users"
            />
            <PrivateRoute
                path="/users/types"
                component={UsersTypes}
                websiteRequired
                websitePermission="users"
            />
            <PrivateRoute
                path="/users/types/create"
                component={UsersTypesType}
                websiteRequired
                websitePermission="users"
            />
            <PrivateRoute
                path="/users/types/:id"
                component={UsersTypesType}
                websiteRequired
                websitePermission="users"
            />
            <PrivateRoute
                path="/users/fields"
                component={UsersFields}
                websiteRequired
                websitePermission="users"
            />
            <PrivateRoute
                path="/users/fields/create"
                component={UsersFieldsField}
                websiteRequired
                websitePermission="users"
            />
            <PrivateRoute
                path="/users/fields/:id"
                component={UsersFieldsField}
                websiteRequired
                websitePermission="users"
            />
            <PrivateRoute
                path="/users/requests"
                component={UsersRequests}
                websiteRequired
                websitePermission="users"
            />
            <PrivateRoute
                path="/users/requests/request/:id"
                component={UsersRequestsRequest}
                websiteRequired
                websitePermission="users"
            />

            <Redirect from="/settings" to="/settings/website" noThrow />
            <PrivateRoute path="/settings/website" component={SettingsWebsite} websiteRequired />
            <PrivateRoute path="/settings/billing" component={SettingsBilling} websiteRequired />
            <PrivateRoute
                path="/settings/billing/checkout"
                component={SettingsBillingCheckout}
                websiteRequired
            />
            <PrivateRoute
                path="/settings/billing/invoice/:id"
                component={SettingsBillingInvoice}
                websiteRequired
            />
            <PrivateRoute
                path="/settings/billing/invoice/:id/pay"
                component={SettingsBillingInvoicePay}
                websiteRequired
            />
            <PrivateRoute
                path="/settings/editor"
                component={ComingSoon}
                websiteRequired
                websitePermission="editor"
            />
            <PrivateRoute
                path="/settings/store"
                component={SettingsStore}
                websiteRequired
                websitePermission="store"
            />
            <PrivateRoute
                path="/settings/chat"
                component={ComingSoon}
                websiteRequired
                websitePermission="chat"
            />
            <PrivateRoute
                path="/settings/emails"
                component={ComingSoon}
                websiteRequired
                websitePermission="email"
            />
            <PrivateRoute
                path="/settings/permissions"
                component={SettingsPermissions}
                websiteRequired
            />
            <PrivateRoute
                path="/settings/permissions/role/create"
                component={SettingsPermissionsRole}
                websiteRequired
            />
            <PrivateRoute
                path="/settings/permissions/role/:id"
                component={SettingsPermissionsRole}
                websiteRequired
            />
            <PrivateRoute
                path="/settings/team-members"
                component={SettingsTeamMembers}
                websiteRequired
            />
            <PrivateRoute
                path="/settings/team-members/team-member/create"
                component={SettingsTeamMembersTeamMember}
                websiteRequired
            />
            <PrivateRoute
                path="/settings/team-members/team-member/:id"
                component={SettingsTeamMembersTeamMember}
                websiteRequired
            />
            <PrivateRoute path="/dashboard" component={Dashboard} websiteRequired />
            <PrivateRoute path="/deployment" component={Deployment} websiteRequired />
            <PrivateRoute path="/websites" component={Websites} />
            <PrivateRoute path="/logout" component={Logout} />
            <Redirect from="/admin" to="/admin/dashboard" noThrow />
            <PrivateRoute path="/admin/users" component={AdminUsers} admin />
            <PrivateRoute path="/admin/users/:id" component={AdminUserId} admin />
            <PrivateRoute path="/admin/websites" component={AdminWebsites} admin />
            <PrivateRoute path="/admin/websites/:id" component={AdminWebsitesWebsite} admin />
            <PrivateRoute path="/admin/dashboard" component={AdminDashboard} admin />
            <Error404 path="*" />
        </Router>
    </Layout>
);

export default App;
