import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { bindActionCreators } from 'redux';

import Panes from '@components/Panes';
import { Grid, Row, Col, Divider } from '@components/Grid';
import Input from '@components/Input';
import Select from '@components/Select';
import Button from '@components/Button';
import { makePutRequest, makeGetRequest } from '@helpers/requests';
import { SETTINGS_WEBSITE, WEBSITE } from '@helpers/api';
import Alert from '@components/Alert';
import { setWebsite } from '@actions/website';
import { withSnackbar } from '@components/Snackbar';
import Command from '@components/Command';

const Website = ({ user, website, setWebsite, openSnackbar }) => {
    const handleSubmit = async (values, actions) => {
        try {
            const {
                name,
                description,
                domain,
                email,
                meta: { githubRepo, type, origins },
            } = values;

            await makePutRequest(SETTINGS_WEBSITE(website._id), {
                name,
                description,
                domain,
                email,
                meta: { githubRepo, type, origins },
            });
            openSnackbar('Successfully saved website settings');
            const { data: websiteData } = await makeGetRequest(WEBSITE(website._id));
            setWebsite(websiteData);
        } catch (error) {
            error !== 'cancelled' && openSnackbar(error?.errorMessage ?? 'An error occurred when loading settings.');
        }
    };

    const { meta = {}, social = {} } = website;
    const { instagram = {} } = social;

    return (
        <Fragment>
            <Command>
                <Command.Breadcrumbs>
                    <Command.Breadcrumbs.Breadcrumb text="Settings" />
                    <Command.Breadcrumbs.Breadcrumb text="Website" />
                </Command.Breadcrumbs>
            </Command>
            <Panes>
                <Panes.Left>
                    <Row>
                        <Col xs={12}>
                            <Formik
                                validationSchema={Yup.object().shape({
                                    name: Yup.string().required('Name is required'),
                                    description: Yup.string().nullable(),
                                    domain: Yup.string().required('Domain is required'),
                                    email: Yup.string().required('Email is required'),
                                    meta: user.permissions.isAdmin
                                        ? Yup.object().shape({
                                              githubRepo: Yup.string().required(
                                                  'Meta Github Repo is required'
                                              ),
                                              type: Yup.string()
                                                  .oneOf([
                                                      'essential',
                                                      'professional',
                                                      'commercial',
                                                  ])
                                                  .required('Meta Type is required'),
                                              origins: Yup.array()
                                                  .of(Yup.string())
                                                  .required('Meta Origins is required'),
                                          })
                                        : undefined,
                                    social: Yup.object().shape({
                                        instagram: Yup.object().shape({
                                            accessToken: Yup.string().nullable(),
                                            name: Yup.string().nullable(),
                                        }),
                                    }),
                                })}
                                initialValues={{
                                    name: website.name,
                                    description: website.description,
                                    domain: website.domain,
                                    email: website.email,
                                    meta: {
                                        githubRepo: meta.githubRepo,
                                        type: meta.type,
                                        origins: meta.origins,
                                    },
                                    social: {
                                        instagram: {
                                            accessToken: instagram.accessToken,
                                            name: instagram.name,
                                        },
                                    },
                                }}
                                onSubmit={handleSubmit}
                            >
                                {props => <WebsiteForm {...props} user={user} />}
                            </Formik>
                        </Col>
                    </Row>
                </Panes.Left>
            </Panes>
        </Fragment>
    );
};

const WebsiteForm = ({
    values,
    touched,
    errors,
    handleSubmit,
    handleChange,
    user,
    isSubmitting,
}) => {
    return (
        <Fragment>
            <Input value={values.name} label="Name" name="name" onChange={handleChange} />
            {touched.name && errors.name && (
                <Fragment>
                    <Divider margin={2} />
                    <Alert type="error" message={errors.name} />
                </Fragment>
            )}
            <Divider />
            <Input
                textarea
                value={values.description}
                label="Description"
                name="description"
                onChange={handleChange}
            />
            {touched.description && errors.description && (
                <Fragment>
                    <Divider margin={2} />
                    <Alert type="error" message={errors.description} />
                </Fragment>
            )}
            <Divider />
            <Input value={values.domain} label="Domain" name="domain" onChange={handleChange} />
            {touched.domain && errors.domain && (
                <Fragment>
                    <Divider margin={2} />
                    <Alert type="error" message={errors.domain} />
                </Fragment>
            )}
            <Divider />
            <Input value={values.email} label="Email" name="email" onChange={handleChange} />
            {touched.email && errors.email && (
                <Fragment>
                    <Divider margin={2} />
                    <Alert type="error" message={errors.email} />
                </Fragment>
            )}
            <Divider />
            <Input
                disabled={!user.permissions.isAdmin}
                value={values.meta.githubRepo}
                label="Github Repository"
                name="meta.githubRepo"
                onChange={handleChange}
            />
            {touched.meta && errors.meta && touched.meta.githubRepo && errors.meta.githubRepo && (
                <Fragment>
                    <Divider margin={2} />
                    <Alert type="error" message={errors.meta.githubRepo} />
                </Fragment>
            )}
            <Divider />

            <Select
                disabled={!user.permissions.isAdmin}
                value={values.meta.type}
                label="Plan Type"
                name="meta.type"
                onChange={handleChange}
            >
                <Select.Option label="Essential" value="essential" />
                <Select.Option label="Professional" value="professional" />
                <Select.Option label="Commercial" value="commercial" />
            </Select>
            <Divider />

            <h2>Social</h2>
            <h3>Instagram</h3>
            <Input
                number
                value={values.social.instagram.accessToken}
                label="Access Token"
                name="social.instagram.accessToken"
                onChange={handleChange}
            />
            {touched.social &&
                errors.social &&
                touched.social.instagram &&
                errors.social.instagram &&
                touched.social.instagram.accessToken &&
                errors.social.instagram.accessToken && (
                    <Fragment>
                        <Divider margin={2} />
                        <Alert type="error" message={errors.social.instagram.accessToken} />
                    </Fragment>
                )}
            <Divider />

            <Input
                number
                value={values.social.instagram.name}
                label="Name"
                name="social.instagram.name"
                onChange={handleChange}
            />
            {touched.social &&
                errors.social &&
                touched.social.instagram &&
                errors.social.instagram &&
                touched.social.instagram.name &&
                errors.social.instagram.name && (
                    <Fragment>
                        <Divider margin={2} />
                        <Alert type="error" message={errors.social.instagram.name} />
                    </Fragment>
                )}
            <Divider />

            <Row end="xs">
                <Col xs={12}>
                    <Button text="Save" success onClick={handleSubmit} submitting={isSubmitting} />
                </Col>
            </Row>
        </Fragment>
    );
};

export default withSnackbar(
    connect(
        ({ user, website }) => ({ user, website }),
        dispatch => bindActionCreators({ setWebsite }, dispatch)
    )(Website)
);
