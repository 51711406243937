import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles.module.scss';
import Label from '@components/Label';

const Stepper = ({
    label,
    hint,
    optional,
    text = '',
    empty = false,
    onClear,
    onIncrement,
    onDecrement,
    incrementDisabled,
    decrementDisabled,
}) => {
    return (
        <Fragment>
            {!!label && <Label text={label} hint={hint} optional={optional} />}
            <div className={`${styles.stepper} ${empty ? styles.stepperEmpty : ''}`}>
                <div className={styles.stepperContent}>
                    <span
                        tabIndex={0}
                        className={`${styles.stepperButton} ${
                            decrementDisabled ? styles.stepperButtonDisabled : ''
                        }`}
                        onClick={!decrementDisabled ? onDecrement : undefined}
                    >
                        <FontAwesomeIcon icon="minus" />
                    </span>
                    <span className={styles.stepperText}>
                        {empty ? <FontAwesomeIcon icon="minus" /> : text}
                    </span>
                    <span
                        tabIndex={0}
                        className={`${styles.stepperButton} ${
                            incrementDisabled ? styles.stepperButtonDisabled : ''
                        }`}
                        onClick={!incrementDisabled ? onIncrement : undefined}
                    >
                        <FontAwesomeIcon icon="plus" />
                    </span>
                </div>

                {!empty && !!onClear && (
                    <span className={styles.stepperClear} onClick={onClear} tabIndex={0}>
                        <FontAwesomeIcon icon="times" />
                    </span>
                )}
            </div>
        </Fragment>
    );
};

export default Stepper;
