import React from 'react';

import ErrorIcon from '@images/wizard-question.svg';
import { HeightContext } from '@helpers/contexts';
import Button from '@components/Button';

import styles from './styles.module.scss';
import { navigate } from '@reach/router';

const Error = ({ title = 'Whoops', text = '', error = {} }) => (
    <HeightContext.Consumer>
        {({ height } = {}) => (
            <div className={styles.error} style={{ minHeight: height }}>
                <ErrorIcon className={styles.errorIcon} />
                <h1>{title}</h1>
                <p>{text || (error?.errorMessage ?? 'Something went wrong 😭')}</p>
                <div className={styles.errorButton}>
                    <Button text="Go back" onClick={() => navigate(-1)} />
                    <Button
                        secondary
                        text="Contact Support"
                        link={`mailto:team@elementsoftworks.co.uk?subject=Merlin Panel - Error - ${text ||
                            error.errorMessage}`}
                    />
                </div>
            </div>
        )}
    </HeightContext.Consumer>
);

export default Error;
