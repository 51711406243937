import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './styles.module.scss';

const Icon = ({ icon, status, size, disabled, ...props }) => {
    let iconClass = '';
    let sizeClass = styles.iconSizeMedium;
    let disabledClass = disabled ? styles.iconDisabled : '';

    if (size === 'small') {
        sizeClass = styles.iconSizeSmall;
    } else if (size === 'large') {
        sizeClass = styles.iconSizeLarge;
    }

    if (status === 'error') {
        iconClass = styles.iconError;
    } else if (status === 'warning') {
        iconClass = styles.iconWarning;
    } else if (status === 'success') {
        iconClass = styles.iconSuccess;
    }

    return (
        <span
            tabIndex={!disabled && props?.onClick ? 0 : undefined}
            className={`${styles.icon} ${sizeClass} ${iconClass} ${disabledClass}`}
            {...props}
        >
            <FontAwesomeIcon icon={icon} />
        </span>
    );
};

export default Icon;
