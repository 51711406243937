import React, { Fragment, useEffect, useRef, useState } from 'react';
import usePrevious from '@helpers/hooks/usePrevious';
import Panes from '@components/Panes';
import Alert from '@components/Alert';
import { Col, Divider, Row } from '@components/Grid';
import Input from '@components/Input';
import Select from '@components/Select';
import Dropdown from '@components/Dropdown';
import Button from '@components/Button';
import List from '@components/List';
import { format, parseISO } from 'date-fns';
import Modal from '@components/Modal';
import { Formik } from 'formik';
import Text from '@components/Text';

import RoleWrapper from '@views/Settings/Permissions/Role/Wrapper';
import RoleForm from '@views/Settings/Permissions/Role/Form';
import { makeDeleteRequest } from '@helpers/requests';
import { TEAM_MEMBER } from '@helpers/api';
import { navigate } from 'gatsby-link';

const Form = ({
    teamMember,
    values,
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    handleChange,
    setFieldValue,

    permissions,
    roles,
    creating,
    countries,
}) => {
    const [deletingTeamMember, setDeletingTeamMember] = useState(false);
    const [roleFocused, setRoleFocused] = useState(false);
    const $role = useRef(null);
    const { email, firstName, lastName, locale, role, isCustomRole } = values;
    const prevIsCustomRole = usePrevious(isCustomRole);
    const [customRoleOpen, setCustomRoleOpen] = useState(false);

    useEffect(() => {
        if (!!isCustomRole && prevIsCustomRole !== isCustomRole) {
            setFieldValue('role', null);
        }
    }, [isCustomRole]);

    const handleRoleSubmit = () => {};

    const handleDelete = async () => {
        try {
            await makeDeleteRequest(TEAM_MEMBER(id));
            openSnackbar(
                `Successfully deleted team member - ${teamMember?.firstName} ${teamMember?.lastName}.`
            );
            navigate('/teamMembers/teamMember');
        } catch (error) {
            error !== 'cancelled' &&
                openSnackbar(
                    error?.errorMessage ??
                        `An error occurred attempting to delete teamMember - ${teamMember?.firstName} ${teamMember?.lastName}.`
                );
        }
    };

    // useEffect(() => {
    //     if (!!isCustomRole && roles?.) {
    //         setFieldValue('role', null);
    //     }
    // }, [role]);

    return (
        <Fragment>
            <Panes>
                <Panes.Left>
                    {creating && (
                        <Fragment>
                            <Alert
                                type="info"
                                message="When you create a team member an email will be sent out to the applicable email address, the team member will be activated once they have set up a password from their setup email."
                            />
                            <Divider />
                        </Fragment>
                    )}
                    <Input
                        name="email"
                        label="Email"
                        value={email}
                        onChange={handleChange}
                        disabled={!creating}
                    />
                    {touched.email && errors.email && (
                        <Fragment>
                            <Divider />
                            <Alert type="error" message={errors.email} />
                        </Fragment>
                    )}
                    <Divider />

                    <Input
                        name="firstName"
                        label="First name"
                        value={firstName}
                        onChange={handleChange}
                    />
                    {touched.firstName && errors.firstName && (
                        <Fragment>
                            <Divider />
                            <Alert type="error" message={errors.firstName} />
                        </Fragment>
                    )}
                    <Divider />

                    <Input
                        name="lastName"
                        label="Last name"
                        value={lastName}
                        onChange={handleChange}
                    />
                    {touched.lastName && errors.lastName && (
                        <Fragment>
                            <Divider />
                            <Alert type="error" message={errors.lastName} />
                        </Fragment>
                    )}
                    <Divider />

                    <Input
                        name="locale.city"
                        label="City"
                        value={locale?.city}
                        onChange={handleChange}
                    />
                    {touched.locale?.city && errors.locale?.city && (
                        <Fragment>
                            <Divider />
                            <Alert type="error" message={errors.locale?.city} />
                        </Fragment>
                    )}
                    <Divider />

                    <Select
                        name="locale.countryCode"
                        label="Country"
                        placeholder="Select a country..."
                        onChange={handleChange}
                        value={locale?.countryCode}
                    >
                        {countries.map(({ code, name }) => (
                            <Select.Option key={code} label={name} value={code} />
                        ))}
                    </Select>
                    {touched.locale?.countryCode && errors.locale?.countryCode && (
                        <Fragment>
                            <Divider />
                            <Alert type="error" message={errors.locale?.countryCode} />
                        </Fragment>
                    )}
                    <Divider />

                    <div ref={$role}>
                        <Input
                            name="role"
                            label="Role"
                            hint="What permissions would you like to grant this team member to?"
                            icon="caret-down"
                            value={
                                values.isCustomRole
                                    ? 'Custom'
                                    : values.role
                                    ? roles?.find(({ _id }) => values.role === _id)?.name
                                    : ''
                            }
                            onClick={() => setRoleFocused(!roleFocused)}
                        />
                    </div>
                    {touched.role && errors.role && (
                        <Fragment>
                            <Divider />
                            <Alert type="error" message={errors.role} />
                        </Fragment>
                    )}
                    {roleFocused && (
                        <Dropdown target={$role} onEscape={() => setRoleFocused(false)}>
                            {roles?.map(({ _id }) => (
                                <Dropdown.Item
                                    text="Name"
                                    description="Vestibulum id ligula porta felis euismod semper."
                                    checked={values.role === _id}
                                    onClick={() => {
                                        setFieldValue('isCustomRole', false);
                                        setFieldValue('role', _id);
                                    }}
                                />
                            ))}
                            <Dropdown.Item
                                text="Custom"
                                description={`Configure a user specific role that is only applicable to ${
                                    !!teamMember?.firstName
                                        ? teamMember?.firstName
                                        : 'this team member.'
                                }`}
                                checked={values.isCustomRole}
                                onClick={() => setFieldValue('isCustomRole', true)}
                            />
                        </Dropdown>
                    )}
                    {isCustomRole && (
                        <Fragment>
                            <Divider margin={2} />
                            {role ? (
                                <Button
                                    text="Edit custom role"
                                    icon="pen"
                                    onClick={() => setCustomRoleOpen(!customRoleOpen)}
                                />
                            ) : (
                                <Button
                                    disabled={customRoleOpen}
                                    text="Create custom role"
                                    icon="plus"
                                    onClick={() => setCustomRoleOpen(true)}
                                />
                            )}
                        </Fragment>
                    )}
                    <Divider />

                    <Row end="xs">
                        <Col xs={12}>
                            {!creating && (
                                <Button
                                    text="Delete"
                                    danger
                                    onClick={() => setDeletingTeamMember(true)}
                                    submitting={deletingTeamMember}
                                />
                            )}

                            <Button
                                submitting={isSubmitting}
                                success
                                text={creating ? 'Invite' : 'Save'}
                                onClick={handleSubmit}
                            />
                        </Col>
                    </Row>
                </Panes.Left>
                <Panes.Right>
                    {customRoleOpen ? (
                        <Fragment>
                            <Panes.Title onBack={() => setCustomRoleOpen(false)}>
                                <h3>{role ? 'Editing custom role' : 'New custom role'}</h3>

                                {!!role && (
                                    <Panes.Title.Actions>
                                        <Panes.Title.Actions.Action
                                            text="Preview"
                                            icon="list"
                                            link={`/settings/permissions/role/${role?._id}`}
                                        />
                                    </Panes.Title.Actions>
                                )}
                            </Panes.Title>

                            <RoleWrapper teamMember={teamMember} onSubmit={handleRoleSubmit}>
                                {props => (
                                    <RoleForm
                                        teamMember={teamMember}
                                        permissions={permissions}
                                        {...props}
                                    />
                                )}
                            </RoleWrapper>
                        </Fragment>
                    ) : (
                        <List>
                            {!creating && (
                                <Fragment>
                                    <List.Item key="id">
                                        <List.Item.Column>
                                            <Text bold>Id</Text>
                                        </List.Item.Column>
                                        <List.Item.Column.Right important>
                                            <Text>{teamMember?._id}</Text>
                                        </List.Item.Column.Right>
                                    </List.Item>
                                    <List.Item key="last-logged-in">
                                        <List.Item.Column>
                                            <Text bold>Last logged in</Text>
                                        </List.Item.Column>
                                        <List.Item.Column.Right important>
                                            <Text>
                                                {!!teamMember?.lastLogin &&
                                                    format(
                                                        parseISO(teamMember?.lastLogin),
                                                        'PPP p'
                                                    )}
                                            </Text>
                                        </List.Item.Column.Right>
                                    </List.Item>
                                    <List.Item key="Name">
                                        <List.Item.Column>
                                            <Text bold>Name</Text>
                                        </List.Item.Column>
                                        <List.Item.Column.Right important>
                                            <Text empty={!firstName && !lastName}>
                                                {firstName} {lastName}
                                            </Text>
                                        </List.Item.Column.Right>
                                    </List.Item>
                                    <List.Item key="City">
                                        <List.Item.Column>
                                            <Text bold>City</Text>
                                        </List.Item.Column>
                                        <List.Item.Column.Right important>
                                            <Text empty={!locale?.city}>{locale?.city}</Text>
                                        </List.Item.Column.Right>
                                    </List.Item>
                                    <List.Item key="Country">
                                        <List.Item.Column>
                                            <Text bold>Country</Text>
                                        </List.Item.Column>
                                        <List.Item.Column.Right important>
                                            <Text empty={!locale?.countryCode}>
                                                {countries?.find(
                                                    ({ code }) => locale?.countryCode === code
                                                )}
                                            </Text>
                                        </List.Item.Column.Right>
                                    </List.Item>
                                </Fragment>
                            )}
                        </List>
                    )}
                </Panes.Right>
            </Panes>

            <Modal
                isOpen={deletingTeamMember}
                title={`Deleting team member - ${teamMember?.firstName} ${teamMember?.lastName}`}
                onClose={() => {
                    setDeletingTeamMember(false);
                }}
            >
                <Modal.Content>
                    <Formik initialValues={{}} onSubmit={handleDelete}>
                        {({ handleSubmit, isSubmitting }) => (
                            <Fragment>
                                <p>Are you sure?</p>
                                <Divider />
                                <Button
                                    text="Delete"
                                    danger
                                    onClick={handleSubmit}
                                    submitting={isSubmitting}
                                />
                                <Button
                                    text="Cancel"
                                    onClick={() => {
                                        setDeletingTeamMember(false);
                                    }}
                                />
                            </Fragment>
                        )}
                    </Formik>
                </Modal.Content>
            </Modal>
        </Fragment>
    );
};

export default Form;
