import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { navigate } from 'gatsby-link';
import { format, parseISO } from 'date-fns';

import { Grid, Divider } from '@components/Grid';
import { makeGetRequest } from '@helpers/requests';
import { FORM_NEWSLETTERS } from '@helpers/api';
import Card from '@components/Card';
import Pagination from '@components/Pagination';
import Command from '@components/Command';
import usePrevious from '@helpers/hooks/usePrevious';
import Empty from '@components/Empty';
import { withSnackbar } from '@components/Snackbar';
import List from '@components/List';

// import styles from './styles.module.scss';

const Newsletters = ({ location, openSnackbar }) => {
    const params = queryString.parse(location.search, { parseNumbers: true });
    const page = params?.page ?? 1;
    const query = params?.query ?? '';

    const [loading, setLoading] = useState(true);
    const [newsletter, setNewsletter] = useState([]);
    const [total, setTotal] = useState(null);
    const [queryTimeout, setQueryTimeout] = useState(null);
    const prevPage = usePrevious(page);
    const prevQuery = usePrevious(query);

    const setNewNewsletter = async () => {
        try {
            if (!loading) setLoading(true);
            const { data: newsletterData } = await makeGetRequest(FORM_NEWSLETTERS, {
                perPage: 10,
                pageNum: page,
                email: query || undefined,
            });
            setTotal(newsletterData?.meta?.total);
            setNewsletter(newsletterData?.results);
            setLoading(false);
        } catch (error) {
            error !== 'cancelled' &&
                openSnackbar(error?.errorMessage ?? 'An error occurred loading newsletters.');
        }
    };

    useEffect(() => {
        (async () => await setNewNewsletter())();
        return () => {
            clearTimeout(queryTimeout);
        };
    }, []);

    useEffect(() => {
        if (!prevPage || prevPage === page) return;
        setNewNewsletter();
    }, [page]);

    useEffect(() => {
        if (!prevQuery || prevQuery === query) return;
        clearTimeout(queryTimeout);
        setQueryTimeout(
            setTimeout(async () => {
                await setNewNewsletter({});
            }, 750)
        );
    }, [query]);

    const handleUrlChange = (query = {}) => {
        navigate(
            `${location.pathname}?${queryString.stringify({
                ...queryString.parse(location.search),
                page: undefined,
                ...query,
            })}`
        );
    };

    return (
        <Fragment>
            <Command>
                <Command.Breadcrumbs>
                    <Command.Breadcrumbs.Breadcrumb text="Forms" link="/forms" />
                    <Command.Breadcrumbs.Breadcrumb text="Newsletters" />
                </Command.Breadcrumbs>
                <Command.Filters>
                    <Command.Filters.Search
                        key="search"
                        onChange={e => handleUrlChange({ query: e.target.value })}
                        value={query}
                    />
                </Command.Filters>
            </Command>

            {loading || !!newsletter.length ? (
                <Grid>
                    <List loading={loading}>
                        {newsletter.map(({ _id, email, timestamp }) => (
                            <List.Item key={_id} title={email} link={`/forms/newsletters/${_id}`}>
                                <List.Item.Title text={email} />
                                <List.Item.Description
                                    text={`Joined on ${format(parseISO(timestamp), 'PPP p')}`}
                                />
                            </List.Item>
                        ))}
                    </List>

                    {!loading && (
                        <Fragment>
                            <Divider />
                            <Pagination
                                meta
                                onChange={page => handleUrlChange({ page })}
                                pageTotal={newsletter.length}
                                total={total}
                                page={page}
                                perPage={10}
                                metaLabel="Subscribers"
                            />
                        </Fragment>
                    )}
                </Grid>
            ) : (
                <Empty title="Hold tight" text="You don't have any subscribers yet!" />
            )}
        </Fragment>
    );
};

export default withSnackbar(Newsletters);
