import React, { Fragment, useEffect, useState } from 'react';
import { withSnackbar } from '@components/Snackbar';
import { format, parseISO, isBefore } from 'date-fns';

import { makeGetRequest } from '@helpers/requests';
import { INVOICES, SUBSCRIPTION } from '@helpers/api';
import Card from '@components/Card';
import { Col, Divider, Grid, Row } from '@components/Grid';
import Command from '@components/Command';
import Loader from '@components/Loader';
import Button from '@components/Button';

import styles from './styles.module.scss';

import EssentialIcon from '@images/essential.svg';
import ProfessionalIcon from '@images/professional.svg';
import CommercialIcon from '@images/commercial.svg';
import { navigate } from 'gatsby-link';
import { useSelector } from 'react-redux';
import List from '@components/List';

const Billing = ({ openSnackbar }) => {
    const { website } = useSelector(({ website }) => ({ website }));
    const [loading, setLoading] = useState(true);
    const [subscription, setSubscription] = useState(null);
    const [invoices, setInvoices] = useState(null);
    const [invoicesPage, setInvoicesPage] = useState(1);

    useEffect(() => {
        (async () => {
            try {
                let subscriptionData;
                // if no subscription exists, don't request subscription data!
                if (website?.subscription?.subscriptionId) {
                    const { data: subscriptionDataRes } = await makeGetRequest(SUBSCRIPTION);
                    subscriptionData = subscriptionDataRes;
                }

                const { data: invoices } = await makeGetRequest(INVOICES, {
                    perPage: 10,
                    pageNum: invoicesPage,
                });

                setSubscription(subscriptionData ?? null);
                setInvoices(invoices);
                setLoading(false);
            } catch (error) {
                error !== 'cancelled' &&
                    openSnackbar(
                        error?.errorMessage ?? 'An error occurred loading your subscription.'
                    );
            }
        })();
    }, []);

    const getPlanName = () => {
        if (subscription.planId === 'commercial-monthly' || 'commercial-annually') {
            return 'Commercial';
        }

        if (subscription.planId === 'professional-monthly' || 'professional-annually') {
            return 'Professional';
        }

        if (subscription.planId === 'essential-monthly' || 'essential-annually') {
            return 'Essential';
        }
    };

    const getPlanIcon = () => {
        if (subscription.planId === 'commercial-monthly' || 'commercial-annually') {
            return <CommercialIcon />;
        }

        if (subscription.planId === 'professional-monthly' || 'professional-annually') {
            return <ProfessionalIcon />;
        }

        if (subscription.planId === 'essential-monthly' || 'essential-annually') {
            return <EssentialIcon />;
        }
    };

    const renderInvoices = () => {
        if (loading || !invoices || (invoices && !invoices?.results?.length)) return null;

        return (
            <Fragment>
                <Divider />
                <Card>
                    <Card.Title>Invoices</Card.Title>
                    <Card.Content>
                        <List>
                            {invoices?.results?.map(invoice => (
                                <List.Item
                                    key={invoice?._id}
                                    link={`/settings/billing/invoice/${invoice._id}`}
                                >
                                    <List.Item.Column>
                                        <List.Item.Title text={`MP-${invoice?.invoiceNumber}`} />
                                        <List.Item.Description
                                            text={format(parseISO(invoice?.due), 'PPP p')}
                                        />
                                    </List.Item.Column>
                                    <List.Item.Column important>
                                        <List.Item.Title
                                            text={`£${(invoice?.total / 100).toFixed(2)}`}
                                        />
                                        {!invoice?.paid &&
                                        !!isBefore(parseISO(invoice?.due), new Date()) ? (
                                            <List.Item.Description
                                                status="error"
                                                text={'Overdue'}
                                            />
                                        ) : (
                                            <List.Item.Description
                                                status={invoice?.paid ? 'success' : 'warning'}
                                                text={invoice?.paid ? 'Paid' : 'Unpaid'}
                                            />
                                        )}
                                    </List.Item.Column>

                                    <List.Item.Actions>
                                        <List.Item.Actions.Action
                                            tooltip="View Invoice as PDF"
                                            icon="file-pdf"
                                            link={invoice?.invoiceUrl}
                                        />
                                    </List.Item.Actions>
                                </List.Item>
                            ))}
                        </List>
                    </Card.Content>
                </Card>
            </Fragment>
        );
    };

    return (
        <Fragment>
            <Command>
                <Command.Breadcrumbs>
                    <Command.Breadcrumbs.Breadcrumb text="Settings" />
                    <Command.Breadcrumbs.Breadcrumb text="Billing" />
                </Command.Breadcrumbs>
            </Command>
            <Grid>
                {loading ? (
                    <Loader />
                ) : (
                    <Fragment>
                        {subscription ? (
                            <Fragment>
                                <Row>
                                    <Col xs={12} md={8} xl={6}>
                                        <div
                                            className={`${styles.plan} ${
                                                subscription.planId === 'essential-monthly' ||
                                                subscription.planId === 'essential-annually'
                                                    ? styles.planEssential
                                                    : ''
                                            } ${
                                                subscription.planId === 'professional-monthly' ||
                                                subscription.planId === 'professional-annually'
                                                    ? styles.planProfessional
                                                    : ''
                                            } ${
                                                subscription.planId === 'commercial-monthly' ||
                                                subscription.planId === 'commercial-annually'
                                                    ? styles.planCommercial
                                                    : ''
                                            }`}
                                        >
                                            <Card divider={false}>
                                                <Card.Title>
                                                    {`${getPlanName()} Plan`}

                                                    <Card.Title.Detail>
                                                        <span className={styles.planIcon}>
                                                            {getPlanIcon()}
                                                        </span>
                                                    </Card.Title.Detail>
                                                </Card.Title>
                                                <Card.Description>
                                                    {`You are due to pay £${
                                                        subscription.price
                                                    } on the ${format(
                                                        parseISO(subscription.nextBillingDate),
                                                        'PPP p'
                                                    )}`}
                                                </Card.Description>
                                                <Card.Actions>
                                                    <Card.Actions.Action
                                                        text="Features"
                                                        link={`https://merlinpanel.com/package/${getPlanName().toLowerCase()}`}
                                                    />
                                                    <Card.Actions.Action
                                                        secondary
                                                        text="Change Plan"
                                                        link={`/settings/billing/checkout`}
                                                    />
                                                </Card.Actions>
                                            </Card>
                                        </div>
                                        <Divider />
                                    </Col>
                                </Row>

                                {renderInvoices()}

                                <Card>
                                    <Card.Title>Subscription</Card.Title>
                                    <Card.Content>
                                        <Card.List>
                                            <Card.List.Item
                                                label="Plan"
                                                value={subscription.planId}
                                            />
                                            <Card.List.Item
                                                label="Created"
                                                value={format(
                                                    parseISO(subscription.createdAt),
                                                    'PPP p'
                                                )}
                                            />
                                            <Card.List.Item
                                                label="Next billing date"
                                                value={format(
                                                    parseISO(subscription.nextBillingDate),
                                                    'PPP p'
                                                )}
                                            />
                                            <Card.List.Item
                                                label="Price"
                                                value={`£${subscription.price}`}
                                            />
                                            <Card.List.Item
                                                label="Billing cycles"
                                                value={subscription.currentBillingCycle}
                                            />
                                            <Card.List.Item
                                                label="Status"
                                                value={subscription.status}
                                            />
                                        </Card.List>
                                    </Card.Content>
                                </Card>
                                <Divider />
                                <Card>
                                    <Card.Title>Transactions</Card.Title>
                                    <Card.Content>
                                        {(subscription?.transactions ?? []).map(transaction => (
                                            <Card.List
                                                key={transaction.createdAt}
                                                title={format(
                                                    parseISO(transaction.createdAt),
                                                    'PPP p'
                                                )}
                                            >
                                                <Card.List.Item
                                                    label="Customer"
                                                    value={`${transaction.customer.firstName} ${transaction.customer.lastName} - ${transaction.customer.email}`}
                                                />
                                                {!!Object.keys(transaction?.creditCard).length && (
                                                    <Fragment>
                                                        <Card.List.Item
                                                            label="Credit card type"
                                                            value={
                                                                transaction?.creditCard?.cardType
                                                            }
                                                        />
                                                        <Card.List.Item
                                                            label="Credit card expiration date"
                                                            value={
                                                                transaction?.creditCard
                                                                    ?.expirationDate
                                                            }
                                                        />
                                                        <Card.List.Item
                                                            label="Credit card last 4 digits"
                                                            value={transaction?.creditCard?.last4}
                                                        />
                                                    </Fragment>
                                                )}
                                            </Card.List>
                                        ))}
                                    </Card.Content>
                                </Card>
                            </Fragment>
                        ) : (
                            // subscription doesn't exist, tell the user
                            <Fragment>
                                <Row>
                                    <Col xs={12} md={8} xl={6}>
                                        <Card divider={false}>
                                            <Card.Title icon="">
                                                Select a plan
                                                {/*<Card.Title.Detail>*/}
                                                {/*    <span className={styles.planIcon}>*/}
                                                {/*        {getPlanIcon()}*/}
                                                {/*    </span>*/}
                                                {/*</Card.Title.Detail>*/}
                                            </Card.Title>
                                            <Card.Description>
                                                You're not currently on a plan, please subscribe to
                                                start using the platform.
                                            </Card.Description>
                                            <Card.Actions divider={false}>
                                                <Card.Actions.Action
                                                    text="Subscribe"
                                                    link={`/settings/billing/checkout`}
                                                />
                                            </Card.Actions>
                                        </Card>
                                    </Col>
                                </Row>

                                {renderInvoices()}
                            </Fragment>
                        )}
                    </Fragment>
                )}
            </Grid>
        </Fragment>
    );
};

export default withSnackbar(Billing);
