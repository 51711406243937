import React, { Fragment } from 'react';
import { navigate } from 'gatsby-link';
import { Divider } from '@components/Grid';
import Alert from '@components/Alert';

import Input from '@views/Editor/File/Fields/Input';
// import List from '@views/Editor/File/Fields/List';
import Image from '@views/Editor/File/Fields/Image';
import Html from '@views/Editor/File/Fields/Html';
import Select from '@components/Select';
import Checkboxes from '@components/Checkboxes';
import Radios from '@components/Radios';
import Switch from '@components/Switch';
import Label from '@components/Label';
import Text from '@components/Text';

import styles from './styles.module.scss';
import Dropzone from '@components/Dropzone';
import Inputs from '@components/CustomField/Inputs';

const Field = data => {
	const {
		field: { type, label, hint, optional, options, items, multiple },
		value,
		touched,
		error,
		handleChange,
		setFieldValue,
		name,
	} = data;

	return (
		<Fragment>
			{(type === 'string' || type === 'text' || type === 'number') &&
				(multiple ? (
					<Fragment>
						<Label text={label} hint={hint} optional={optional} />
						<Inputs
							values={value}
							onChange={data => {
								setFieldValue(name, data);
							}}
						/>
					</Fragment>
				) : (
					<Input {...data} name={name} />
				))}

			{type === 'collection' && (
				<div className={styles.customFieldCollection}>
					<h2>{label}</h2>
					{!!hint && <Text faded>{hint}</Text>}
					<Divider margin={2} />
					{items?.map((item, index) => (
						<Field
							{...data}
							value={value?.[item?._id]}
							touched={touched?.[item?._id]}
							error={error?.[item?._id]}
							field={item}
							name={`${name}.${item?._id}`}
						/>
					))}
				</div>
			)}
			{type === 'image' && (
				<Dropzone
					label={label}
					hint={hint}
					optional={optional}
					image={value}
					multiple={multiple}
					onChange={images => setFieldValue(name, images)}
					folder="store"
				/>
			)}
			{type === 'html' && <Html {...data} />}
			{type === 'dropdown' && (
				<Select
					{...data}
					label={label}
					hint={hint}
					optional={optional}
					onChange={handleChange}
				>
					{options?.map(({ _id, label }) => (
						<Select.Option label={label} value={_id} />
					))}
				</Select>
			)}
			{type === 'checkbox' && (
				<Checkboxes
					{...data}
					label={label}
					hint={hint}
					optional={optional}
					onChange={({ active }) => setFieldValue(name, active)}
				>
					{options?.map(({ _id, label }) => (
						<Checkboxes.Checkbox key={_id} label={label} value={_id} checked={value} />
					))}
				</Checkboxes>
			)}
			{type === 'radio' && (
				<Radios
					{...data}
					label={label}
					hint={hint}
					optional={optional}
					onChange={({ id }) => setFieldValue(name, id)}
				>
					{options?.map(({ _id, label }) => (
						<Radios.Radio key={_id} label={label} value={_id} checked={value} />
					))}
				</Radios>
			)}
			{type === 'switch' && (
				<Switch
					{...data}
					label={label}
					hint={hint}
					optional={optional}
					name={name}
					checked={value}
					onChange={(e, checked) => {
						setFieldValue(name, checked);
					}}
				/>
			)}
			{touched && error && typeof error === 'string' && (
				<Fragment>
					<Divider />
					<Alert type="error" message={error || ''} />
				</Fragment>
			)}
			<Divider />
		</Fragment>
	);
};

export default Field;
