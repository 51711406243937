import React, { useEffect, useState, Fragment } from 'react';

import { makeGetRequest } from '@helpers/requests';
import Command from '@components/Command';
import { Grid, Row, Col, Divider } from '@components/Grid';
import Card from '@components/Card';
import Loader from '@components/Loader';
import { format, parseISO } from 'date-fns';
import { STORE_CHECKOUTS_CHECKOUT } from '@helpers/api';
import { navigate } from 'gatsby-link';
import { connect } from 'react-redux';
import { withSnackbar } from '@components/Snackbar';
import List from '@components/List';
import Text from '@components/Text';

const Viewer = ({ website, id, openSnackbar }) => {
	const [loading, setLoading] = useState(true);
	const [checkout, setCheckout] = useState(null);

	useEffect(() => {
		(async () => {
			try {
				const { data: checkout } = await makeGetRequest(STORE_CHECKOUTS_CHECKOUT(id));
				setCheckout(checkout);
				setLoading(false);
			} catch (error) {
				error !== 'cancelled' &&
					openSnackbar(error?.errorMessage ?? 'An error occurred loading this checkout.');
			}
		})();
	}, []);

	return (
		<Fragment>
			<Command>
				<Command.Breadcrumbs>
					<Command.Breadcrumbs.Breadcrumb text="Store" link="/store" />
					<Command.Breadcrumbs.Breadcrumb text="Checkouts" link="/store/checkouts" />
					<Command.Breadcrumbs.Breadcrumb text="Checkout" />
				</Command.Breadcrumbs>
			</Command>
			{loading ? (
				<Loader />
			) : (
				<Grid>
					<Row>
						<Col xs={12}>
							<Card>
								<Card.Content>
									<Card.List>
										<Card.List.Item label="Id" value={checkout._id} />
										<Card.List.Item
											label="Created"
											value={format(
												parseISO(checkout.meta?.timestamp),
												'PPP p'
											)}
										/>
										{!!checkout.stage?.lastUpdated && (
											<Card.List.Item
												label="Last updated"
												value={format(
													parseISO(checkout.stage?.lastUpdated),
													'PPP p'
												)}
											/>
										)}
										{!!checkout?.meta?.discountCode && (
											<Card.List.Item
												label="Discount Code"
												value={checkout?.meta?.discountCode}
											/>
										)}
										{!!checkout?.meta?.discountedAmount && (
											<Card.List.Item
												label="Discounted Amount"
												value={`${website.store.currency.symbol}${checkout
													?.meta?.discountedAmount / 100}`}
											/>
										)}
										{!!checkout?.meta?.discountedType && (
											<Card.List.Item
												label="Discount Type"
												value={
													(checkout?.meta?.discountedType ===
														'percentageOff' &&
														'Percentage off') ||
													(checkout?.meta?.discountedType ===
														'amountOff' &&
														'Amount Off') ||
													(checkout?.meta?.discountedType ===
														'newAmount' &&
														'New amount') ||
													''
												}
											/>
										)}
										<Card.List.Item
											label="Currency"
											value={website.store.currency.code}
										/>

										{!!checkout?.meta?.total ? (
											<Card.List.Item label="Total" bold>
												<Card.List.Item.Content>
													<Text bold>{`${website.store.currency.symbol}${(
														checkout?.meta?.total / 100
													).toFixed(2)}`}</Text>
												</Card.List.Item.Content>
											</Card.List.Item>
										) : (
											<Card.List.Item label="Total">
												<Card.List.Item.Content>
													<Text bold>
														{`${
															website.store.currency.symbol
														}${checkout.products
															?.reduce(
																(acc, curr) =>
																	(curr?.data?.price / 100 ?? 0) +
																	acc,
																0
															)
															?.toFixed?.(2)}`}
													</Text>
												</Card.List.Item.Content>
											</Card.List.Item>
										)}
									</Card.List>
								</Card.Content>
							</Card>
							<Divider />

							{(!!checkout.billing || !!checkout.shipping) && (
								<Row>
									<Col xs={12} xl={6}>
										{!!checkout.billing && (
											<Fragment>
												<Card>
													<Card.Title small>Billing</Card.Title>
													<Card.List>
														<Card.List.Item
															key="billing_added"
															label="Added"
															value={format(
																parseISO(checkout.billing.added),
																'PPP p'
															)}
														/>
														<Card.List.Item
															key="billing_name"
															label="Name"
															value={checkout.billing.fullName}
														/>
														<Card.List.Item
															key="billing_email"
															label="Email"
															value={checkout.billing.email}
														/>
														<Card.List.Item
															key="billing_phone"
															label="Phone Number"
														>
															<Card.List.Item.Content>
																<a
																	href={`tel:${checkout.billing.phone}`}
																>
																	{checkout.billing.phone}
																</a>
															</Card.List.Item.Content>
														</Card.List.Item>
														<Card.List.Item
															key="billing_address_street_one"
															label="Address One"
															value={checkout.billing.streetOne}
														/>
														{!!checkout.billing.streetTwo && (
															<Card.List.Item
																key="billing_address_street_two"
																label="Address Two"
																value={checkout.billing.streetTwo}
															/>
														)}
														<Card.List.Item
															key="billing_address_postcode"
															label="ZIP Code / Postal Code"
															value={checkout.billing.postcode}
														/>
														<Card.List.Item
															key="billing_city"
															label="City"
															value={checkout.billing.city}
														/>
														<Card.List.Item
															key="billing_county"
															label="County / Province"
															value={checkout.billing.county}
														/>
														<Card.List.Item
															key="billing_country"
															label="Country"
															value={checkout.billing.country}
														/>
													</Card.List>
												</Card>
												<Divider />
											</Fragment>
										)}
									</Col>
									<Col xs={12} xl={6}>
										{!!checkout.shipping && (
											<Fragment>
												<Card>
													<Card.Title small>Shipping</Card.Title>
													<Card.List>
														<Card.List.Item
															key="billing_added"
															label="Added"
															value={format(
																parseISO(checkout.shipping.added),
																'PPP p'
															)}
														/>
														<Card.List.Item
															key="billing_name"
															label="Name"
															value={checkout.shipping.fullName}
														/>
														<Card.List.Item
															key="billing_email"
															label="Email"
															value={checkout.shipping.email}
														/>
														<Card.List.Item
															key="billing_phone"
															label="Phone Number"
														>
															<Card.List.Item.Content>
																<a
																	href={`tel:${checkout.shipping.phone}`}
																>
																	{checkout.shipping.phone}
																</a>
															</Card.List.Item.Content>
														</Card.List.Item>
														<Card.List.Item
															key="billing_address_street_one"
															label="Address One"
															value={checkout.shipping.streetOne}
														/>
														{!!checkout.shipping.streetTwo && (
															<Card.List.Item
																key="billing_address_street_two"
																label="Address Two"
																value={checkout.shipping.streetTwo}
															/>
														)}
														<Card.List.Item
															key="billing_address_postcode"
															label="ZIP Code / Postal Code"
															value={checkout.shipping.postcode}
														/>
														<Card.List.Item
															key="billing_city"
															label="City"
															value={checkout.shipping.city}
														/>
														<Card.List.Item
															key="billing_county"
															label="County / Province"
															value={checkout.shipping.county}
														/>
														<Card.List.Item
															key="billing_country"
															label="Country"
															value={checkout.shipping.country}
														/>
													</Card.List>
												</Card>
												<Divider />
											</Fragment>
										)}
									</Col>
								</Row>
							)}

							{!!checkout?.products?.length && (
								<Card>
									<Card.Title small>Products</Card.Title>
									<Card.Content>
										<List>
											{checkout?.products
												?.filter(product => !!product?.data)
												?.map(
													({
														quantity,
														data: {
															_id,
															name,
															description,
															price,
															images,
														},
													}) => (
														<List.Item
															key={_id}
															title={`${quantity}x ${name}`}
															onClick={() =>
																navigate(`/store/products/${_id}`)
															}
														>
															<List.Item.Avatar
																alt={name}
																src={
																	images &&
																	Array.isArray(images) &&
																	images.length
																		? images[0].url
																		: ''
																}
															/>

															<List.Item.Column>
																<p>
																	<b>{name}</b>
																</p>
																<p>
																	{description.length > 300
																		? `${description.slice(
																				0,
																				300
																		  )}...`
																		: description}
																</p>
																<p>
																	{parseInt(price) === 0
																		? 'Free'
																		: `${
																				website.store
																					.currency.symbol
																		  }${(price / 100).toFixed(
																				2
																		  )}`}
																</p>
															</List.Item.Column>
														</List.Item>
													)
												)}
										</List>
									</Card.Content>
								</Card>
							)}

							{/*{!!order.ua && (*/}
							{/*    <Card>*/}
							{/*        <Card.List title="User agent">*/}
							{/*            <Card.List.Item*/}
							{/*                label="Mobile"*/}
							{/*                value={order.ua.isMobile === 'true'}*/}
							{/*            />*/}
							{/*            <Card.List.Item*/}
							{/*                label="Desktop"*/}
							{/*                value={order.ua.isDesktop === 'true'}*/}
							{/*            />*/}
							{/*            <Card.List.Item*/}
							{/*                label="Bot"*/}
							{/*                value={order.ua.isBot === 'true'}*/}
							{/*            />*/}
							{/*            <Card.List.Item label="Browser" value={order.ua.browser} />*/}
							{/*            <Card.List.Item*/}
							{/*                label="Browser Version"*/}
							{/*                value={order.ua.version}*/}
							{/*            />*/}
							{/*            <Card.List.Item label="OS" value={order.ua.os} />*/}
							{/*            <Card.List.Item*/}
							{/*                label="Platform"*/}
							{/*                value={order.ua.platform}*/}
							{/*            />*/}
							{/*            <Card.List.Item label="Source" value={order.ua.source} />*/}
							{/*        </Card.List>*/}
							{/*    </Card>*/}
							{/*)}*/}
						</Col>
					</Row>
				</Grid>
			)}
		</Fragment>
	);
};

export default withSnackbar(connect(({ website }) => ({ website }))(Viewer));
