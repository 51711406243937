import React, { Fragment } from 'react';
import { checkLinkIsExternal, filterInternalChildren, subcomponent } from '@helpers/index';

import styles from './styles.module.scss';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@components/Button';
import { navigate } from 'gatsby-link';
import Tooltip from '@components/Tooltip';
import Badge from '@components/Badge';
import Tags from '@components/Tags';

const List = ({ children, isCard, loading, className = '', divider = true, small = false }) => {
    const Items = subcomponent(children, List.Item);

    return (
        <div
            className={`${className} ${styles.list} ${isCard ? styles.listParentIsCard : ''} ${
                divider ? styles.listHasDivider : ''
            } ${small ? styles.listIsSmall : ''}`}
        >
            {loading ? (
                Array(10)
                    .fill()
                    .map((x, i) => (
                        <List.Item key={i}>
                            {!loading?.avatar === false ? null : <List.Item.Avatar loading />}
                            <List.Item.Column>
                                {!loading?.title === false ? null : <List.Item.Title loading />}
                                {!loading?.description === false ? null : (
                                    <List.Item.Description loading />
                                )}
                                {loading?.avatars ? <List.Item.Avatars loading /> : null}
                            </List.Item.Column>
                            {!loading?.action === false ? null : (
                                <List.Item.Actions>
                                    <List.Item.Actions.Action loading />
                                </List.Item.Actions>
                            )}
                        </List.Item>
                    ))
            ) : (
                <Fragment>
                    {subcomponent(children, List.Title, true)}
                    {Items}
                </Fragment>
            )}
        </div>
    );
};
List.displayName = 'List';

List.Title = ({ priority = 3, link, onClick, text }) => {
    const Heading = `h${priority >= 1 && priority <= 6 ? priority : 3}`;

    return (
        <Heading className={styles.listTitle}>
            {!!link ? (
                link.includes('http://') || link.includes('https://') ? (
                    <a className={styles.listTitleText} href={link}>
                        {text}
                    </a>
                ) : (
                    <Link className={styles.listTitleText} to={link}>
                        {text}
                    </Link>
                )
            ) : (
                <span className={styles.listTitleText}>{text}</span>
            )}
            {(!!link || !!onClick) && (
                <div className={styles.listTitleArrow}>
                    <FontAwesomeIcon icon="chevron-right" />
                </div>
            )}
        </Heading>
    );
};
List.Title.displayName = 'Title';

List.Item = ({ link, onClick, children }) => {
    const Avatar = subcomponent(children, List.Item.Avatar, true);
    const Actions = subcomponent(children, List.Item.Actions, true, { parentLink: !!link });
    const Columns = subcomponent(children, List.Item.Column);
    const ColumnRight = subcomponent(children, List.Item.Column.Right, true, {
        type: 'right',
        alignRight: true,
        parentLink: !!link,
    });

    // create left column, or if no column exists create one anyway with any root column components found embedded within
    const ColumnOne = (Columns[0] &&
        React.cloneElement(Columns[0], { type: 'left', parentLink: !!link })) || (
        <List.Item.Column>
            {subcomponent(children, List.Item.Checked, true)}
            {subcomponent(children, List.Item.Title, true, { parentLink: !!link })}
            {subcomponent(children, List.Item.Avatars, true)}
            {subcomponent(children, List.Item.Description, true)}
        </List.Item.Column>
    );
    const ColumnTwo =
        Columns[1] && React.cloneElement(Columns[1], { type: 'middle', parentLink: !!link });
    const ColumnThree =
        (Columns[2] &&
            React.cloneElement(Columns[2] || Columns[1], { type: 'right', parentLink: !!link })) ||
        ColumnRight;

    const totalColumns = Columns.length + (!!ColumnRight ? 1 : 0);

    const columns = (
        <div className={styles.listItemColumns}>
            {ColumnOne}
            {ColumnTwo}
            {ColumnThree}
        </div>
    );

    const arrow = !!(link || onClick) && (
        <div className={styles.listItemArrow}>
            <FontAwesomeIcon icon="chevron-right" />
        </div>
    );

    const classNames = [
        styles.listItem,
        !!(link || onClick) ? styles.listItemHoverable : null,
        totalColumns === 1 ? styles.listItemOneColumn : null,
        totalColumns === 2 ? styles.listItemTwoColumns : null,
        totalColumns === 3 ? styles.listItemThreeColumns : null,
    ]
        .filter(x => !!x)
        .join(' ');

    const content = (
        <Fragment>
            {Avatar}
            <div className={styles.listItemMainWrapper}>
                <div className={styles.listItemMain}>
                    {columns}
                    {Actions}
                    {arrow}
                </div>
                {subcomponent(children, List.Item.Tags, true)}
            </div>
        </Fragment>
    );

    if (link) {
        return (
            <Link to={link} className={classNames} onClick={onClick}>
                {content}
            </Link>
        );
    }

    return (
        <div
            className={classNames}
            tabIndex={onClick ? 0 : null}
            onClick={onClick}
            onKeyDown={!!onClick ? e => e.key === 'Enter' && onClick(e) : undefined}
        >
            {content}
        </div>
    );
};
List.Item.displayName = 'Item';

List.Item.Avatar = ({ alt, src, loading }) => {
    return (
        <div className={`${styles.listItemAvatar} ${loading ? styles.listItemAvatarLoading : ''}`}>
            {!loading && (!!src ? <img alt={alt} src={src} /> : <div />)}
        </div>
    );
};

List.Item.Avatar.displayName = 'Avatar';

List.Item.Column = ({
    children,
    center,
    type,
    important,
    parentLink,
    alignRight,
    grow,
    align,
    width,
}) => {
    const typeClass =
        ((type === 'left' && styles.listItemColumnLeft) ||
            (type === 'middle' && styles.listItemColumnMiddle) ||
            (type === 'right' && styles.listItemColumnRight)) ??
        '';
    const Avatars = subcomponent(children, List.Item.Avatars, true);
    const filteredChildren = filterInternalChildren(children, [
        List.Item.Avatars,
        List.Item.Checked,
        List.Item.Title,
        List.Item.Description,
    ]);

    return (
        <div
            className={`${styles.listItemColumn} ${typeClass} ${
                important ? styles.listItemColumnImportant : ''
            } ${alignRight ? styles.listItemColumnAlignRight : ''} ${
                Avatars ? styles.listItemColumnHasAvatars : ''
            } ${grow ? styles.listItemColumnGrow : ''} ${
                center ? styles.listItemColumnCenter : ''
            }`}
            style={{ alignSelf: align, flexBasis: width, flexGrow: !!width ? 0 : null }}
        >
            {subcomponent(children, List.Item.Checked, true)}
            {subcomponent(children, List.Item.Title, true, { parentLink })}
            {Avatars}
            {subcomponent(children, List.Item.Description, true)}
            {filteredChildren}
        </div>
    );
};
List.Item.Column.displayName = 'Column';

List.Item.Checked = ({ text, checked }) => {
    return (
        <div
            className={`${styles.listItemColumnChecked} ${
                checked ? styles.listItemColumnCheckedYes : styles.listItemColumnCheckedNo
            }`}
        >
            <FontAwesomeIcon icon={checked ? 'check' : 'times'} />
            {!!text && <p>{`${text}`}</p>}
        </div>
    );
};
List.Item.Checked.displayName = 'Checked';

List.Item.Actions = ({ parentLink, children }) => {
    return (
        <div className={styles.listItemActions}>
            {subcomponent(children, List.Item.Actions.Action, false, { parentLink }).slice(0, 3)}
        </div>
    );
};
List.Item.Actions.displayName = 'Actions';

List.Item.Actions.Action = ({
    parentLink,
    tooltip,
    onClick,
    link,
    icon,
    iconSize,
    loading,
    disabled,
}) => {
    const handleClick = async (e, shouldNavigate) => {
        e.preventDefault();
        e.stopPropagation();

        if (disabled) return;

        onClick && (await onClick(e));

        if (shouldNavigate) {
            checkLinkIsExternal(link) ? window?.open(link, '_newtab') : navigate(link);
        }
    };

    const Wrapper = ({ children }) => {
        if (!tooltip) return <div className={styles.listItemActionsActionWrapper}>{children}</div>;
        return (
            <Tooltip className={styles.listItemActionsActionWrapper} text={tooltip}>
                {children}
            </Tooltip>
        );
    };

    return (
        <Wrapper>
            {link && !parentLink ? (
                checkLinkIsExternal(link) ? (
                    <a
                        tabIndex={disabled ? -1 : null}
                        rel="noopener noreferrer"
                        target="_blank"
                        href={!disabled ? link : undefined}
                        className={`${styles.listItemActionsAction} ${
                            onClick || link ? styles.listItemActionsActionHoverable : ''
                        } ${loading ? styles.listItemActionsActionLoading : ''} ${
                            disabled ? styles.listItemActionsActionDisabled : ''
                        }`}
                        onClick={handleClick}
                    >
                        <FontAwesomeIcon
                            icon={['fad', icon || '']}
                            style={!!iconSize && { fontSize: `${iconSize}rem` }}
                        />
                    </a>
                ) : (
                    <Link
                        tabIndex={disabled ? -1 : null}
                        to={!disabled ? link : undefined}
                        className={`${styles.listItemActionsAction} ${
                            onClick || link ? styles.listItemActionsActionHoverable : ''
                        } ${disabled ? styles.listItemActionsActionDisabled : ''}`}
                        onClick={handleClick}
                    >
                        <FontAwesomeIcon
                            icon={['fad', icon || '']}
                            style={!!iconSize && { fontSize: `${iconSize}rem` }}
                        />
                    </Link>
                )
            ) : (
                <div
                    tabIndex={disabled ? -1 : link || onClick ? 0 : null}
                    className={`${styles.listItemActionsAction} ${
                        onClick || link ? styles.listItemActionsActionHoverable : ''
                    } ${loading ? styles.listItemActionsActionLoading : ''} ${
                        disabled ? styles.listItemActionsActionDisabled : ''
                    }`}
                    onClick={link ? e => handleClick(e, true) : !!onClick ? handleClick : undefined}
                    onKeyDown={e =>
                        e.key === 'Enter' &&
                        (link ? handleClick(e, true) : !!onClick ? handleClick(e) : undefined)
                    }
                >
                    {!loading && (
                        <FontAwesomeIcon
                            icon={['fad', icon || '']}
                            style={!!iconSize && { fontSize: `${iconSize}rem` }}
                        />
                    )}
                </div>
            )}
        </Wrapper>
    );
};
List.Item.Actions.Action.displayName = 'Action';

List.Item.Column.Right = props => <List.Item.Column {...props} />;
List.Item.Column.Right.displayName = 'Right';

List.Item.Title = ({
    link,
    text,
    bold = true,
    small = false,
    faded = false,
    children,
    onClick,
    parentLink,
    loading,
}) => {
    const classNames = [
        styles.listItemColumnTitle,
        bold ? styles.listItemColumnTitleBold : null,
        small ? styles.listItemColumnTitleSmall : null,
        faded ? styles.listItemColumnTitleFaded : null,
    ]
        .filter(x => !!x)
        .join(' ');

    if (loading) {
        return <div className={`${classNames} ${styles.listItemColumnTitleLoading}`} />;
    }

    const handleLinkClick = e => {
        e.stopPropagation();
        e.preventDefault();

        navigate(link);
    };

    return (
        <p
            className={classNames}
            onClick={link && parentLink ? handleLinkClick : onClick}
            tabIndex={onClick ? 0 : null}
        >
            {link && !parentLink ? <Link to={link}>{text || children}</Link> : text || children}
        </p>
    );
};
List.Item.Title.displayName = 'Title';

List.Item.Description = ({ icon: iconName, status, text, children, loading, small }) => {
    const classNames = [
        styles.listItemColumnDescription,
        status === 'success' ? styles.listItemColumnDescriptionSuccess : null,
        status === 'danger' ? styles.listItemColumnDescriptionError : null,
        status === 'warning' ? styles.listItemColumnDescriptionWarning : null,
        loading ? styles.listItemColumnDescriptionLoading : null,
        small ? styles.listItemColumnDescriptionSmall : null,
    ]
        .filter(x => !!x)
        .join(' ');

    const icon = status ? (
        status === 'success' ? (
            <FontAwesomeIcon className={styles.listItemColumnDescriptionIcon} icon="check-circle" />
        ) : status === 'danger' || status === 'warning' ? (
            <FontAwesomeIcon
                className={styles.listItemColumnDescriptionIcon}
                icon="exclamation-circle"
            />
        ) : null
    ) : iconName ? (
        <FontAwesomeIcon className={styles.listItemColumnDescriptionIcon} icon={iconName} />
    ) : null;

    return (
        <div className={classNames}>
            {icon}
            {!loading && !!text && <p className={styles.listItemColumnDescriptionText}>{text}</p>}
            {!loading && !!children && (
                <div className={styles.listItemColumnDescriptionContent}>{children}</div>
            )}
            {loading && <p className={styles.listItemColumnDescriptionText} />}
        </div>
    );
};
List.Item.Description.displayName = 'Description';

List.Item.Avatars = ({ children, loading }) => {
    // Maximum 5 avatars
    const Avatar = subcomponent(children, List.Item.Avatars.Avatar).slice(0, 10);
    if (!Avatar.length) return null;

    return <div className={styles.listItemColumnAvatars}>{Avatar}</div>;
};
List.Item.Avatars.displayName = 'Avatars';

List.Item.Avatars.Avatar = ({ alt, src, tooltip }) => {
    const Wrapper = ({ children }) => {
        if (!tooltip) return <Fragment>{children}</Fragment>;
        return (
            <Tooltip className={styles.listItemColumnAvatarsAvatarImage} text={tooltip}>
                {children}
            </Tooltip>
        );
    };

    return (
        <div className={styles.listItemColumnAvatarsAvatar}>
            <Wrapper>
                <img alt={alt} src={src} />
            </Wrapper>
        </div>
    );
};
List.Item.Avatars.Avatar.displayName = 'Avatar';

List.Item.Tags = ({ children }) => {
    const Tag = subcomponent(children, List.Item.Tags.Tag);
    if (!Tag.length) return null;

    return (
        <div className={styles.listItemTags}>
            <Tags>{Tag}</Tags>
        </div>
    );
};
List.Item.Tags.displayName = 'Tags';
List.Item.Tags.Tag = Tags.Tag;

export default List;
