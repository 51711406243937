export function setWebsite(website) {
    return {
        type: 'WEBSITE_SET',
        payload: website,
    };
}

export const setWebsiteEditorNavigation = websiteEditorNavigation => ({
    type: 'WEBSITE_SET_EDITOR_NAVIGATION',
    payload: websiteEditorNavigation,
});

export function removeWebsite() {
    return {
        type: 'WEBSITE_REMOVE',
    };
}

export function updateWebsite(website) {
    return {
        type: 'WEBSITE_UPDATE',
        payload: website,
    };
}
