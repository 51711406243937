import React, { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '@actions/user';
import { navigate } from 'gatsby-link';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Input from '@components/Input';
import Alert from '@components/Alert';
import { Grid, Row, Col, Divider } from '@components/Grid';
import { makePostRequest } from '@helpers/requests';
import { USER_PASSWORD_RESET_COMPLETE } from '@helpers/api';
import Layout from '@components/Layout';
import SEO from '@components/SEO';
import { withSnackbar } from '@components/Snackbar';
import queryString from 'query-string';
import Card from '@components/Card';

const ForgotPasswordConfirm = ({ openSnackbar, location }) => {
    const params = queryString.parse(location.search);
    const userId = params?.id;
    const code = params?.code;
    const dispatch = useDispatch();
    const user = useSelector(({ user }) => user);

    useEffect(() => {
        if (!code || !userId) {
            navigate('/');
        }
    }, []);

    const handlePasswordChange = async values => {
        try {
            const { data } = await makePostRequest(USER_PASSWORD_RESET_COMPLETE, {
                code,
                id: userId,
                password: values.password,
            });

            const userData = await loginUser(data);
            dispatch(userData);
            navigate('/account/overview');
            openSnackbar('Successfully changed password');
        } catch (error) {
            error !== 'cancelled' &&
                openSnackbar(
                    error?.errorMessage ??
                        'An error occurred when attempting to complete your password reset.'
                );
        }
    };

    return (
        <Layout>
            <SEO title={'Resetting password'} />
            <Grid>
                <h1>{user ? 'Password reset' : 'Forgot password'}</h1>
                <Formik
                    validationSchema={yupObjectSchema}
                    initialValues={{ password: '', confirmPassword: '' }}
                    onSubmit={handlePasswordChange}
                >
                    {({ handleSubmit, values, errors, touched, isSubmitting, handleChange }) => (
                        <Fragment>
                            <Row>
                                <Col xs={12} lg={6}>
                                    <Card>
                                        <Card.Content>
                                            <Input
                                                label="New password"
                                                name="password"
                                                type="password"
                                                value={values.password}
                                                onChange={handleChange}
                                            />
                                            {touched.password && errors.password && (
                                                <Alert type="error" message={errors.password} />
                                            )}
                                            <Divider />
                                            <Input
                                                label="Confirm new password"
                                                name="confirmPassword"
                                                type="password"
                                                value={values.confirmPassword}
                                                onChange={handleChange}
                                            />
                                            {touched.confirmPassword && errors.confirmPassword && (
                                                <Alert
                                                    type="error"
                                                    message={errors.confirmPassword}
                                                />
                                            )}
                                        </Card.Content>
                                        <Card.Actions>
                                            <Card.Actions.Action
                                                text="Submit"
                                                onClick={handleSubmit}
                                                submitting={isSubmitting}
                                            />
                                        </Card.Actions>
                                    </Card>
                                </Col>
                            </Row>
                        </Fragment>
                    )}
                </Formik>
            </Grid>
        </Layout>
    );
};

const yupObjectSchema = Yup.object().shape({
    password: Yup.lazy(value =>
        !value
            ? Yup.string()
            : Yup.string()
                  .min(8, 'Password must be at least 8 characters')
                  .required('Password is required')
    ),
    confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'Passwords do not match'),
});

export default withSnackbar(ForgotPasswordConfirm);
