import { bakeLocalStorage, deleteLocalStorage, readLocalStorage } from '@helpers/storage';
import { addHours, addSeconds, formatISO, isBefore, parseISO } from 'date-fns';

export default (state = readLocalStorage('help'), action) => {
    // Check to see what type of action is being fired
    switch (action.type) {
        case 'HELP_SET':
            const data = {
                data: action?.payload ?? [],
                expiry: formatISO(addHours(new Date(), 15)),
            };

            bakeLocalStorage('help', data);
            return data;
        default:
            // remove user from existence if the user expiry is after the current date.
            // Note the API will already track this, so if they hack the localStorage expiry we'll still invalidate any
            // requests.
            if (state?.expiry && isBefore(parseISO(state.expiry), new Date())) {
                deleteLocalStorage('help');
                return null;
            }

            // Return state by default if nothing else is met
            return state;
    }
};
