import React, { Fragment } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '@components/Button';
import Choices from '@components/Choices';
import Radios from '@components/Radios';
import Alert from '@components/Alert';
import { Divider, Row, Col } from '@components/Grid';
import { useSelector } from 'react-redux';
import { capitalise } from '@helpers/index';

const Choose = ({ onChange, onSubmit, plans, plan, planRecurringType }) => {
    const { website } = useSelector(({ website }) => ({ website }));

    return (
        <Fragment>
            <Alert
                type="info"
                title={`You are on the ${capitalise(website.meta.type)} plan`}
                message={`Your website was built using the ${capitalise(
                    website.meta.type
                )} plan features. Therefore you must select the ${capitalise(
                    website.meta.type
                )} plan or above in order for your website to function.`}
            />
            <Divider />
            <h1>{website?.subscription?.active ? `Change your plan` : `Select your plan`}</h1>
            <Formik
                validationSchema={yupSubscriptionChooseObjectSchema}
                initialValues={{
                    planId: plan ? plan.id : null,
                    planRecurringType,
                }}
                onSubmit={(values, actions) => onSubmit && onSubmit(values, actions)}
            >
                {({ setFieldValue, handleSubmit, isSubmitting, values, errors, touched }) => {
                    const { planId, planRecurringType } = values;

                    const handlePlanChange = ({ id }) => {
                        onChange({ planId: id, planRecurringType });
                        setFieldValue('planId', id);
                    };

                    const handleRecurringChange = ({ id }) => {
                        onChange({ planId, planRecurringType: id });
                        setFieldValue('planRecurringType', id);
                    };

                    return (
                        <Fragment>
                            <Choices radio onChange={handlePlanChange} block>
                                {plans.map(item => {
                                    const totalMonthlyInYear = item.prices.monthly.price * 12;
                                    const percentage = parseInt(
                                        ((parseFloat(totalMonthlyInYear) -
                                            parseFloat(item.prices.annually.price)) /
                                            parseFloat(totalMonthlyInYear)) *
                                            100
                                    );

                                    return (
                                        <Choices.Choice
                                            key={item.id}
                                            title={item.name}
                                            description={item.description}
                                            checked={planId || (!planId && website.meta.type)}
                                            badge={
                                                planRecurringType === 'annually' && percentage
                                                    ? `${percentage}% off`
                                                    : null
                                            }
                                            disabled={
                                                (website.meta.type === 'commercial' &&
                                                    (item.id === 'essential' ||
                                                        item.id === 'professional')) ||
                                                (website.meta.type === 'professional' &&
                                                    item.id === 'commercial')
                                            }
                                        >
                                            <Choices.Choice.Content>
                                                <p>
                                                    <b>{`£${
                                                        item?.prices[planRecurringType]?.price
                                                    } per ${
                                                        planRecurringType === 'monthly'
                                                            ? 'month'
                                                            : 'annum'
                                                    }`}</b>
                                                </p>
                                            </Choices.Choice.Content>
                                        </Choices.Choice>
                                    );
                                })}
                            </Choices>

                            {touched.planId && errors.planId && (
                                <Fragment>
                                    <Divider margin={2} />
                                    <Alert plan="error" message={errors.planId} />
                                </Fragment>
                            )}

                            <Divider />

                            <h2>How would you like to pay?</h2>
                            {plan ? (
                                planRecurringType === 'annually' ? (
                                    <p>
                                        You've saved{' '}
                                        <b>{`£${parseFloat(plan.prices.monthly.price) * 12 -
                                            parseFloat(plan.prices.annually.price)}`}</b>{' '}
                                        by choosing to pay annually!
                                    </p>
                                ) : (
                                    <p>
                                        You'll save{' '}
                                        <b>{`£${parseFloat(plan.prices.monthly.price) * 12 -
                                            parseFloat(plan.prices.annually.price)}`}</b>{' '}
                                        each year if you pay annually!
                                    </p>
                                )
                            ) : (
                                <p>
                                    You could save more if you pay annually, select a plan to see
                                    the price saving.
                                </p>
                            )}

                            <Radios inline onChange={handleRecurringChange}>
                                <Radios.Radio
                                    key="monthly"
                                    label="Monthly"
                                    checked={values.planRecurringType}
                                />
                                <Radios.Radio
                                    key="annually"
                                    label="Annually"
                                    checked={values.planRecurringType}
                                />
                            </Radios>

                            <Divider />

                            <Row end="xs">
                                <Col xs={12}>
                                    <Button onClick={handleSubmit} submitting={isSubmitting}>
                                        Continue
                                    </Button>
                                </Col>
                            </Row>
                        </Fragment>
                    );
                }}
            </Formik>
        </Fragment>
    );
};

const yupSubscriptionChooseObjectSchema = Yup.object().shape({
    planId: Yup.string()
        .nullable()
        .required('Plan plan is required'),
});

export default Choose;
