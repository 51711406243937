import React, { useState, Fragment, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Location, Redirect } from '@reach/router';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';

import LogoWhite from '@images/logo-white.svg';
import Logo from '@images/logo.svg';
import Dropdown from '@components/Dropdown';

import styles from './styles.module.scss';

const Navigation = ({ user, website, $navigation, $mobileNavigation, routes, admin }) => {
    const $account = useRef(null);
    const [navigationMobileOpen, setNavigationMobileOpen] = useState(false);
    const [accountOpen, setAccountOpen] = useState(false);
    const [mobileNavigationOpen, setMobileNavigationOpen] = useState(false);
    const [mobileNavigationClosing, setMobileNavigationClosing] = useState(false);

    const routeMatches = ({ match, children }) => {
        if (match) return true;

        if (children) {
            if (children.some(routeMatches)) return true;
        }
    };

    const renderRoutes = (routes, renderedFromChildren = false) =>
        routes
            .filter(({ hidden }) => !hidden)
            .map(({ text, path, icon, children = [], disabled, match, getProps }) => {
                const className = `${styles.navigationItemLink} ${
                    disabled ? styles.navigationItemLinkDisabled : ''
                }`;

                return (
                    <li key={`${text}_${path}`} className={styles.navigationItem}>
                        <Link
                            tabIndex={disabled ? -1 : null}
                            to={path}
                            onClick={!children.length ? handleMobileClose : undefined}
                            getProps={props => {
                                const pathProps = (getProps && getProps(props)) || {};

                                return {
                                    ...pathProps,
                                    className: `${className} ${pathProps?.className ?? ''} ${
                                        routeMatches({ match, children })
                                            ? `${styles.navigationItemLinkActive}`
                                            : ''
                                    }`,
                                };
                            }}
                        >
                            {!!icon && <FontAwesomeIcon icon={['fad', icon]} />}
                            <span>{text}</span>
                        </Link>

                        {!renderedFromChildren && !disabled && !!children.length && (
                            <ul>{renderRoutes(children, true)}</ul>
                        )}
                    </li>
                );
            });

    const handleMobileClose = () => {
        setMobileNavigationClosing(true);

        setTimeout(() => {
            setMobileNavigationOpen(false);
            setMobileNavigationClosing(false);
        }, 200);
    };

    const handleMobileToggle = () => {
        if (mobileNavigationOpen) {
            handleMobileClose();
            return;
        }

        setMobileNavigationOpen(true);
    };

    return (
        <Fragment>
            <div ref={$mobileNavigation} className={styles.navigationMobile}>
                <div
                    onKeyPress={handleMobileToggle}
                    tabIndex={0}
                    className={styles.navigationMobileToggle}
                    onClick={handleMobileToggle}
                >
                    <FontAwesomeIcon icon={['fad', 'bars']} />
                </div>

                <Link
                    to="/websites"
                    className={styles.navigationMobileLogo}
                    aria-label="Logo"
                    onClick={() => setNavigationMobileOpen(!navigationMobileOpen)}
                >
                    {!!admin ? <Logo /> : <LogoWhite />}
                </Link>
            </div>

            <nav
                ref={$navigation}
                className={`${styles.navigation} ${!!admin ? styles.navigationAdmin : ''} ${
                    mobileNavigationOpen ? styles.navigationMobileOpen : ''
                } ${mobileNavigationClosing ? styles.navigationMobileClosing : ''}`}
            >
                <Link
                    to="/websites"
                    className={styles.navigationLogo}
                    aria-label="Logo"
                    onClick={() => setNavigationMobileOpen(!navigationMobileOpen)}
                >
                    {!!admin ? <Logo /> : <LogoWhite />}
                </Link>

                {!admin && (
                    <a
                        href={website?.domain}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.navigationWebsite}
                    >
                        <span
                            className={`
                        ${styles.navigationWebsiteAvatar} 
                        ${website?.meta?.type === 'commercial' &&
                            styles.navigationWebsiteAvatarCommercial} 
                        ${website?.meta?.type === 'essential' &&
                            styles.navigationWebsiteAvatarEssential} 
                        ${website?.meta?.type === 'professional' &&
                            styles.navigationWebsiteAvatarProfessional}`}
                            style={{
                                backgroundImage:
                                    typeof website?.avatar === 'string' && website?.avatar.length
                                        ? `url(${website?.avatar})`
                                        : null,
                            }}
                        />
                        <span className={styles.navigationWebsiteName}>
                            <span>{website?.name}</span>
                            <span className={styles.navigationWebsiteDomain}>
                                {website?.domain}
                            </span>
                        </span>
                    </a>
                )}

                <ul className={styles.navigationContent}>{renderRoutes(routes)}</ul>

                {user && (
                    <Fragment>
                        {accountOpen && (
                            <Dropdown
                                target={$account}
                                offset={[0, 30]}
                                onEscape={() => setAccountOpen(false)}
                            >
                                <Dropdown.Item text="Websites" link="/websites" />
                                <Dropdown.Item text="Profile" link="/account/profile" />
                                <Dropdown.Item
                                    disabled
                                    text="Notifications"
                                    link="/account/notifications"
                                />
                                <Dropdown.Item disabled text="Billing" link="/account/billing" />
                                <Dropdown.Item
                                    disabled
                                    text="Referrals"
                                    link="/account/referrals"
                                />
                                <Dropdown.Item text="Log out" link="/logout" />
                            </Dropdown>
                        )}
                        <div
                            ref={$account}
                            className={styles.navigationAccount}
                            onClick={() => setAccountOpen(!accountOpen)}
                        >
                            <div className={styles.navigationAccountOverview}>
                                <div className={styles.navigationAccountOverviewContent}>
                                    <span
                                        className={styles.navigationAccountOverviewContentUsername}
                                    >
                                        {user.firstName}
                                    </span>
                                    <span className={styles.navigationAccountOverviewContentEmail}>
                                        {user.email}
                                    </span>
                                </div>
                                <FontAwesomeIcon icon={['fad', 'chevron-up']} />
                            </div>
                        </div>
                    </Fragment>
                )}
            </nav>

            <span
                onClick={handleMobileToggle}
                className={`${styles.navigationMobileOverlay} ${
                    mobileNavigationOpen ? styles.navigationMobileOverlayOpen : ''
                }
                    ${mobileNavigationClosing ? styles.navigationMobileOverlayClosing : ''}`}
            />
        </Fragment>
    );
};

const NavigationWithLocation = props => (
    <Location>{locationContext => <Navigation {...locationContext} {...props} />}</Location>
);

export default connect(({ user, website }) => ({ user, website }))(NavigationWithLocation);
