import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './styles.module.scss';

// re-usable component proxy for ReactPaginate plugin
class Pagination extends React.Component {
    render() {
        const currentStartPages = this.props.perPage * (this.props.page - 1) + 1;
        const currentEndPages = this.props.perPage * (this.props.page - 1) + this.props.pageTotal;

        return (
            <React.Fragment>
                <ReactPaginate
                    containerClassName={styles.pagination}
                    pageClassName={styles.paginationItem}
                    activeClassName={styles.paginationItemActive}
                    previousClassName={`${styles.paginationItemDirection} ${styles.paginationItemDirectionPrevious}`}
                    nextClassName={`${styles.paginationItemDirection} ${styles.paginationItemDirectionNext}`}
                    disabledClassName={styles.paginationItemDirectionDisabled}
                    pageCount={Math.ceil(this.props.total / this.props.perPage) || 1}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    forcePage={this.props.page - 1}
                    onPageChange={page =>
                        this.props.onChange && this.props.onChange(page.selected + 1)
                    }
                    disableInitialCallback={true}
                    nextLabel={<FontAwesomeIcon icon={['fas', 'chevron-right']} />}
                    previousLabel={<FontAwesomeIcon icon={['fas', 'chevron-left']} />}
                />
                {this.props.meta ? (
                    <p className={styles.paginationMeta}>
                        {' '}
                        {`${currentStartPages}-${currentEndPages} of ${this.props.total} ${
                            this.props.metaLabel ? this.props.metaLabel : ''
                        }`}
                    </p>
                ) : null}
            </React.Fragment>
        );
    }
}

Pagination.defaultProps = {
    page: 1,
    perPage: 30,
    pageTotal: 30,
};

Pagination.propTypes = {
    page: PropTypes.number.isRequired,
    perPage: PropTypes.number,
    pageTotal: PropTypes.number,
    total: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    meta: PropTypes.bool,
    metaLabel: PropTypes.string,
};

export default Pagination;
