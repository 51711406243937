import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { Row, Col, Divider } from '@components/Grid';
import Input from '@components/Input';
import Button from '@components/Button';
import { makePutRequest } from '@helpers/requests';
import { SETTINGS_STORE, WEBSITE } from '@helpers/api';
import Panes from '@components/Panes';
import Select from '@components/Select';
import Alert from '@components/Alert';
import { setWebsite } from '@actions/website';
import { makeGetRequest } from '@helpers/requests';
import { bindActionCreators } from 'redux';
import { withSnackbar } from '@components/Snackbar';
import Command from '@components/Command';

const Website = ({ user, website, setWebsite, openSnackbar }) => {
    const handleSubmit = async (values, actions) => {
        try {
            const {
                name,
                description,
                stripePublishableKey,
                stripeSecretKey,
                currency: { code, symbol },
                meta: { stockLimit, productLimit },
            } = values;

            await makePutRequest(SETTINGS_STORE(website._id), {
                store: {
                    name,
                    description,
                    stripePublishableKey,
                    stripeSecretKey,
                    currency: { code, symbol },
                },
                meta: {
                    stockLimit,
                    productLimit,
                },
            });

            openSnackbar('Successfully saved store settings');
            const { data: websiteData } = await makeGetRequest(WEBSITE(website._id));
            setWebsite(websiteData);
        } catch (error) {
            error !== 'cancelled' && openSnackbar(error?.errorMessage ?? 'An error occurred loading settings.');
        }
    };

    const { store = {}, meta = {} } = website;
    const { currency = {} } = store;

    return (
        <Fragment>
            <Command>
                <Command.Breadcrumbs>
                    <Command.Breadcrumbs.Breadcrumb text="Settings" />
                    <Command.Breadcrumbs.Breadcrumb text="Store" />
                </Command.Breadcrumbs>
            </Command>
            <Panes>
                <Panes.Left>
                    <Row>
                        <Col xs={12}>
                            <Formik
                                validationSchema={Yup.object().shape({
                                    name: Yup.string().required('Store Name is required'),
                                    description: Yup.string().required(
                                        'Store Description is required'
                                    ),
                                    stripePublishableKey: Yup.string().required(
                                        'Store Stripe Publishable Key is required'
                                    ),
                                    stripeSecretKey: Yup.string().required(
                                        'Store Stripe Secret Key is required'
                                    ),
                                    currency: Yup.object().shape({
                                        code: Yup.string().oneOf(['USD', 'EUR', 'GBP']),
                                        symbol: Yup.string().oneOf(['$', '€', '£']),
                                    }),
                                    meta: Yup.object().shape({
                                        stockLimit: Yup.number().required(
                                            'Stock Limit is required'
                                        ),
                                        productLimit: Yup.number(),
                                    }),
                                })}
                                initialValues={{
                                    name: store.name,
                                    description: store.description,
                                    stripePublishableKey: store.stripePublishableKey,
                                    stripeSecretKey: store.stripeSecretKey,
                                    currency: {
                                        code: currency.code,
                                        symbol: currency.symbol,
                                    },
                                    meta: {
                                        stockLimit: meta.stockLimit,
                                        productLimit: meta.productLimit,
                                    },
                                }}
                                onSubmit={handleSubmit}
                            >
                                {props => <WebsiteForm {...props} user={user} />}
                            </Formik>
                        </Col>
                    </Row>
                </Panes.Left>
            </Panes>
        </Fragment>
    );
};

const WebsiteForm = ({
    values,
    touched,
    errors,
    handleSubmit,
    handleChange,
    setFieldValue,
    user,
    isSubmitting,
}) => {
    const handleCurrencyChange = e => {
        const newCurrencyCode = e.target.value;

        switch (newCurrencyCode) {
            case 'USD': {
                setFieldValue('currency.code', 'USD');
                setFieldValue('currency.symbol', '$');
                break;
            }
            case 'EUR': {
                setFieldValue('currency.code', 'EUR');
                setFieldValue('currency.symbol', '€');
                break;
            }
            default: {
                setFieldValue('currency.code', 'GBP');
                setFieldValue('currency.symbol', '£');
            }
        }
    };

    return (
        <Fragment>
            <Input value={values.name} label="Name" name="name" onChange={handleChange} />
            {touched.name && errors.name && (
                <Fragment>
                    <Divider margin={2} />
                    <Alert type="error" message={errors.name} />
                </Fragment>
            )}
            <Divider />
            <Input
                textarea
                value={values.description}
                label="Description"
                name="description"
                onChange={handleChange}
            />
            {touched.description && errors.description && (
                <Fragment>
                    <Divider margin={2} />
                    <Alert type="error" message={errors.description} />
                </Fragment>
            )}
            <Divider />
            <Input
                value={values.stripePublishableKey}
                label="Stripe Publishable Key"
                name="stripePublishableKey"
                onChange={handleChange}
            />
            {touched.stripePublishableKey && errors.stripePublishableKey && (
                <Fragment>
                    <Divider margin={2} />
                    <Alert type="error" message={errors.stripePublishableKey} />
                </Fragment>
            )}
            <Divider />
            <Input
                value={values.stripeSecretKey}
                label="Stripe Secret Key"
                name="stripeSecretKey"
                onChange={handleChange}
            />
            {touched.stripeSecretKey && errors.stripeSecretKey && (
                <Fragment>
                    <Divider margin={2} />
                    <Alert type="error" message={errors.stripeSecretKey} />
                </Fragment>
            )}
            <Divider />
            <Select
                value={values.currency.code}
                label="Currency"
                name="currency.code"
                onChange={handleCurrencyChange}
            >
                <Select.Option label="USD ($)" value="USD" />
                <Select.Option label="EUR (€)" value="EUR" />
                <Select.Option label="GBP (£)" value="GBP" />
            </Select>
            <Divider />
            <Input
                number
                value={values.meta.productLimit}
                label="Product Limit"
                name="meta.productLimit"
                onChange={handleChange}
                disabled={!user.permissions.isAdmin}
            />
            {touched.meta && errors.meta && touched.meta.productLimit && errors.meta.productLimit && (
                <Fragment>
                    <Divider margin={2} />
                    <Alert type="error" message={errors.meta.productLimit} />
                </Fragment>
            )}
            <Divider />
            <Input
                number
                value={values.meta.stockLimit}
                label="Stock Limit"
                name="meta.stockLimit"
                onChange={handleChange}
                disabled={!user.permissions.isAdmin}
            />
            {touched.meta && errors.meta && touched.meta.stockLimit && errors.meta.stockLimit && (
                <Fragment>
                    <Divider margin={2} />
                    <Alert type="error" message={errors.meta.stockLimit} />
                </Fragment>
            )}
            <Divider />

            <Row end="xs">
                <Col xs={12}>
                    <Button text="Save" success onClick={handleSubmit} submitting={isSubmitting} />
                </Col>
            </Row>
        </Fragment>
    );
};

export default withSnackbar(
    connect(
        ({ user, website }) => ({ user, website }),
        dispatch => bindActionCreators({ setWebsite }, dispatch)
    )(Website)
);
