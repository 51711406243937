import React, { Fragment, useEffect, useRef, useState } from 'react';
import { makeGetRequest, makePostRequest } from '@helpers/requests';
import { INVOICE, INVOICE_PAY, INVOICE_PAY_TOKEN, WEBSITE } from '@helpers/api';
import { navigate } from 'gatsby-link';
import Loader from '@components/Loader';
import { Grid, Row, Col, Divider } from '@components/Grid';
import Card from '@components/Card';
import Command from '@components/Command';
import List from '@components/List';
import { withSnackbar } from '@components/Snackbar';
import Button from '@components/Button';
import Addresses from '@components/Addresses';
import Alert from '@components/Alert';
import Logo from '@images/logo.svg';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { setWebsite } from '@actions/website';
import Menu from '@components/Menu';
import DropIn from 'braintree-web-drop-in-react';

const Pay = ({ openSnackbar, location, id }) => {
    const params = queryString.parse(location.search);
    const { accessCode, websiteId } = params;
    const { user } = useSelector(({ user }) => ({ user }));
    const dispatch = useDispatch();
    const braintree = useRef(null);
    const [loading, setLoading] = useState(true);
    const [invoice, setInvoice] = useState(null);
    const [submitting, setSubmitting] = useState(null);
    const [token, setToken] = useState(null);
    const [paidInvoice, setPaidInvoice] = useState(null);

    const handleSubmit = async () => {
        try {
            setSubmitting(true);

            const { nonce } = await braintree.current.instance.requestPaymentMethod({
                amount: (invoice?.total / 100).toFixed(2),
                billingAddress: {
                    company: invoice?.address?.name,
                    streetAddress: invoice?.address?.streetOne,
                    extendedAddress: invoice?.address?.streetTwo,
                    locality: invoice?.address?.city,
                    region: invoice?.address?.county,
                    postalCode: invoice?.address?.postcode,
                    countryCodeAlpha2: invoice?.address?.country,
                },
            });

            // Create object to send to server
            const data = {
                accessCode,
                token: nonce,
            };

            // Create invoice payment
            const { data: invoice } = await makePostRequest(INVOICE_PAY(id), data);
            setPaidInvoice(invoice);

            openSnackbar(`You successfully paid invoice MP-${invoice?.invoiceNumber ?? ''}.`);
            navigate(`/`);
        } catch (error) {
            setSubmitting(false);
            // clear payment methods
            braintree.current.instance.clearSelectedPaymentMethod();
            error !== 'cancelled' && openSnackbar(
                error?.errorMessage ?? 'An error occurred submitting your billing details.'
            );
        }
    };

    useEffect(() => {
        if (!websiteId) {
            openSnackbar('No website id was given to load this invoice.');
            navigate('/');
            return;
        }

        if (!accessCode) {
            openSnackbar('No access code was given to load this invoice.');
            navigate('/');
            return;
        }

        (async () => {
            try {
                // if a user exists, go to the private pay invoice page instead (set website first)
                if (user) {
                    const { data: website } = await makeGetRequest(WEBSITE(websiteId));
                    dispatch(setWebsite(website));
                    navigate(`/settings/billing/invoice/${id}/pay`);
                    return;
                }

                const { data: invoice } = await makeGetRequest(INVOICE(id), {
                    websiteId,
                    accessCode,
                });
                const { data: token } = await makeGetRequest(INVOICE_PAY_TOKEN);

                setInvoice(invoice);
                setToken(token);
                setLoading(false);
            } catch (error) {
                error !== 'cancelled' && openSnackbar(error?.errorMessage ?? 'An error occurred loading this invoice.');
                navigate('/');
            }
        })();
    }, []);

    return (
        <Fragment>
            <Menu />

            {loading ? (
                <Loader />
            ) : (
                <Grid small>
                    <Row>
                        <Col xs={12} lg={8}>
                            <Card>
                                <Card.Content>
                                    <h2>Payment details</h2>
                                    <Divider />

                                    {loading && <Loader height={150} />}
                                    {!token ? null : (
                                        <div
                                            className={`braintree-dropin-wrapper ${
                                                loading ? 'braintree-dropin-loading' : ''
                                            }`}
                                        >
                                            <DropIn
                                                options={{
                                                    vaultManager: true,
                                                    authorization: token,
                                                    paypal: {
                                                        flow: 'vault',
                                                    },
                                                    paypalCredit: {
                                                        flow: 'checkout',
                                                        amount: (invoice?.total / 100).toFixed(2),
                                                        currency: 'GBP',
                                                    },
                                                }}
                                                onInstance={() => setLoading(false)}
                                                ref={braintree}
                                            />
                                        </div>
                                    )}

                                    <Row end="xs">
                                        <Col xsShrink>
                                            <Button
                                                onClick={handleSubmit}
                                                submitting={submitting}
                                                disabled={loading || !token}
                                            >
                                                {`Pay £${(invoice?.total / 100).toFixed(2)}`}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Content>
                            </Card>

                            <Divider lgMargin={0} />
                        </Col>
                        <Col xs={12} lg={4}>
                            <Card>
                                <Card.Title>{`Pay Invoice (MP-${invoice?.invoiceNumber})`}</Card.Title>
                                <Card.Content>
                                    <Card.List>
                                        <Card.List.Item
                                            label="Subtotal"
                                            value={`£${(invoice?.subtotal / 100).toFixed(2)}`}
                                        />
                                        <Card.List.Item
                                            label="VAT"
                                            value={`£${(invoice?.vat / 100).toFixed(2)}`}
                                        />
                                        <Card.List.Item
                                            label="Total"
                                            value={`£${(invoice?.total / 100).toFixed(2)}`}
                                        />
                                    </Card.List>
                                </Card.Content>
                            </Card>
                        </Col>
                    </Row>
                </Grid>
            )}
        </Fragment>
    );
};

export default withSnackbar(Pay);
