import React, { useState, useEffect } from 'react';

import { Motion, spring } from 'react-motion';
import { Grid } from '@components/Grid';

import styles from './styles.module.scss';

const Sheet = ({ children, open = true, onClose }) => {
    const [opacity, setOpacity] = useState(open ? 0.5 : 0);
    const [translate, setTranslate] = useState(open ? 0 : 100);
    const [display, setDisplay] = useState('hidden');
    const [displayTimeout, setDisplayTimeout] = useState(null);

    useEffect(() => {
        clearTimeout(displayTimeout);
        open ? handleOpen() : handleClose();
    }, [open]);

    const handleOpen = () => {
        setOpacity(0.5);
        setTranslate(0);
        setDisplay('visible');
    };

    const handleClose = () => {
        setOpacity(0);
        setTranslate(100);

        setDisplayTimeout(
            setTimeout(() => {
                setDisplay('hidden');
            }, 300)
        );
    };

    return (
        <Motion
            style={{
                opacity: spring(opacity),
                translate: spring(translate),
            }}
        >
            {({ opacity, translate }) => (
                <div className={styles.sheet} style={{ visibility: display }}>
                    <div className={styles.sheetOverlay} style={{ opacity }} onClick={onClose} />
                    <div
                        className={styles.sheetContent}
                        style={{ transform: `translateY(${translate}%)` }}
                    >
                        {display === 'visible' && (
                            <Grid noMargin small>
                                {children}
                            </Grid>
                        )}
                    </div>
                </div>
            )}
        </Motion>
    );
};

export default Sheet;
