import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { navigate } from 'gatsby-link';

import { Grid, Divider } from '@components/Grid';
import { makeGetRequest } from '@helpers/requests';
import { STORE_PRODUCTS } from '@helpers/api';
import Card from '@components/Card';
import Pagination from '@components/Pagination';
import Command from '@components/Command';
import usePrevious from '@helpers/hooks/usePrevious';
import Tags from '@components/Tags';
import Empty from '@components/Empty';

import styles from './styles.module.scss';
import { withSnackbar } from '@components/Snackbar';
import List from '@components/List';
import Text from '@components/Text';

const Products = ({ user, website, page, query, tags, deleted, location, openSnackbar }) => {
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(null);
    const [products, setProducts] = useState([]);
    const [queryTimeout, setQueryTimeout] = useState(null);
    const prevPage = usePrevious(page);
    const prevQuery = usePrevious(query);
    const prevTags = usePrevious(tags);
    const prevDeleted = usePrevious(deleted);

    const setNewProducts = async () => {
        try {
            if (!loading) setLoading(true);
            const { data: productsData } = await makeGetRequest(STORE_PRODUCTS, {
                perPage: 10,
                pageNum: page,
                search: query ? query : undefined,
                tags,
                deleted,
            });
            setTotal(productsData?.meta?.total);
            setProducts(productsData?.results);
            setLoading(false);
        } catch (error) {
            error !== 'cancelled' &&
                openSnackbar(error?.errorMessage ?? 'An error occurred when loading products.');
        }
    };

    useEffect(() => {
        (async () => await setNewProducts())();
        return () => {
            clearTimeout(queryTimeout);
        };
    }, []);

    useEffect(() => {
        if (!prevDeleted && !deleted) return;
        setNewProducts();
    }, [deleted]);

    useEffect(() => {
        if (!prevPage || prevPage === page) return;
        setNewProducts();
    }, [page]);

    useEffect(() => {
        if (prevTags === undefined || JSON.stringify(prevTags) === JSON.stringify(tags)) return;
        setNewProducts();
    }, [tags]);

    useEffect(() => {
        if (!prevQuery || prevQuery === query) return;
        clearTimeout(queryTimeout);
        setQueryTimeout(
            setTimeout(async () => {
                await setNewProducts();
            }, 750)
        );
    }, [query]);

    const handleUrlChange = (query = {}) => {
        navigate(
            `${location.pathname}?${queryString.stringify({
                ...queryString.parse(location.search),
                page: undefined,
                ...query,
            })}`
        );
    };

    return (
        <Fragment>
            <Command>
                <Command.Breadcrumbs>
                    <Command.Breadcrumbs.Breadcrumb text="Store" link="/store" />
                    <Command.Breadcrumbs.Breadcrumb
                        text={deleted ? 'Deleted Products' : 'Products'}
                    />
                </Command.Breadcrumbs>

                <Command.Action
                    icon={['fad', deleted ? 'shopping-basket' : 'trash']}
                    link={`/store/products${deleted ? '' : '?deleted=true'}`}
                    tooltip={deleted ? 'View all products' : 'View deleted products'}
                />

                <Command.Action
                    disabled={total >= website.meta.productsLimit}
                    text="Create"
                    icon="plus"
                    link="/store/products/create"
                    allowDisabledOnClick
                    onClick={
                        total >= website.meta.productsLimit
                            ? () =>
                                  openSnackbar(
                                      'Maximum product limit reached, please contact support.'
                                  )
                            : undefined
                    }
                />

                <Command.Filters>
                    <Command.Filters.Search
                        key="search"
                        onChange={e => handleUrlChange({ query: e.target.value })}
                        value={query}
                    />

                    <Command.Filters.Filter key="tags" text="Tags" active={tags.length}>
                        <Tags
                            onCreate={tag =>
                                handleUrlChange({ tags: [...new Set([...tags, tag])] })
                            }
                            onRemove={tag => {
                                const index = tags.indexOf(tag);
                                if (index > -1) {
                                    const newTags = tags.slice();
                                    newTags.splice(index, 1);
                                    handleUrlChange({ tags: newTags });
                                }
                            }}
                        >
                            {tags.map(tag => (
                                <Tags.Tag key={tag}>{tag}</Tags.Tag>
                            ))}
                        </Tags>
                    </Command.Filters.Filter>
                </Command.Filters>
            </Command>

            {loading || !!products?.length ? (
                <Grid>
                    <Card>
                        <Card.Content>
                            <List loading={loading}>
                                {products?.map(
                                    ({
                                        _id,
                                        name,
                                        description,
                                        price,
                                        tags = [],
                                        quantity,
                                        images,
                                        category,
                                    }) => (
                                        <List.Item
                                            key={_id}
                                            title={name}
                                            className={styles.product}
                                            link={`/store/products/${_id}?${queryString.stringify({
                                                deleted,
                                            })}`}
                                        >
                                            <List.Item.Avatar
                                                alt={name}
                                                src={
                                                    images && Array.isArray(images) && images.length
                                                        ? images[0].url
                                                        : ''
                                                }
                                            />
                                            <List.Item.Column center>
                                                <Text bold>{name}</Text>
                                                {!!category && <Text>{category?.name}</Text>}
                                            </List.Item.Column>
                                            <List.Item.Column.Right>
                                                <Text bold>
                                                    {parseInt(price) === 0
                                                        ? 'Free'
                                                        : `${website.store.currency.symbol}${(
                                                              price / 100
                                                          ).toFixed(2)}`}
                                                </Text>
                                                <Text>{`${
                                                    quantity === null
                                                        ? 'Unlimited'
                                                        : `${quantity} Available`
                                                }`}</Text>
                                            </List.Item.Column.Right>

                                            {!!tags.length && (
                                                <List.Item.Tags>
                                                    {tags.map((tag, index) => (
                                                        <Tags.Tag key={`${tag}_${index}`}>
                                                            {tag}
                                                        </Tags.Tag>
                                                    ))}
                                                </List.Item.Tags>
                                            )}
                                        </List.Item>
                                    )
                                )}
                            </List>
                        </Card.Content>
                    </Card>

                    {!loading && (
                        <Fragment>
                            <Divider />
                            <Pagination
                                meta
                                onChange={page => handleUrlChange({ page })}
                                pageTotal={products?.length}
                                total={total}
                                page={page}
                                perPage={10}
                                metaLabel="Products"
                            />
                        </Fragment>
                    )}
                </Grid>
            ) : !!tags.length || !!query.length || queryTimeout ? (
                <Empty title="Nothing here" text="Your filters didn't seem to find anything." />
            ) : (
                <Empty title="Hold tight" text="You don't have any products yet!" />
            )}
        </Fragment>
    );
};

export default withSnackbar(
    connect(({ user, website }, ownProps) => {
        const params = queryString.parse(ownProps.location.search, { parseNumbers: true });
        const page = params.page ?? 1;
        const query = params.query || '';
        const deleted = params.deleted ? true : undefined;
        const tags = Array.isArray(params.tags)
            ? params.tags
            : [].concat(params.tags).filter(x => !!x);
        return { user, website, page, query, tags, deleted };
    })(Products)
);
