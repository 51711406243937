import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Input from '@components/Input';
import styles from './styles.module.scss';

// return dynamic option entries [{ label: '' }, ...], UI will alternatively style differently depending on type passed
// ('dropdown', 'checkbox' or 'radio')
const Options = ({ type, options, onChange }) => {
	const $options = [];

	return (
		<ul
			className={`${styles.fieldOption} ${type === 'dropdown' &&
				styles.fieldOptionDropdown} ${type === 'checkbox' &&
				styles.fieldOptionCheckbox} ${type === 'radio' && styles.fieldOptionRadio}`}
		>
			{(!!options?.length ? options : [{ label: 'Option 1' }]).map((value, index) => (
				<li key={index}>
					{!!type && (
						<div className={styles.fieldOptionKey}>
							{type === 'dropdown' ? `${index + 1}.` : null}
						</div>
					)}

					<Input
						ref={ref => ($options[index] = ref)}
						value={value?.label}
						placeholder={`Option ${index + 1}`}
						onChange={e => {
							onChange(
								options?.map((option, currIndex) =>
									currIndex !== index
										? option
										: { _id: option?._id, label: e.target.value }
								) ?? [{ label: e.target.value }]
							);
						}}
					/>
					{options?.length > 1 && (
						<div className={styles.fieldOptionDelete}>
							<FontAwesomeIcon
								icon="times"
								onClick={() => {
									onChange(
										options.filter((option, currIndex) => currIndex !== index)
									);
								}}
							/>
						</div>
					)}
				</li>
			))}
			<li>
				<div className={styles.fieldOptionKey}>
					{type === 'dropdown' ? `${options ? options.length + 1 : 2}.` : null}
				</div>
				<Input
					placeholder="Add option"
					value=""
					onClick={() => {
						onChange([
							...(options && !!options?.length ? options : [{ label: 'Option 1' }]),
							{ label: `Option ${options ? options.length + 1 : 2}` },
						]);
					}}
				/>
			</li>
		</ul>
	);
};

export default Options;
