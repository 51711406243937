import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { filterInternalChildren, subcomponent } from '@helpers';
import styles from './styles.module.scss';
import Button from '@components/Button';
import Dropdown from '@components/Dropdown';
import Input from '@components/Input';

const Title = ({
    bold = true,
    empty,
    value,
    href,
    hrefColour = true,
    big = false,
    medium = true,
    small = false,
    badge,
    icon,
    iconStatus,
    text,
    children,
    editable,
    placeholder,
    onChange,
}) => {
    const titleImage = subcomponent(children, Title.Image, true);
    const titleDetail = subcomponent(children, Title.Detail, true);
    const titleActions = subcomponent(children, Title.Actions, true);

    const filteredChildren = filterInternalChildren(children, [
        Title.Actions,
        Title.Detail,
        Title.Image,
    ]);

    const content = empty ? (
        <span className={styles.cardTitleEmpty} />
    ) : (
        <Fragment>
            {!!icon && (
                <FontAwesomeIcon
                    className={`${styles.cardTitleIcon} ${iconStatus === 'error' &&
                        styles.cardTitleIconError} ${iconStatus === 'warning' &&
                        styles.cardTitleIconWarning} ${iconStatus === 'success' &&
                        styles.cardTitleIconSuccess}`}
                    icon={icon}
                />
            )}
            {editable ? (
                <Input
                    key="card-title-input"
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                />
            ) : (
                <h3>{text || filteredChildren}</h3>
            )}
        </Fragment>
    );

    return (
        <div
            className={`${styles.cardTitle} ${bold !== false ? styles.cardTitleBold : ''} ${
                !!(big && !small) ? styles.cardTitleBig : ''
            } ${!!(medium && !small && !big) ? styles.cardTitleMedium : ''} ${
                small && !big ? styles.cardTitleSmall : ''
            } ${hrefColour ? '' : styles.cardTitleHrefNoColour} ${
                badge ? styles.cardTitleBadge : ''
            } ${editable ? styles.cardTitleEditable : ''} ${
                !!titleActions ? styles.cardTitleHasActions : ''
            }`}
        >
            {titleImage}
            <div className={styles.cardTitleHeading}>
                {!!href ? <Link to={href}>{content}</Link> : content}
            </div>
            {!!titleActions ? titleActions : titleDetail}
        </div>
    );
};

Title.Actions = ({ children }) => {
    return (
        <div className={styles.cardTitleActions}>
            {subcomponent(children, Title.Actions.Action)}
        </div>
    );
};
Title.Actions.displayName = 'Actions';

Title.Actions.Action = ({ children, icon }) => {
    const [isOpen, setIsOpen] = useState(false);
    const DropdownItem = subcomponent(children, Title.Actions.Action.Item);

    return (
        <Fragment>
            <div className={styles.cardTitleActionsAction}>
                <FontAwesomeIcon icon={icon} onClick={() => setIsOpen(!isOpen)} />
            </div>
            {!!DropdownItem && isOpen && (
                <Dropdown onEscape={() => setIsOpen(false)}>{DropdownItem}</Dropdown>
            )}
        </Fragment>
    );
};
Title.Actions.Action.displayName = 'Action';
Title.Actions.Action.Item = Dropdown.Item;

Title.Detail = ({ text, children, bold, stars, src, alt, svg, className }) => {
    let cardTitleDetailStars = null,
        cardTitleDetailImage = null;

    if (!text && !children && !stars && !src) return null;

    if (stars) {
        cardTitleDetailStars = Array(stars)
            .fill()
            .map((star, index) => <FontAwesomeIcon key={index} icon="star" />);
    }

    if (src) {
        if (svg) {
            cardTitleDetailImage = <SVG alt={alt} src={src} />;
        } else {
            cardTitleDetailImage = <img alt={alt} src={src} />;
        }
    }

    return (
        <span
            className={`${styles.cardTitleDetail} 
            ${className}
            ${bold ? styles.cardTitleDetailBold : ''}
            ${cardTitleDetailImage ? styles.cardTitleDetailImage : ''} 
            ${cardTitleDetailStars ? styles.cardTitleDetailStars : ''}`}
        >
            {cardTitleDetailStars || cardTitleDetailImage || text || children}
        </span>
    );
};

Title.Image = ({ src, svg, height, className, alt }) => {
    if (svg) {
        return <SVG style={{ height }} className={className} alt={alt || ''} src={src} />;
    }

    return <img style={{ height }} className={className} alt={alt || ''} src={src} />;
};

const cardTitleDetailPropTypes = {
    text: PropTypes.string,
    bold: PropTypes.bool,
    image: PropTypes.shape({
        alt: PropTypes.string,
        src: PropTypes.string,
    }),
    stars: PropTypes.number,
};

const cardImagePropTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    href: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Title.Detail.propTypes = cardTitleDetailPropTypes;

Title.propTypes = {
    href: PropTypes.string,
    description: PropTypes.string,
    detail: PropTypes.shape(cardTitleDetailPropTypes),
    bold: PropTypes.bool,
    big: PropTypes.bool,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape(cardImagePropTypes)]),
};

Title.displayName = 'Title';
Title.Detail.displayName = 'Detail';
Title.Image.displayName = 'Image';

export default Title;
