import React, { Fragment, useEffect, useState } from 'react';
import Command from '@components/Command';
import { makeGetRequest } from '@helpers/requests';
import { navigate } from 'gatsby-link';
import { INVOICE } from '@helpers/api';
import List from '@components/List';
import Card from '@components/Card';
import { Col, Divider, Grid, Row } from '@components/Grid';
import Alert from '@components/Alert';
import {
    isBefore,
    parseISO,
    differenceInDays,
    differenceInHours,
    differenceInMinutes,
    format,
} from 'date-fns';
import { withSnackbar } from '@components/Snackbar';

const Invoice = ({ openSnackbar, id }) => {
    const [loading, setLoading] = useState(true);
    const [invoice, setInvoice] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                const { data: invoice } = await makeGetRequest(INVOICE(id));
                setInvoice(invoice);
                setLoading(false);
            } catch (error) {
                if (error === 'cancelled') return;
                openSnackbar(error?.errorMessage ?? 'An error occurred loading this invoice.');
                navigate('/settings/billing');
            }
        })();
    }, []);

    const overdue = invoice && !invoice?.paid && !!isBefore(parseISO(invoice?.due), new Date());
    const overdueDifferenceInDays = overdue && differenceInDays(new Date(), parseISO(invoice?.due));
    const overdueDifferenceInHours =
        overdue && differenceInHours(new Date(), parseISO(invoice?.due));
    const overdueDifferenceInMinutes =
        overdue && differenceInMinutes(new Date(), parseISO(invoice?.due));
    const overdueDays =
        (overdue && `${overdueDifferenceInDays} day${overdueDifferenceInDays > 1 ? 's' : ''}`) ??
        null;
    const overdueHours =
        (overdue &&
            overdueDifferenceInDays === 0 &&
            `${overdueDifferenceInHours} hour${overdueDifferenceInHours > 1 ? 's' : ''}`) ??
        null;
    const overdueMinutes =
        (overdue &&
            overdueDifferenceInDays === 0 &&
            overdueDifferenceInHours === 0 &&
            `${overdueDifferenceInMinutes} minute${overdueDifferenceInMinutes > 1 ? 's' : ''}`) ??
        null;
    const overdueTime = overdueMinutes || overdueHours || overdueDays;

    return (
        <Fragment>
            <Command>
                <Command.Breadcrumbs>
                    <Command.Breadcrumbs.Breadcrumb text="Settings" />
                    <Command.Breadcrumbs.Breadcrumb text="Billing" link="/settings/billing" />
                    <Command.Breadcrumbs.Breadcrumb
                        text={`Invoice${
                            invoice?.invoiceNumber ? ` (MP-${invoice.invoiceNumber})` : ''
                        }`}
                    />
                </Command.Breadcrumbs>

                {!!invoice?.invoiceUrl && (
                    <Command.Action
                        text="Download"
                        icon={['fad', 'file-pdf']}
                        link={invoice?.invoiceUrl}
                    />
                )}
            </Command>

            <Grid>
                <Fragment>
                    {!loading && (
                        <Fragment>
                            {!invoice?.paid && (
                                <Fragment>
                                    <Row>
                                        <Col xs={12} lg={8} xl={6}>
                                            {overdue ? (
                                                <Alert
                                                    type="error"
                                                    title="Overdue invoice"
                                                    message={`You were due to pay this invoice ${overdueTime} ago, which currently has an outstanding balance of £${(
                                                        invoice.total / 100
                                                    ).toFixed(
                                                        2
                                                    )}, please pay as soon as possible if you wish to keep using our services.`}
                                                >
                                                    <Alert.Actions>
                                                        <Alert.Actions.Action
                                                            text="Pay now"
                                                            link={`/settings/billing/invoice/${invoice._id}/pay`}
                                                        />
                                                    </Alert.Actions>
                                                </Alert>
                                            ) : (
                                                <Alert
                                                    type="warning"
                                                    title="Unpaid invoice"
                                                    message={`You haven't yet paid this invoice, which currently has an outstanding balance of £${(
                                                        invoice.total / 100
                                                    ).toFixed(2)}`}
                                                >
                                                    <Alert.Actions>
                                                        <Alert.Actions.Action
                                                            text="Pay now"
                                                            link={`/settings/billing/invoice/${invoice._id}/pay`}
                                                        />
                                                    </Alert.Actions>
                                                </Alert>
                                            )}
                                        </Col>
                                    </Row>
                                    <Divider />
                                </Fragment>
                            )}
                        </Fragment>
                    )}

                    {loading ||
                        (!loading && !!invoice?.items?.length && (
                            <Fragment>
                                <List>
                                    <List.Item>
                                        <List.Item.Column>
                                            <List.Item.Title text="Invoice Number" />
                                        </List.Item.Column>
                                        <List.Item.Column.Right important>
                                            <List.Item.Title
                                                small
                                                text={`MP-${invoice?.invoiceNumber}`}
                                                bold={false}
                                            />
                                        </List.Item.Column.Right>
                                    </List.Item>
                                    <List.Item>
                                        <List.Item.Column>
                                            <List.Item.Title text="Due" />
                                        </List.Item.Column>
                                        <List.Item.Column.Right important>
                                            <List.Item.Title
                                                small
                                                text={format(parseISO(invoice?.due), 'PPP p')}
                                                bold={false}
                                            />
                                        </List.Item.Column.Right>
                                    </List.Item>
                                    <List.Item>
                                        <List.Item.Column>
                                            <List.Item.Title text="Address" />
                                        </List.Item.Column>
                                        <List.Item.Column.Right important>
                                            <List.Item.Title
                                                small
                                                bold={false}
                                                text={`${invoice?.address?.streetOne}${
                                                    invoice?.address?.streetTwo
                                                        ? ` ${invoice?.address?.streetOne}`
                                                        : ''
                                                }`}
                                            />
                                            <List.Item.Description
                                                text={`${invoice?.address?.city}, ${invoice?.address?.county}, ${invoice?.address?.postcode}, ${invoice?.address?.country}`}
                                            />
                                        </List.Item.Column.Right>
                                    </List.Item>
                                    <List.Item>
                                        <List.Item.Column>
                                            <List.Item.Title text="Paid" />
                                        </List.Item.Column>
                                        <List.Item.Column.Right important>
                                            <List.Item.Checked checked={invoice?.paid} />
                                        </List.Item.Column.Right>
                                    </List.Item>
                                    <List.Item>
                                        <List.Item.Column>
                                            <List.Item.Title text="Subtotal" />
                                        </List.Item.Column>
                                        <List.Item.Column.Right important>
                                            <List.Item.Title
                                                small
                                                text={`£${(invoice?.subtotal / 100).toFixed(2)}`}
                                                bold={false}
                                            />
                                        </List.Item.Column.Right>
                                    </List.Item>
                                    <List.Item>
                                        <List.Item.Column>
                                            <List.Item.Title text="VAT" />
                                        </List.Item.Column>
                                        <List.Item.Column.Right important>
                                            <List.Item.Title
                                                small
                                                text={`£${(invoice?.vat / 100).toFixed(2)}`}
                                                bold={false}
                                            />
                                        </List.Item.Column.Right>
                                    </List.Item>
                                    <List.Item>
                                        <List.Item.Column>
                                            <List.Item.Title text="Total" />
                                        </List.Item.Column>
                                        <List.Item.Column.Right important>
                                            <List.Item.Title
                                                small
                                                text={`£${(invoice?.total / 100).toFixed(2)}`}
                                            />
                                        </List.Item.Column.Right>
                                    </List.Item>
                                </List>
                                <Divider />

                                <Card>
                                    <Card.Title>Items</Card.Title>
                                    <Card.Content>
                                        <List loading={loading}>
                                            {invoice?.items?.map(
                                                ({ _id, item, description, quantity, amount }) => (
                                                    <List.Item key={_id}>
                                                        <List.Item.Column>
                                                            <List.Item.Title text={item} />
                                                            <List.Item.Description
                                                                text={description}
                                                            />
                                                        </List.Item.Column>
                                                        <List.Item.Column important>
                                                            <List.Item.Title text="Quantity" />
                                                            <List.Item.Description
                                                                text={quantity}
                                                            />
                                                        </List.Item.Column>
                                                        <List.Item.Column important>
                                                            <List.Item.Title text="Price" />
                                                            <List.Item.Description
                                                                text={`£${(amount / 100).toFixed(
                                                                    2
                                                                )}`}
                                                            />
                                                        </List.Item.Column>
                                                    </List.Item>
                                                )
                                            )}
                                        </List>
                                    </Card.Content>
                                </Card>
                            </Fragment>
                        ))}
                </Fragment>
            </Grid>
        </Fragment>
    );
};

export default withSnackbar(Invoice);
