import React, { useEffect, useState, Fragment } from 'react';
import { connect, useSelector } from 'react-redux';
import queryString from 'query-string';
import { navigate } from 'gatsby-link';
import { format, parseISO } from 'date-fns';

import { Grid, Divider } from '@components/Grid';
import { makeGetRequest, makePutRequest } from '@helpers/requests';
import { USER_REQUEST, USER_REQUESTS, USERS } from '@helpers/api';
import Card from '@components/Card';
import Pagination from '@components/Pagination';
import Command from '@components/Command';
import usePrevious from '@helpers/hooks/usePrevious';
import Empty from '@components/Empty';
import { withSnackbar } from '@components/Snackbar';
import List from '@components/List';
import Text from '@components/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './styles.module.scss';
import Modal from '@components/Modal';
import Button from '@components/Button';

const Requests = ({ location, openSnackbar }) => {
    const params = queryString.parse(location.search, { parseNumbers: true });
    const page = params.page ?? 1;
    const query = params.query || undefined;
    const type = params.type || 'pending';
    // const { request, website } = useSelector(({ request, website }) => ({ request, website }));

    const [declining, setDeclining] = useState(false);
    const [processingDecline, setProcessingDecline] = useState(false);
    const [approving, setApproving] = useState(false);
    const [processingApprove, setProcessingApprove] = useState(false);
    const [loading, setLoading] = useState(true);
    const [requests, setRequests] = useState([]);
    const [total, setTotal] = useState(null);
    const [queryTimeout, setQueryTimeout] = useState(null);
    const prevPage = usePrevious(page);
    const prevQuery = usePrevious(query);

    const setNewRequests = async () => {
        try {
            if (!loading) setLoading(true);
            const { data: requestsData } = await makeGetRequest(USER_REQUESTS, {
                perPage: 30,
                pageNum: page,
                search: query,
                type:
                    (type === 'approved' && 'approved') ||
                    (type === 'declined' && 'declined') ||
                    'pending',
            });
            setTotal(requestsData.meta.total);
            setRequests(requestsData?.results);
            setLoading(false);
        } catch (error) {
            if (error !== 'cancelled') {
                openSnackbar(error?.errorMessage ?? 'An error occurred loading requests.');
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        setNewRequests();
    }, [type]);

    const handleDecision = async () => {
        try {
            if (!!approving) {
                setProcessingApprove(true);
            } else {
                setProcessingDecline(true);
            }

            const { data: decisionData } = await makePutRequest(
                USER_REQUEST(approving?._id || declining?._id),
                {
                    decision: !!approving,
                }
            );
            if (!!approving) {
                setProcessingApprove(false);
            } else {
                setProcessingDecline(false);
            }

            openSnackbar(
                `Successfully ${!!approving ? 'approved' : 'declined'} ${
                    declining?.user
                        ? `${declining?.user?.firstName} ${declining?.user?.lastName} (${declining?.user?.email})`
                        : 'this user'
                }.`
            );
            await setNewRequests();
            if (!!approving) {
                setApproving(false);
            } else {
                setDeclining(false);
            }
        } catch (error) {
            if (error !== 'cancelled') {
                openSnackbar(
                    `An error occurred attempting to ${!!approving ? 'approve' : 'decline'} ${
                        declining?.user
                            ? `${declining?.user?.firstName} ${declining?.user?.lastName} (${declining?.user?.email})`
                            : 'this user'
                    }.`
                );

                if (!!approving) {
                    setProcessingApprove(false);
                    setApproving(false);
                } else {
                    setProcessingDecline(false);
                    setDeclining(false);
                }
            }
        }
    };

    useEffect(() => {
        (async () => await setNewRequests())();
        return () => {
            clearTimeout(queryTimeout);
        };
    }, []);

    useEffect(() => {
        if (!prevPage || prevPage === page) return;
        setNewRequests();
    }, [page]);

    useEffect(() => {
        if (!prevQuery || prevQuery === query) return;
        clearTimeout(queryTimeout);
        setQueryTimeout(
            setTimeout(async () => {
                await setNewRequests({});
            }, 750)
        );
    }, [query]);

    const handleUrlChange = (query = {}) => {
        navigate(
            `${location.pathname}?${queryString.stringify({
                ...queryString.parse(location.search),
                page: undefined,
                ...query,
            })}`
        );
    };

    return (
        <Fragment>
            <Command>
                <Command.Breadcrumbs>
                    <Command.Breadcrumbs.Breadcrumb text="Users" link="/users" />
                    <Command.Breadcrumbs.Breadcrumb text="Requests" />
                </Command.Breadcrumbs>

                <Command.Filters>
                    <Command.Filters.Search
                        key="search"
                        onChange={e => handleUrlChange({ query: e.target.value })}
                        value={query}
                    />
                </Command.Filters>
            </Command>

            {loading || !!requests.length ? (
                <Grid>
                    <Card>
                        <Card.Content>
                            <List loading={loading}>
                                {(requests ?? []).map(request => {
                                    const {
                                        _id,
                                        type,
                                        user: { firstName, lastName, email, avatar } = {},
                                    } = request;

                                    return (
                                        <List.Item
                                            key={_id}
                                            link={`/users/requests/request/${_id}`}
                                        >
                                            <List.Item.Avatar alt={firstName} src={avatar} />
                                            <List.Item.Column>
                                                <Text bold>
                                                    {!!firstName
                                                        ? `${firstName} ${lastName}`
                                                        : 'Guest'}
                                                </Text>
                                                {!!email && <Text>{email}</Text>}
                                            </List.Item.Column>
                                            {!!type && (
                                                <List.Item.Column center important>
                                                    <div className={styles.requestTransition}>
                                                        {/*TODO: Show previous user type if existed*/}
                                                        {/*<Text>{'Standard'}</Text>*/}
                                                        <span>
                                                            <FontAwesomeIcon
                                                                icon={['fad', 'long-arrow-right']}
                                                            />
                                                        </span>
                                                        <Text>{type}</Text>
                                                    </div>
                                                </List.Item.Column>
                                            )}
                                            <List.Item.Actions>
                                                <List.Item.Actions.Action
                                                    success
                                                    tooltip="Decline"
                                                    icon="trash"
                                                    onClick={() => setDeclining(request)}
                                                />
                                                <List.Item.Actions.Action
                                                    success
                                                    tooltip="Approve"
                                                    icon="check"
                                                    onClick={() => setApproving(request)}
                                                />
                                            </List.Item.Actions>
                                        </List.Item>
                                    );
                                })}
                            </List>
                        </Card.Content>
                    </Card>

                    {!loading && (
                        <Fragment>
                            <Divider />
                            <Pagination
                                meta
                                onChange={page => handleUrlChange({ page })}
                                pageTotal={requests.length}
                                total={total}
                                page={page}
                                perPage={30}
                                metaLabel="Requests"
                            />
                        </Fragment>
                    )}

                    <Modal isOpen={declining} onClose={() => setDeclining(false)}>
                        <Modal.Content>
                            <p>{`Are you sure you want to decline ${
                                declining?.user?.firstName
                                    ? `${declining?.user?.firstName} ${declining?.user?.lastName}`
                                    : 'this user'
                            } from becoming the ${declining?.type} user type?`}</p>
                            <Button onClick={() => setDeclining(false)}>Cancel</Button>
                            <Button loading={processingDecline} danger onClick={handleDecision}>
                                Decline
                            </Button>
                        </Modal.Content>
                    </Modal>

                    <Modal isOpen={approving} onClose={() => setApproving(false)}>
                        <Modal.Content>
                            <p>{`Are you sure you want to approve ${
                                approving?.user?.firstName
                                    ? `${approving?.user?.firstName} ${approving?.user?.lastName}`
                                    : 'this user'
                            } to become the ${approving?.type} user type?`}</p>
                            <Button onClick={() => setApproving(false)}>Cancel</Button>
                            <Button loading={processingApprove} success onClick={handleDecision}>
                                Approve
                            </Button>
                        </Modal.Content>
                    </Modal>
                </Grid>
            ) : (
                <Empty title="Hold tight" text="You don't have any requests yet!" />
            )}
        </Fragment>
    );
};

export default withSnackbar(Requests);
