import React from 'react';

import { subcomponent } from '@helpers';
import { LayoutContext } from '@helpers/contexts';
import { Grid } from '@components/Grid';

import styles from './styles.module.scss';
import { HeightContext } from '@helpers/contexts';
import { navigate } from '@reach/router';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@components/Button';
import { filterInternalChildren } from '@helpers/index';

const Panes = ({ children }) => {
    return (
        <LayoutContext.Consumer>
            {({ $panes, $paneLeft, $paneRight } = {}) => {
                const $RightPane = subcomponent(children, Panes.Right, true, {
                    $paneRight,
                });
                const $RightPaneTitle =
                    !!$RightPane && subcomponent($RightPane?.props?.children, Panes.Title, true);
                const closable = !!$RightPaneTitle?.props?.onBack;

                return (
                    <HeightContext.Consumer>
                        {({ height } = {}) => (
                            <div ref={$panes}>
                                <Grid
                                    fullHeight
                                    className={`${styles.panesContainer}`}
                                    style={{ height }}
                                >
                                    <div
                                        className={`${styles.panes} ${
                                            closable ? styles.panesClosable : ''
                                        }`}
                                    >
                                        {subcomponent(children, Panes.Left, true, {
                                            $paneLeft,
                                        })}
                                        {$RightPane}
                                    </div>
                                </Grid>
                            </div>
                        )}
                    </HeightContext.Consumer>
                );
            }}
        </LayoutContext.Consumer>
    );
};

Panes.Left = ({ className = '', children, $paneLeft, noPadding = false }) => (
    <div
        ref={$paneLeft}
        className={`${styles.panesLeft} ${noPadding ? styles.panesNoPadding : ''} ${className}`}
    >
        {subcomponent(children, Panes.Title, true)}

        <div className={styles.panesContent}>{filterInternalChildren(children, [Panes.Title])}</div>
    </div>
);
Panes.Left.displayName = 'Content';

Panes.Right = ({ className = '', children, $paneRight, noPadding = false }) => (
    <div
        ref={$paneRight}
        className={`${styles.panesRight} ${noPadding ? styles.panesNoPadding : ''} ${className}`}
    >
        {subcomponent(children, Panes.Title, true)}

        <div className={styles.panesContent}>{filterInternalChildren(children, [Panes.Title])}</div>
    </div>
);
Panes.Right.displayName = 'Preview';

Panes.Title = ({ onBack, children }) => (
    <div className={styles.panesTitle}>
        {!!onBack && (
            <div className={styles.panesTitleBack} onClick={onBack}>
                <FontAwesomeIcon icon={['fad', 'angle-left']} />
            </div>
        )}
        <div className={styles.panesTitleContent}>
            {filterInternalChildren(children, [Panes.Title.Actions])}
        </div>
        {subcomponent(children, Panes.Title.Actions, true)}
    </div>
);
Panes.Title.displayName = 'Title';

Panes.Title.Actions = ({ children }) => (
    <div className={styles.panesTitleActions}>
        {subcomponent(children, Panes.Title.Actions.Action)}
    </div>
);
Panes.Title.Actions.displayName = 'Actions';

Panes.Title.Actions.Action = props => (
    <div className={styles.panesTitleActionsAction}>
        <Button {...props} />
    </div>
);
Panes.Title.Actions.Action.displayName = 'Action';

export default Panes;
