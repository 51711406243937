import React, { Fragment, useEffect, useRef, useState } from 'react';

import Alert from '@components/Alert';
import Button from '@components/Button';
import Addresses from '@components/Addresses';
import { Col, Divider, Row } from '@components/Grid';
import { makeGetRequest } from '@helpers/requests';
import { SUBSCRIPTION_BRAINTREE_TOKEN } from '@helpers/api';
import DropIn from 'braintree-web-drop-in-react';
import Loader from '@components/Loader';

const SubscriptionPayment = ({
    onSubmit,
    onPrevious,
    plan,
    plans,
    planRecurringType,
    submitting,
}) => {
    const braintree = useRef(null);
    const [address, setAddress] = useState(null);
    const [showNoAddressError, setShowNoAddressError] = useState(false);
    const [token, setToken] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                const { data: token } = await makeGetRequest(SUBSCRIPTION_BRAINTREE_TOKEN);
                setToken(token);
            } catch (error) {
                error !== 'cancelled' && openSnackbar(error?.errorMessage ?? 'An error occurred loading Braintree.');
            }
        })();
    }, []);

    const handleAddressSelect = address => {
        setShowNoAddressError(false);
        setAddress(address);
    };
    const handleSubmit = () => {
        if (!address) {
            setShowNoAddressError(true);
            return;
        }

        onSubmit({ braintree, address, total });
    };

    const total = parseFloat(plan.prices[planRecurringType].price);

    return (
        <Fragment>
            <h1>Confirm and pay</h1>

            <Alert
                type="info"
                message={`This is a recurring subscription, you have chose to pay ${planRecurringType}. You will always be able to cancel by contacting us directly.`}
            />
            <Divider />

            <h2>Billing address</h2>
            <Addresses onSelect={handleAddressSelect} />
            {showNoAddressError && !address && (
                <Alert
                    className="mb-8"
                    message="You must select an address"
                    showIcon
                    type="warning"
                />
            )}
            <Divider />

            <h2>Payment details</h2>
            <div style={{ position: 'relative' }}>
                {loading ? <Loader height={200} /> : null}
                {!token ? null : (
                    <div className={`${loading ? 'braintree-dropin-loading' : ''}`}>
                        <DropIn
                            options={{
                                vaultManager: true,
                                authorization: token,
                                paypal: {
                                    flow: 'vault',
                                },
                                // applePay: {
                                //     displayName: 'My Store',
                                //     paymentRequest: {
                                //         total: {
                                //             label: 'My Store',
                                //             amount: total.toFixed(2),
                                //         },
                                //         requiredBillingContactFields: ['postalAddress'],
                                //     },
                                // },
                                // googlePay: {
                                //     merchantId: '09559114429702614958', // Omit this line in sandbox; insert your Google merchant ID in production
                                //     transactionInfo: {
                                //         currencyCode: 'GBP',
                                //         totalPriceStatus: 'FINAL',
                                //         totalPrice: total.toFixed(2), // Your amount
                                //     },
                                // },
                                // venmo: {
                                //     allowNewBrowserTab: false,
                                // },
                                paypalCredit: {
                                    flow: 'checkout',
                                    amount: total.toFixed(2),
                                    currency: 'GBP',
                                },
                            }}
                            onInstance={() => setLoading(false)}
                            ref={braintree}
                        />
                    </div>
                )}
            </div>

            {/*<p>*/}
            {/*    <small>*/}
            {/*        <a*/}
            {/*            href="https://help.skippermyboat.com/policies-and-legal/cancellation-policy"*/}
            {/*            target="_blank"*/}
            {/*            rel="noopener noreferrer"*/}
            {/*        >*/}
            {/*            Cancellation Policy*/}
            {/*        </a>*/}
            {/*    </small>*/}
            {/*</p>*/}

            <Row>
                <Col xsGrow>
                    <Button secondary onClick={onPrevious} disabled={loading || !token}>
                        Back
                    </Button>
                </Col>
                <Col xsShrink>
                    <Button
                        onClick={handleSubmit}
                        submitting={submitting}
                        disabled={loading || !token}
                    >
                        {`Pay £${total.toFixed(2)}`}
                    </Button>
                </Col>
            </Row>
        </Fragment>
    );
};

export default SubscriptionPayment;
