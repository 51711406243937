import React from 'react';
import PropTypes from 'prop-types';

import styles from '../styles.module.scss';

class Day extends React.Component {
    handleKeyUp(e) {
        const code = e.which || e.keyCode;
        if (code === 13 && this.props.onClick) {
            this.props.onClick(this.props.day);
        }
    }

    render() {
        const activeClass = this.props.active ? styles.datepickerDayActive : '';
        const disabledClass = this.props.disabled ? styles.datepickerDayDisabled : '';
        const hoveredClass = this.props.hovered ? styles.datepickerDayHovered : '';
        const selectedClass = this.props.selected ? styles.datepickerDaySelected : '';
        const todayClass = this.props.today ? styles.datepickerDayToday : '';
        const day = typeof this.props.day === 'number' ? this.props.day : null;

        if (this.props.empty) {
            return <li />;
        }

        return (
            <li
                tabIndex={this.props.disabled ? null : 0}
                className={`${styles.datepickerDay} ${todayClass} ${activeClass} ${disabledClass} ${selectedClass} ${hoveredClass}`}
                onClick={
                    this.props.disabled
                        ? undefined
                        : () => this.props.onClick && this.props.onClick(this.props.day)
                }
                onKeyUp={e => this.handleKeyUp(e)}
                onMouseEnter={
                    this.props.disabled
                        ? undefined
                        : () => this.props.onHover && this.props.onHover(this.props.day)
                }
            >
                <span>{day}</span>
            </li>
        );
    }
}

Day.propTypes = {
    onClick: PropTypes.func,
    empty: PropTypes.bool,
    active: PropTypes.bool,
    today: PropTypes.bool,
};

export default Day;
