import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { navigate } from 'gatsby-link';
import { format, parseISO } from 'date-fns';

import { Grid, Divider } from '@components/Grid';
import { makeGetRequest } from '@helpers/requests';
import { STORE_ORDERS } from '@helpers/api';
import Card from '@components/Card';
import Pagination from '@components/Pagination';
import Command from '@components/Command';
import usePrevious from '@helpers/hooks/usePrevious';
import Empty from '@components/Empty';
import { withSnackbar } from '@components/Snackbar';
import styles from '@views/Store/Checkouts/styles.module.scss';
import Text from '@components/Text';

const Orders = ({ website, page, query, location, openSnackbar }) => {
	const [loading, setLoading] = useState(true);
	const [orders, setOrders] = useState([]);
	const [total, setTotal] = useState(null);
	const [queryTimeout, setQueryTimeout] = useState(null);
	const prevPage = usePrevious(page);
	const prevQuery = usePrevious(query);

	const setNewOrders = async () => {
		try {
			if (!loading) setLoading(true);
			const { data: ordersData } = await makeGetRequest(STORE_ORDERS, {
				perPage: 10,
				pageNum: page,
				query,
			});
			setTotal(ordersData.meta.total);
			setOrders(ordersData?.results);
			setLoading(false);
		} catch (error) {
			error !== 'cancelled' &&
				openSnackbar(error?.errorMessage ?? 'An error occurred loading orders.');
		}
	};

	useEffect(() => {
		(async () => await setNewOrders())();
		return () => {
			clearTimeout(queryTimeout);
		};
	}, []);

	console.log({ orders, loading });

	useEffect(() => {
		if (!prevPage || prevPage === page) return;
		setNewOrders();
	}, [page]);

	useEffect(() => {
		if (!prevQuery || prevQuery === query) return;
		clearTimeout(queryTimeout);
		setQueryTimeout(
			setTimeout(async () => {
				await setNewOrders();
			}, 750)
		);
	}, [query]);

	const handleUrlChange = (query = {}) => {
		navigate(
			`${location.pathname}?${queryString.stringify({
				...queryString.parse(location.search),
				page: undefined,
				...query,
			})}`
		);
	};

	return (
		<Fragment>
			<Command title="Orders">
				<Command.Filters>
					<Command.Filters.Search
						key="search"
						onChange={e => handleUrlChange({ query: e.target.value })}
						value={query}
					/>
				</Command.Filters>
			</Command>
			{loading || !!orders.length ? (
				<Grid>
					<Card loading={loading}>
						<Card.List>
							{loading ? (
								<Card.List.Item avatar title description />
							) : (
								orders
									?.filter(order => !!order?.products && !!order?.meta)
									?.map(
										({
											_id,
											meta: { timestamp, total, discountCode },
											stage,
											billing,
											products,
										}) => (
											<Card.List.Item
												key={_id}
												title={`${billing?.fullName ?? 'Guest'}`}
												onClick={() => navigate(`/store/orders/${_id}`)}
											>
												{!!products?.[0]?.data?.images?.[0]?.url && (
													<Card.List.Item.Avatar
														src={products?.[0]?.data?.images?.[0]?.url}
													/>
												)}
												<Card.List.Item.Content>
													<ul className={styles.checkoutItems}>
														<li>
															{products?.length === 1
																? products?.[0]?.data?.name
																: `${products?.length} Product${
																		products?.length === 1
																			? ''
																			: 's'
																  }`}
														</li>

														{!!billing && <li>{billing.email}</li>}

														{!!discountCode && (
															<li>{`Discount - ${discountCode}`}</li>
														)}
													</ul>
													<Text>
														{`${website.store.currency.symbol}${total /
															100}`}
													</Text>
												</Card.List.Item.Content>
												<Card.List.Item.Position.Bottom.Left
													faded
													text={`${format(
														parseISO(stage?.lastUpdated ?? timestamp),
														'PPP p'
													)}`}
												/>
												<Card.List.Item.Position.Bottom.Right
													faded
													text={`Id: ${_id}`}
												/>
											</Card.List.Item>
										)
									)
							)}
						</Card.List>
					</Card>

					{!loading && (
						<Fragment>
							<Divider />
							<Pagination
								meta
								onChange={page => handleUrlChange({ page })}
								pageTotal={orders?.length}
								total={total}
								page={page}
								perPage={10}
								metaLabel="Orders"
							/>
						</Fragment>
					)}
				</Grid>
			) : !!query.length || queryTimeout ? (
				<Empty title="Nothing here" text="Your filters didn't seem to find anything." />
			) : (
				<Empty title="Hold tight" text="You don't have any checkouts yet!" />
			)}
		</Fragment>
	);
};

export default withSnackbar(
	connect(({ website }, ownProps) => {
		const params = queryString.parse(ownProps.location.search, { parseNumbers: true });
		const page = params.page ?? 1;
		const query = params.query || undefined;
		return { website, page, query };
	})(Orders)
);
