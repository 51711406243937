import React from 'react';

import Input from '@components/Input';

const InputField = ({
    website,
    field: { type, label, hint, optional, min, max, id, path, multiple, decimals, positive },
    activeField,
    value,
    touched,
    error,
    handleChange,
    setFieldValue,
    name,
}) => {
    const handleInputChange = e => {
        let value = e.target.value;

        if (!value) {
            setFieldValue(name, undefined);
            return;
        }

        if (type === 'number') {
            value = parseFloat(value);
        }

        setFieldValue(name, value);
    };

    return (
        <Input
            name={name}
            value={value}
            label={label}
            hint={hint}
            optional={optional}
            number={type === 'number' && { min, max, decimals, positive }}
            textarea={type === 'text' || type === 'paragraph'}
            onChange={handleInputChange}
        />
    );
};

export default InputField;
