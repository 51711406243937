import React, { Fragment } from 'react';
import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Text = ({ wrap, single, bold, faded, empty, children }) => {
    // const Single = ({ children }) => (single ? <span>{children}</span> : children);
    const Empty = ({ children }) =>
        empty ? <span className={styles.textEmptyIcon} /> : <Fragment>{children}</Fragment>;
    const Bold = ({ children }) => (bold ? <b>{children}</b> : <Fragment>{children}</Fragment>);

    return (
        <p
            className={`${styles.text} ${faded ? styles.textFaded : ''} ${
                single ? styles.textSingle : ''
            } ${wrap ? styles.textWrap : ''} 
            ${empty ? styles.textEmpty : ''}`}
        >
            <Empty>
                <Bold>{children}</Bold>
            </Empty>
        </p>
    );
};

export default Text;
