import React, { Fragment, useEffect, useRef, useState } from 'react';
import { makeGetRequest } from '@helpers/requests';
import { MEDIA } from '@helpers/api';
import Loader from '@components/Loader';
import Command from '@components/Command';
import { Grid } from '@components/Grid';

import styles from './styles.module.scss';
import { connect } from 'react-redux';
import { makePostRequest } from '@helpers/requests';
import { MEDIA_UPLOAD } from '@helpers/api';
import Label from '@components/Label';
import { withSnackbar } from '@components/Snackbar';

const MediaLibrary = ({ website, onSelect, multiple, openSnackbar }) => {
    const [view, setView] = useState('list');
    const [loading, setLoading] = useState(true);
    const [uploading, setUploading] = useState(false);
    const [media, setMedia] = useState([]);
    const $upload = useRef(null);

    useEffect(() => {
        (async () => {
            try {
                const { data: media } = await makeGetRequest(MEDIA);
                setMedia(media);

                setLoading(false);
            } catch (error) {
                error !== 'cancelled' && openSnackbar(
                    error?.errorMessage ?? 'An error occurred when attempting loading media.'
                );
            }
        })();
    }, []);

    const handleMediaUpload = async () => {
        try {
            setUploading(true);
            const data = await Promise.all(
                Array.from($upload.current.files).map(async file => {
                    const formData = new FormData();
                    formData.append('media', file);
                    const { data: media } = await makePostRequest(MEDIA_UPLOAD, formData, null, {
                        'Content-Type': 'multipart/form-data',
                    });
                    return media;
                })
            );

            setMedia([...data, ...media]);
            setUploading(false);
        } catch (error) {
            error !== 'cancelled' && openSnackbar(error?.errorMessage ?? 'An error occurred when uploading media.');
            setUploading(false);
        }
    };

    return (
        <Fragment>
            <Command>
                <Command.Breadcrumbs>
                    <Command.Breadcrumbs.Breadcrumb text="Media Library" />
                </Command.Breadcrumbs>

                <Command.Action
                    text="Upload"
                    onClick={() => $upload.current.click()}
                    submitting={uploading}
                />
            </Command>
            <input ref={$upload} hidden type="file" onChange={handleMediaUpload} />
            {loading ? (
                <Loader />
            ) : (
                <Grid>
                    <div
                        className={`${styles.media} ${
                            view === 'list' ? styles.mediaViewList : ''
                        } ${view === 'gallery' ? styles.mediaViewGallery : ''}`}
                    >
                        <Fragment>
                            {view === 'list' && (
                                <div className={styles.mediaLabels}>
                                    <Label
                                        className={styles.mediaLabelsThumbnail}
                                        text="Thumbnail"
                                    />
                                    <Label className={styles.mediaLabelsFilename} text="File" />
                                    <Label
                                        className={styles.mediaLabelsExtension}
                                        text="Extension"
                                    />
                                </div>
                            )}
                            {media.map(item => (
                                <div
                                    key={item.id}
                                    className={styles.mediaItem}
                                    tabIndex={!!onSelect ? 0 : undefined}
                                    onClick={!!onSelect ? () => onSelect(item) : undefined}
                                >
                                    {view === 'list' && (
                                        <Fragment>
                                            <img
                                                className={styles.mediaItemThumbnail}
                                                src={`${item.urls.preview}?websiteId=${website._id}&token=${website.api.key}`}
                                            />
                                            <span className={styles.mediaItemFilename}>
                                                {item.fileName}
                                            </span>
                                            <span className={styles.mediaItemExtension}>
                                                {item.fileExtension}
                                            </span>
                                        </Fragment>
                                    )}

                                    {view === 'gallery' && (
                                        <img
                                            src={`${item.urls.preview}?websiteId=${website._id}&token=${website.api.key}`}
                                        />
                                    )}
                                </div>
                            ))}
                        </Fragment>
                    </div>
                </Grid>
            )}
        </Fragment>
    );
};

export default withSnackbar(connect(({ website }) => ({ website }))(MediaLibrary));
