import React from 'react';

import styles from '../styles.module.scss';

export default class DatepickerLabels extends React.Component {
    render() {
        const days = ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'].map((item, i) => (
            <li key={i}>{item}</li>
        ));

        return <ul className={styles.datepickerLabels}>{days}</ul>;
    }
}
