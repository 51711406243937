import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    makeDeleteRequest,
    makeGetRequest,
    makePostRequest,
    makePutRequest,
} from '@helpers/requests';
import Loader from '@components/Loader';
import Address from './Address';
import Choices from '@components/Choices';
import Sheet from '@components/Sheet';
import { ADDRESS, ADDRESS_SET_DEFAULT } from '@helpers/api';
import { updateUser } from '@actions/user';
import { useSnackbar } from '@components/Snackbar';

const Addresses = ({ onSelect, onRemove }) => {
    const [openSnackbar, closeSnackbar] = useSnackbar();
    const { website, user } = useSelector(({ website, user }) => ({ website, user }));
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [addresses, setAddresses] = useState(null);
    const [creating, setCreating] = useState(false);
    const [editing, setEditing] = useState(false);
    const [editingAddress, setEditingAddress] = useState({});
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [submittingDefaultAddress, setSubmittingDefaultAddress] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const { data } = await makeGetRequest(ADDRESS);
                let defaultAddress;
                setAddresses(data);

                // if onSelect passed give default selected address or first address in array to higher component if any exist
                if (onSelect && data && data.length) {
                    if (user?._id && user?.defaultAddress) {
                        defaultAddress = data.find(item => item._id === user?.defaultAddress);
                    }

                    if (defaultAddress) {
                        setSelectedAddress(defaultAddress._id);
                        onSelect(defaultAddress);
                    } else {
                        const sortedAddresses = data.sort(
                            (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
                        );
                        setSelectedAddress(sortedAddresses[0]._id);
                        onSelect(sortedAddresses[0]);
                    }
                }

                setLoading(false);
            } catch (error) {
                error !== 'cancelled' &&
                    openSnackbar(
                        error?.errorMessage ?? 'An error occurred attempting to load you addresses.'
                    );
            }
        })();
    }, []);

    const handleAddressEdit = address => {
        setEditingAddress({ ...address });
        setEditing(true);
    };

    const handleCreateEditAddressClose = () => {
        setEditingAddress({});
        setCreating(false);
        setEditing(false);
    };

    const handleAddressDefault = async id => {
        try {
            setSubmittingDefaultAddress(id);
            const { data: defaultAddress } = await makePostRequest(ADDRESS_SET_DEFAULT(id));
            dispatch(updateUser({ ...user, defaultAddress: defaultAddress._id }));
            setSubmittingDefaultAddress(false);
        } catch (error) {
            error !== 'cancelled' &&
                openSnackbar(
                    error?.errorMessage ?? 'An error occurred attempting to set a default address.'
                );
            setSubmittingDefaultAddress(false);
        }
    };

    // DELETE request
    const handleDelete = async ({ id, title }) => {
        try {
            const { data: userData } = await makeDeleteRequest(`${ADDRESS}/${id}`);
            openSnackbar(`Successfully deleted address - ${title}`);
            const newAddresses = addresses.filter(address => address._id !== id);
            setAddresses(newAddresses);
            // "deleted" property because luke :|
            dispatch(updateUser({ ...user, ...userData.deleted }));
            onRemove &&
                onRemove({
                    removed: addresses.find(address => address._id === id),
                    addresses: newAddresses,
                });
        } catch (error) {
            error !== 'cancelled' &&
                openSnackbar(
                    error?.errorMessage ?? 'An error occurred attempting deleting your address.'
                );
        }
    };

    // POST request
    const handleCreate = async (values, actions) => {
        const { streetOne, streetTwo, city, county, country, postcode } = values;

        try {
            let address;
            // currently editing address (use PUT not POST)
            if (editingAddress._id) {
                const editedAddress = await makePutRequest(`${ADDRESS}/${editingAddress._id}`, {
                    streetOne,
                    streetTwo,
                    city,
                    county,
                    country,
                    postcode,
                });
                address = editedAddress.data;
                setEditingAddress({});
                setEditing(false);
                error !== 'cancelled' && openSnackbar(`Successfully edited address - ${streetOne}`);
                setAddresses(addresses.map(item => (item._id === address._id ? address : item)));
            } else {
                const { data: address } = await makePostRequest(ADDRESS, {
                    streetOne,
                    streetTwo,
                    city,
                    county,
                    country,
                    postcode,
                });
                setCreating(false);
                openSnackbar(`Successfully added address - ${streetOne}`);
                const noAddressesYet = !addresses.length;

                setAddresses([address, ...addresses]);

                // if no default address exists for this user, set this new address as the default
                if (!user.defaultAddress) {
                    await handleAddressDefault(address._id);
                }

                // if this is the first address to be created, automatically select if allowed
                if (noAddressesYet) {
                    setSelectedAddress(address._id);
                    onSelect && onSelect(address);
                }
            }
        } catch (error) {
            error !== 'cancelled' &&
                openSnackbar(error?.errorMessage ?? 'An error occurred adding your address.');
        }
    };

    const handleChange = ({ id }) => {
        setSelectedAddress(id);
        onSelect && onSelect(addresses.find(address => address._id === id));
    };

    if (loading) {
        return <Loader tip="Loading Addresses..." />;
    }

    return (
        <Fragment>
            <Choices radio={!!onSelect} onChange={handleChange}>
                {addresses
                    .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                    .map(({ _id, streetOne, streetTwo, city, county, country, postcode }) => (
                        <Choices.Choice
                            key={_id}
                            title={`${streetOne}${streetTwo ? `, ${streetTwo}` : ''}`}
                            description={`${county} • ${city} • ${country} • ${postcode}`}
                            onDelete={handleDelete}
                            checked={selectedAddress}
                            badge={website.defaultAddress === _id ? 'Default' : null}
                        >
                            <Choices.Choice.Action
                                secondary
                                onClick={() =>
                                    handleAddressEdit({
                                        _id,
                                        streetOne,
                                        streetTwo,
                                        city,
                                        county,
                                        country,
                                        postcode,
                                    })
                                }
                            >
                                Edit
                            </Choices.Choice.Action>
                            {user?.defaultAddress !== _id && (
                                <Choices.Choice.Action
                                    secondary
                                    onClick={() => handleAddressDefault(_id)}
                                    submitting={submittingDefaultAddress === _id}
                                >
                                    Set default
                                </Choices.Choice.Action>
                            )}
                        </Choices.Choice>
                    ))}

                <Choices.Create title="Add an address" onCreate={() => setCreating(true)} />
            </Choices>
            {
                <Sheet open={creating || editing} onClose={handleCreateEditAddressClose}>
                    <Address
                        onSubmit={handleCreate}
                        onCancel={handleCreateEditAddressClose}
                        {...editingAddress}
                    />
                </Sheet>
            }
        </Fragment>
    );
};

export default Addresses;
