import React, { useEffect, useState, Fragment } from 'react';
import { connect, useSelector } from 'react-redux';
import queryString from 'query-string';
import { navigate } from 'gatsby-link';
import { format, parseISO } from 'date-fns';

import { Grid, Divider } from '@components/Grid';
import { makeGetRequest } from '@helpers/requests';
import { USERS } from '@helpers/api';
import Card from '@components/Card';
import Pagination from '@components/Pagination';
import Command from '@components/Command';
import usePrevious from '@helpers/hooks/usePrevious';
import Empty from '@components/Empty';
import { withSnackbar } from '@components/Snackbar';
import List from '@components/List';

// import styles from './styles.module.scss';

const Users = ({ location, openSnackbar }) => {
    const params = queryString.parse(location.search, { parseNumbers: true });
    const page = params.page ?? 1;
    const query = params.query || undefined;
    // const { user, website } = useSelector(({ user, website }) => ({ user, website }));

    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [total, setTotal] = useState(null);
    const [queryTimeout, setQueryTimeout] = useState(null);
    const prevPage = usePrevious(page);
    const prevQuery = usePrevious(query);

    const setNewUsers = async () => {
        try {
            if (!loading) setLoading(true);
            const { data: usersData } = await makeGetRequest(USERS, {
                perPage: 30,
                pageNum: page,
                search: query,
            });
            setTotal(usersData.meta.total);
            setUsers(usersData?.results);
        } catch (error) {
            error !== 'cancelled' &&
                openSnackbar(error?.errorMessage ?? 'An error occurred loading users.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        (async () => await setNewUsers())();
        return () => {
            clearTimeout(queryTimeout);
        };
    }, []);

    useEffect(() => {
        if (!prevPage || prevPage === page) return;
        setNewUsers();
    }, [page]);

    useEffect(() => {
        if (!prevQuery || prevQuery === query) return;
        clearTimeout(queryTimeout);
        setQueryTimeout(
            setTimeout(async () => {
                await setNewUsers({});
            }, 750)
        );
    }, [query]);

    const handleUrlChange = (query = {}) => {
        navigate(
            `${location.pathname}?${queryString.stringify({
                ...queryString.parse(location.search),
                page: undefined,
                ...query,
            })}`
        );
    };

    return (
        <Fragment>
            <Command>
                <Command.Breadcrumbs>
                    <Command.Breadcrumbs.Breadcrumb text="Users" />
                </Command.Breadcrumbs>

                <Command.Action text="Create" icon="plus" link="/users/user/create" />

                <Command.Filters>
                    <Command.Filters.Search
                        key="search"
                        onChange={e => handleUrlChange({ query: e.target.value })}
                        value={query}
                    />
                </Command.Filters>
            </Command>

            {loading || !!users.length ? (
                <Grid>
                    <Card>
                        <Card.Content>
                            <List loading={loading}>
                                {(users ?? []).map(
                                    ({ _id, firstName, lastName, email, lastLogin, locale }) => (
                                        <List.Item
                                            key={_id}
                                            onClick={() => navigate(`/users/user/${_id}`)}
                                        >
                                            <List.Item.Column>
                                                <List.Item.Title
                                                    text={`${firstName} ${lastName}`}
                                                />
                                                <List.Item.Description text={`${email}`} />
                                            </List.Item.Column>
                                            <List.Item.Column>
                                                <List.Item.Title
                                                    small
                                                    bold={false}
                                                    text="Location"
                                                />
                                                <List.Item.Description
                                                    text={`${locale?.city ??
                                                        ''}, ${locale?.countryCode ?? ''}`}
                                                />
                                            </List.Item.Column>
                                            <List.Item.Column>
                                                <List.Item.Title
                                                    small
                                                    bold={false}
                                                    text={`Last logged in`}
                                                />
                                                <List.Item.Description
                                                    text={
                                                        !!lastLogin &&
                                                        format(parseISO(lastLogin), 'PPP p')
                                                    }
                                                />
                                            </List.Item.Column>
                                        </List.Item>
                                    )
                                )}
                            </List>
                        </Card.Content>
                    </Card>

                    {!loading && (
                        <Fragment>
                            <Divider />
                            <Pagination
                                meta
                                onChange={page => handleUrlChange({ page })}
                                pageTotal={users.length}
                                total={total}
                                page={page}
                                perPage={30}
                                metaLabel="Users"
                            />
                        </Fragment>
                    )}
                </Grid>
            ) : (
                <Empty title="Hold tight" text="You don't have any users yet!" />
            )}
        </Fragment>
    );
};

export default withSnackbar(Users);
