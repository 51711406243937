import { bakeLocalStorage, deleteLocalStorage, readLocalStorage } from '@helpers/storage';

export default (state = readLocalStorage('website'), action) => {
    let newState;
    // Check to see what type of action is being fired
    switch (action.type) {
        case 'WEBSITE_SET':
            bakeLocalStorage('website', action.payload);
            return action.payload;
        case 'WEBSITE_SET_EDITOR_NAVIGATION': {
            newState = {
                ...(state || {}),
                editor: {
                    ...((state || {}).editor || {}),
                    navigation: action.payload || [],
                },
            };
            bakeLocalStorage('website', newState);
            return newState;
        }
        case 'WEBSITE_UPDATE':
            bakeLocalStorage('website', action.payload);
            return action.payload;
        case 'WEBSITE_REMOVE':
            deleteLocalStorage('website');
            return null;
        default:
            // Return state by default if nothing else is met
            return state;
    }
};
