import React, { useEffect, useState, Fragment } from 'react';
import * as Yup from 'yup';

import { Divider, Grid } from '@components/Grid';
import { makeDeleteRequest, makeGetRequest } from '@helpers/requests';
import { USER_TYPE, USER_TYPES, USERS } from '@helpers/api';
import Card from '@components/Card';
import Command from '@components/Command';
import Empty from '@components/Empty';

import { withSnackbar } from '@components/Snackbar';
import Modal from '@components/Modal';
import { Formik } from 'formik';

import Button from '@components/Button';
import List from '@components/List';
import { Link } from 'gatsby';
import queryString from 'query-string';
import Pagination from '@components/Pagination';
import { navigate } from 'gatsby-link';

const Types = ({ openSnackbar }) => {
    const params = queryString.parse(location.search, { parseNumbers: true });
    const page = params.page ?? 1;
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(null);
    const [types, setTypes] = useState([]);
    const [selectedType, setSelectedType] = useState(null);
    const [deletingType, setDeletingType] = useState(false);
    const [deletingTypeUsers, setDeletingTypeUsers] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                const { data: typesData } = await makeGetRequest(USER_TYPES, {
                    perPage: 10,
                    pageNum: page,
                });
                setTotal(typesData?.meta?.total);
                setTypes(typesData?.results);
            } catch (error) {
                error !== 'cancelled' &&
                    openSnackbar(
                        error?.errorMessage ?? 'An error occurred attempting to load user types.'
                    );
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        if (!deletingType) return;

        (async () => {
            try {
                const { data: typeUsagesData } = await makeGetRequest(USERS, {
                    type: selectedType?._id,
                    perPage: 5,
                    pageNum: 1,
                });

                typeUsagesData?.meta?.total && setDeletingTypeUsers(typeUsagesData);
            } catch (error) {
                error !== 'cancelled' &&
                    openSnackbar(
                        error?.errorMessage ?? 'An error occurred when attempting to load users.'
                    );
            } finally {
                setLoading(false);
            }
        })();
    }, [deletingType]);

    const handleUrlChange = (query = {}) => {
        navigate(
            `${location.pathname}?${queryString.stringify({
                ...queryString.parse(location.search),
                page: undefined,
                ...query,
            })}`
        );
    };

    return (
        <Fragment>
            <Command>
                <Command.Breadcrumbs>
                    <Command.Breadcrumbs.Breadcrumb text="Users" link="/users" />
                    <Command.Breadcrumbs.Breadcrumb text="Types" />
                </Command.Breadcrumbs>

                <Command.Action text="Create" icon="list" link="/users/types/create" />
            </Command>

            {loading || !!types.length ? (
                <Grid>
                    <Card>
                        <Card.Content>
                            <List loading={loading}>
                                {types.map(({ name, _id, id, disabled }) => (
                                    <List.Item key={_id} link={`/users/types/${_id}`}>
                                        <List.Item.Title text={name} />
                                        <List.Item.Description text={id} />
                                    </List.Item>
                                ))}
                            </List>
                        </Card.Content>
                    </Card>
                    {!loading && (
                        <Fragment>
                            <Divider />
                            <Pagination
                                meta
                                onChange={page => handleUrlChange({ page })}
                                pageTotal={types?.length}
                                total={total}
                                page={page}
                                perPage={10}
                                metaLabel="Products"
                            />
                        </Fragment>
                    )}
                </Grid>
            ) : (
                <Empty title="Hold tight" text="You don't have any types yet!" />
            )}
        </Fragment>
    );
};

export default withSnackbar(Types);
