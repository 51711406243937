import React, { Fragment, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Location } from '@reach/router';
import objectPath from 'object-path';
import * as objectPathImmutable from 'object-path-immutable';
import { parseISO, format } from 'date-fns';

import Command from '@components/Command';
import Loader from '@components/Loader';
import Panes from '@components/Panes';
import Error from '@components/Error';

import styles from './styles.module.scss';
import Field from './Field';
import List from './List';
import { makePostRequest } from '@helpers/requests';
import { EDITOR_EXPLORER_FILE } from '@helpers/api';
import { withSnackbar } from '@components/Snackbar';

const File = ({ loading, data, error, path, onChange: handleChange, location, openSnackbar }) => {
    const { lastEditor, folder, file, fields = [] } = data || {};

    const handleSubmit = async (values, actions) => {
        try {
            await makePostRequest(
                EDITOR_EXPLORER_FILE,
                {
                    fields: values,
                    body: undefined,
                },
                { folder: folder ? folder.id : undefined, file: file.id }
            );
        } catch (error) {
            error !== 'cancelled' &&
                openSnackbar(error?.errorMessage ?? 'An error occurred loading this file.');
        }
    };

    const getFlatFields = (currentFields = fields, acc = {}) => {
        currentFields.forEach(({ type, fields, value, path, ...data }) => {
            if (type === 'list') {
                if (Array.isArray(fields)) {
                    fields.forEach(item => getFlatFields(item, acc));
                }
            }

            acc[path.join('.')] = { type, fields, value, path, ...data };
        });

        return acc;
    };

    const getValueFields = (currentFields = fields, acc = {}) => {
        currentFields.forEach(({ value, path }) => {
            objectPath.set(acc, path, value);
        });

        return acc;
    };

    const getPathFields = (currentPath = path, acc = []) => {
        if (!currentPath) return [];

        const currentField = flatFields[currentPath.join('.')];

        if (!currentField) return [];

        // -2 as a list will always have a index value before itself, we only ever want to track list property names
        const parentPath = currentPath.slice(0, currentPath.length - 2);

        acc.push(currentField);

        if (parentPath) {
            getPathFields(parentPath, acc);
        }

        return acc;
    };

    const renderStringValidation = ({ label, optional, min, max }) => {
        let value = Yup.string();
        if (max) value = value.max(max, `${label} can't be longer than ${max} characters.`);
        if (min) value = value.min(min, `${label} can't be less than ${min} characters.`);
        if (!optional) value = value.required(`${label} is required.`);
        return value;
    };
    const renderNumberValidation = ({ label, optional, min, max, positive, decimals }) => {
        let value = Yup.number();
        if (max) value = value.max(max, `${label} can't be higher than ${max}.`);
        if (min) value = value.min(min, `${label} can't be less than ${min}.`);
        if (positive) value = value.positive(`${label} must be a positive number.`);
        if (!decimals) value = value.integer(`${label} can't have decimals.`);
        if (!optional) value = value.required(`${label} is required.`);
        return value;
    };
    const renderValidationImage = ({ id, multiple, min, max, label, optional }) => {
        let value;

        if (multiple) {
            let media = Yup.string();
            value = Yup.array().of(media);
            if (max) value = value.max(max, `${label} can't have more than ${max} images.`);
            if (min) value = value.min(min, `${label} can't have less than ${min} images.`);
            if (!optional) media = media.required(`${label} is required.`);
        } else {
            value = Yup.string();
        }

        if (!optional) value = value.required(`${label} is required.`);
        return value;
    };
    const renderValidationList = ({ validation, label, optional, max, min }) => {
        const validationFields = {};

        validation.forEach(validationItem => {
            validationFields[validationItem.id] = renderValidationField(validationItem);
        });

        let value = Yup.array().of(Yup.object().shape(validationFields));
        if (max) value = value.max(max, `${label} can't have more than ${max} items.`);
        if (min) value = value.min(min, `${label} can't have less than ${min} items.`);
        if (!optional) value = value.required(`${label} is required.`);
        return value;
    };
    const renderValidationField = ({ type, ...field }) => {
        if (type === 'string' || type === 'text') return renderStringValidation(field);
        if (type === 'number') return renderNumberValidation(field);
        if (type === 'list') return renderValidationList(field);
        if (type === 'image') return renderValidationImage(field);

        return undefined;
    };
    const renderValidation = (currentFields = fields) => {
        const schema = {};

        Object.keys(currentFields).forEach(key => {
            schema[currentFields[key].id] = renderValidationField(currentFields[key]);
        });

        return Yup.object().shape(schema);
    };

    const flatFields = getFlatFields();
    const valueFields = getValueFields();
    const pathFields = getPathFields();

    const findFieldValue = path => {
        if (!Array.isArray(path)) return;
        return flatFields[path.join('.')];
    };

    const fieldExists = path => {
        if (!Array.isArray(path)) return;
        return flatFields.hasOwnProperty(path.join('.'));
    };

    const formatPath = path =>
        path.reduce((acc, curr) => {
            if (typeof curr === 'number') return `${acc}[${curr}]`;
            return `${acc ? `${acc}.` : ''}${curr}`;
        }, '');

    // update to a list of fields to the current active fields
    // we use this to add, delete and re-order fields
    const handleSetActiveFields = newFields => {
        handleChange({
            ...data,
            fields: objectPathImmutable.set(
                fields,
                [...activeField.internalPath, 'fields'],
                newFields
            ),
        });
    };

    const activeField = findFieldValue(path);

    return (
        <div className={styles.file}>
            {error ||
                (loading && (
                    <Command>
                        <Command.Breadcrumbs>
                            <Command.Breadcrumbs.Breadcrumb text="Editor" />
                        </Command.Breadcrumbs>
                    </Command>
                ))}

            {error ? (
                <Error error={error} />
            ) : loading ? (
                <Loader />
            ) : !!fields ? (
                <Formik
                    validationSchema={renderValidation()}
                    initialValues={valueFields}
                    onSubmit={handleSubmit}
                >
                    {({ handleSubmit, isSubmitting, values, errors, touched, ...props }) => {
                        const data = {
                            activeField,
                            paths: path,
                            pathFields,
                            ...props,
                        };

                        return (
                            <Fragment>
                                <Command>
                                    <Command.Breadcrumbs>
                                        <Command.Breadcrumbs.Breadcrumb text="Editor" />
                                        <Fragment>
                                            {!!folder && (
                                                <Command.Breadcrumbs.Breadcrumb
                                                    key={folder.id}
                                                    text={folder.label}
                                                    link={`/editor?folder=${folder.id}`}
                                                />
                                            )}
                                            {!!file && (
                                                <Command.Breadcrumbs.Breadcrumb
                                                    key={file.id}
                                                    text={file.label}
                                                    link={
                                                        !!path.length
                                                            ? `/editor?${
                                                                  folder
                                                                      ? `folder=${folder.id}&`
                                                                      : ''
                                                              }file=${file.id}`
                                                            : null
                                                    }
                                                />
                                            )}
                                        </Fragment>
                                    </Command.Breadcrumbs>

                                    <Command.Action
                                        text="Save"
                                        icon="folder-upload"
                                        onClick={handleSubmit}
                                        submitting={isSubmitting}
                                    />
                                </Command>

                                <Panes>
                                    <Panes.Left noPadding>
                                        {!!lastEditor && (
                                            <div
                                                className={`${styles.lastEditor} ${
                                                    !!activeField ? styles.lastEditorBlurred : ''
                                                }`}
                                            >
                                                <div
                                                    className={styles.lastEditorAvatar}
                                                    style={{
                                                        backgroundImage: `url(${lastEditor.avatar})`,
                                                    }}
                                                />
                                                <div className={styles.lastEditorContent}>
                                                    <p
                                                        className={styles.lastEditorTimestamp}
                                                    >{`Last Edited on ${format(
                                                        parseISO(lastEditor.timestamp),
                                                        'PPP p'
                                                    )}`}</p>
                                                    <p
                                                        className={styles.lastEditorUser}
                                                    >{`By ${lastEditor.firstName} ${lastEditor.lastName} - ${lastEditor.email}`}</p>
                                                </div>
                                            </div>
                                        )}

                                        <div
                                            className={`${styles.fields} ${
                                                !!activeField ? styles.fieldsBlurred : ''
                                            }`}
                                        >
                                            {fields.map((field, index) => (
                                                <Field
                                                    key={`${field.id}_${index}`}
                                                    field={field}
                                                    {...data}
                                                    name={formatPath(field?.path)}
                                                    value={values[field.path]}
                                                    touched={touched[field.path]}
                                                    error={errors[field.path]}
                                                    formatPath={formatPath}
                                                />
                                            ))}
                                        </div>
                                    </Panes.Left>

                                    {!!activeField && (
                                        <Panes.Right className={styles.listPane} noPadding>
                                            <List
                                                {...activeField}
                                                {...data}
                                                setFields={handleSetActiveFields}
                                                values={values}
                                                touched={touched}
                                                errors={errors}
                                                formatPath={formatPath}
                                            />
                                        </Panes.Right>
                                    )}
                                </Panes>
                            </Fragment>
                        );
                    }}
                </Formik>
            ) : (
                <Error text="Looks like this file doesn't exist" />
            )}
        </div>
    );
};

export default withSnackbar(props => (
    <Location>{locationContext => <File {...locationContext} {...props} />}</Location>
));
