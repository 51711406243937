import React, { useEffect, useState, Fragment } from 'react';

import { Divider, Grid } from '@components/Grid';
import { makeGetRequest } from '@helpers/requests';
import { STORE_FIELDS } from '@helpers/api';
import Card from '@components/Card';
import Command from '@components/Command';
import Empty from '@components/Empty';

import { withSnackbar } from '@components/Snackbar';

import List from '@components/List';
import queryString from 'query-string';
import Pagination from '@components/Pagination';
import { navigate } from 'gatsby-link';
import Text from '@components/Text';

const Products = ({ openSnackbar, location }) => {
	const params = queryString.parse(location.search, { parseNumbers: true });
	const page = params.page ?? 1;
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState(null);
	const [fields, setFields] = useState([]);

	useEffect(() => {
		(async () => {
			try {
				const { data: fieldsData } = await makeGetRequest(STORE_FIELDS, {
					perPage: 10,
					pageNum: page,
				});
				setTotal(fieldsData?.meta?.total);
				setFields(fieldsData?.results);
			} catch (error) {
				error !== 'cancelled' &&
					openSnackbar(
						error?.errorMessage ?? 'An error occurred when attempting to load fields.'
					);
			} finally {
				setLoading(false);
			}
		})();
	}, [page]);

	const handleUrlChange = (query = {}) => {
		navigate(
			`${location.pathname}?${queryString.stringify({
				...queryString.parse(location.search),
				page: undefined,
				...query,
			})}`
		);
	};

	return (
		<Fragment>
			<Command>
				<Command.Breadcrumbs>
					<Command.Breadcrumbs.Breadcrumb text="Store" link="/store" />
					<Command.Breadcrumbs.Breadcrumb text="Fields" />
				</Command.Breadcrumbs>

				<Command.Action text="Create" icon="list" link={`/store/fields/create`} />
			</Command>

			{loading || !!fields?.length ? (
				<Grid>
					<Card>
						<Card.Content>
							<List loading={loading}>
								{fields?.map(({ label, _id, hint, id, disabled }) => (
									<List.Item key={_id} link={`/store/fields/${_id}`}>
										<List.Item.Column>
											<Text bold>{label}</Text>
											<Text>{hint}</Text>
											<Text faded>{id}</Text>
										</List.Item.Column>
									</List.Item>
								))}
							</List>
						</Card.Content>
					</Card>

					{!loading && (
						<Fragment>
							<Divider />
							<Pagination
								meta
								onChange={page => handleUrlChange({ page })}
								pageTotal={fields?.length}
								total={total}
								page={page}
								perPage={10}
								metaLabel="Products"
							/>
						</Fragment>
					)}
				</Grid>
			) : (
				<Empty title="Hold tight" text="You don't have any fields yet!" />
			)}
		</Fragment>
	);
};

export default withSnackbar(Products);
