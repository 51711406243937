import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { HeightContext } from '@helpers/contexts';

import styles from './styles.module.scss';

export default ({ height: strictHeight }) => (
    <HeightContext.Consumer>
        {({ height } = {}) => (
            <div className={styles.loading} style={{ height: strictHeight || height }}>
                <FontAwesomeIcon icon={['fad', 'circle-notch']} spin />
            </div>
        )}
    </HeightContext.Consumer>
);
