import { combineReducers } from 'redux';

import UserReducer from './user';
import WebsiteReducer from './website';
import HelpReducer from './help';

export default combineReducers({
    user: UserReducer,
    website: WebsiteReducer,
    help: HelpReducer,
});
