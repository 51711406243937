import React, { Fragment, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';

import styles from './styles.module.scss';
import { LayoutContext } from '@helpers/contexts';

const Tooltip = ({ className = '', text, children, placement = 'bottom' }) => {
    const [open, setOpen] = useState(false);

    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement);

    return (
        <LayoutContext.Consumer>
            {({ $app } = {}) => (
                <Fragment>
                    <div
                        className={className}
                        ref={setReferenceElement}
                        onMouseEnter={() => setOpen(true)}
                        onMouseLeave={() => setOpen(false)}
                    >
                        {children}
                    </div>

                    {open &&
                        ReactDOM.createPortal(
                            <div
                                className={styles.tooltipWrapper}
                                ref={setPopperElement}
                                style={popperStyles.popper}
                                {...attributes.popper}
                            >
                                <div className={`${styles.tooltip}`}>{text}</div>
                            </div>,
                            $app?.current
                        )}
                </Fragment>
            )}
        </LayoutContext.Consumer>
    );
};

export default Tooltip;
