import React, { Fragment, useEffect, useState } from 'react';
import { withSnackbar } from '@components/Snackbar';
import {
    makeDeleteRequest,
    makeGetRequest,
    makePostRequest,
    makePutRequest,
} from '@helpers/requests';
import { USER, USER_TYPE, USER_TYPES, USERS } from '@helpers/api';
import Command from '@components/Command';
import { navigate } from 'gatsby-link';
import queryString from 'query-string';
import { Col, Divider, Grid, Row } from '@components/Grid';
import List from '@components/List';
import { format, parseISO } from 'date-fns';
import Modal from '@components/Modal';
import { Formik } from 'formik';
import { Link } from 'gatsby';
import Button from '@components/Button';
import * as Yup from 'yup';
import Input from '@components/Input';
import Alert from '@components/Alert';
import Loader from '@components/Loader';
import Panes from '@components/Panes';
import { useSelector } from 'react-redux';
import Switch from '@components/Switch';

const Type = ({ openSnackbar, id }) => {
    const user = useSelector(({ user }) => user);
    const [loading, setLoading] = useState(true);
    const [type, setType] = useState(null);
    const [deletingType, setDeletingType] = useState(false);
    const [users, setUsers] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                if (id) {
                    const { data: typeData } = await makeGetRequest(USER_TYPE(id));
                    setType(typeData);
                }

                const { data: typeUsagesData } = await makeGetRequest(USERS, {
                    type: id,
                    perPage: 5,
                    pageNum: 1,
                });

                typeUsagesData?.meta?.total && setUsers(typeUsagesData);
            } catch (error) {
                error !== 'cancelled' &&
                    openSnackbar(
                        error?.errorMessage ?? 'An error occurred attempting to load this type.'
                    );
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const handleDeleteType = async () => {
        try {
            const { name, _id } = type;
            await makeDeleteRequest(USER_TYPE(_id));
            setDeletingType(false);
            openSnackbar(`Successfully deleted type - ${name}`);
            navigate('/users/types');
        } catch (error) {
            error !== 'cancelled' &&
                openSnackbar(
                    error?.errorMessage ??
                        'An error occurred when attempting to delete a user type.'
                );
        }
    };

    const handleSubmitType = async ({ disabled = false, id, name, private: _private }) => {
        try {
            const { data: typeData } = !!type
                ? await makePutRequest(USER_TYPE(type?._id), {
                      disabled,
                      id,
                      name,
                      private: _private,
                  })
                : await makePostRequest(USER_TYPES, { disabled, id, name, private: _private });

            setType(typeData);
            navigate(`/users/types/${typeData?._id}`);
            openSnackbar(`${typeData?.name ?? ''} type has been successfully edited.`);
        } catch (error) {
            error !== 'cancelled' &&
                openSnackbar(
                    error?.errorMessage ?? `An error occurred when attempting to edit this type.`
                );
        }
    };

    return (
        <Fragment>
            <Command>
                <Command.Breadcrumbs>
                    <Command.Breadcrumbs.Breadcrumb text="Users" link="/users" />
                    <Command.Breadcrumbs.Breadcrumb text="Types" link="/users/types" />
                    <Command.Breadcrumbs.Breadcrumb text={type?.name ?? 'Type'} />
                </Command.Breadcrumbs>
            </Command>

            {loading ? (
                <Loader />
            ) : (
                <Formik
                    initialValues={{
                        disabled: type?.disabled,
                        id: type?.id,
                        name: type?.name ?? '',
                        private: type?.private,
                    }}
                    validationSchema={Yup.object().shape({
                        id: Yup.string().nullable(),
                        name: Yup.string().required('Name is required'),
                        disabled: Yup.boolean(),
                        private: Yup.boolean(),
                    })}
                    onSubmit={handleSubmitType}
                >
                    {({
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                    }) => {
                        // private is a restricted word, underscored to be safe.
                        const { id, name, disabled, private: _private } = values;

                        return (
                            <Panes>
                                <Panes.Left>
                                    {!!user?.permissions?.isAdmin && (
                                        <Fragment>
                                            <Input
                                                optional
                                                name="id"
                                                label="Id"
                                                hint="Id is automatically generated if not specified"
                                                onChange={e =>
                                                    setFieldValue(
                                                        'id',
                                                        e.target.value
                                                            .toLowerCase()
                                                            .split(' ')
                                                            .join('-')
                                                    )
                                                }
                                                value={id}
                                            />
                                            {!!type?.id && (
                                                <Fragment>
                                                    <Divider />
                                                    <Alert
                                                        title="Editing an ID is dangerous!"
                                                        type="warning"
                                                        message="Make sure you know what you're doing, the corresponding website may be using this id to render a specific layout, changing this id may stop it from appearing."
                                                    />
                                                </Fragment>
                                            )}
                                            {touched.id && errors.id && (
                                                <Fragment>
                                                    <Divider />
                                                    <Alert type="error" message={errors.id} />
                                                </Fragment>
                                            )}
                                            <Divider />
                                        </Fragment>
                                    )}

                                    <Input
                                        name="name"
                                        label="Name"
                                        value={name}
                                        onChange={handleChange}
                                    />
                                    {touched.type && errors.type && (
                                        <Fragment>
                                            <Divider margin={2} />
                                            <Alert type="error" message={errors.type} />
                                        </Fragment>
                                    )}

                                    {!!user?.permissions?.isAdmin && (
                                        <Fragment>
                                            <Divider />
                                            <Switch
                                                label="Disabled"
                                                hint="Prevent non-admins from editing fundamental fields."
                                                name="disabled"
                                                checked={disabled}
                                                onChange={(e, checked) =>
                                                    setFieldValue('disabled', checked)
                                                }
                                            />
                                        </Fragment>
                                    )}

                                    <Divider />
                                    <Switch
                                        label="Private"
                                        hint="If turned on, you must approve all registrations to this user type manually."
                                        name="disabled"
                                        checked={_private}
                                        onChange={(e, checked) => setFieldValue('private', checked)}
                                    />

                                    <Divider />
                                    <Row end="xs">
                                        <Col xs={12}>
                                            {!!type &&
                                                (user?.permissions?.isAdmin || !type?.disabled) && (
                                                    <Button
                                                        submitting={deletingType}
                                                        danger
                                                        onClick={() => setDeletingType(true)}
                                                    >
                                                        Delete
                                                    </Button>
                                                )}
                                            <Button
                                                submitting={isSubmitting}
                                                success
                                                onClick={handleSubmit}
                                            >
                                                {!!type ? 'Save' : 'Create'}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Panes.Left>
                                <Panes.Right>
                                    <List>
                                        {(!!type || !!user?.permissions?.isAdmin) && (
                                            <List.Item>
                                                <List.Item.Column>
                                                    <List.Item.Title text="Id" />
                                                </List.Item.Column>
                                                <List.Item.Column.Right important>
                                                    <List.Item.Title small bold={false} text={id} />
                                                </List.Item.Column.Right>
                                            </List.Item>
                                        )}
                                        <List.Item>
                                            <List.Item.Column>
                                                <List.Item.Title text="Name" />
                                            </List.Item.Column>
                                            <List.Item.Column.Right important>
                                                <List.Item.Title small bold={false} text={name} />
                                            </List.Item.Column.Right>
                                        </List.Item>
                                        {!!type && (
                                            <List.Item>
                                                <List.Item.Column>
                                                    <List.Item.Title text="Added by" />
                                                </List.Item.Column>
                                                <List.Item.Column.Right important>
                                                    <List.Item.Title
                                                        small
                                                        bold={false}
                                                        text={type?.addedBy}
                                                    />
                                                </List.Item.Column.Right>
                                            </List.Item>
                                        )}
                                        <List.Item>
                                            <List.Item.Column>
                                                <List.Item.Title text="Disabled" />
                                            </List.Item.Column>
                                            <List.Item.Column.Right important>
                                                <List.Item.Checked checked={disabled} />
                                            </List.Item.Column.Right>
                                        </List.Item>
                                        <List.Item>
                                            <List.Item.Column>
                                                <List.Item.Title text="Private" />
                                            </List.Item.Column>
                                            <List.Item.Column.Right important>
                                                <List.Item.Checked checked={_private} />
                                            </List.Item.Column.Right>
                                        </List.Item>
                                        {!!type && (
                                            <List.Item>
                                                <List.Item.Column>
                                                    <List.Item.Title text="Created" />
                                                </List.Item.Column>
                                                <List.Item.Column.Right important>
                                                    <List.Item.Title
                                                        small
                                                        bold={false}
                                                        text={
                                                            !!type?.timestamp &&
                                                            format(
                                                                parseISO(type?.timestamp),
                                                                'PPP p'
                                                            )
                                                        }
                                                    />
                                                </List.Item.Column.Right>
                                            </List.Item>
                                        )}
                                    </List>
                                </Panes.Right>
                            </Panes>
                        );
                    }}
                </Formik>
            )}

            <Modal
                isOpen={deletingType}
                title={`Deleting type - ${type?.name}`}
                onClose={() => {
                    setDeletingType(false);
                }}
            >
                <Modal.Content>
                    <Formik initialValues={{}} onSubmit={handleDeleteType}>
                        {({ handleSubmit, isSubmitting }) => (
                            <Fragment>
                                {!!users ? (
                                    <Fragment>
                                        <p>
                                            Are you sure? Deleting this type{' '}
                                            <strong>
                                                will dissociate {users?.meta?.total ?? 0} users
                                            </strong>
                                            , {users?.results?.length} of these users are listed
                                            below.
                                        </p>
                                        <ul>
                                            {users?.results?.map(user => (
                                                <li key={user?._id}>
                                                    <Link to={`/users/user/${user?._id}`}>
                                                        {user?.firstName ?? ''}{' '}
                                                        {user?.lastName ?? ''}
                                                    </Link>
                                                </li>
                                            ))}
                                            {users?.meta?.total > 5 && (
                                                <li>and {users?.meta?.total - 5} more</li>
                                            )}
                                        </ul>
                                    </Fragment>
                                ) : (
                                    <p>Are you sure?</p>
                                )}
                                <Divider />
                                <Button
                                    text="Delete"
                                    danger
                                    onClick={handleSubmit}
                                    submitting={isSubmitting}
                                />
                                <Button
                                    text="Cancel"
                                    onClick={() => {
                                        setDeletingType(false);
                                    }}
                                />
                            </Fragment>
                        )}
                    </Formik>
                </Modal.Content>
            </Modal>
        </Fragment>
    );
};

export default withSnackbar(Type);
