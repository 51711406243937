import React from 'react';

import styles from './styles.module.scss';
import ComingSoonGraphic from '@images/coming-soon.svg';
import { HeightContext } from '@helpers/contexts';
import Button from '@components/Button';

const ComingSoon = () => (
    <HeightContext.Consumer>
        {({ height } = {}) => (
            <div className={styles.comingSoon} style={{ minHeight: height }}>
                <div className={styles.comingSoonIcon}>
                    <ComingSoonGraphic />

                    <span className={`${styles.circle} ${styles.circle1}`} />
                    <span className={`${styles.circle} ${styles.circle2}`} />
                    <span className={`${styles.circle} ${styles.circle3}`} />
                    <span className={`${styles.circle} ${styles.circle4}`} />
                    <span className={`${styles.circle} ${styles.circle5}`} />
                    <span className={`${styles.circle} ${styles.circle6}`} />
                </div>

                <h1>Coming soon</h1>
                <p>
                    You found something that we’re still working on. Next time you visit, it might
                    of arrived!
                </p>
                <Button text="Go home" link="/" />
            </div>
        )}
    </HeightContext.Consumer>
);

export default ComingSoon;
