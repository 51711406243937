import React, { Fragment, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';

import { useSnackbar } from '@components/Snackbar';
import {
    makeDeleteRequest,
    makeGetRequest,
    makePostRequest,
    makePutRequest,
} from '@helpers/requests';
import { PERMISSIONS, PERMISSIONS_ROLE, PERMISSIONS_ROLES } from '@helpers/api';
import Command from '@components/Command';
import { Divider } from '@components/Grid';
import Panes from '@components/Panes';
import List from '@components/List';
import Text from '@components/Text';
import queryString from 'query-string';
import { navigate } from 'gatsby-link';
import Modal from '@components/Modal';
import Button from '@components/Button';

import Wrapper from '@views/Settings/Permissions/Role/Wrapper';
import Form from '@views/Settings/Permissions/Role/Form';
import Loader from '@components/Loader';

const Permission = ({ id, location }) => {
    const $teamMember = useRef(null);
    const params = queryString.parse(location.search);
    const teamMemberId = params.teamMemberId;
    const [openSnackbar] = useSnackbar();
    const [permissions, setPermissions] = useState([]);
    const [role, setRole] = useState(null);

    const [loading, setLoading] = useState(true);
    // const prevId = usePrevious(id);

    useEffect(() => {
        (async () => {
            try {
                const { data: permissionsData } = await makeGetRequest(PERMISSIONS);
                setPermissions(permissionsData);

                if (!!id) {
                    const { data: roleData } = await makeGetRequest(PERMISSIONS_ROLE(id));
                    setRole(roleData);
                }

                if (!!teamMemberId) {
                    // TODO: Luke to add team member GET
                    // const { data: teamMemberData } = await makeGetRequest(TEAM_MEMBER(teamMemberId));
                    // setTeamMember(teamMemberData);
                }

                setLoading(false);
            } catch (error) {
                error !== 'cancelled' &&
                    openSnackbar(
                        error?.errorMessage ??
                            'An error occurred attempting to load your permissions.'
                    );
            }
        })();
    }, []);

    // useEffect(() => {
    //     (async () => {
    //         if (!!prevId && !!id && prevId !== id) {
    //             setLoading(true);
    //             const { data: roleData } = await makeGetRequest(PERMISSIONS_ROLE(id));
    //             setRole(roleData);
    //             setLoading(false);
    //         }
    //     })();
    // }, [id]);

    const handleSubmit = async ({ name, description, permissions }, actions) => {
        try {
            const data = {
                name: !!name ? name : undefined,
                description: !!description ? description : undefined,
                permissions,
            };

            const { data: roleData } = !!role?._id
                ? await makePutRequest(PERMISSIONS_ROLE(role._id), data)
                : await makePostRequest(PERMISSIONS_ROLES, data);

            openSnackbar(`${name} was successfully ${!!role?._id ? 'saved' : 'created'}.`);

            if (!role) {
                navigate(`/settings/permissions/role/${roleData?._id}`);
            }
        } catch (error) {
            error !== 'cancelled' &&
                openSnackbar(
                    error?.errorMessage ??
                        `An error occurred ${!!role?._id ? 'saving' : 'creating'} this role.`
                );
        }
    };

    return (
        <Fragment>
            <Command>
                <Command.Breadcrumbs>
                    <Command.Breadcrumbs.Breadcrumb text="Settings" />
                    <Command.Breadcrumbs.Breadcrumb
                        text="Permissions"
                        link="/settings/permissions"
                    />
                    <Command.Breadcrumbs.Breadcrumb text="Role" />
                </Command.Breadcrumbs>
            </Command>

            {loading ? (
                <Loader />
            ) : (
                <Wrapper role={role} onSubmit={handleSubmit}>
                    {props => {
                        const { values } = props;
                        const { name, description } = values;

                        return (
                            <Panes>
                                <Panes.Left>
                                    <Form permissions={permissions} {...props} />
                                </Panes.Left>
                                <Panes.Right>
                                    <Fragment>
                                        <List>
                                            <List.Item>
                                                <List.Item.Column>
                                                    <Text bold>Name</Text>
                                                </List.Item.Column>
                                                <List.Item.Column.Right important>
                                                    <Text empty={!name}>{name}</Text>
                                                </List.Item.Column.Right>
                                            </List.Item>
                                            <List.Item>
                                                <List.Item.Column>
                                                    <Text bold>Description</Text>
                                                </List.Item.Column>
                                                <List.Item.Column.Right important>
                                                    <Text empty={!description}>{description}</Text>
                                                </List.Item.Column.Right>
                                            </List.Item>
                                        </List>
                                        <Divider />
                                        {!!values.permissions?.length && (
                                            <List>
                                                <List.Title text="Permissions" />
                                                {permissions
                                                    ?.filter(
                                                        ({ name }) =>
                                                            name ===
                                                            values.permissions.some(
                                                                permission => permission === name
                                                            )
                                                    )
                                                    ?.map(permission => (
                                                        <List.Item>
                                                            <List.Item.Column>
                                                                <Text bold>Name</Text>
                                                                <Text faded>
                                                                    Maecenas sed diam eget risus
                                                                    varius blandit sit amet non
                                                                    magna.
                                                                </Text>
                                                            </List.Item.Column>
                                                        </List.Item>
                                                    ))}
                                            </List>
                                        )}
                                    </Fragment>
                                </Panes.Right>
                            </Panes>
                        );
                    }}
                </Wrapper>
            )}
        </Fragment>
    );
};

export default Permission;
