import React, { Fragment, useEffect, useRef, useState } from 'react';
import { withSnackbar } from '@components/Snackbar';

import { Col, Divider, Grid, Row } from '@components/Grid';
import Command from '@components/Command';
import { makeGetRequest, makePostRequest, makePutRequest } from '@helpers/requests';
import { SUBSCRIPTION_PLANS, SUBSCRIPTION } from '@helpers/api';
import { navigate } from 'gatsby-link';
import { useDispatch, useSelector } from 'react-redux';
import Steps from '@components/Steps';
import Loader from '@components/Loader';

import Choose from '@views/Settings/Billing/Checkout/Choose';
import Payment from '@views/Settings/Billing/Checkout/Payment';
import Card from '@components/Card';
import { capitalise } from '@helpers/index';
import { setWebsite } from '@actions/website';

const BillingCheckout = ({ openSnackbar }) => {
    const dispatch = useDispatch();
    const { user, website } = useSelector(({ user, website }) => ({ user, website }));
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [step, setStep] = useState('choose');
    const [plans, setPlans] = useState([]);
    const [planId, setPlanId] = useState(website.meta.type ?? null);
    const [planRecurringType, setPlanRecurringType] = useState('monthly');

    const plan = plans.find(({ id }) => id === planId);

    useEffect(() => {
        (async () => {
            try {
                const { data: plans } = await makeGetRequest(SUBSCRIPTION_PLANS);
                setPlans(plans);
                setLoading(false);
            } catch (error) {
                error !== 'cancelled' && openSnackbar(error?.errorMessage ?? 'An error occurred loading Braintree.');
            }
        })();
    }, []);

    const handlePlanSubmit = ({ planId, planRecurringType }) => {
        setPlanId(planId);
        setPlanRecurringType(planRecurringType);
        setStep('payment');
    };

    const handlePaymentSubmit = async ({ braintree, address, total }) => {
        // Reject request braintree fails validation
        try {
            setSubmitting(true);

            const { nonce } = await braintree.current.instance.requestPaymentMethod({
                amount: total.toFixed(2),
                email: user.email,
                billingAddress: {
                    givenName: user.firstName, // ASCII-printable characters required, else will throw a validation error
                    surname: user.lastName, // ASCII-printable characters required, else will throw a validation error
                    phoneNumber: user.phone && user.phone.number,
                    streetAddress: address.streetOne,
                    extendedAddress: address.streetTwo,
                    locality: address.city,
                    region: address.county,
                    postalCode: address.postcode,
                    countryCodeAlpha2: address.country,
                },
            });

            // Create object to send to server
            const data = {
                planId: plan.prices[planRecurringType].id,
                token: nonce,
                addressId: address._id,
            };

            // Create or change the subscription
            const { data: newWebsite } = website?.subscription?.subscriptionId
                ? await makePutRequest(SUBSCRIPTION, data)
                : await makePostRequest(SUBSCRIPTION, data);

            dispatch(setWebsite({ ...website, ...newWebsite }));

            openSnackbar(
                website?.subscription?.subscriptionId
                    ? `Your subscription was successfully changed to the ${plan.id} plan.`
                    : `You successfully subscribed to the ${plan.id} plan.`
            );

            navigate('/settings/billing');
        } catch (error) {
            setSubmitting(false);
            // clear payment methods
            braintree.current.instance.clearSelectedPaymentMethod();
            openSnackbar(
                error?.errorMessage ?? 'An error occurred submitting your billing details.'
            );
        }
    };

    const checkoutProps = {
        plans,
        plan,
        planId,
        planRecurringType,
        submitting,
    };

    const planTotalMonthlyInYear = (plan?.prices?.monthly?.price ?? 0) * 12;
    const planPercentage = parseInt(
        ((parseFloat(planTotalMonthlyInYear ?? 0) -
            parseFloat(plan?.prices?.annually?.price ?? 0)) /
            parseFloat(planTotalMonthlyInYear ?? 0)) *
            100
    );

    return (
        <Fragment>
            <Command>
                <Command.Breadcrumbs>
                    <Command.Breadcrumbs.Breadcrumb text="Settings" />
                    <Command.Breadcrumbs.Breadcrumb text="Billing" link="/settings/billing" />
                    <Command.Breadcrumbs.Breadcrumb text="Checkout" />
                </Command.Breadcrumbs>
            </Command>
            <Grid>
                {loading ? (
                    <Loader />
                ) : (
                    <Fragment>
                        <Row>
                            <Col xs={12} lg={8}>
                                <Steps current={step}>
                                    <Steps.Step
                                        name="choose"
                                        title={
                                            website?.subscription?.active
                                                ? `Change your plan`
                                                : `Select your plan`
                                        }
                                    />
                                    <Steps.Step name="payment" title="Payment" />
                                </Steps>

                                <Divider />

                                {step === 'choose' && (
                                    <Choose
                                        onChange={({ planId, planRecurringType }) => {
                                            setPlanId(planId);
                                            setPlanRecurringType(planRecurringType);
                                        }}
                                        onSubmit={handlePlanSubmit}
                                        {...checkoutProps}
                                    />
                                )}

                                {step === 'payment' && (
                                    <Payment
                                        onPrevious={() => setStep('choose')}
                                        onSubmit={handlePaymentSubmit}
                                        {...checkoutProps}
                                    />
                                )}
                            </Col>

                            <Col xs={12} lg={4}>
                                <Card>
                                    <Card.Position.Top.Left text="Plan" emphasis />
                                    <Card.Title empty={!plan} badgeColour="#000000">
                                        {plan && plan.name}
                                    </Card.Title>
                                    <Card.Content>
                                        {!plan && (
                                            <p>
                                                Select a membership type in either a monthly or
                                                annually recurring subscription.
                                            </p>
                                        )}

                                        {/*{typeHasFeatures && (*/}
                                        {/*    <Card.List title="Features" divider={false}>*/}
                                        {/*        {type.features.map(feature => (*/}
                                        {/*            <Card.List.Item key={feature}>*/}
                                        {/*                <Card.List.Item.Content>*/}
                                        {/*                    {feature}*/}
                                        {/*                </Card.List.Item.Content>*/}
                                        {/*            </Card.List.Item>*/}
                                        {/*        ))}*/}
                                        {/*    </Card.List>*/}
                                        {/*)}*/}

                                        <Card.List divider={false}>
                                            <Fragment>
                                                {plan &&
                                                    planRecurringType === 'annually' &&
                                                    planPercentage > 0 && (
                                                        <Card.List.Item label="Savings">
                                                            <Card.List.Item.Content>{`${planPercentage}%`}</Card.List.Item.Content>
                                                        </Card.List.Item>
                                                    )}
                                                <Card.List.Item label="Recurring">
                                                    <Card.List.Item.Content>
                                                        {capitalise(planRecurringType)}
                                                    </Card.List.Item.Content>
                                                </Card.List.Item>
                                                {plan && (
                                                    <Card.List.Item label="Total" bold>
                                                        <Card.List.Item.Content>
                                                            {plan &&
                                                                `£${plan.prices[planRecurringType].price}`}
                                                        </Card.List.Item.Content>
                                                    </Card.List.Item>
                                                )}
                                            </Fragment>
                                        </Card.List>
                                    </Card.Content>
                                </Card>
                            </Col>
                        </Row>
                    </Fragment>
                )}
            </Grid>
        </Fragment>
    );
};

export default withSnackbar(BillingCheckout);
