import React, { Fragment, useEffect, useState } from 'react';
import { useSnackbar } from '@components/Snackbar';
import { makeGetRequest } from '@helpers/requests';
import { PERMISSIONS_ROLES } from '@helpers/api';
import { Divider, Grid } from '@components/Grid';
import Command from '@components/Command';
import List from '@components/List';
import Text from '@components/Text';

const Permissions = () => {
    const [openSnackbar] = useSnackbar();
    const [roles, setRoles] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                const { data: rolesData } = await makeGetRequest(PERMISSIONS_ROLES);

                setRoles(rolesData);
                setLoading(false);
            } catch (error) {
                error !== 'cancelled' &&
                    openSnackbar(
                        error?.errorMessage ??
                            'An error occurred attempting to load your permissions.'
                    );
            }
        })();
    }, []);

    console.log({ roles });
    return (
        <Fragment>
            <Command>
                <Command.Breadcrumbs>
                    <Command.Breadcrumbs.Breadcrumb text="Settings" />
                    <Command.Breadcrumbs.Breadcrumb
                        text="Permissions"
                        link="/settings/permissions"
                    />
                </Command.Breadcrumbs>

                <Command.Action icon="plus" text="Create" link="/settings/permissions/role/create" />
            </Command>

            <Grid>
                <h1>Roles</h1>
                <List loading={loading}>
                    {roles?.standard?.map(({ _id, name, permissions, locked }) => (
                        <List.Item key={_id} link={`/settings/permissions/role/${_id}`}>
                            <List.Item.Column>
                                <Text bold>{name}</Text>
                            </List.Item.Column>
                            <List.Item.Column>
                                <Text>{`${permissions?.length} Permissions`}</Text>
                            </List.Item.Column>
                            <List.Item.Column>
                                <Text>Last updated on</Text>
                            </List.Item.Column>
                            {!locked && (
                                <List.Item.Actions>
                                    <List.Item.Actions.Action tooltip="Edit" icon="pen" />
                                    <List.Item.Actions.Action tooltip="Delete" icon="trash" />
                                </List.Item.Actions>
                            )}
                        </List.Item>
                    ))}
                </List>
                <Divider />

                {!!roles?.userSpecific?.length && (
                    <Fragment>
                        <h1>Team Member Roles</h1>
                        <List></List>
                    </Fragment>
                )}
            </Grid>
        </Fragment>
    );
};

export default Permissions;
