import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';

import List from '@components/List';
import Text from '@components/Text';
import { Divider } from '@components/Grid';

const Preview = ({
    discount,
    values: { active, name, code, type, limit, amount, startDate, endDate },
    categories,
    products,
    accountTypes,
    calcProductPriceDiscount,
}) => {
    const website = useSelector(({ website }) => website);
    const amountType =
        !!amount &&
        ((type === 'percentageOff' && `${(amount * 100 > 100 ? 100 : amount * 100).toFixed(2)}%`) ||
            (type === 'amountOff' && `-${website?.store?.currency?.symbol}${amount}`) ||
            (type === 'newAmount' && `${website?.store?.currency?.symbol}${amount}`));

    return (
        <Fragment>
            <List>
                {!!discount?._id && (
                    <List.Item>
                        <List.Item.Column>
                            <Text bold>Id</Text>
                        </List.Item.Column>
                        <List.Item.Column.Right important>
                            <Text>{discount?._id}</Text>
                        </List.Item.Column.Right>
                    </List.Item>
                )}
                <List.Item>
                    <List.Item.Column>
                        <Text bold>Active</Text>
                    </List.Item.Column>
                    <List.Item.Column.Right important>
                        <List.Item.Checked checked={!!active} />
                    </List.Item.Column.Right>
                </List.Item>
                <List.Item>
                    <List.Item.Column>
                        <Text bold>Name</Text>
                    </List.Item.Column>
                    <List.Item.Column.Right important>
                        <Text>{name}</Text>
                    </List.Item.Column.Right>
                </List.Item>
                {!!code && (
                    <List.Item>
                        <List.Item.Column>
                            <Text bold>Code</Text>
                        </List.Item.Column>
                        <List.Item.Column.Right important>
                            <Text>{code}</Text>
                        </List.Item.Column.Right>
                    </List.Item>
                )}
                <List.Item>
                    <List.Item.Column>
                        <Text bold>Type</Text>
                    </List.Item.Column>
                    <List.Item.Column.Right important>
                        <Text>
                            {(type === 'percentageOff' && 'Percentage off') ||
                                (type === 'amountOff' && 'Amount off') ||
                                (type === 'newAmount' && 'New amount') ||
                                ''}
                        </Text>
                    </List.Item.Column.Right>
                </List.Item>
                {!!limit && (
                    <List.Item>
                        <List.Item.Column>
                            <Text bold>Limit</Text>
                        </List.Item.Column>
                        <List.Item.Column.Right important>
                            <Text>{limit}</Text>
                        </List.Item.Column.Right>
                    </List.Item>
                )}
                <List.Item>
                    <List.Item.Column>
                        <Text bold>Amount</Text>
                    </List.Item.Column>
                    {!!amount && (
                        <List.Item.Column.Right important>{amountType}</List.Item.Column.Right>
                    )}
                </List.Item>
                {!!startDate && (
                    <List.Item>
                        <List.Item.Column>
                            <Text bold>Start date</Text>
                        </List.Item.Column>
                        <List.Item.Column.Right important>
                            <Text>{format(parseISO(startDate), 'PPP')}</Text>
                        </List.Item.Column.Right>
                    </List.Item>
                )}
                {!!endDate && (
                    <List.Item>
                        <List.Item.Column>
                            <Text bold>End date</Text>
                        </List.Item.Column>
                        <List.Item.Column.Right important>
                            <Text>{format(parseISO(endDate), 'PPP')}</Text>
                        </List.Item.Column.Right>
                    </List.Item>
                )}
            </List>

            {!!categories.length && (
                <Fragment>
                    <Divider />
                    <List>
                        <List.Title text="Categories" />
                        {categories.map(({ _id, name }) => (
                            <List.Item key={_id} link={`/store/categories/${_id}`}>
                                <List.Item.Column>
                                    <Text bold>{name}</Text>
                                    <Text faded>{_id}</Text>
                                </List.Item.Column>
                            </List.Item>
                        ))}
                    </List>
                </Fragment>
            )}

            {!!products.length && (
                <Fragment>
                    <Divider />
                    <List>
                        <List.Title text="Products" />
                        {products.map(({ _id, name, category, images, price }) => (
                            <List.Item key={_id} link={`/store/products/${_id}`}>
                                <List.Item.Avatar alt={name} src={images[0]?.url} />
                                <List.Item.Column>
                                    <Text bold>{name}</Text>
                                    <Text>{`${category?.name} • ${
                                        website?.store?.currency?.symbol
                                    }${(price / 100).toFixed(2)}${
                                        !!amount
                                            ? ` • ${calcProductPriceDiscount(price)} after discount`
                                            : ''
                                    }`}</Text>
                                    <Text faded>{_id}</Text>
                                </List.Item.Column>
                            </List.Item>
                        ))}
                    </List>
                </Fragment>
            )}

            {!!accountTypes.length && (
                <Fragment>
                    <Divider />
                    <List>
                        <List.Title text="Account types" />
                        {accountTypes.map(({ _id, name }) => (
                            <List.Item key={_id} link={`/store/products/${_id}`}>
                                <List.Item.Column>
                                    <Text bold>{name}</Text>
                                    <Text faded>{_id}</Text>
                                </List.Item.Column>
                            </List.Item>
                        ))}
                    </List>
                </Fragment>
            )}
        </Fragment>
    );
};

export default Preview;
