import React, { useEffect, useState, Fragment } from 'react';
import * as Yup from 'yup';

import Loader from '@components/Loader';
import { Col, Divider, Grid, Row } from '@components/Grid';
import {
    makeDeleteRequest,
    makeGetRequest,
    makePostRequest,
    makePutRequest,
} from '@helpers/requests';
import { STORE_CATEGORIES, STORE_CATEGORY, STORE_PRODUCTS } from '@helpers/api';
import Card from '@components/Card';
import Command from '@components/Command';
import Empty from '@components/Empty';
import Input from '@components/Input';

import { withSnackbar } from '@components/Snackbar';
import Modal from '@components/Modal';
import { Formik } from 'formik';

import Button from '@components/Button';
import Alert from '@components/Alert';
import List from '@components/List';
import { Link } from 'gatsby';
import { navigate } from 'gatsby-link';
import queryString from 'query-string';
import Pagination from '@components/Pagination';
import { format, parseISO } from 'date-fns';
import Panes from '@components/Panes';
import { useSelector } from 'react-redux';
import Switch from '@components/Switch';

const Products = ({ openSnackbar, id }) => {
    const user = useSelector(({ user }) => user);
    const params = queryString.parse(location.search, { parseNumbers: true });
    const page = params?.page ?? 1;
    const [loading, setLoading] = useState(true);
    const [category, setCategory] = useState(null);
    const [products, setProducts] = useState(null);
    const [deletingCategory, setDeletingCategory] = useState(false);

    const handleUrlChange = (query = {}) => {
        navigate(
            `${location.pathname}?${queryString.stringify({
                ...queryString.parse(location.search),
                page: undefined,
                ...query,
            })}`
        );
    };

    useEffect(() => {
        (async () => {
            try {
                if (id) {
                    const { data: categoryData } = await makeGetRequest(STORE_CATEGORY(id));
                    setCategory(categoryData);
                }
            } catch (error) {
                error !== 'cancelled' &&
                    openSnackbar(
                        error?.errorMessage ??
                            'An error occurred when attempting to load this category.'
                    );
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        if (!id) return;

        (async () => {
            try {
                const { data: productsData } = await makeGetRequest(STORE_PRODUCTS, {
                    category: id,
                    perPage: 10,
                    pageNum: page,
                });

                setProducts(productsData);
            } catch (error) {
                error !== 'cancelled' &&
                    openSnackbar(
                        error?.errorMessage ??
                            'An error occurred when attempting to load this categories products.'
                    );
            }
        })();
    }, [page]);

    const handleDeleteCategory = async () => {
        try {
            const { name, _id } = category;
            await makeDeleteRequest(STORE_CATEGORY(_id));
            setDeletingCategory(false);
            openSnackbar(`Successfully deleted category - ${name}`);
            navigate('/store/categories');
        } catch (error) {
            error !== 'cancelled' &&
                openSnackbar(
                    error?.errorMessage ?? 'An error occurred when attempting to delete a category.'
                );
        }
    };

    const handleSubmit = async ({ disabled = false, id, name }) => {
        try {
            const { data: categoryData } = await (!!category
                ? makePutRequest(STORE_CATEGORY(category?._id), { disabled, id, name })
                : makePostRequest(STORE_CATEGORIES, { disabled, id, name }));
            setCategory(categoryData);
            navigate(`/store/categories/${categoryData?._id}`);
            openSnackbar(`Successfully edited category.`);
        } catch (error) {
            error !== 'cancelled' &&
                openSnackbar(
                    error?.errorMessage ??
                        `An error occurred when attempting to edit this category.`
                );
        }
    };

    return (
        <Fragment>
            <Command>
                <Command.Breadcrumbs>
                    <Command.Breadcrumbs.Breadcrumb text="Store" link="/store" />
                    <Command.Breadcrumbs.Breadcrumb text="Categories" link="/store/categories" />
                    <Command.Breadcrumbs.Breadcrumb text={category?.name ?? 'Category'} />
                </Command.Breadcrumbs>
            </Command>

            {loading ? (
                <Loader />
            ) : (
                <Formik
                    initialValues={{
                        disabled: category?.disabled,
                        id: category?.id,
                        name: category?.name,
                    }}
                    validationSchema={Yup.object().shape({
                        id: Yup.string().nullable(),
                        name: Yup.string().required('Name is required.'),
                        disabled: Yup.boolean(),
                    })}
                    onSubmit={handleSubmit}
                >
                    {({
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                    }) => {
                        const { id, name, disabled } = values;

                        return (
                            <Panes>
                                <Panes.Left>
                                    {!!user?.permissions?.isAdmin && (
                                        <Fragment>
                                            <Input
                                                optional
                                                name="id"
                                                label="Id"
                                                hint="Id is automatically generated if not specified"
                                                onChange={e =>
                                                    setFieldValue(
                                                        'id',
                                                        e.target.value
                                                            .toLowerCase()
                                                            .split(' ')
                                                            .join('-')
                                                    )
                                                }
                                                value={id}
                                            />
                                            {!!category?.id && (
                                                <Fragment>
                                                    <Divider />
                                                    <Alert
                                                        title="Editing an ID is dangerous!"
                                                        type="warning"
                                                        message="Make sure you know what you're doing, the corresponding website may be using this id to render a specific layout, changing this id may stop it from appearing."
                                                    />
                                                </Fragment>
                                            )}
                                            {touched.id && errors.id && (
                                                <Fragment>
                                                    <Divider />
                                                    <Alert type="error" message={errors.id} />
                                                </Fragment>
                                            )}
                                            <Divider />
                                        </Fragment>
                                    )}
                                    <Input
                                        name="name"
                                        label="Name"
                                        value={name}
                                        onChange={handleChange}
                                    />
                                    {touched.name && errors.name && (
                                        <Fragment>
                                            <Divider margin={2} />
                                            <Alert type="error" message={errors.name} />
                                        </Fragment>
                                    )}

                                    {!!user?.permissions?.isAdmin && (
                                        <Fragment>
                                            <Divider />
                                            <Switch
                                                label="Disabled"
                                                hint="Prevent non-admins from editing fundamental fields."
                                                name="disabled"
                                                checked={disabled}
                                                onChange={(e, checked) =>
                                                    setFieldValue('disabled', checked)
                                                }
                                            />
                                        </Fragment>
                                    )}

                                    <Divider />
                                    <Row end="xs">
                                        <Col xs={12}>
                                            {!!category &&
                                                (user?.permissions?.isAdmin ||
                                                    !category?.disabled) && (
                                                    <Button
                                                        submitting={deletingCategory}
                                                        danger
                                                        onClick={() => setDeletingCategory(true)}
                                                    >
                                                        Delete
                                                    </Button>
                                                )}
                                            <Button
                                                submitting={isSubmitting}
                                                success
                                                onClick={handleSubmit}
                                            >
                                                {!!category ? 'Save' : 'Create'}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Panes.Left>
                                <Panes.Right>
                                    <List>
                                        <List.Item>
                                            <List.Item.Column>
                                                <List.Item.Title text="Id" />
                                            </List.Item.Column>
                                            <List.Item.Column.Right important>
                                                <List.Item.Title small text={id} bold={false} />
                                            </List.Item.Column.Right>
                                        </List.Item>
                                        <List.Item>
                                            <List.Item.Column>
                                                <List.Item.Title text="Name" />
                                            </List.Item.Column>
                                            <List.Item.Column.Right important>
                                                <List.Item.Title small text={name} bold={false} />
                                            </List.Item.Column.Right>
                                        </List.Item>
                                        <List.Item>
                                            <List.Item.Column>
                                                <List.Item.Title text="Disabled" />
                                            </List.Item.Column>
                                            <List.Item.Column.Right important>
                                                <List.Item.Checked checked={disabled} />
                                            </List.Item.Column.Right>
                                        </List.Item>
                                        {!!category && (
                                            <List.Item>
                                                <List.Item.Column>
                                                    <List.Item.Title text="Created" />
                                                </List.Item.Column>
                                                <List.Item.Column.Right important>
                                                    <List.Item.Title
                                                        small
                                                        text={
                                                            !!category?.timestamp &&
                                                            format(
                                                                parseISO(category?.timestamp),
                                                                'PPP p'
                                                            )
                                                        }
                                                        bold={false}
                                                    />
                                                </List.Item.Column.Right>
                                            </List.Item>
                                        )}
                                        {!!category && (
                                            <List.Item>
                                                <List.Item.Column>
                                                    <List.Item.Title text="Added by" />
                                                </List.Item.Column>
                                                <List.Item.Column.Right important>
                                                    <List.Item.Title
                                                        small
                                                        text={category?.addedBy}
                                                        bold={false}
                                                    />
                                                </List.Item.Column.Right>
                                            </List.Item>
                                        )}
                                        {!!category && (
                                            <List.Item>
                                                <List.Item.Column>
                                                    <List.Item.Title text="Products" />
                                                </List.Item.Column>
                                                <List.Item.Column.Right important>
                                                    <List.Item.Title
                                                        small
                                                        text={`${products?.meta?.total ?? ''}`}
                                                        bold={false}
                                                    />
                                                </List.Item.Column.Right>
                                            </List.Item>
                                        )}
                                    </List>

                                    {loading ||
                                        (!!products?.meta?.total && !!category && (
                                            <Fragment>
                                                <Divider />
                                                <Card>
                                                    <Card.Title>Products</Card.Title>
                                                    <Card.Content>
                                                        <List loading={loading}>
                                                            {products?.results?.map(
                                                                ({ name, _id }) => (
                                                                    <List.Item
                                                                        key={_id}
                                                                        link={`/store/products/${_id}`}
                                                                    >
                                                                        <List.Item.Title
                                                                            text={name}
                                                                        />
                                                                        <List.Item.Description
                                                                            text={_id}
                                                                        />
                                                                    </List.Item>
                                                                )
                                                            )}
                                                        </List>
                                                    </Card.Content>
                                                </Card>

                                                {!loading && !!products?.meta?.total && (
                                                    <Fragment>
                                                        <Divider />
                                                        <Pagination
                                                            meta
                                                            onChange={page =>
                                                                handleUrlChange({ page })
                                                            }
                                                            pageTotal={products?.results?.length}
                                                            total={products?.meta?.total}
                                                            page={page}
                                                            perPage={10}
                                                            metaLabel="Products"
                                                        />
                                                    </Fragment>
                                                )}
                                            </Fragment>
                                        ))}
                                </Panes.Right>
                            </Panes>
                        );
                    }}
                </Formik>
            )}

            <Modal
                isOpen={deletingCategory}
                title={`Deleting category - ${category?.name}`}
                onClose={() => {
                    setDeletingCategory(false);
                }}
            >
                <Modal.Content>
                    <Formik initialValues={{}} onSubmit={handleDeleteCategory}>
                        {({ handleSubmit, isSubmitting }) => (
                            <Fragment>
                                {!!products ? (
                                    <Fragment>
                                        {products?.meta?.total ? (
                                            <p>
                                                Are you sure? Deleting this category{' '}
                                                <strong>
                                                    will dissociate {products?.meta?.total ?? 0}{' '}
                                                    products
                                                </strong>
                                                , {products?.products?.length} of these products are
                                                listed below.
                                            </p>
                                        ) : (
                                            <p>Are you sure?</p>
                                        )}

                                        <ul>
                                            {products?.products?.map(product => (
                                                <li>
                                                    <Link to={`/store/products/${product?._id}`}>
                                                        {product?.name}
                                                    </Link>
                                                </li>
                                            ))}
                                            {products?.meta?.total > 5 && (
                                                <li>and {products?.meta?.total - 5} more</li>
                                            )}
                                        </ul>
                                    </Fragment>
                                ) : (
                                    <p>Are you sure?</p>
                                )}
                                <Divider />
                                <Button
                                    text="Delete"
                                    danger
                                    onClick={handleSubmit}
                                    submitting={isSubmitting}
                                />
                                <Button
                                    text="Cancel"
                                    onClick={() => {
                                        setDeletingCategory(false);
                                    }}
                                />
                            </Fragment>
                        )}
                    </Formik>
                </Modal.Content>
            </Modal>
        </Fragment>
    );
};

export default withSnackbar(Products);
