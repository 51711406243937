import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import Field from '@views/Forms/Form/Field';
import styles from './styles.module.scss';
import { FastField } from 'formik';

const Fields = ({
    name,
    handleChange,
    setFieldValue,
    errors,
    touched,
    fields,
    onReorder,
    refs,
    isSameItem,
    onFocus,
    onChange,
    onDuplicate,
    onDelete,
    currentFocused,
}) => {
    return (
        <DragDropContext onDragEnd={result => onReorder(fields, result)}>
            <Droppable droppableId="section-fields-droppable">
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={`${styles.sectionFields} ${
                            snapshot.isDragging ? styles.sectionFieldsDragging : ''
                        }`}
                    >
                        {fields.map((field, index) => (
                            <Draggable
                                key={field?._id || field?.id}
                                draggableId={field?._id || field?.id}
                                isDragDisabled={fields.length === 1}
                                index={index}
                            >
                                {(provided, snapshot) => (
                                    <Field
                                        setFieldValue={setFieldValue}
                                        handleChange={handleChange}
                                        name={`${name}.${index}`}
                                        errors={errors?.[index]}
                                        touched={touched?.[index]}
                                        {...field}
                                        draggable={{ provided, snapshot }}
                                        isSameItem={isSameItem}
                                        currentFocused={currentFocused}
                                        field={field}
                                        total={fields?.length}
                                        id={field?._id || field?.id}
                                        refs={refs}
                                        onFocus={() => onFocus(field)}
                                        onChange={onChange}
                                        onDuplicate={onDuplicate}
                                        onDelete={onDelete}
                                    />
                                )}
                            </Draggable>
                        ))}

                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default Fields;
