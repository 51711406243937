import React, { useEffect, useState, Fragment } from 'react';

import { makeGetRequest } from '@helpers/requests';
import Command from '@components/Command';
import { Grid, Row, Col, Divider } from '@components/Grid';
import Card from '@components/Card';
import Loader from '@components/Loader';
import { format, parseISO } from 'date-fns';
import { STORE_ORDERS_ORDER } from '@helpers/api';
import { navigate } from 'gatsby-link';
import { connect } from 'react-redux';
import { withSnackbar } from '@components/Snackbar';
import List from '@components/List';
import Text from '@components/Text';

const Viewer = ({ website, id, openSnackbar }) => {
	const [loading, setLoading] = useState(true);
	const [order, setOrder] = useState(null);

	useEffect(() => {
		(async () => {
			try {
				const { data: order } = await makeGetRequest(STORE_ORDERS_ORDER(id));
				setOrder(order);
				setLoading(false);
			} catch (error) {
				error !== 'cancelled' &&
					openSnackbar(error?.errorMessage ?? 'An error occurred loading this order.');
			}
		})();
	}, []);

	return (
		<Fragment>
			<Command>
				<Command.Breadcrumbs>
					<Command.Breadcrumbs.Breadcrumb text="Store" link="/store" />
					<Command.Breadcrumbs.Breadcrumb text="Orders" link="/store/orders" />
					<Command.Breadcrumbs.Breadcrumb text="Order" />
				</Command.Breadcrumbs>
			</Command>
			{loading ? (
				<Loader />
			) : (
				<Grid>
					<Row>
						<Col xs={12}>
							<Card>
								<Card.Content>
									<Card.List>
										<Card.List.Item label="Id" value={order._id} />
										<Card.List.Item
											label="Created"
											value={format(parseISO(order.meta?.timestamp), 'PPP p')}
										/>
										{order.stage?.lastUpdated && (
											<Card.List.Item
												label="Last updated"
												value={format(
													parseISO(order.stage?.lastUpdated),
													'PPP p'
												)}
											/>
										)}
										{!!order?.meta?.discountCode && (
											<Card.List.Item
												label="Discount Code"
												value={order?.meta?.discountCode}
											/>
										)}
										{!!order?.meta?.discountedAmount && (
											<Card.List.Item
												label="Discounted Amount"
												value={`${website.store.currency.symbol}${order
													?.meta?.discountedAmount / 100}`}
											/>
										)}
										{!!order?.meta?.discountedType && (
											<Card.List.Item
												label="Discount Type"
												value={
													(order?.meta?.discountedType ===
														'percentageOff' &&
														'Percentage off') ||
													(order?.meta?.discountedType === 'amountOff' &&
														'Amount Off') ||
													(order?.meta?.discountedType === 'newAmount' &&
														'New amount') ||
													''
												}
											/>
										)}
										<Card.List.Item
											label="Currency"
											value={website.store.currency.code}
										/>
										<Card.List.Item label="Total" bold>
											<Card.List.Item.Content>
												<Text bold>{`${website.store.currency.symbol}${(
													order?.meta?.total / 100
												).toFixed(2)}`}</Text>
											</Card.List.Item.Content>
										</Card.List.Item>
									</Card.List>
								</Card.Content>
							</Card>
							<Divider />
							{(!!order.billing || !!order.shipping) && (
								<Row>
									<Col xs={12} xl={6}>
										{!!order.billing && (
											<Fragment>
												<Card>
													<Card.Title small>Billing</Card.Title>
													<Card.List>
														<Card.List.Item
															key="billing_added"
															label="Added"
															value={format(
																parseISO(order?.billing?.added),
																'PPP p'
															)}
														/>
														<Card.List.Item
															key="billing_name"
															label="Name"
															value={order?.billing?.fullName}
														/>
														<Card.List.Item
															key="billing_email"
															label="Email"
															value={order?.billing?.email}
														/>
														<Card.List.Item
															key="billing_phone"
															label="Phone Number"
														>
															<Card.List.Item.Content>
																<a
																	href={`tel:${order?.billing?.phone}`}
																>
																	{order?.billing?.phone}
																</a>
															</Card.List.Item.Content>
														</Card.List.Item>
														<Card.List.Item
															key="billing_address_street_one"
															label="Address One"
															value={order?.billing?.streetOne}
														/>
														{!!order?.billing?.streetTwo && (
															<Card.List.Item
																key="billing_address_street_two"
																label="Address Two"
																value={order?.billing?.streetTwo}
															/>
														)}
														<Card.List.Item
															key="billing_address_postcode"
															label="ZIP Code / Postal Code"
															value={order?.billing?.postcode}
														/>
														<Card.List.Item
															key="billing_city"
															label="City"
															value={order?.billing?.city}
														/>
														<Card.List.Item
															key="billing_county"
															label="County / Province"
															value={order?.billing?.county}
														/>
														<Card.List.Item
															key="billing_country"
															label="Country"
															value={order?.billing?.country}
														/>
													</Card.List>
												</Card>
												<Divider />
											</Fragment>
										)}
									</Col>
									<Col xs={12} xl={6}>
										{!!order.shipping && (
											<Fragment>
												<Card>
													<Card.Title small>Shipping</Card.Title>
													<Card.List>
														<Card.List.Item
															key="billing_added"
															label="Added"
															value={format(
																parseISO(order?.shipping?.added),
																'PPP p'
															)}
														/>
														<Card.List.Item
															key="billing_name"
															label="Name"
															value={order?.shipping?.fullName}
														/>
														<Card.List.Item
															key="billing_email"
															label="Email"
															value={order?.shipping?.email}
														/>
														<Card.List.Item
															key="billing_phone"
															label="Phone Number"
															value={
																<a
																	href={`tel:${order?.shipping?.phone}`}
																>
																	{order?.shipping?.phone}
																</a>
															}
														/>
														<Card.List.Item
															key="billing_address_street_one"
															label="Address One"
															value={order?.shipping?.streetOne}
														/>
														{!!order?.shipping?.streetTwo && (
															<Card.List.Item
																key="billing_address_street_two"
																label="Address Two"
																value={order?.shipping?.streetTwo}
															/>
														)}
														<Card.List.Item
															key="billing_address_postcode"
															label="ZIP Code / Postal Code"
															value={order?.shipping?.postcode}
														/>
														<Card.List.Item
															key="billing_city"
															label="City"
															value={order?.shipping?.city}
														/>
														<Card.List.Item
															key="billing_county"
															label="County / Province"
															value={order?.shipping?.county}
														/>
														<Card.List.Item
															key="billing_country"
															label="Country"
															value={order?.shipping?.country}
														/>
													</Card.List>
												</Card>
												<Divider />
											</Fragment>
										)}
									</Col>
								</Row>
							)}

							<Card>
								<Card.Title small>Products</Card.Title>
								<Card.Content>
									<List>
										{order?.products
											?.filter(product => !!product?.data)
											?.map(
												({
													quantity,
													data: { _id, name, description, price, images },
												}) => (
													<List.Item
														key={_id}
														title={`${quantity}x ${name}`}
														onClick={() =>
															navigate(`/store/products/${_id}`)
														}
													>
														<List.Item.Avatar
															alt={name}
															src={
																images &&
																Array.isArray(images) &&
																images.length
																	? images[0].url
																	: ''
															}
														/>

														<List.Item.Column>
															<p>
																<b>{name}</b>
															</p>
															<p>
																{description.length > 300
																	? `${description.slice(
																			0,
																			300
																	  )}...`
																	: description}
															</p>
															<p>
																{parseInt(price) === 0
																	? 'Free'
																	: `${
																			website.store.currency
																				.symbol
																	  }${(price / 100).toFixed(2)}`}
															</p>
														</List.Item.Column>
													</List.Item>
												)
											)}
									</List>
								</Card.Content>
							</Card>
							{/*{!!order.ua && (*/}
							{/*    <Card>*/}
							{/*        <Card.List title="User agent">*/}
							{/*            <Card.List.Item*/}
							{/*                label="Mobile"*/}
							{/*                value={order.ua.isMobile === 'true'}*/}
							{/*            />*/}
							{/*            <Card.List.Item*/}
							{/*                label="Desktop"*/}
							{/*                value={order.ua.isDesktop === 'true'}*/}
							{/*            />*/}
							{/*            <Card.List.Item*/}
							{/*                label="Bot"*/}
							{/*                value={order.ua.isBot === 'true'}*/}
							{/*            />*/}
							{/*            <Card.List.Item label="Browser" value={order.ua.browser} />*/}
							{/*            <Card.List.Item*/}
							{/*                label="Browser Version"*/}
							{/*                value={order.ua.version}*/}
							{/*            />*/}
							{/*            <Card.List.Item label="OS" value={order.ua.os} />*/}
							{/*            <Card.List.Item*/}
							{/*                label="Platform"*/}
							{/*                value={order.ua.platform}*/}
							{/*            />*/}
							{/*            <Card.List.Item label="Source" value={order.ua.source} />*/}
							{/*        </Card.List>*/}
							{/*    </Card>*/}
							{/*)}*/}
						</Col>
					</Row>
				</Grid>
			)}
		</Fragment>
	);
};

export default withSnackbar(connect(({ website }) => ({ website }))(Viewer));
