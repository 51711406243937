import React from 'react';

import styles from './styles.module.scss';

const Ellipsis = () => (
    <div className={styles.ellipsis}>
        <div className={styles.ellipsis1} />
        <div className={styles.ellipsis2} />
        <div className={styles.ellipsis3} />
    </div>
);

export default Ellipsis;
