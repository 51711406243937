import React, { Fragment } from 'react';
import { Row, Col, Grid } from '@components/Grid';
import Card from '@components/Card';

import styles from './styles.module.scss';
import List from '@components/List';

const Billing = () => {
    return (
        <Fragment>
            <h1 className={styles.heading}>Billing</h1>
            <Row>
                <Col xs={12} md={4} lg={3}>
                    <Grid>
                        <List></List>
                    </Grid>
                </Col>
            </Row>
        </Fragment>
    );
};

export default Billing;
