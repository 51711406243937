import React, { Fragment } from 'react';
import { parseISO, format } from 'date-fns';

import Card from '@components/Card';
import Command from '@components/Command';
import { Grid } from '@components/Grid';
import Error from '@components/Error';
import styles from './styles.module.scss';
import List from '@components/List';

const Folder = ({ loading, data, error, onSelect }) => {
    // if (!loading && (!data || !Array.isArray(data))) {
    //     return <p>No files yet...</p>;
    // }

    const { folder, contents = [] } = data || {};

    return (
        <div>
            <Command>
                <Command.Breadcrumbs>
                    <Command.Breadcrumbs.Breadcrumb text="Editor" />
                    {!loading && folder && <Command.Breadcrumbs.Breadcrumb text={folder.label} />}
                </Command.Breadcrumbs>
            </Command>

            {error ? (
                <Error error={error} />
            ) : (
                <Grid>
                    {/*<List loading={loading}>*/}
                    {/*    {*/}
                    {/*        contents.map(({ lastEditor, file, size }) => (*/}
                    {/*            <List.Item*/}
                    {/*                key={file.id}*/}
                    {/*                title={file.label}*/}
                    {/*                description={`${file.id} • ${size}`}*/}
                    {/*                onClick={() => onSelect({ folder, file })}>*/}
                    {/*                <List.Item.Column>*/}

                    {/*                </List.Item.Column>*/}
                    {/*            </List.Item>*/}
                    {/*        )*/}
                    {/*    }*/}
                    {/*</List>*/}

                    <Card loading={loading}>

                        <Card.List>
                            {loading ? (
                                <Card.List.Item title description>
                                    <Card.List.Item.Avatar />
                                    <Card.List.Item.Action />
                                    <Card.List.Item.Position.Bottom.Left emphasis />
                                </Card.List.Item>
                            ) : (
                                contents.map(({ lastEditor, file, size }) => (
                                    <Card.List.Item
                                        key={file.id}
                                        title={file.label}
                                        description={`${file.id} • ${size}`}
                                        onClick={() => onSelect({ folder, file })}
                                    >
                                        <Card.List.Item.Icon icon="file" />
                                        {!!lastEditor && (
                                            <Card.List.Item.Content.Right>
                                                <div className={styles.lastEditor}>
                                                    <div className={styles.lastEditorContent}>
                                                        <p
                                                            className={styles.lastEditorTimestamp}
                                                        >{`Last Edited on ${format(
                                                            parseISO(lastEditor.timestamp),
                                                            'PPP'
                                                        )}`}</p>
                                                        <p
                                                            className={styles.lastEditorUser}
                                                        >{`By ${lastEditor.firstName} ${lastEditor.lastName}`}</p>
                                                    </div>
                                                    <div
                                                        className={styles.lastEditorAvatar}
                                                        style={{
                                                            backgroundImage: `url(${lastEditor.avatar})`,
                                                        }}
                                                    />
                                                </div>
                                            </Card.List.Item.Content.Right>
                                        )}
                                    </Card.List.Item>
                                ))
                            )}
                        </Card.List>
                    </Card>
                </Grid>
            )}
        </div>
    );
};

export default Folder;
