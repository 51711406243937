import React, { Fragment, useEffect, useState } from 'react';
import Command from '@components/Command';
import { Divider, Grid } from '@components/Grid';
import { navigate } from 'gatsby-link';
import queryString from 'query-string';
import usePrevious from '@helpers/hooks/usePrevious';
import { makeGetRequest } from '@helpers/requests';
import { FORMS } from '@helpers/api';
import List from '@components/List';
import Empty from '@components/Empty';
import { withSnackbar } from '@components/Snackbar';
import Pagination from '@components/Pagination';
import Text from '@components/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@components/Tooltip';
import Icon from '@components/Icon';

const Forms = ({ location, openSnackbar }) => {
    const params = queryString.parse(location.search);
    const page = params?.page ?? 1;
    const query = params?.query;

    console.log({ query });

    const [loading, setLoading] = useState(true);
    const [forms, setForms] = useState([]);
    const [total, setTotal] = useState(null);
    const [queryTimeout, setQueryTimeout] = useState(null);
    const prevQuery = usePrevious(query);

    const setNewForms = async () => {
        try {
            if (!loading) setLoading(true);
            const { data: formsData } = await makeGetRequest(FORMS, {
                perPage: 10,
                pageNum: page,
                search: query || undefined,
            });
            setForms(formsData?.results);
            setTotal(formsData?.meta?.total);
            setLoading(false);
        } catch (error) {
            error !== 'cancelled' &&
                openSnackbar(error?.errorMessage ?? 'An error occurred loading forms.');
        }
    };

    useEffect(() => {
        (async () => await setNewForms())();
        return () => {
            clearTimeout(queryTimeout);
        };
    }, []);

    useEffect(() => {
        if (!prevQuery || prevQuery === query) return;
        clearTimeout(queryTimeout);
        setLoading(true);
        setQueryTimeout(
            setTimeout(async () => {
                await setNewForms({});
            }, 750)
        );
    }, [query]);

    const handleUrlChange = (query = {}) => {
        navigate(
            `${location.pathname}?${queryString.stringify({
                ...queryString.parse(location.search),
                page: undefined,
                ...query,
            })}`
        );
    };

    return (
        <Fragment>
            <Command>
                <Command.Breadcrumbs>
                    <Command.Breadcrumbs.Breadcrumb text="Forms" />
                </Command.Breadcrumbs>
                <Command.Filters>
                    <Command.Filters.Search
                        key="search"
                        onChange={e => handleUrlChange({ query: e.target.value })}
                        value={query}
                    />
                </Command.Filters>

                <Command.Action text="Create" icon="plus" link="/forms/form/create" />
            </Command>

            {loading || !!forms.length ? (
                <Grid>
                    <List loading={loading}>
                        {forms.map(({ _id, name, description, locked }) => (
                            <List.Item key={_id} link={`/forms/form/${_id}`}>
                                <List.Item.Column grow>
                                    <Text bold>{name}</Text>
                                    <Text>{description}</Text>
                                </List.Item.Column>
                                <List.Item.Column.Right center shrink>
                                    <Tooltip text={locked ? 'Locked' : 'Unlocked'}>
                                        <Icon icon={['fad', locked ? 'lock' : 'lock-open']} />
                                    </Tooltip>
                                </List.Item.Column.Right>
                            </List.Item>
                        ))}
                    </List>

                    {!loading && (
                        <Fragment>
                            <Divider />
                            <Pagination
                                meta
                                onChange={page => handleUrlChange({ page })}
                                pageTotal={forms.length}
                                total={total}
                                page={page}
                                perPage={10}
                                metaLabel="Forms"
                            />
                        </Fragment>
                    )}
                </Grid>
            ) : !!query?.length ? (
                <Empty title="Nothing here" text="Your filters didn't seem to find anything." />
            ) : (
                <Empty title="Hold tight" text="You don't have any forms yet!" />
            )}
        </Fragment>
    );
};

export default withSnackbar(Forms);
