import React, { useEffect, useState, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby-link';
import * as Yup from 'yup';
import { Formik } from 'formik';
import queryString from 'query-string';
import objectPath from 'object-path';

import { toFixed } from '@helpers';
import { makeGetRequest, makePostRequest, makePutRequest } from '@helpers/requests';
import { STORE_PRODUCT, STORE_PRODUCT_NEW, STORE_CATEGORIES } from '@helpers/api';
import Loader from '@components/Loader';
import { withSnackbar } from '@components/Snackbar';
import Command from '@components/Command';

import Form from '@views/Store/Products/Product/Form';

const Product = ({ website, id, deleted, openSnackbar }) => {
    const [loading, setLoading] = useState(!!id);
    const [product, setProduct] = useState(null);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                if (id) {
                    const { data: productData } = await makeGetRequest(STORE_PRODUCT(id), {
                        deleted,
                    });
                    setProduct({
                        ...productData,
                        quantityType: productData.quantity === null ? 'unlimited' : 'limited',
                    });
                }

                const { data: categoriesData } = await makeGetRequest(STORE_CATEGORIES, {
                    perPage: 1000,
                    pageNum: 1,
                });
                setCategories(categoriesData?.results);

                setLoading(false);
            } catch (error) {
                error !== 'cancelled' &&
                    openSnackbar(
                        error?.errorMessage ?? 'An error occurred when loading this product.'
                    );
                navigate('/store/products');
            }
        })();
    }, []);

    const getCustomFieldsData = customFields => {
        const data = [];

        for (let key in customFields) {
            if (customFields.hasOwnProperty(key)) {
                const customField = product?.customFields.find(({ _id }) => _id === key);
                let value = customFields[key];

                if (customField?.type === 'collection') {
                    value = [];

                    for (let itemKey in customFields[key]) {
                        if (customFields[key].hasOwnProperty(itemKey)) {
                            const customFieldItem = customField?.items?.find(
                                ({ _id }) => _id === itemKey
                            );
                            const itemValue = customFields[key][itemKey];

                            if (customFieldItem?.type === 'image') {
                                value.push({
                                    _id: key,
                                    value: customFieldItem?.multiple
                                        ? !!itemValue?.length
                                            ? itemValue?.map(({ fileName }) => fileName)
                                            : undefined
                                        : itemValue?.fileName,
                                });

                                continue;
                            }

                            value.push({
                                _id: itemKey,
                                value: customFields[key][itemKey],
                            });
                        }
                    }
                }

                if (customField?.type === 'image') {
                    data.push({
                        _id: key,
                        value: customField?.multiple
                            ? !!value?.length
                                ? value?.map(({ fileName }) => fileName)
                                : undefined
                            : value?.fileName,
                    });

                    continue;
                }

                data.push({
                    _id: key,
                    value,
                });
            }
        }

        return data;
    };

    const handleSave = async (
        {
            sku,
            name,
            description,
            category,
            tags,
            quantity,
            images,
            price,
            shortName,
            quantityType,
            widthType,
            heightType,
            lengthType,
            weightType,
            shipping,
            shippable,
            customFields,
        },
        actions
    ) => {
        try {
            const customFieldsData = getCustomFieldsData(customFields);

            await makePutRequest(STORE_PRODUCT(product._id), {
                sku,
                name,
                description,
                category,
                tags,
                quantity: quantityType === 'unlimited' ? null : quantity,
                images:
                    !!images && Array.isArray(images) && !!images.length
                        ? images.map(({ fileName }) => fileName)
                        : [],
                price: `${parseInt(price * 100)}`,
                shortName,
                deleted,
                shipping: {
                    active: shippable,
                    ...(shippable
                        ? {
                              dimensions: {
                                  width: toFixed(
                                      parseFloat(shipping.dimensions.width) *
                                          (widthType === 'cm' ? 1 : 2.54),
                                      2
                                  ),
                                  length: toFixed(
                                      parseFloat(shipping.dimensions.length) *
                                          (lengthType === 'cm' ? 1 : 2.54),
                                      2
                                  ),
                                  height: toFixed(
                                      parseFloat(shipping.dimensions.height) *
                                          (heightType === 'cm' ? 1 : 2.54),
                                      2
                                  ),
                              },
                              weight: {
                                  value: toFixed(
                                      parseFloat(shipping.weight.value) *
                                          (weightType === 'g' ? 1 : 453.592),
                                      3
                                  ),
                              },
                          }
                        : {}),
                },
                customFields: customFieldsData,
            });

            openSnackbar('Your product was successfully saved.');
        } catch (error) {
            error !== 'cancelled' &&
                openSnackbar(error?.errorMessage ?? 'An error occurred saving this product.');
        }
    };

    const handleCreate = async (
        {
            sku,
            name,
            description,
            category,
            tags,
            quantity,
            images,
            price,
            shippable,
            shipping,
            shortName,
            quantityType,
            widthType,
            heightType,
            lengthType,
            weightType,
            customFields,
        },
        actions
    ) => {
        try {
            const customFieldsData = getCustomFieldsData(customFields);

            const { data: product } = await makePostRequest(STORE_PRODUCT_NEW, {
                sku,
                name,
                description,
                category,
                tags,
                quantity: quantityType === 'unlimited' ? null : quantity,
                images:
                    !!images && Array.isArray(images)
                        ? images.map(({ fileName }) => fileName)
                        : undefined,
                price: `${parseInt(price * 100)}`,
                shortName,
                shipping: {
                    active: shippable,
                    ...(shippable
                        ? {
                              dimensions: {
                                  width:
                                      parseFloat(shipping.dimensions.width) *
                                      (widthType === 'cm' ? 1 : 2.54),
                                  length:
                                      parseFloat(shipping.dimensions.length) *
                                      (lengthType === 'cm' ? 1 : 2.54),
                                  height:
                                      parseFloat(shipping.dimensions.height) *
                                      (heightType === 'cm' ? 1 : 2.54),
                              },
                              weight: {
                                  value:
                                      parseFloat(shipping.weight.value) *
                                      (weightType === 'g' ? 1 : 453.592),
                              },
                          }
                        : {}),
                },
                customFields: customFieldsData,
            });
            openSnackbar('Your product has been successfully created.');
            navigate(`/store/products/${product._id}`);
        } catch (error) {
            error !== 'cancelled' &&
                openSnackbar(error?.errorMessage ?? 'An error occurred creating this product.');
        }
    };

    const handleSubmit = async (data, actions) => {
        product ? await handleSave(data, actions) : await handleCreate(data, actions);
    };

    const renderStringValidation = ({ label, optional, min, max }) => {
        let value = Yup.string();
        if (max) value = value.max(max, `${label} can't be longer than ${max} characters.`);
        if (min) value = value.min(min, `${label} can't be less than ${min} characters.`);
        if (!optional) {
            value = value.required(`${label} is required.`);
        } else {
            value = value.nullable();
        }
        return value;
    };

    const renderHtmlValidation = ({ label, optional, min, max }) => {
        let value = Yup.string();
        if (!optional) {
            value = value.required(`${label} is required.`);
        } else {
            value = value.nullable();
        }
        return value;
    };

    const renderNumberValidation = ({ label, optional, min, max, positive, decimals }) => {
        let value = Yup.number();
        if (max) value = value.max(max, `${label} can't be higher than ${max}.`);
        if (min) value = value.min(min, `${label} can't be less than ${min}.`);
        if (positive) value = value.positive(`${label} must be a positive number.`);
        if (!decimals) value = value.integer(`${label} can't have decimals.`);
        if (!optional) {
            value = value.required(`${label} is required.`);
        } else {
            value = value.nullable();
        }
        return value;
    };

    const renderValidationImage = ({ id, multiple, min, max, label, optional }) => {
        let value;

        if (multiple) {
            let media = Yup.string();
            value = Yup.array().of(media);
            if (max) value = value.max(max, `${label} can't have more than ${max} images.`);
            if (min) value = value.min(min, `${label} can't have less than ${min} images.`);
            if (!optional) media = media.required(`${label} is required.`);
        } else {
            value = Yup.string().nullable();
        }

        if (!optional) {
            value = value.required(`${label} is required.`);
        } else {
            value = value.nullable();
        }
        return value;
    };

    const renderValidationCollection = ({ items, label, optional }) => {
        const validationFields = {};

        items.forEach(validationItem => {
            validationFields[validationItem._id] = renderValidationField(validationItem);
        });

        let value = Yup.object().shape(validationFields);
        if (!optional) {
            value = value.required(`${label} is required.`);
        } else {
            value = value.nullable();
        }
        return value;
    };

    const renderOptionsCheckbox = ({ label, optional, min, max }) => {
        let value = Yup.array().of(Yup.string());
        if (max) value = value.max(max, `${label} can't contain more than ${max} options.`);
        if (min) value = value.min(min, `${label} can't contain less than ${min} options.`);
        if (!optional) {
            value = value.required(`${label} is required.`);
        } else {
            value = value.nullable();
        }
        return value;
    };

    const renderValidationField = ({ type, ...field }) => {
        if (type === 'string' || type === 'text' || type === 'select' || type === 'radio')
            return renderStringValidation(field);
        if (type === 'html') return renderHtmlValidation(field);
        if (type === 'number') return renderNumberValidation(field);
        if (type === 'collection') return renderValidationCollection(field);
        if (type === 'image') return renderValidationImage(field);
        if (type === 'checkbox') return renderOptionsCheckbox(field);

        return undefined;
    };

    const renderCustomFieldsValidation = () => {
        const customFields = product?.customFields ?? [];
        const schema = {};

        customFields.forEach(field => {
            schema[field?._id] = renderValidationField(field);
        });

        return Yup.object().shape(schema);
    };

    const getCustomFields = () => {
        const customFields = product?.customFields ?? [];
        const values = {};

        customFields.forEach(({ value, type, items, _id }) => {
            if (type === 'collection') {
                const customFieldValues = {};

                items?.forEach(item => {
                    customFieldValues[item?._id] = item?.value;
                });

                values[_id] = customFieldValues;
                return;
            }

            values[_id] = value;
        });

        return values;
    };

    return (
        <Fragment>
            <Command>
                <Command.Breadcrumbs>
                    <Command.Breadcrumbs.Breadcrumb text="Store" link="/store" />
                    <Command.Breadcrumbs.Breadcrumb text="Products" link={`/store/products`} />
                    <Command.Breadcrumbs.Breadcrumb text={product?.name ?? 'Product'} />
                </Command.Breadcrumbs>
            </Command>

            {loading ? (
                <Loader />
            ) : (
                <Formik
                    validationSchema={Yup.object().shape({
                        sku: Yup.string(),
                        name: Yup.string().required('Name is required'),
                        description: Yup.string().required('Description is required'),
                        category: Yup.string(),
                        tags: Yup.array(Yup.string()),
                        quantity: Yup.number()
                            .integer('Quantity must be an integer')
                            .nullable()
                            .when('quantityType', {
                                is: 'limited',
                                then: Yup.number()
                                    .integer('Quantity must be an integer')
                                    .required('Quantity is required'),
                            }),
                        shortName: Yup.string().required('Short name is required'),
                        price: Yup.string().required('Price is required'),
                        images: Yup.array(
                            Yup.object().shape({
                                url: Yup.string(),
                                fileName: Yup.string(),
                            })
                        ),
                        quantityType: Yup.string(),
                        weightType: Yup.string().oneOf(['g', 'lb']),
                        lengthType: Yup.string().oneOf(['cm', 'in']),
                        widthType: Yup.string().oneOf(['cm', 'in']),
                        heightType: Yup.string().oneOf(['cm', 'in']),
                        shippable: Yup.boolean(),
                        shipping: Yup.object()
                            .nullable()
                            .when('shippable', {
                                is: true,
                                then: Yup.object().shape({
                                    dimensions: Yup.object().shape({
                                        length: Yup.number()
                                            .positive('Length must be a positive number')
                                            .required('Length is required'),
                                        width: Yup.number()
                                            .positive('Width must be a positive number')
                                            .required('Width is required'),
                                        height: Yup.number()
                                            .positive('Height must be a positive number')
                                            .required('Height is required'),
                                    }),
                                    weight: Yup.object().shape({
                                        value: Yup.number()
                                            .positive('Weight must be a positive number')
                                            .required('Weight is required'),
                                    }),
                                }),
                            }),
                        customFields: renderCustomFieldsValidation(),
                    })}
                    initialValues={{
                        sku: product?.sku,
                        name: product?.name,
                        description: product?.description,
                        category: product?.category?._id,
                        tags: product?.tags,
                        quantity: product?.quantity,
                        images: product?.images,
                        price: product?.price ? (product.price / 100).toFixed(2) : undefined,
                        shortName: product?.shortName,
                        quantityType: product?.quantity === null ? 'unlimited' : 'limited',
                        weightType: 'g',
                        lengthType: 'cm',
                        widthType: 'cm',
                        heightType: 'cm',
                        shippable: !!product?.shipping?.active ?? true,
                        shipping: {
                            dimensions: {
                                length: product?.shipping?.dimensions?.length,
                                height: product?.shipping?.dimensions?.height,
                                width: product?.shipping?.dimensions?.width,
                            },
                            weight: {
                                value: product?.shipping?.weight?.value,
                            },
                        },
                        customFields: getCustomFields(),
                    }}
                    onSubmit={handleSubmit}
                >
                    {props => (
                        <Form
                            categories={categories}
                            product={product}
                            website={website}
                            allCustomFields={product?.customFields}
                            openSnackbar={openSnackbar}
                            {...props}
                        />
                    )}
                </Formik>
            )}
        </Fragment>
    );
};

export default withSnackbar(
    connect(({ user, website }, ownProps) => {
        const params = queryString.parse(ownProps.location.search);
        const deleted = params.deleted || undefined;

        return { user, website, deleted };
    })(Product)
);
