import React, { Children, cloneElement, isValidElement } from 'react';

import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Content = ({ children, className = '', loading }) => {
    const childrenWithProps = Children.map(children, child => {
        // Checking isValidElement is the safe way and avoids a TS error too.
        if (isValidElement(child) && child?.type?.displayName) {
            return cloneElement(child, { isCard: true });
        }

        return child;
    });

    return (
        <div
            className={`${styles.cardContent} ${
                loading ? styles.cardContentLoading : ''
            } ${className}`}
        >
            {loading && (
                <div className={styles.cardContentLoader}>
                    <FontAwesomeIcon icon={['fad', 'circle-notch']} spin />
                </div>
            )}
            {!loading && childrenWithProps}
        </div>
    );
};

export default Content;
