import React, { Fragment, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { createEditor } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';
import { withHistory } from 'slate-history';

import styles from './styles.module.scss';
import Alert from '@components/Alert';
import { Divider } from '@components/Grid';

import { connect } from 'react-redux';

import Input from './Fields/Input';
import List from './Fields/List';
import Image from './Fields/Image';
import Html from './Fields/Html';

const Field = data => {
    const {
        field: { type, label, path },
        touched,
        error,
        formatPath,
        name,
    } = data;

    console.log({ name });

    return (
        // <div
        //     className={`${styles.field} ${
        //         /*!!activeField && activeField.path[0] === path[0] ? styles.fieldActive : ''*/
        //     }`}
        // >
        <div className={`${styles.field}`}>
            {(type === 'string' || type === 'text' || type === 'number') && (
                <Input {...data} name={name} />
            )}
            {type === 'list' && <List {...data} name={name} />}
            {type === 'image' && <Image {...data} name={name} />}
            {type === 'html' && <Html {...data} name={name} />}
            {touched && error && (
                <Fragment>
                    <Divider />
                    <Alert
                        type="error"
                        message={
                            (typeof error === 'string' && error) ||
                            (type === 'list' && `One or more errors occurred inside ${label}.`) ||
                            ''
                        }
                    />
                </Fragment>
            )}
        </div>
    );
};

export default connect(({ website }) => ({ website }))(Field);
