import React, { Fragment, useEffect, useState } from 'react';
import Command from '@components/Command';
import Loader from '@components/Loader';
import { useSnackbar } from '@components/Snackbar';
import { navigate } from '@reach/router';
import { makeGetRequest } from '@helpers/requests';
import { USER_REQUEST } from '@helpers/api';
import Panes from '@components/Panes';
import Label from '@components/Label';
import Text from '@components/Text';
import { Col, Divider, Grid, Row } from '@components/Grid';
import List from '@components/List';
import Card from '@components/Card';
import { format, parseISO } from 'date-fns';
import Button from '@components/Button';
import Modal from '@components/Modal';

const Request = ({ id }) => {
    const [declining, setDeclining] = useState(false);
    const [processingDecline, setProcessingDecline] = useState(false);
    const [approving, setApproving] = useState(false);
    const [processingApprove, setProcessingApprove] = useState(false);
    const [loading, setLoading] = useState(true);
    const [request, setRequest] = useState(null);
    const [openSnackbar] = useSnackbar();

    useEffect(() => {
        (async () => {
            try {
                if (!id) {
                    openSnackbar('No user request was given.');
                    navigate(-1);
                    return;
                }

                const { data: requestData } = await makeGetRequest(USER_REQUEST(id));
                setRequest(requestData);
                setLoading(false);
            } catch (error) {
                if (error !== 'cancelled') {
                    openSnackbar(
                        error?.errorMessage ??
                            'An error occurred attempting to load this user request.'
                    );
                    navigate(-1);
                }
            }
        })();
    }, []);

    if (loading) return <Loader />;

    const { _id, type, user } = request;

    return (
        <Fragment>
            <Command>
                <Command.Breadcrumbs>
                    <Command.Breadcrumbs.Breadcrumb text="Users" link="/users" />
                    <Command.Breadcrumbs.Breadcrumb text="Requests" link="/users/requests" />
                    <Command.Breadcrumbs.Breadcrumb
                        text={
                            !!user ? `${user?.firstName ?? ''} ${user?.lastName ?? ''}` : 'Request'
                        }
                    />
                </Command.Breadcrumbs>
            </Command>

            <Grid>
                <Card onClick={() => navigate(`/users/user/${user?._id}`)}>
                    <Card.Avatar alt={user?.firstName} src={user?.avatar} />
                    <Card.Title>{`${user?.firstName} ${user?.lastName}`}</Card.Title>
                    <Card.Description>{user?.email}</Card.Description>
                    <Card.Actions>
                        <Card.Actions.Action small danger text="Decline" />
                        <Card.Actions.Action small success text="Approve" icon="check" />
                    </Card.Actions>
                </Card>
                <Divider />

                <List>
                    <List.Item>
                        <List.Item.Column>
                            <Text bold>Id</Text>
                        </List.Item.Column>
                        <List.Item.Column.Right important>
                            <Text empty={!_id}>{_id}</Text>
                        </List.Item.Column.Right>
                    </List.Item>
                    <List.Item>
                        <List.Item.Column>
                            <Text bold>Last logged in</Text>
                        </List.Item.Column>
                        <List.Item.Column.Right important>
                            <Text empty={!user?.lastLogin}>
                                {!!user?.lastLogin && format(parseISO(user?.lastLogin), 'PPP p')}
                            </Text>
                        </List.Item.Column.Right>
                    </List.Item>
                    <List.Item>
                        <List.Item.Column>
                            <Text bold>Email</Text>
                        </List.Item.Column>
                        <List.Item.Column.Right important>
                            <Text empty={!user?.email}>{user?.email}</Text>
                        </List.Item.Column.Right>
                    </List.Item>
                    <List.Item>
                        <List.Item.Column>
                            <Text bold>First Name</Text>
                        </List.Item.Column>
                        <List.Item.Column.Right important>
                            <Text empty={!user?.firstName}>{user?.firstName}</Text>
                        </List.Item.Column.Right>
                    </List.Item>
                    <List.Item>
                        <List.Item.Column>
                            <Text bold>Last Name</Text>
                        </List.Item.Column>
                        <List.Item.Column.Right important>
                            <Text empty={!user?.lastName}>{user?.lastName}</Text>
                        </List.Item.Column.Right>
                    </List.Item>
                    <List.Item>
                        <List.Item.Column>
                            <Text bold>City</Text>
                        </List.Item.Column>
                        <List.Item.Column.Right important>
                            <Text empty={!user?.locale?.city}>{user?.locale?.city}</Text>
                        </List.Item.Column.Right>
                    </List.Item>
                    <List.Item>
                        <List.Item.Column>
                            <Text bold>Country</Text>
                        </List.Item.Column>
                        <List.Item.Column.Right important>
                            <Text empty={!user?.locale?.country}>{user?.locale?.country}</Text>
                        </List.Item.Column.Right>
                    </List.Item>
                </List>
                <Divider />

                <h2>Types</h2>
                <List>
                    <List.Item link={`/users/types`}>
                        <List.Item.Column>
                            <Text bold>Current Type</Text>
                        </List.Item.Column>
                        <List.Item.Column.Right important>
                            {/* TODO: Add current type */}
                        </List.Item.Column.Right>
                    </List.Item>
                    <List.Item link={`/users/types/${type}`}>
                        <List.Item.Column>
                            <Text bold>Requested Type</Text>
                        </List.Item.Column>
                        <List.Item.Column.Right important>
                            <Text empty={!type}>{type}</Text>
                        </List.Item.Column.Right>
                    </List.Item>
                </List>
                <Divider />

                <Row end="xs">
                    <Col xs={12}>
                        <Button danger text="Decline" />
                        <Button success text="Approve" icon="check" />
                    </Col>
                </Row>
            </Grid>

            <Modal isOpen={declining} onClose={() => setDeclining(false)}>
                <Modal.Content>
                    <p>{`Are you sure you want to decline ${
                        declining?.user?.firstName
                            ? `${declining?.user?.firstName} ${declining?.user?.lastName} (${declining?.user?.email})`
                            : 'this user'
                    } from becoming the ${declining?.type} user type?`}</p>
                    <Button onClick={() => setDeclining(false)}>Cancel</Button>
                    <Button loading={processingDecline} danger onClick={handleDecline}>
                        Decline
                    </Button>
                </Modal.Content>
            </Modal>

            <Modal isOpen={approving} onClose={() => setApproving(false)}>
                <Modal.Content>
                    <p>{`Are you sure you want to approve ${
                        approving?.user?.firstName
                            ? `${approving?.user?.firstName} ${approving?.user?.lastName} (${approving?.user?.email})`
                            : 'this user'
                    } to become the ${approving?.type} user type?`}</p>
                    <Button onClick={() => setApproving(false)}>Cancel</Button>
                    <Button loading={processingApprove} success onClick={handleApprove}>
                        Approve
                    </Button>
                </Modal.Content>
            </Modal>
        </Fragment>
    );
};

export default Request;
