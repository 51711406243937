import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import Day from './DatepickerDay';

import styles from '../styles.module.scss';

class Days extends React.Component {
    isDayActive(day) {
        const date = this.props.active.filter(activeDate => !!activeDate);

        for (let i = 0; i < date.length; i++) {
            if (
                moment(date[i]).year() === this.props.year &&
                moment(date[i]).month() === this.props.month &&
                moment(date[i]).date() === day
            ) {
                return true;
            }
        }

        return false;
    }

    isDayToday(day) {
        const date = this.getDate(day);
        const today = moment(new Date());

        return date.isSame(today, 'date');
    }

    isDayHovered(day) {
        // if the datepicker is in range mode and the first date (startDate) has been clicked (made active)
        if (this.props.range && this.props.active[0]) {
            const date = this.getDate(day);
            const hoveredDate = moment(
                `${this.props.hoveredDay}-${this.props.hoveredMonth + 1}-${this.props.hoveredYear}`,
                'D-M-YYYY'
            );

            // if the day in the loop is after the startDate
            // and the startDate is before the current hovered date
            // and the endDate doesn't exist yet or
            // the endDate exists and the current day in the loop is before the endDate
            return (
                date.isAfter(this.props.active[0]) &&
                this.props.active[0].isBefore(hoveredDate) &&
                date.isSameOrBefore(hoveredDate) &&
                (!this.props.active[1] ||
                    (this.props.active[1] && date.isBefore(this.props.active[1])))
            );
        }

        return false;
    }

    isDaySelected(day) {
        if (this.props.range && this.props.active[0]) {
            const date = this.getDate(day);

            // if the startDate and endDate is active and the day in the loop is before the endDate
            return (
                date.isAfter(this.props.active[0]) &&
                this.props.active[0] &&
                this.props.active[1] &&
                date.isBefore(this.props.active[1])
            );
        }
    }

    isDayDisabled(day) {
        if (!day) return true;
        const date = this.getDate(day);

        // if in the array of disabled dates matches the current day in the loop,
        // or the current day is before the minDate
        // or the current day is after the maxDate
        // disable the date
        if (
            (this.props.disabledDates &&
                Array.isArray(this.props.disabledDates) &&
                this.props.disabledDates.some(disabledDate => date.isSame(disabledDate))) ||
            (this.props.minDate && this.handleMinDateDisabled(date)) ||
            (this.props.maxDate && this.handleMaxDateDisabled(date)) ||
            (this.props.minDays && this.handleMinDaysDisabled(date)) ||
            (this.props.maxDays && this.handleMaxDaysDisabled(date))
        ) {
            return true;
        }

        // don't disable the date
        return false;
    }

    handleMinDateDisabled(date) {
        // render all days than fall before the minDate.day disabled
        return date.isBefore(moment(this.props.minDate, 'D-M-YYYY'));
    }

    handleMaxDateDisabled(date) {
        // render all days than fall after the maxDate.day disabled
        return date.isAfter(moment(this.props.maxDate, 'D-M-YYYY'));
    }

    handleMinDaysDisabled(date) {
        if (!this.props.range || !this.props.minDays || !this.props.active[0]) {
            return false;
        }

        return (
            date.isAfter(moment(this.props.active[0], 'D-M-YYYY')) &&
            date.isBefore(moment(this.props.active[0], 'D-M-YYYY').add(this.props.minDays, 'days'))
        );
    }

    handleMaxDaysDisabled(date) {
        if (!this.props.range || !this.props.maxDays || !this.props.active[0]) {
            return false;
        }

        return (
            date.isAfter(moment(this.props.active[0], 'D-M-YYYY')) &&
            date.isAfter(moment(this.props.active[0], 'D-M-YYYY').add(this.props.maxDays, 'days'))
        );
    }

    getDate(day = null) {
        return moment(
            `${day !== null ? day : '01'}-${this.props.month + 1}-${this.props.year}`,
            'D-M-YYYY'
        );
    }

    render() {
        const date = this.getDate();
        const monthItems = Array(date.daysInMonth())
            .fill()
            .map((x, i) => i + 1);
        const prevMonthItems = Array(date.day() - 1 === -1 ? 6 : date.day() - 1)
            .fill()
            .map((x, i) => i + 1)
            .map(day => <Day key={day} empty={true} />);

        return (
            <ul className={styles.datepickerDays} onMouseLeave={this.props.onHoverEnd}>
                {prevMonthItems}
                {monthItems.map(day => {
                    const disabled = this.isDayDisabled(day);

                    return (
                        <Day
                            key={day}
                            day={day}
                            active={this.isDayActive(day)}
                            today={this.isDayToday(day)}
                            disabled={disabled}
                            hovered={this.isDayHovered(day)}
                            selected={this.isDaySelected(day)}
                            onClick={day =>
                                !disabled && this.props.onChange
                                    ? this.props.onChange({
                                          day,
                                          month: this.props.month,
                                          year: this.props.year,
                                      })
                                    : undefined
                            }
                            onHover={day =>
                                !disabled && this.props.onHover
                                    ? this.props.onHover({
                                          day,
                                          month: this.props.month,
                                          year: this.props.year,
                                      })
                                    : undefined
                            }
                        />
                    );
                })}
            </ul>
        );
    }
}

Days.propTypes = {
    onChange: PropTypes.func,
    onHover: PropTypes.func,
    onHoverEnd: PropTypes.func,
    range: PropTypes.bool,
    active: PropTypes.array,
    year: PropTypes.number,
    month: PropTypes.number,
    hoveredYear: PropTypes.number,
    hoveredMonth: PropTypes.number,
    hoveredDay: PropTypes.number,
    minDays: PropTypes.number,
    maxDays: PropTypes.number,
};

export default Days;
