import React, { Fragment, useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby-link';

import usePrevious from '@helpers/hooks/usePrevious';
import {
	makeDeleteRequest,
	makeGetRequest,
	makePostRequest,
	makePutRequest,
} from '@helpers/requests';
import { STORE_DISCOUNT, STORE_DISCOUNTS, STORE_PRODUCT } from '@helpers/api';
import Panes from '@components/Panes';
import Input from '@components/Input';
import { Col, Divider, Row } from '@components/Grid';
import Alert from '@components/Alert';
import Select from '@components/Select';
import Label from '@components/Label';
import Tags from '@components/Tags';
import Radios from '@components/Radios';
import Dropzone from '@components/Dropzone';
import Switch from '@components/Switch';
import Button from '@components/Button';
import Modal from '@components/Modal';
import Slider from '@components/ImageSlider';

import Field from '@views/Store/Products/Product/Field';
import DiscountWrapper from '@views/Store/Discounts/Discount/Wrapper';
import DiscountForm from '@views/Store/Discounts/Discount/Form';
import List from '@components/List';
import Text from '@components/Text';
import Image from '@views/Editor/File/Fields/Image';
import Html from '@views/Editor/File/Fields/Html';

const Form = ({
	values,
	errors,
	touched,
	setFieldValue,
	handleChange,
	handleSubmit,
	isSubmitting,
	handleBlur,
	categories = [],
	product,
	website,
	allCustomFields,
	openSnackbar,
}) => {
	const {
		sku,
		name,
		shortName,
		description,
		category,
		tags = [],
		quantity,
		images = [],
		price,
		quantityType,
		weightType,
		heightType,
		lengthType,
		widthType,
		shippable,
		shipping,
		customFields,
	} = values;
	const [discounts, setDiscounts] = useState([]);
	const [creatingDiscount, setCreatingDiscount] = useState(false);
	const [activeDiscount, setActiveDiscount] = useState(null);
	const [deleting, setDeleting] = useState(false);
	const [processingDeletion, setProcessingDeletion] = useState(false);
	const [uploadingImage, setUploadingImage] = useState(false);
	const prevQuantityType = usePrevious(quantityType);
	const prevWeightType = usePrevious(weightType);
	const prevLengthType = usePrevious(lengthType);
	const prevWidthType = usePrevious(widthType);
	const prevHeightType = usePrevious(heightType);
	const $quantity = useRef(null);

	useEffect(() => {
		handleSetDiscounts();
	}, []);

	useEffect(() => {
		if (prevQuantityType === undefined) return;
		// enforce error validation on submit if user doesn't enter a quantity when selected 'limited' option
		setFieldValue('quantity', quantityType === 'unlimited' ? null : quantity || null);
	}, [quantityType]);

	useEffect(() => {
		if (prevWeightType === undefined || !shipping.weight.value) return;

		// prevWeightType === 'g' ? convert grams to pounds : convert pounds to grams
		setFieldValue(
			'shipping.weight.value',
			toFixed(shipping.weight.value * (prevWeightType === 'g' ? 0.00220462 : 453.592), 3)
		);
	}, [weightType]);

	useEffect(() => {
		if (prevLengthType === undefined || !shipping.dimensions.length) return;

		// prevWeightType === 'cm' ? convert centimetres to inches : convert inches to centimetres
		setFieldValue(
			'shipping.dimensions.length',
			toFixed(shipping.dimensions.length * (prevLengthType === 'cm' ? 0.393701 : 2.54), 2)
		);
	}, [lengthType]);

	useEffect(() => {
		if (prevHeightType === undefined || !shipping.dimensions.height) return;

		// prevHeightType === 'cm' ? convert centimetres to millimetres : convert millimetres to centimetres
		setFieldValue(
			'shipping.dimensions.height',
			toFixed(shipping.dimensions.height * (prevHeightType === 'cm' ? 0.393701 : 2.54), 2)
		);
	}, [heightType]);

	useEffect(() => {
		if (prevWidthType === undefined || !shipping.dimensions.width) return;

		// prevWeightType === 'cm' ? convert centimetres to millimetres : convert millimetres to centimetres
		setFieldValue(
			'shipping.dimensions.width',
			toFixed(shipping.dimensions.width * (prevWidthType === 'cm' ? 0.393701 : 2.54), 2)
		);
	}, [widthType]);

	useEffect(() => {
		if (creatingDiscount === false) {
			setActiveDiscount(null);
		}
	}, [creatingDiscount]);

	useEffect(() => {
		if (activeDiscount) {
			setCreatingDiscount(true);
		}
	}, [activeDiscount]);

	const handleSetDiscounts = async () => {
		try {
			if (product?._id) {
				const { data: discountsData } = await makeGetRequest(STORE_DISCOUNTS, {
					perPage: 1000,
					pageNum: 1,
					// product: product?._id,
				});

				setDiscounts(
					discountsData?.results?.filter(
						discount =>
							discount?.products?.some(
								discountProduct => discountProduct?._id === product?._id
							) ||
							discount?.categories?.some(
								discountCategory => discountCategory?._id === category
							) ||
							(!discount?.products?.length && !discount?.categories)
					)
				);
			}
		} catch (error) {
			error !== 'cancelled' &&
				openSnackbar(error?.erroMessage ?? 'An error occurred finding your discounts.');
		}
	};

	const handleTagRemoval = tag => {
		const index = tags.indexOf(tag);

		if (index > -1) {
			const newTags = tags.slice();
			newTags.splice(index, 1);
			setFieldValue('tags', newTags);
		}
	};

	const handleDelete = async () => {
		try {
			setProcessingDeletion(true);
			await makeDeleteRequest(STORE_PRODUCT(product._id));
			setDeleting(false);
			openSnackbar('Your product has been successfully deleted.');
			navigate('/store/products');
		} catch (error) {
			error !== 'cancelled' &&
				openSnackbar(error?.errorMessage ?? 'An error occurred deleting this product.');
		}
	};

	// create new discount
	const handleDiscountSubmit = async ({
		name,
		code,
		active,
		type,
		amount,
		limit,
		startDate,
		endDate,
		categories,
		products,
		accountTypes,
	}) => {
		const data = {
			name,
			code,
			active,
			type,
			amount,
			limit,
			startDate,
			endDate,
			categories: !!categories?.length ? categories : undefined,
			products: !!products?.length ? products : undefined,
			accountTypes: !!accountTypes?.length ? accountTypes : undefined,
		};

		try {
			!!activeDiscount?._id
				? await makePutRequest(STORE_DISCOUNT(activeDiscount._id), data)
				: await makePostRequest(STORE_DISCOUNTS, data);

			openSnackbar(
				`${name} was successfully ${!!activeDiscount?._id ? 'saved' : 'created'}.`
			);

			setCreatingDiscount(false);
			await handleSetDiscounts();
		} catch (error) {
			error !== 'cancelled' &&
				openSnackbar(
					error?.errorMessage ??
						`An error occurred ${
							!!activeDiscount?._id ? 'saving' : 'creating'
						} this discount.`
				);
		}
	};

	const calcProductPriceDiscount = (price, discount) => {
		let value;

		switch (discount?.type) {
			case 'percentageOff': {
				value = price - price * discount?.amount;
				break;
			}
			case 'amountOff': {
				value = price - discount?.amount / 100;
				break;
			}
			case 'newAmount': {
				value = discount?.amount / 100;
				break;
			}
		}

		return value <= 0 ? 'Free' : `${website?.store?.currency?.symbol}${value.toFixed(2)}`;
	};

	return (
		<Panes>
			<Panes.Left>
				<Input
					label="SKU"
					name="sku"
					value={sku}
					onChange={handleChange}
					theme="dashed"
					onBlur={handleBlur}
					optional
				/>
				{touched.sku && errors.sku && (
					<Fragment>
						<Divider />
						<Alert type="error" message={errors.sku} />
					</Fragment>
				)}
				<Divider />
				<Input
					label="Short Name"
					hint="Used for the product URL"
					name="shortName"
					value={shortName}
					onChange={handleChange}
					theme="dashed"
					onBlur={handleBlur}
				/>
				{touched.shortName && errors.shortName && (
					<Fragment>
						<Divider />
						<Alert type="error" message={errors.shortName} />
					</Fragment>
				)}
				<Divider />
				<Input
					label="Name"
					name="name"
					value={name}
					onChange={handleChange}
					theme="dashed"
					onBlur={handleBlur}
				/>
				{touched.name && errors.name && (
					<Fragment>
						<Divider />
						<Alert type="error" message={errors.name} />
					</Fragment>
				)}
				<Divider />
				<Select
					placeholder="Choose a category"
					label="Category"
					labelOnClick={() => navigate(`/store/categories`)}
					optional
					disabled={!categories.length}
					value={category}
					onChange={e => setFieldValue('category', e.target.value)}
				>
					{categories.map(({ _id, name }) => (
						<Select.Option key={_id} label={name} value={_id} />
					))}
				</Select>
				<Divider />
				<Input
					textarea
					label="Description"
					name="description"
					value={description}
					onChange={handleChange}
					theme="dashed"
					hint="Enter product description here"
					onBlur={handleBlur}
				/>
				{touched.description && errors.description && (
					<Fragment>
						<Divider />
						<Alert type="error" message={errors.description} />
					</Fragment>
				)}
				<Divider />
				<Label text="Tags" />
				<Tags
					onCreate={tag => {
						setFieldValue('tags', [...tags, tag]);
					}}
					onRemove={handleTagRemoval}
				>
					{tags.map((tag, index) => (
						<Tags.Tag key={`${tag}_${index}`}>{tag}</Tags.Tag>
					))}
				</Tags>
				{touched.tags && errors.tags && (
					<Fragment>
						<Divider />
						<Alert type="error" message={errors.tags} />
					</Fragment>
				)}
				<Divider />
				<Input
					label="Price"
					number={{ min: 0, decimals: 2 }}
					value={price}
					name="price"
					onChange={handleChange}
					theme="dashed"
				/>
				{touched.price && errors.price && (
					<Fragment>
						<Divider />
						<Alert type="error" message={errors.price} />
					</Fragment>
				)}
				<Divider />
				<Label text="Stock" hint={`Maximum ${website.meta.stockLimit} if limited`} />
				<Row middle="xs">
					<Col shrink>
						<Radios inline onChange={({ id }) => setFieldValue('quantityType', id)}>
							<Radios.Radio id="unlimited" label="Unlimited" checked={quantityType} />
							<Radios.Radio id="limited" label="Limited" checked={quantityType} />
						</Radios>
					</Col>

					{quantityType === 'limited' && (
						<Col grow>
							<Input
								ref={$quantity}
								number={{ min: 0, max: website.meta.stockLimit || 1000 }}
								onChange={handleChange}
								name="quantity"
								value={quantity}
								theme="dashed"
								placeholder="Enter your current stock amount"
							/>
						</Col>
					)}
				</Row>
				{touched.quantityType && errors.quantity && (
					<Fragment>
						<Divider />
						<Alert type="error" message={errors.quantity} />
					</Fragment>
				)}
				<Divider />
				<Label text="Images" />
				<Dropzone
					image={images}
					multiple={true}
					onChange={images => setFieldValue('images', images)}
					folder="store"
					onUplopading={() => setUploadingImage(true)}
					onUploaded={() => setUploadingImage(false)}
				/>
				<Divider />
				<Label
					text="Discounts"
					hint="View discounts currently active on this product, and add new discounts"
					optional
					onClick={() => navigate(`/store/discounts`)}
				/>
				{!!discounts.length && (
					<Fragment>
						<List>
							{discounts.map(discount => (
								<List.Item
									key={discount?._id}
									onClick={() => {
										setActiveDiscount(discount);
									}}
								>
									<List.Item.Column>
										<Text bold>{discount?.name}</Text>
										<Text>
											{discount?.amount &&
												`${(discount?.type === 'percentageOff' &&
													`${(discount?.amount * 100 > 100
														? 100
														: discount?.amount * 100
													).toFixed(2)}%`) ||
													`${discount?.type === 'amountOff' ? '-' : ''}${
														website?.store?.currency?.symbol
													}${discount?.amount / 100}`} • `}
											{`${calcProductPriceDiscount(
												price * 100,
												discount
											)} with discount`}
										</Text>
										{!!discount?.code && <Text faded>{discount?.code}</Text>}
									</List.Item.Column>
								</List.Item>
							))}
						</List>
						<Divider margin={1} />
					</Fragment>
				)}
				<Divider margin={1} />
				<Button
					text="Create discount"
					icon="plus"
					onClick={() => {
						setActiveDiscount(null);
						setCreatingDiscount(true);
					}}
					disabled={creatingDiscount && !activeDiscount}
				/>

				{touched.name && errors.name && (
					<Fragment>
						<Divider />
						<Alert type="error" message={errors.name} />
					</Fragment>
				)}
				<Divider />
				<Switch
					label="Shipping"
					hint="If this is a digital product, turn shipping off"
					name="shippable"
					checked={shippable}
					onChange={(e, checked) => setFieldValue('shippable', checked)}
				/>
				{shippable && (
					<Fragment>
						{!(
							website?.store?.shipStation?.apiKey &&
							website?.store?.shipStation?.apiSecret
						) && (
							<Fragment>
								<Divider />
								<Alert
									type="warning"
									message="You haven't set up shipping for your store, please fill
                                        in your ShipStation api key and api secret in your store settings."
								>
									<Alert.Actions>
										<Alert.Actions.Action
											text="Set up"
											link="/settings/store"
										/>
										<Alert.Actions.Action
											primary
											transparent
											text="Contact support"
											link="mailto:support@merlinpanel.com"
										/>
									</Alert.Actions>
								</Alert>
							</Fragment>
						)}
						<Divider />
						<Label
							text="Length"
							hint="Measure your product length in inches or centimetres"
						/>
						<Row middle="xs">
							<Col shrink>
								<Radios
									inline
									onChange={({ id }) => setFieldValue('lengthType', id)}
								>
									<Radios.Radio id="in" label="in" checked={lengthType} />
									<Radios.Radio id="cm" label="cm" checked={lengthType} />
								</Radios>
							</Col>
							<Col grow>
								<Input
									number={{ decimals: 2 }}
									name="shipping.dimensions.length"
									value={values.shipping.dimensions.length}
									onChange={handleChange}
								/>
							</Col>
						</Row>
						{touched.shipping?.dimensions?.length &&
							errors.shipping?.dimensions?.length && (
								<Fragment>
									<Divider />
									<Alert
										type="error"
										message={errors.shipping.dimensions.length}
									/>
								</Fragment>
							)}
						<Divider />
						<Label
							text="Width"
							hint="Measure your product length in inches or centimetres"
						/>
						<Row middle="xs">
							<Col shrink>
								<Radios
									inline
									onChange={({ id }) => setFieldValue('widthType', id)}
								>
									<Radios.Radio id="in" label="in" checked={widthType} />
									<Radios.Radio id="cm" label="cm" checked={widthType} />
								</Radios>
							</Col>
							<Col grow>
								<Input
									number={{ decimals: 2 }}
									name="shipping.dimensions.width"
									value={values.shipping.dimensions.width}
									onChange={handleChange}
								/>
							</Col>
						</Row>
						{touched.shipping?.dimensions?.width && errors.shipping?.dimensions?.width && (
							<Fragment>
								<Divider />
								<Alert type="error" message={errors.shipping.dimensions.width} />
							</Fragment>
						)}
						<Divider />
						<Label
							text="Height"
							hint="Measure your product length in inches or centimetres"
						/>
						<Row middle="xs">
							<Col shrink>
								<Radios
									inline
									onChange={({ id }) => setFieldValue('heightType', id)}
								>
									<Radios.Radio id="in" label="in" checked={heightType} />
									<Radios.Radio id="cm" label="cm" checked={heightType} />
								</Radios>
							</Col>
							<Col grow>
								<Input
									number={{ decimals: 2 }}
									name="shipping.dimensions.height"
									value={values.shipping.dimensions.height}
									onChange={handleChange}
								/>
							</Col>
						</Row>
						{touched.shipping?.dimensions?.height &&
							errors.shipping?.dimensions?.height && (
								<Fragment>
									<Divider />
									<Alert
										type="error"
										message={errors.shipping.dimensions.height}
									/>
								</Fragment>
							)}
						<Divider />

						<Label
							text="Weight"
							hint="Measure your product weight in pounds or grams"
						/>
						<Row middle="xs">
							<Col shrink>
								<Radios
									inline
									onChange={({ id }) => setFieldValue('weightType', id)}
								>
									<Radios.Radio id="lb" label="lb" checked={weightType} />
									<Radios.Radio id="g" label="g" checked={weightType} />
								</Radios>
							</Col>
							<Col grow>
								<Input
									number={{ decimals: 3 }}
									name="shipping.weight.value"
									value={values.shipping.weight.value}
									onChange={handleChange}
								/>
							</Col>
						</Row>
						{touched.shipping?.dimensions?.length && errors.shipping?.weight?.value && (
							<Fragment>
								<Divider />
								<Alert type="error" message={errors.shipping.weight.value} />
							</Fragment>
						)}
					</Fragment>
				)}
				<Divider />

				{!!allCustomFields && !!allCustomFields?.length && (
					<Fragment>
						{allCustomFields?.map((field, index) => (
							<Field
								key={`${field._id}_${index}`}
								field={field}
								name={`customFields.${field?._id}`}
								value={customFields[field?._id]}
								touched={touched?.customFields?.[field?._id]}
								error={errors?.customFields?.[field?._id]}
								handleChange={handleChange}
								setFieldValue={setFieldValue}
							/>
						))}
					</Fragment>
				)}

				<Row end="xs">
					<Col xs={12}>
						{!!product && (
							<Button
								disabled={uploadingImage}
								loading={processingDeletion}
								danger
								onClick={() => setDeleting(true)}
							>
								Delete
							</Button>
						)}
						<Button
							disabled={uploadingImage}
							loading={isSubmitting}
							success
							onClick={handleSubmit}
						>
							{product ? 'Save' : 'Create'}
						</Button>
					</Col>
				</Row>

				{product && (
					<Modal isOpen={deleting} onClose={() => setDeleting(false)}>
						<Modal.Content>
							<p>{`Are you sure you want to delete "${name}"?`}</p>
							<Button onClick={() => setDeleting(false)}>Cancel</Button>
							<Button loading={processingDeletion} danger onClick={handleDelete}>
								Delete
							</Button>
						</Modal.Content>
					</Modal>
				)}
			</Panes.Left>
			<Panes.Right>
				{creatingDiscount ? (
					<Fragment>
						<Panes.Title onBack={() => setCreatingDiscount(false)}>
							<h3>
								{activeDiscount?.name
									? `Editing discount - ${activeDiscount?.name}`
									: 'New discount'}
							</h3>

							{!!activeDiscount?._id && (
								<Panes.Title.Actions>
									<Panes.Title.Actions.Action
										text="Preview"
										icon="list"
										link={`/store/discounts/${activeDiscount?._id}`}
									/>
								</Panes.Title.Actions>
							)}
						</Panes.Title>

						<DiscountWrapper
							discount={
								activeDiscount || {
									products: [product],
								}
							}
							onSubmit={handleDiscountSubmit}
						>
							{props => <DiscountForm {...props} />}
						</DiscountWrapper>
					</Fragment>
				) : (
					<Fragment>
						{!!images?.length && (
							<Fragment>
								<Slider height={300}>
									{images.map(({ url }) => (
										<Slider.Item alt={name} key={url} src={url} />
									))}
								</Slider>
								<Divider />
							</Fragment>
						)}

						<h2>{name}</h2>
						<Tags>
							{tags.map((tag, index) => (
								<Tags.Tag key={`${tag}_${index}`}>{tag}</Tags.Tag>
							))}
						</Tags>
						<Divider margin={2} />
						<p>{description}</p>
						<p>
							<b>
								{parseFloat(price) === 0 && 'Free'}
								{!!parseFloat(price) &&
									`${website.store.currency.symbol}${parseFloat(price).toFixed(
										2
									)}`}
							</b>
						</p>
					</Fragment>
				)}
			</Panes.Right>
		</Panes>
	);
};

export default Form;
