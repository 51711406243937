import React, { useEffect, useState, Fragment } from 'react';
import queryString from 'query-string';
import { navigate } from 'gatsby-link';
import { format, parseISO } from 'date-fns';

import { Grid, Divider } from '@components/Grid';
import { makeGetRequest } from '@helpers/requests';
import { FORM_SUBMISSIONS } from '@helpers/api';
import Pagination from '@components/Pagination';
import Command from '@components/Command';
import usePrevious from '@helpers/hooks/usePrevious';
import Empty from '@components/Empty';
import { withSnackbar } from '@components/Snackbar';
import List from '@components/List';
import Text from '@components/Text';

const Submissions = ({ location, openSnackbar }) => {
    const params = queryString.parse(location.search, { parseNumbers: true });
    const page = params.page ?? 1;
    const query = params?.query;

    const [loading, setLoading] = useState(true);
    const [submissions, setSubmissions] = useState([]);
    const [total, setTotal] = useState(null);
    const [queryTimeout, setQueryTimeout] = useState(null);
    const prevPage = usePrevious(page);
    const prevQuery = usePrevious(query);

    const setNewSubmissions = async () => {
        try {
            if (!loading) setLoading(true);

            const { data: submissionsData } = await makeGetRequest(FORM_SUBMISSIONS, {
                perPage: 10,
                pageNum: page,
                search: query || undefined,
            });

            setTotal(submissionsData.meta.total);
            setSubmissions(submissionsData?.results);
            setLoading(false);
        } catch (error) {
            error !== 'cancelled' &&
                openSnackbar(error?.errorMessage ?? 'An error occurred loading submissions.');
        }
    };

    useEffect(() => {
        (async () => await setNewSubmissions())();

        return () => {
            clearTimeout(queryTimeout);
        };
    }, []);

    useEffect(() => {
        if (!prevPage || prevPage === page) return;
        setNewSubmissions();
    }, [page]);

    useEffect(() => {
        if (!prevQuery || prevQuery === query) return;
        clearTimeout(queryTimeout);
        setQueryTimeout(
            setTimeout(async () => {
                await setNewSubmissions();
            }, 500)
        );
    }, [query]);

    const handleUrlChange = (query = {}) => {
        navigate(
            `${location.pathname}?${queryString.stringify({
                ...queryString.parse(location.search),
                page: undefined,
                ...query,
            })}`
        );
    };

    console.log({ query, queryTimeout });

    return (
        <Fragment>
            <Command>
                <Command.Breadcrumbs>
                    <Command.Breadcrumbs.Breadcrumb text="Forms" link="/forms" />
                    <Command.Breadcrumbs.Breadcrumb text="Submissions" />
                </Command.Breadcrumbs>
                <Command.Filters>
                    <Command.Filters.Search
                        key="search"
                        onChange={e => handleUrlChange({ query: e.target.value })}
                        value={query}
                    />
                </Command.Filters>
            </Command>

            {loading || (!!submissions && submissions?.length) ? (
                <Grid>
                    <Fragment>
                        <List loading={loading}>
                            {submissions?.map(({ _id, formId, name, meta: { timestamp } }) => (
                                <List.Item key={_id} link={`/forms/submissions/${_id}`}>
                                    <List.Item.Column>
                                        <Text bold>{name ?? formId}</Text>
                                        <Text>{`Sent ${`${format(
                                            parseISO(timestamp),
                                            'PPP p'
                                        )}`}`}</Text>
                                    </List.Item.Column>
                                </List.Item>
                            ))}
                        </List>

                        {!loading && (
                            <Fragment>
                                <Divider />
                                <Pagination
                                    meta
                                    onChange={page => handleUrlChange({ page })}
                                    pageTotal={submissions?.length}
                                    total={total}
                                    page={page}
                                    perPage={10}
                                    metaLabel="Submissions"
                                />
                            </Fragment>
                        )}
                    </Fragment>
                </Grid>
            ) : !!query?.length ? (
                <Empty title="Nothing here" text="Your filters didn't seem to find anything." />
            ) : (
                <Empty title="Hold tight" text="You don't have any submissions yet!" />
            )}
        </Fragment>
    );
};

export default withSnackbar(Submissions);
