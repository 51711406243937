import React, { useEffect, useState, Fragment } from 'react';
import { navigate } from 'gatsby-link';

import { makeGetRequest, makePostRequest, makePutRequest } from '@helpers/requests';
import { STORE_DISCOUNT, STORE_DISCOUNTS } from '@helpers/api';

import Loader from '@components/Loader';
import Panes from '@components/Panes';

import { useSnackbar } from '@components/Snackbar';
import Command from '@components/Command';
import Form from '@views/Store/Discounts/Discount/Form';
import Preview from '@views/Store/Discounts/Discount/Preview';
import Wrapper from '@views/Store/Discounts/Discount/Wrapper';

const Discount = ({ id, location }) => {
	const [openSnackbar] = useSnackbar();
	const [loading, setLoading] = useState(!!id);
	const [discount, setDiscount] = useState(null);

	useEffect(() => {
		(async () => {
			try {
				if (id) {
					const { data: discountData } = await makeGetRequest(STORE_DISCOUNT(id));
					setDiscount(discountData);
				}

				setLoading(false);
			} catch (error) {
				error !== 'cancelled' &&
					openSnackbar(
						error?.errorMessage ?? 'An error occurred when loading this discount.'
					);
				navigate('/store/discounts');
			}
		})();
	}, []);

	const handleSubmit = async (
		{
			name,
			code,
			active,
			type,
			amount,
			limit,
			startDate,
			endDate,
			categories,
			products,
			accountTypes,
		},
		actions
	) => {
		const data = {
			name,
			code: code || undefined,
			active,
			type,
			// convert amount to pence before sending to API (unless type is percentageOff as this is a single decimal unit)
			amount: type !== 'percentageOff' ? amount * 100 : amount,
			limit,
			startDate,
			endDate,
			categories: !!categories?.length ? categories : undefined,
			products: !!products?.length ? products : undefined,
			accountTypes: !!accountTypes?.length ? accountTypes : undefined,
		};

		try {
			const { data: discountData } = !!discount?._id
				? await makePutRequest(STORE_DISCOUNT(discount._id), data)
				: await makePostRequest(STORE_DISCOUNTS, data);

			openSnackbar(`${name} was successfully ${!!discount?._id ? 'saved' : 'created'}.`);

			if (!discount) {
				navigate(`/store/discounts/${discountData?._id}`);
			}
		} catch (error) {
			error !== 'cancelled' &&
				openSnackbar(
					error?.errorMessage ??
						`An error occurred ${
							!!discount?._id ? 'saving' : 'creating'
						} this discount.`
				);
		}
	};

	return (
		<Fragment>
			<Command>
				<Command.Breadcrumbs>
					<Command.Breadcrumbs.Breadcrumb text="Store" link="/store" />
					<Command.Breadcrumbs.Breadcrumb text="Discounts" link={`/store/discounts`} />
					<Command.Breadcrumbs.Breadcrumb text={discount?.name ?? 'Discount'} />
				</Command.Breadcrumbs>
			</Command>

			{loading ? (
				<Loader />
			) : (
				<Wrapper discount={discount} onSubmit={handleSubmit}>
					{props => (
						<Fragment>
							<Panes>
								<Panes.Left>
									<Form {...props} />
								</Panes.Left>
								<Panes.Right>
									<Preview {...props} />
								</Panes.Right>
							</Panes>
						</Fragment>
					)}
				</Wrapper>
			)}
		</Fragment>
	);
};

export default Discount;
