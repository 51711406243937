import React, { Fragment, useState } from 'react';

import Layout from '@components/Layout';
import Card from '@components/Card';
import Dropzone from 'react-dropzone';
import styles from './styles.module.scss';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Col, Divider, Grid, Row } from '@components/Grid';
import Input from '@components/Input';
import Alert from '@components/Alert';
import { makePostRequest } from '@helpers/requests';
import {
    USER_AVATAR,
    USER_EMAIL_CHANGE,
    USER_PASSWORD_REPLACE,
    USER_PASSWORD_RESET,
    USER_PROFILE,
} from '@helpers/api';
import { getUserFromToken } from '@helpers/auth';
import { updateUser } from '@actions/user';
import { withSnackbar } from '@components/Snackbar';
import { useDispatch } from 'react-redux';

const EditDetails = ({ openSnackbar, user }) => {
    const dispatch = useDispatch();
    const [profilePictureUploading, setProfilePictureUploading] = useState(false);
    const [changeEmailSent, setChangeEmailSent] = useState(false);

    const handleProfilePictureUpload = async files => {
        try {
            setProfilePictureUploading(true);
            const formData = new FormData();
            formData.append('file', files[0]);

            await makePostRequest(USER_AVATAR, formData, {
                'Content-Type': 'multipart/form-data',
            });

            const data = await getUserFromToken();
            dispatch(updateUser({ user: data.user }));

            setProfilePictureUploading(false);
        } catch (error) {
            error !== 'cancelled' &&
                openSnackbar(
                    error?.errorMessage ?? 'An error occurred uploading your user avatar.'
                );
            setProfilePictureUploading(false);
        }
    };

    const handleDetailsSubmit = async (values, actions) => {
        try {
            // Unfortunately luke decided this would be a POST so we have to send all essential user information back
            // (excluding stuff that can't be edited, please make this a PUT future luke).
            let data = {
                description: user?.description,
                avatar: user?.avatar,
                locale: user?.locale,
                notifications: user?.notifications,
                firstName: values.firstName,
                lastName: values.lastName,
            };

            const { data: userData } = await makePostRequest(USER_PROFILE, data);
            dispatch(updateUser({ user: userData }));
            openSnackbar('Account details were successfully saved.');
        } catch (error) {
            error !== 'cancelled' &&
                openSnackbar(
                    error?.errorMessage ??
                        'An error occurred when attempting to save your account details.'
                );
        }
    };

    const handleEmailChange = async (values, actions) => {
        try {
            await makePostRequest(USER_EMAIL_CHANGE, { newEmail: values.email });
            setChangeEmailSent(true);
            openSnackbar('Check your email for a reset email confirmation.');
        } catch (error) {
            error !== 'cancelled' &&
                openSnackbar(
                    error?.errorMessage ??
                        'An error occurred when attempting to changing your email.'
                );
        }
    };

    const handlePasswordChange = async ({ currentPassword, newPassword }, actions) => {
        try {
            await makePostRequest(USER_PASSWORD_REPLACE, { currentPassword, newPassword });
            openSnackbar('Your password was successfully changed.');
            actions.setStatus({ success: 'Your password was successfully changed.' });
        } catch (error) {
            error !== 'cancelled' &&
                openSnackbar(
                    error?.errorMessage ??
                        'An error occurred when attempting to changing your password.'
                );
        }
    };

    return (
        <Grid small>
            <Formik
                validationSchema={Yup.object().shape({
                    firstName: Yup.string().required('First name is required'),
                    lastName: Yup.string(),
                })}
                initialValues={{ firstName: user?.firstName, lastName: user?.lastName || '' }}
                onSubmit={handleDetailsSubmit}
            >
                {({ values, touched, errors, handleSubmit, handleChange, isSubmitting }) => (
                    <Card>
                        <Card.Title>Edit your profile</Card.Title>
                        <Card.Content>
                            <Row>
                                <Col xs={12} lg={6}>
                                    <Input
                                        label="First name"
                                        name="firstName"
                                        value={values.firstName}
                                        onChange={handleChange}
                                    />
                                    {touched.firstName && errors.firstName && (
                                        <Fragment>
                                            <Divider margin={2} />
                                            <Alert type="error" message={errors.firstName} />
                                        </Fragment>
                                    )}
                                    <Divider />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} lg={6}>
                                    <Input
                                        label="Last name"
                                        name="lastName"
                                        value={values.lastName}
                                        onChange={handleChange}
                                    />
                                    {touched.lastName && errors.lastName && (
                                        <Fragment>
                                            <Divider margin={2} />
                                            <Alert type="error" message={errors.lastName} />
                                        </Fragment>
                                    )}
                                </Col>
                            </Row>
                        </Card.Content>
                        <Card.Actions>
                            <Card.Actions.Action
                                text="Save"
                                onClick={handleSubmit}
                                submitting={isSubmitting}
                            />
                        </Card.Actions>
                    </Card>
                )}
            </Formik>
            <Divider />
            <Card>
                <Card.Title>Profile picture</Card.Title>
                <Card.Content>
                    <Dropzone
                        multiple={false}
                        onDrop={handleProfilePictureUpload}
                        accept={['.png', '.jpg', '.jpeg']}
                        maxSize={3145728}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div
                                className={`${styles.dropzone} ${
                                    profilePictureUploading ? styles.dropzoneUploading : ''
                                }`}
                                {...getRootProps()}
                            >
                                <div className={styles.dropzoneAvatar}>
                                    <span
                                        style={{
                                            backgroundImage: `url(${user?.avatar})`,
                                        }}
                                    />
                                </div>

                                <input {...getInputProps()} />

                                {profilePictureUploading ? (
                                    <span>Uploading...</span>
                                ) : (
                                    <Fragment>
                                        <p>
                                            Drag and drop or click here to upload a new profile
                                            picture
                                        </p>
                                        <span>Allowed file types - JPG, PNG</span>
                                        <span>Maximum file size - 3MB</span>
                                    </Fragment>
                                )}
                            </div>
                        )}
                    </Dropzone>
                </Card.Content>
            </Card>
            <Divider />
            <Formik
                validationSchema={Yup.object().shape({
                    email: Yup.string()
                        .email('Must be a valid email address')
                        .required('Email address is required'),
                })}
                initialValues={{ email: '' }}
                onSubmit={handleEmailChange}
            >
                {({ values, touched, errors, handleSubmit, handleChange, isSubmitting }) => (
                    <Card>
                        <Card.Title>Change email address</Card.Title>
                        <Card.Content>
                            <Row>
                                <Col xs={12} lg={6}>
                                    <Input
                                        secondary
                                        label="New email address"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                    />
                                    {touched.email && errors.email && (
                                        <Fragment>
                                            <Divider margin={2} />
                                            <Alert type="error" message={errors.email} />
                                        </Fragment>
                                    )}

                                    {changeEmailSent && (
                                        <Fragment>
                                            <Divider margin={2} />
                                            <Alert
                                                type="success"
                                                message={`New email confirmation sent to ${user.email}`}
                                            />
                                        </Fragment>
                                    )}
                                </Col>
                            </Row>
                        </Card.Content>
                        <Card.Actions>
                            <Card.Actions.Action
                                text="Request email change"
                                submitting={isSubmitting}
                                onClick={handleSubmit}
                            />
                        </Card.Actions>
                    </Card>
                )}
            </Formik>
            <Divider />
            <Formik
                validationSchema={Yup.object().shape({
                    currentPassword: Yup.string().required('Current password is required'),
                    newPassword: Yup.string().required('New password is required'),
                    newPasswordConfirmation: Yup.string().oneOf(
                        [Yup.ref('newPassword'), null],
                        'Passwords must match'
                    ),
                })}
                initialValues={{
                    currentPassword: '',
                    newPassword: '',
                    newPasswordConfirmation: '',
                }}
                onSubmit={handlePasswordChange}
            >
                {({
                    status,
                    values,
                    touched,
                    errors,
                    handleSubmit,
                    handleChange,
                    isSubmitting,
                }) => (
                    <Card>
                        <Card.Title>Change password</Card.Title>
                        <Card.Content>
                            <Row>
                                <Col xs={12} lg={6}>
                                    <Input
                                        secondary
                                        label="Current password"
                                        name="currentPassword"
                                        type="password"
                                        value={values.currentPassword}
                                        onChange={handleChange}
                                    />
                                    {touched.currentPassword && errors.currentPassword && (
                                        <Fragment>
                                            <Divider margin={2} />
                                            <Alert type="error" message={errors.currentPassword} />
                                        </Fragment>
                                    )}

                                    <Divider margin={2} />

                                    <Input
                                        secondary
                                        label="New password"
                                        name="newPassword"
                                        type="password"
                                        value={values.newPassword}
                                        onChange={handleChange}
                                    />
                                    {touched.newPassword && errors.newPassword && (
                                        <Fragment>
                                            <Divider margin={2} />
                                            <Alert type="error" message={errors.newPassword} />
                                        </Fragment>
                                    )}

                                    <Divider margin={2} />

                                    <Input
                                        secondary
                                        label="Confirm new password"
                                        name="newPasswordConfirmation"
                                        type="password"
                                        value={values.newPasswordConfirmation}
                                        onChange={handleChange}
                                    />
                                    {touched.newPasswordConfirmation &&
                                        errors.newPasswordConfirmation && (
                                            <Fragment>
                                                <Divider margin={2} />
                                                <Alert
                                                    type="error"
                                                    message={errors.newPasswordConfirmation}
                                                />
                                            </Fragment>
                                        )}

                                    {status?.success && (
                                        <Fragment>
                                            <Divider margin={2} />
                                            <Alert type="success" message={status?.success} />
                                        </Fragment>
                                    )}
                                </Col>
                            </Row>
                        </Card.Content>
                        <Card.Actions>
                            <Card.Actions.Action
                                text="Save"
                                submitting={isSubmitting}
                                onClick={handleSubmit}
                            />
                        </Card.Actions>
                    </Card>
                )}
            </Formik>
        </Grid>
    );
};

export default withSnackbar(EditDetails);
