import React from 'react';
// import SVG from 'react-inlinesvg';
import { Link } from 'gatsby';

import styles from './styles.module.scss';
import { Grid } from '@components/Grid';

const Footer = ({ forwardedRef }) => {
    return (
        <footer ref={forwardedRef}>
            <Grid>
                <div className={styles.footer}>
                    <p className={styles.footerCopyright}>
                        © Copyright Merlin Panel {new Date().getFullYear()}
                    </p>
                    <p
                        className={styles.footerCredit}
                        style={{ marginRight: process.env.NODE_ENV === 'development' ? 0 : null }}
                    >
                        Product of <a href="https://elementsoftworks.co.uk">Element Softworks</a>{' '}
                        Ltd
                    </p>
                </div>
            </Grid>
        </footer>
    );
};

export default React.forwardRef((props, ref) => <Footer {...props} forwardedRef={ref} />);
