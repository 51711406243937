import React from 'react';

import { Grid, Divider } from '@components/Grid';
import Input from '@components/Input';
import Button from '@components/Button';
import Select from '@components/Select';

export default ({
    values,
    handleChange,
    onCancel,
    handleSubmit,
    handleBlur,
    isSubmitting,
    errors,
    touched,
    ...props
}) => {
    return (
        <Grid>
            <h3>Create a New Website</h3>
            {inputs(values, handleChange).map(input => (
                <React.Fragment>
                    <Input
                        onBlur={handleBlur}
                        /* hint={touched[input.name] ? errors[input.name] : null} */
                        {...input}
                    />
                    <Divider margin={2} />
                </React.Fragment>
            ))}
            <Select
                value={values.meta.type}
                label="Merlin Plan"
                name="meta.type"
                onChange={handleChange}
            >
                <Select.Option label="Essential" value="essential" />
                <Select.Option label="Professional" value="professional" />
                <Select.Option label="Commercial" value="commercial" />
            </Select>
            <Divider margin={3} />
            <Button transparent onClick={onCancel}>
                Cancel
            </Button>
            <Button loading={isSubmitting} success onClick={handleSubmit}>
                Create
            </Button>
        </Grid>
    );
};

const inputs = (values = {}, onChange) => [
    {
        name: 'name',
        value: values?.name,
        label: 'Website Name',
        onChange,
    },
    {
        name: 'domain',
        value: values?.domain,
        label: 'Domain Name',
        onChange,
    },
    {
        name: 'email',
        value: values?.email,
        label: 'Email Address',
        onChange,
    },
    ,
    {
        name: 'meta.githubRepo',
        value: values?.meta?.githubRepo,
        label: 'Github Repo',
        onChange,
    },
];
