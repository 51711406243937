import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
    faExclamationTriangle as faSolidExclamationTriangle,
    faCaretDown as faSolidCaretDown,
    faChevronLeft as faSolidChevronLeft,
    faChevronRight as faSolidChevronRight,
    faTimes as faSolidTimes,
    faPlus as faSolidPlus,
    faCheck as faSolidCheck,
    faSearch as faSolidSearch,
    faExclamationCircle as faSolidExclamationCircle,
    faFolder as faSolidFolder,
    faFile as faSolidFile,
    faCloudUpload as faSolidCloudUpload,
    faFolderUpload as faSolidFolderUpload,
    faBold as faSolidBold,
    faItalic as faSolidItalic,
    faUnderline as faSolidUnderline,
    faCode as faSolidCode,
    faH1 as faSolidH1,
    faH2 as faSolidH2,
    faH3 as faSolidH3,
    faQuoteLeft as faSolidQuoteLeft,
    faListOl as faSolidListOl,
    faListUl as faSolidListUl,
    faLink as faSolidLink,
    faHorizontalRule as faSolidHorizontalRule,
    faRemoveFormat as faSolidRemoveFormat,
    faCircle as faSolidCircle,
    faInfoCircle as faSolidInfoCircle,
    faCheckCircle as faSolidCheckCircle,
    faList as faSolidList,
    faPen as faSolidPen,
    faEllipsisV as faSolidEllipsisV,
    faGripHorizontal as faSolidGridHorizontal,
    faCalendar as faSolidCalendar,
    faMinus as faSolidMinus,
} from '@fortawesome/pro-solid-svg-icons';
import {
    faEnvelope as faDuotoneEnvelope,
    faNewspaper as faDuotoneNewspaper,
    faServer as faDuotoneServer,
    faComment as faDuotoneComment,
    faShoppingBasket as faDuotoneShoppingBasket,
    faChartPie as faDuotoneChartPie,
    faTachometerAltFastest as faDuotoneTachometerAltFastest,
    faChevronUp as faDuotoneChevronUp,
    faChevronDown as faDuotoneChevronDown,
    faPen as faDuotonePen,
    faCogs as faDuotoneCogs,
    faMailbox as faDuotoneMailbox,
    faTrash as faDuotoneTrash,
    faBars as faDuotoneBars,
    faDrawSquare as faDuotoneDrawSquare,
    faCircleNotch as faDuotoneCircleNotch,
    faAngleRight as faDuotoneAngleRight,
    faAngleLeft as faDuotoneAngleLeft,
    faBrowser as faDuotoneBrowser,
    faCheckCircle as faDuotoneCheckCircle,
    faUser as faDuotoneUser,
    faUsersCrown as faDuotoneUsersCrown,
    faExternalLink as faDuotoneExternalLink,
    faExclamation as faDuotoneExclamation,
    faCheck as faDuotoneCheck,
    faFilePdf as faDuotoneFilePDF,
    faArrowsV as faDuotoneArrowsV,
    faPlus as faDuotonePlus,
    faEquals as faDuotoneEquals,
    faClone as faDuotoneClone,
    faCommentAltLines as faDuotoneCommentAltLines,
    faParagraph as faDuotoneParagraph,
    faScrubber as faDuotoneScrubber,
    faCheckSquare as faDuotoneCheckSquare,
    faQuestion as faDuotoneQuestion,
    faList as faDuotoneList,
    faUserHeadset as faDuotoneUserHeadset,
    faMousePointer as faDuotoneMousePointer,
    faLock as faDuotoneLock,
    faLockOpen as faDuotoneLockOpen,
    faLongArrowRight as faDuotoneLongArrowRight,
} from '@fortawesome/pro-duotone-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import { LayoutContext, HeightContext } from '@helpers/contexts';
import SEO from '@components/SEO';
import Navigation from '@components/Navigation';
import Footer from '@components/Footer';

// main site style
import '@styles/style.scss';
import { Location, useMatch } from '@reach/router';
import { setHelp } from '@actions/help';
import { bindActionCreators } from 'redux';
import ErrorBoundary from '@components/Error/Boundary';
import Loader from '@components/Loader';
import queryString from 'query-string';
import styles from '@components/Navigation/styles.module.scss';
import Menu from '@components/Menu';
import { makeGetRequest } from '@helpers/requests';
import { EDITOR_NAVIGATION, HELP } from '@helpers/api';
import usePrevious from '@helpers/hooks/usePrevious';
import { setWebsiteEditorNavigation } from '@actions/website';
import { useSnackbar } from '@components/Snackbar';

library.add(
    // solid icons
    faSolidExclamationTriangle,
    faSolidCaretDown,
    faSolidChevronLeft,
    faSolidChevronRight,
    faSolidTimes,
    faSolidPlus,
    faSolidCheck,
    faSolidSearch,
    faSolidExclamationCircle,
    faSolidFolder,
    faSolidFile,
    faSolidCloudUpload,
    faSolidFolderUpload,
    faSolidBold,
    faSolidItalic,
    faSolidUnderline,
    faSolidCode,
    faSolidH1,
    faSolidH2,
    faSolidH3,
    faSolidQuoteLeft,
    faSolidListOl,
    faSolidListUl,
    faSolidLink,
    faSolidHorizontalRule,
    faSolidRemoveFormat,
    faSolidCircle,
    faSolidInfoCircle,
    faSolidCheckCircle,
    faSolidList,
    faSolidPen,
    faSolidEllipsisV,
    faSolidGridHorizontal,
    faSolidCalendar,
    faSolidMinus,

    // duotone icons
    faDuotoneBars,
    faDuotoneEnvelope,
    faDuotoneNewspaper,
    faDuotoneServer,
    faDuotoneComment,
    faDuotoneShoppingBasket,
    faDuotoneChartPie,
    faDuotoneTachometerAltFastest,
    faDuotoneChevronUp,
    faDuotoneChevronDown,
    faDuotonePen,
    faDuotoneCogs,
    faDuotoneMailbox,
    faDuotoneTrash,
    faDuotoneDrawSquare,
    faDuotoneCircleNotch,
    faDuotoneAngleRight,
    faDuotoneAngleLeft,
    faDuotoneBrowser,
    faDuotoneCheckCircle,
    faDuotoneUser,
    faDuotoneUsersCrown,
    faDuotoneExternalLink,
    faDuotoneExclamation,
    faDuotoneCheck,
    faDuotoneFilePDF,
    faDuotoneArrowsV,
    faDuotonePlus,
    faDuotoneEquals,
    faDuotoneClone,
    faDuotoneCommentAltLines,
    faDuotoneParagraph,
    faDuotoneScrubber,
    faDuotoneCheckSquare,
    faDuotoneQuestion,
    faDuotoneList,
    faDuotoneUserHeadset,
    faDuotoneMousePointer,
    faDuotoneLock,
    faDuotoneLockOpen,
    faDuotoneLongArrowRight
);

const ScrollTop = ({ children, location: { pathname }, $elements = [] }) => {
    useEffect(() => {
        if ($elements) {
            $elements.map($element => {
                if ($element && $element.current) {
                    $element.current.scrollTo(0, 0);
                }
            });
        }
    }, [pathname]);

    return children || null;
};

const Layout = ({ children, className = '', location }) => {
    const [openSnackbar] = useSnackbar();
    const dispatch = useDispatch();
    const { user, website, help } = useSelector(({ user, website, help }) => ({
        user,
        website,
        help,
    }));
    const $main = useRef(null);
    const $content = useRef(null);
    const $footer = useRef(null);
    const $navigation = useRef(null);
    const $mobileNavigation = useRef(null);
    const $app = useRef(null);
    const $command = useRef(null);
    const $panes = useRef(null);
    const $paneLeft = useRef(null);
    const $paneRight = useRef(null);
    const [heights, setHeights] = useState({
        height: null,
        contentHeight: null,
        mainContent: null,
        footerHeight: null,
        commandHeight: null,
        mobileNavigationHeight: null,
    });
    const [scrolled, setScrolled] = useState(false);
    const [client, setClient] = useState(false);
    const prevWebsite = usePrevious(website);

    const handleContentResize = () => {
        handleSetHeights();
    };

    const handleContentScroll = () => {
        if ($main.current.scrollTop > 0) {
            setScrolled(true);
            return;
        }

        setScrolled(false);
    };

    const handleSetHeights = () => {
        setHeights({
            height: `calc(100vh - ${(($footer.current && $footer.current.offsetHeight) || 0) +
                (($command.current && $command.current.clientHeight) || 0) +
                (($mobileNavigation.current && $mobileNavigation.current.offsetHeight) || 0)}px)`,
            contentHeight: $content.current && $content.current.clientHeight,
            mainContent: $main.current && $main.current.clientHeight,
            footerHeight: $footer.current && $footer.current.clientHeight,
            commandHeight: $command.current && $command.current.clientHeight,
        });
    };

    useEffect(() => {
        setClient(true);

        if (!help) {
            (async () => {
                try {
                    const { data: help } = await makeGetRequest(HELP, null, null, true);
                    dispatch(setHelp(help));
                } catch (error) {
                    console.log({ '/lists/help error': error });
                }
            })();
        }

        if (typeof window !== 'undefined') {
            window.addEventListener('resize', handleContentResize);
            $main.current.addEventListener('scroll', handleContentScroll);

            handleContentScroll();
            handleSetHeights();
        }

        return () => {
            if (typeof window !== 'undefined') {
                $main.current.removeEventListener('scroll', handleContentScroll);
                window.removeEventListener('resize', handleContentResize);
            }
        };
    }, []);

    useEffect(() => {
        (async () => {
            try {
                if (
                    website?.features?.editor &&
                    ((!!prevWebsite && !!website && prevWebsite !== website) ||
                        (!prevWebsite && !!website))
                ) {
                    if (!website?.editor?.navigation) {
                        const { data: navigationData } = await makeGetRequest(
                            EDITOR_NAVIGATION,
                            null,
                            null,
                            true
                        );
                        dispatch(setWebsiteEditorNavigation(navigationData));
                    }
                }
            } catch (error) {
                openSnackbar(
                    error?.errorMessage ??
                        'An error occurred attempting to load your editor navigation, please try reselecting your website.'
                );
            }
        })();
    }, [website]);

    useEffect(() => {
        handleSetHeights();
    }, [$content.current, $main.current, $command.current, $footer.current]);

    useEffect(() => typeof window !== 'undefined' && handleSetHeights(), [location.pathname]);

    const ADMIN_ROUTES = [
        {
            text: 'Dashboard',
            path: '/admin/dashboard',
            match: useMatch('/admin/dashboard'),
            icon: 'tachometer-alt-fastest',
        },
        {
            text: 'Users',
            path: '/admin/users',
            match: useMatch('/admin/users'),
            icon: 'user',
            partial: true,
        },
        {
            text: 'User',
            path: '/admin/users/:id',
            match: useMatch('/admin/users/:id'),
            hidden: true,
        },
        {
            text: 'Websites',
            path: '/admin/websites',
            match: useMatch('/admin/websites'),
            icon: 'browser',
            partial: true,
        },
        {
            text: 'Website',
            path: '/admin/websites/:id',
            match: useMatch('/admin/websites/:id'),
            hidden: true,
        },
    ];

    const USER_ROUTES = [
        {
            text: 'Dashboard',
            path: '/dashboard',
            match: useMatch('/dashboard'),
            icon: 'tachometer-alt-fastest',
            disabled: false,
        },
        {
            text: 'Editor',
            path: '/editor',
            match: useMatch('/editor'),
            icon: 'draw-square',
            disabled: !website?.features?.editor,
            children: [
                ...(website?.editor?.navigation.map(({ label, id, folder }) => ({
                    text: label,
                    path: `/editor?${folder ? `folder=${id}` : `file=${id}`}`,
                    disabled: !website?.features?.editor,
                    getProps: ({ location }) => {
                        const parsed = queryString.parse(location.search);
                        const disabled = !website?.features?.editor;
                        const className = `${styles.navigationItemLink} ${
                            disabled ? styles.navigationItemLinkDisabled : ''
                        }`;

                        if (
                            location.pathname === '/editor' &&
                            ((folder && parsed.folder === id) || (!folder && parsed.file === id))
                        ) {
                            return {
                                className: `${className} ${styles.navigationItemLinkActive}`,
                            };
                        }
                    },
                })) ?? []),
                {
                    text: 'Settings',
                    path: '/settings/editor',
                    match: useMatch('/settings/editor'),
                    disabled: !website?.features?.editor,
                },
            ],
        },
        {
            text: 'Analytics',
            path: '/analytics',
            match: useMatch('/analytics'),
            icon: 'chart-pie',
            disabled: !website?.features?.analytics,
        },
        {
            text: 'DNS',
            path: '/dns',
            match: useMatch('/dns'),
            icon: 'server',
            disabled: !website?.features?.dns,
        },
        {
            text: 'Emails',
            path: '/emails',
            match: useMatch('/emails'),
            icon: 'envelope',
            disabled: !website?.features?.email,
            children: [
                {
                    text: 'Inbox',
                    path: '/emails/inbox',
                    match: useMatch('/emails/inbox'),
                    disabled: !website?.features?.email,
                },
                {
                    text: 'Accounts',
                    path: '/emails/accounts',
                    match: useMatch('/emails/accounts'),
                    disabled: !website?.features?.email,
                },
                {
                    text: 'Settings',
                    path: '/settings/emails',
                    match: useMatch('/settings/emails'),
                    disabled: !website?.features?.email,
                },
            ],
        },
        {
            text: 'Chat',
            path: '/chat',
            match: useMatch('/chat'),
            icon: 'comment',
            disabled: !website?.features?.chat,
            children: [
                {
                    text: 'Overview',
                    path: '/chat',
                    match: useMatch('/chat'),
                    partial: false,
                    disabled: !website?.features?.chat,
                },
                {
                    text: 'Conversations',
                    path: '/chat/conversations',
                    match: useMatch('/chat/conversations'),
                    disabled: !website?.features?.chat,
                },
                {
                    text: 'Settings',
                    path: '/settings/chat',
                    match: useMatch('/settings/chat'),
                    disabled: !website?.features?.chat,
                },
            ],
        },
        {
            text: 'Store',
            path: '/store',
            match: useMatch('/store'),
            icon: 'shopping-basket',
            disabled: !website?.features?.store,
            children: [
                // {
                //     text: 'Overview',
                //     path: '/store/overview',
                //     match: useMatch('/store/overview'),
                //     disabled: !website?.features?.store,
                // },
                {
                    text: 'Products',
                    path: '/store/products',
                    match: useMatch('/store/products'),
                    disabled: !website?.features?.store,
                    children: [
                        {
                            path: '/store/products/create',
                            match: useMatch('/store/products/create'),
                            disabled: !website?.features?.store,
                        },
                        {
                            path: '/store/products/:id',
                            match: useMatch('/store/products/:id'),
                            disabled: !website?.features?.store,
                        },
                    ],
                },

                {
                    text: 'Categories',
                    path: '/store/categories',
                    match: useMatch('/store/categories'),
                    disabled: !website?.features?.store,
                    children: [
                        {
                            text: 'Category',
                            path: '/store/categories/create',
                            match: useMatch('/store/categories/create'),
                            disabled: !website?.features?.store,
                        },
                        {
                            text: 'Category',
                            path: '/store/categories/:id',
                            match: useMatch('/store/categories/:id'),
                            disabled: !website?.features?.store,
                        },
                    ],
                },
                {
                    text: 'Discounts',
                    path: '/store/discounts',
                    match: useMatch('/store/discounts'),
                    disabled: !website?.features?.store,
                    children: [
                        {
                            path: '/store/discounts/create',
                            match: useMatch('/store/discounts/create'),
                            disabled: !website?.features?.store,
                        },
                        {
                            path: '/store/discounts/:id',
                            match: useMatch('/store/discounts/:id'),
                            disabled: !website?.features?.store,
                        },
                    ],
                },
                {
                    text: 'Checkouts',
                    path: '/store/checkouts',
                    match: useMatch('/store/checkouts'),
                    disabled: !website?.features?.store,
                    children: [
                        {
                            text: 'Checkout',
                            path: '/store/checkouts/:id',
                            match: useMatch('/store/checkouts/:id'),
                            disabled: !website?.features?.store,
                        },
                    ],
                },

                {
                    text: 'Orders',
                    path: '/store/orders',
                    match: useMatch('/store/orders'),
                    disabled: !website?.features?.store,
                    children: [
                        {
                            text: 'Order',
                            path: '/store/orders/:id',
                            match: useMatch('/store/orders/:id'),
                            disabled: !website?.features?.store,
                        },
                    ],
                },
                {
                    text: 'Inventory',
                    path: '/store/inventory',
                    match: useMatch('/store/inventory'),
                    disabled: !website?.features?.store,
                },
                {
                    text: 'Fields',
                    path: '/store/fields',
                    match: useMatch('/store/fields'),
                    disabled: !website?.features?.store,
                    children: [
                        {
                            text: 'Field',
                            path: '/store/fields/:id',
                            match: useMatch('/store/fields/:id'),
                            disabled: !website?.features?.store,
                        },
                    ],
                },
                {
                    text: 'Settings',
                    path: '/settings/store',
                    match: useMatch('/settings/store'),
                    disabled: !website?.features?.store,
                },
            ],
        },
        {
            text: 'Users',
            path: '/users',
            icon: 'user',
            disabled: !website?.features?.users,
            children: [
                {
                    text: 'Users',
                    path: '/users',
                    match: useMatch('/users'),
                    disabled: !website?.features?.users,
                    children: [
                        {
                            text: 'User',
                            path: '/users/user/create',
                            match: useMatch('/users/user/create'),
                            disabled: !website?.features?.users,
                        },
                        {
                            text: 'User',
                            path: '/users/user/:id',
                            match: useMatch('/users/user/:id'),
                            disabled: !website?.features?.users,
                        },
                    ],
                },
                {
                    text: 'Types',
                    path: '/users/types',
                    match: useMatch('/users/types'),
                    disabled: !website?.features?.users,
                    children: [
                        {
                            text: 'Type',
                            path: '/users/types/:id',
                            match: useMatch('/users/types/:id'),
                            disabled: !website?.features?.users,
                        },
                    ],
                },
                {
                    text: 'Fields',
                    path: '/users/fields',
                    match: useMatch('/users/fields'),
                    disabled: !website?.features?.users,
                    children: [
                        {
                            text: 'Field',
                            path: '/users/fields/create',
                            match: useMatch('/users/fields/:id'),
                            disabled: !website?.features?.users,
                        },
                        {
                            text: 'Field',
                            path: '/users/fields/:id',
                            match: useMatch('/users/fields/:id'),
                            disabled: !website?.features?.users,
                        },
                    ],
                },
                {
                    text: 'Requests',
                    path: '/users/requests',
                    match: useMatch('/users/requests'),
                    disabled: !website?.features?.users,
                    children: [
                        {
                            text: 'Request',
                            path: '/users/requests/request',
                            match: useMatch('/users/requests/request/:id'),
                            disabled: !website?.features?.users,
                        },
                    ],
                },
            ],
        },
        {
            text: 'Forms',
            path: '/forms',
            match: useMatch('/forms'),
            icon: 'mailbox',
            disabled: !website?.features?.enquiries,
            children: [
                {
                    text: 'Overview',
                    path: '/forms/overview',
                    match: useMatch('/forms/overview'),
                    disabled: !website?.features?.enquiries,
                },
                {
                    text: 'Forms',
                    path: '/forms',
                    match: useMatch('/forms'),
                    disabled: !website?.features?.enquiries,
                    children: [
                        {
                            text: 'Form',
                            path: '/forms/form/create',
                            match: useMatch('/forms/form/create'),
                            disabled: !website?.features?.enquiries,
                        },
                        {
                            text: 'Form',
                            path: '/forms/form/:id',
                            match: useMatch('/forms/form/:id'),
                            disabled: !website?.features?.enquiries,
                        },
                    ],
                },
                {
                    text: 'Submissions',
                    path: '/forms/submissions',
                    match: useMatch('/forms/submissions'),
                    disabled: !website?.features?.enquiries,
                    children: [
                        {
                            text: 'Submission',
                            path: '/forms/submissions/:id',
                            match: useMatch('/forms/submissions/:id'),
                            disabled: !website?.features?.enquiries,
                        },
                    ],
                },

                {
                    hidden: true,
                    text: 'Newsletters',
                    path: '/forms/newsletters',
                    match: useMatch('/forms/newsletters'),
                    disabled: !website?.features?.enquiries,
                    children: [
                        {
                            text: 'Subscriber',
                            path: '/forms/newsletters/:id',
                            match: useMatch('/forms/newsletters/:id'),
                            disabled: !website?.features?.enquiries,
                        },
                    ],
                },
            ],
        },
        {
            text: 'Settings',
            path: '/settings',
            match: useMatch('/settings'),
            icon: 'cogs',
            disabled: !website?.features?.settings,
            children: [
                {
                    text: 'Website',
                    path: '/settings/website',
                    match: useMatch('/settings/website'),
                    disabled: false,
                },
                {
                    text: 'Billing',
                    path: '/settings/billing',
                    match: useMatch('/settings/billing'),
                    disabled: false,
                    children: [
                        {
                            hidden: true,
                            text: 'Checkout',
                            path: '/settings/billing/checkout',
                            match: useMatch('/settings/billing/checkout'),
                        },
                    ],
                },
                {
                    text: 'Editor',
                    path: '/settings/editor',
                    match: useMatch('/settings/editor'),
                    disabled: !website?.features?.editor,
                },
                {
                    text: 'Emails',
                    path: '/settings/emails',
                    match: useMatch('/settings/emails'),
                    disabled: !website?.features?.email,
                },
                {
                    text: 'Chat',
                    path: '/settings/chat',
                    match: useMatch('/settings/chat'),
                    disabled: !website?.features?.chat,
                },
                {
                    text: 'Store',
                    path: '/settings/store',
                    match: useMatch('/settings/store'),
                    disabled: !website?.features?.store,
                },
                {
                    text: 'Permissions',
                    path: '/settings/permissions',
                    match: useMatch('/settings/permissions'),
                    disabled: false,
                    children: [
                        {
                            hidden: true,
                            text: 'Checkout',
                            path: '/settings/permissions/role/create',
                            match: useMatch('/settings/permissions/role/create'),
                        },
                        {
                            hidden: true,
                            text: 'Checkout',
                            path: '/settings/permissions/role/:id',
                            match: useMatch('/settings/permissions/role/:id'),
                        },
                    ],
                },
                {
                    text: 'Team Members',
                    path: '/settings/team-members',
                    match: useMatch('/settings/team-members'),
                    disabled: false,
                    children: [
                        {
                            hidden: true,
                            text: 'Team Member',
                            path: '/settings/team-members/team-member/create',
                            match: useMatch('/settings/team-members/team-member/create'),
                        },
                        {
                            hidden: true,
                            text: 'Team Member',
                            path: '/settings/team-members/team-member/:id',
                            match: useMatch('/settings/team-members/team-member/:id'),
                        },
                    ],
                },
            ],
        },
    ];

    const ACCOUNT_ROUTES = [
        {
            text: 'Websites',
            path: '/websites',
            match: useMatch('/websites'),
        },
        {
            text: 'Billing',
            path: '/account/billing',
            match: useMatch('/account/billing'),
            disabled: true,
            hidden: false,
        },
        {
            text: 'Referrals',
            path: '/account/referrals',
            match: useMatch('/account/referrals'),
            disabled: true,
            hidden: false,
        },
        {
            text: 'Profile',
            path: '/account/profile',
            match: useMatch('/account/profile'),
            children: [
                {
                    text: 'Edit profile',
                    path: '/account/profile/edit',
                    match: useMatch('/account/profile/edit'),
                    hidden: true,
                },
                {
                    text: 'Forgot password',
                    path: '/account/profile/forgot-password',
                    match: useMatch('/account/profile/forgot-password'),
                    hidden: true,
                },
                {
                    text: 'Forgot password confirm',
                    path: '/account/profile/forgot-password-confirm',
                    match: useMatch('/account/profile/forgot-password-confirm'),
                    hidden: true,
                },
                {
                    text: 'Verify email',
                    path: '/account/profile/verify-email',
                    match: useMatch('/account/profile/verify-email'),
                    hidden: true,
                },
            ],
        },
        {
            text: 'Admin',
            path: '/admin',
            match: useMatch('/admin'),
            hidden: !user?.permissions?.isAdmin,
        },
    ];

    const routeMatches = route => {
        if (route.match) return true;

        if (route.children) {
            if (route.children.some(routeMatches)) return true;
        }
    };

    return (
        <div className={`app ${className}`} ref={$app}>
            <SEO />

            <LayoutContext.Provider
                value={{
                    $app,
                    $content,
                    $main,
                    $footer,
                    $navigation,
                    $mobileNavigation,
                    $command,
                    $panes,
                    $paneLeft,
                    $paneRight,
                    scrolled,
                }}
            >
                {/* Prevent navigation until a user exists and the user has selected a single website to operate and the
                user isn't on the website selection view */}
                {!!user && !!website && USER_ROUTES.some(routeMatches) && (
                    <Navigation
                        $navigation={$navigation}
                        $mobileNavigation={$mobileNavigation}
                        routes={USER_ROUTES}
                    />
                )}

                {/*Prevent administration navigation until a user has no website selected and has navigated to an administration route*/}
                {user?.permissions?.isAdmin && ADMIN_ROUTES.some(routeMatches) && (
                    <Navigation
                        $navigation={$navigation}
                        $mobileNavigation={$mobileNavigation}
                        routes={ADMIN_ROUTES}
                        admin
                    />
                )}

                <ScrollTop location={location} $elements={[$main]}>
                    <main ref={$main}>
                        <div ref={$content} style={{ flexBasis: heights.height }}>
                            {ACCOUNT_ROUTES.some(routeMatches) && <Menu routes={ACCOUNT_ROUTES} />}

                            <HeightContext.Provider value={heights}>
                                <ErrorBoundary>{client ? children : <Loader />}</ErrorBoundary>
                            </HeightContext.Provider>
                        </div>
                        <Footer ref={$footer} />
                    </main>
                </ScrollTop>
            </LayoutContext.Provider>
        </div>
    );
};

const LayoutWithLocation = props => (
    <Location>{locationContext => <Layout {...locationContext} {...props} />}</Location>
);

export default LayoutWithLocation;
