import React, { useEffect, useState, useRef, Fragment } from 'react';

import { Grid, Row, Col, Divider } from '@components/Grid';
import Card from '@components/Card';
import Command from '@components/Command';

import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { returnProfilePicture } from '@helpers/index';

import { makeGetRequest, makePutRequest } from '@helpers/requests';
import {
    ADMIN_WEBSITE,
    ADMIN_WEBSITE_NETLIFY_LINK,
    ADMIN_WEBSITE_NETLIFY_WEBSITES,
} from '@helpers/api';
import { Link } from 'gatsby';
import { startCase } from 'lodash';
import moment from 'moment';
import { withSnackbar } from '@components/Snackbar';
import Select from '@components/Select';
import Button from '@components/Button';

const Website = ({ id, openSnackbar }) => {
    const [users, setUsers] = useState([]);
    const [website, setWebsite] = useState(null);
    const [netlifyWebsites, setNetlifyWebsites] = useState([]);
    const [selectedNetlifyWebsite, setSelectedNetlifyWebsite] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                if (!id) return throw null;
                const { data: websiteData } = await makeGetRequest(ADMIN_WEBSITE(id));
                const { data: netlifyWebsitesData } = await makeGetRequest(
                    ADMIN_WEBSITE_NETLIFY_WEBSITES
                );
                setUsers(websiteData?.users);
                setWebsite(websiteData?.website);
                setNetlifyWebsites(netlifyWebsitesData);
            } catch (error) {
                error !== 'cancelled' && openSnackbar(error?.errorMessage ?? 'An error occurred loading this website.');
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const handleSaveNetlifyInstance = async () => {
        try {
            const { data: newWebsiteData } = await makePutRequest(
                ADMIN_WEBSITE_NETLIFY_LINK(website?._id),
                { siteId: selectedNetlifyWebsite }
            );
            setWebsite(newWebsiteData);
            openSnackbar('Website netlify instance successfully updated.');
        } catch (error) {
            error !== 'cancelled' && openSnackbar(
                error?.errorMessage ?? 'An error occurred setting this websites netlify instance.'
            );
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <Grid>
                <Command title="Loading..." />
                <h2>Website Information</h2>
                <Card loading={loading}>
                    <Card.List>
                        <Card.List.Item title description>
                            <Card.List.Item.Avatar />
                            <Card.List.Item.Action />
                            <Card.List.Item.Position.Bottom.Left emphasis />
                        </Card.List.Item>
                    </Card.List>
                </Card>
                <h2>Users</h2>
                <Card loading={loading}>
                    <Card.List>
                        <Card.List.Item title description>
                            <Card.List.Item.Avatar />
                            <Card.List.Item.Action />
                            <Card.List.Item.Position.Bottom.Left emphasis />
                        </Card.List.Item>
                    </Card.List>
                </Card>
            </Grid>
        );
    }

    return (
        <Grid>
            <Command title={website?.name ?? 'Unknown'} />
            <h2>Website Information</h2>
            <Card>
                <Card.List>
                    <Card.List.Item title="Merlin Plan">
                        <Card.List.Item.Content.Right>
                            {startCase(website?.meta?.type ?? 'Unknown')}
                        </Card.List.Item.Content.Right>
                    </Card.List.Item>
                    {website?.store?.currency && (
                        <Card.List.Item title="Store Currency">
                            <Card.List.Item.Content.Right>
                                {`${website?.store?.currency?.symbol}${website?.store?.currency?.code}`}
                            </Card.List.Item.Content.Right>
                        </Card.List.Item>
                    )}
                    <Card.List.Item title="Last Updated Timestamp">
                        <Card.List.Item.Content.Right>
                            {moment(website?.meta?.lastUpdatedTimestamp).format('Do MMM YYYY')}
                        </Card.List.Item.Content.Right>
                    </Card.List.Item>
                    <Card.List.Item title="GitHub Repository">
                        <Card.List.Item.Content.Right>
                            {website?.meta?.githubRepo ?? ''}
                        </Card.List.Item.Content.Right>
                    </Card.List.Item>
                </Card.List>
            </Card>
            <Divider />
            <h2>Users</h2>
            <Card>
                <Card.List>
                    {users.map(user => (
                        <Card.List.Item
                            key={user._id}
                            title={`${user.firstName} ${user.lastName} - ${user.email}`}
                            description={user._id}
                        >
                            <Card.List.Item.Avatar size={50} src={returnProfilePicture(user)} />
                            <Card.List.Item.Content.Right>
                                <Link to={`/admin/users/${user._id}`}>
                                    <Card.List.Item.Icon icon={faChevronRight} />
                                </Link>
                            </Card.List.Item.Content.Right>
                        </Card.List.Item>
                    ))}
                </Card.List>
            </Card>
            <Divider />
            <h2>Netlify Instance</h2>

            {!!netlifyWebsites.length && (
                <Row>
                    <Col xs={12} mg={6} lg={8}>
                        <Row bottom="xs">
                            <Col xsGrow>
                                <Select
                                    secondary
                                    value={selectedNetlifyWebsite || website?.netlify?.siteId || ''}
                                    onChange={e => setSelectedNetlifyWebsite(e.target.value)}
                                >
                                    {netlifyWebsites.map(website => (
                                        <Select.Option label={website?.name} value={website?.id} />
                                    ))}
                                </Select>
                            </Col>
                            <Col xsShrink>
                                <Button text="Save Instance" onClick={handleSaveNetlifyInstance} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
        </Grid>
    );
};

export default withSnackbar(Website);
