import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';

const Wrapper = ({ children, onSubmit, role, teamMember }) => {
    return (
        <Formik
            enableReinitialize
            validationSchema={Yup.object().shape({
                name: !!teamMember
                    ? Yup.mixed().nullable()
                    : Yup.string().required('Name is required.'),
                description: !!teamMember
                    ? Yup.mixed().nullable()
                    : Yup.string().required('Description is required.'),
                permissions: Yup.array()
                    .of(Yup.string())
                    .min(1, 'Must have at least 1 permission.')
                    .required('Permissions are required.'),
            })}
            initialValues={{
                name: role?.name,
                description: role?.description,
                permissions: role?.permissions ?? [],
            }}
            onSubmit={onSubmit}
        >
            {formikProps => {
                const props = {
                    role: role ? role : teamMember?.role,
                };

                return children({ ...formikProps, ...props });
            }}
        </Formik>
    );
};

export default Wrapper;
