import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Link } from 'gatsby';
import { Formik } from 'formik';
import { faChevronRight, faPlusSquare } from '@fortawesome/pro-solid-svg-icons';

import Card from '@components/Card';
import Modal from '@components/Modal';
import { Grid } from '@components/Grid';
import Command from '@components/Command';

import { ADMIN_WEBSITE, API_ROUTE } from '@helpers/api';
import { makeGetRequest, makePostRequest } from '@helpers/requests';

import FormikNewWebsite from './Form';
import { returnProfilePicture } from '@helpers';
import { withSnackbar } from '@components/Snackbar';

const Websites = ({ openSnackbar }) => {
    const [websites, setwebsites] = useState([]);
    const [query, setQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(false);

    const toggleModal = () => setModal(!modal);
    const renderFormikForm = props => <FormikNewWebsite {...props} onCancel={toggleModal} />; // Used in <Formik />

    useEffect(() => {
        makeGetRequest(ADMIN_WEBSITE)
            .then(({ data }) => setwebsites(data))
            .finally(() => setLoading(false));
    }, []);

    // Allows webwebsites to be filtered by the previously input query in our search bar
    function filterByQuery({ _id = '', name = '', domain = '' }) {
        // Simple includes search on _id, domain, and name fields
        return _id.includes(query) || name.includes(query) || domain.includes(query);
    }

    async function onNewWebsiteSubmit(values, actions) {
        try {
            const { data: newWebsite } = await makePostRequest(ADMIN_WEBSITE, values);
            const { data: websites } = await makeGetRequest(ADMIN_WEBSITE);
            setwebsites(websites);
        } catch (error) {
            console.log(error);
            error !== 'cancelled' &&
                openSnackbar('Failed to create website, check console for details.');
        }

        actions.setSubmitting(false);
        toggleModal();
    }

    return (
        <React.Fragment>
            <Modal isOpen={modal} onClose={toggleModal}>
                <Modal.Content>
                    <Formik
                        render={renderFormikForm}
                        onSubmit={onNewWebsiteSubmit}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                    />
                </Modal.Content>
            </Modal>
            <Grid>
                <Command title="Websites">
                    <Command.Filters>
                        <Command.Filters.Search
                            key="search"
                            onChange={({ target }) => setQuery(target.value)}
                            value={query}
                        />
                    </Command.Filters>
                    <Command.Action
                        success
                        text="New Website"
                        icon={faPlusSquare}
                        onClick={toggleModal}
                        submitting={false}
                    />
                </Command>

                <Card loading={loading}>
                    <Card.List>
                        {loading ? (
                            <Card.List.Item title description>
                                <Card.List.Item.Avatar />
                                <Card.List.Item.Action />
                                <Card.List.Item.Position.Bottom.Left emphasis />
                            </Card.List.Item>
                        ) : (
                            websites.filter(filterByQuery).map(website => (
                                <Card.List.Item
                                    key={website?._id}
                                    title={
                                        <span>
                                            {website?.name} -{' '}
                                            <a
                                                href={website?.domain}
                                                target="_blank"
                                                rel="noopen noreferrer"
                                            >
                                                {website?.domain}
                                            </a>
                                        </span>
                                    }
                                    description={website?._id}
                                >
                                    <Card.List.Item.Avatar
                                        size={50}
                                        src={`${API_ROUTE}/editor/favicon.png?websiteId=${website?._id}`}
                                    />
                                    {/* <Card.List.Item.Icon icon={faBrowser} /> */}
                                    <Card.List.Item.Content.Right>
                                        <Link to={`/admin/websites/${website?._id}`}>
                                            <Card.List.Item.Icon icon={faChevronRight} />
                                        </Link>
                                    </Card.List.Item.Content.Right>
                                </Card.List.Item>
                            ))
                        )}
                    </Card.List>
                </Card>
            </Grid>
        </React.Fragment>
    );
};

export default withSnackbar(Websites);

const initialValues = {
    name: '',
    domain: '',
    email: '',
    store: {},
    meta: { type: '', githubRepo: '' },
};

const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    domain: Yup.string().required(),
    email: Yup.string()
        .email()
        .required(),
    store: Yup.object(),
    meta: Yup.object().shape({
        type: Yup.string().oneOf(['essential', 'professional', 'commercial']),
        githubRepo: Yup.string().required(),
    }),
});
