import React, { useEffect, useState } from 'react';

import { Grid } from '@components/Grid';
import Card from '@components/Card';
import Command from '@components/Command';

import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

import { makeGetRequest } from '@helpers/requests';
import { returnProfilePicture } from '@helpers/index';
import { ADMIN_USERS } from '@helpers/api';
import moment from 'moment';
import { Link } from 'gatsby';

const AdminUsers = () => {
    const [users, setUsers] = useState([]);
    const [query, setQuery] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        makeGetRequest(ADMIN_USERS)
            .then(({ data }) => setUsers(data))
            .finally(() => setLoading(false));
    }, []);

    // Allows users to be filtered by the previously input query in our search bar
    function filterByQuery({ firstName = '', lastName = '', email = '' }) {
        // Simple includes search on name and email fields
        return firstName.includes(query) || lastName.includes(query) || email.includes(query);
    }

    return (
        <Grid>
            <Command title="Users">
                <Command.Filters>
                    <Command.Filters.Search
                        key="search"
                        onChange={({ target }) => setQuery(target.value)}
                        value={query}
                    />
                </Command.Filters>
            </Command>

            <Card loading={loading}>
                <Card.List>
                    {loading ? (
                        <Card.List.Item title description>
                            <Card.List.Item.Avatar />
                            <Card.List.Item.Action />
                            <Card.List.Item.Position.Bottom.Left emphasis />
                        </Card.List.Item>
                    ) : (
                        users.filter(filterByQuery).map(user => (
                            <Card.List.Item
                                key={user._id}
                                title={`${user.firstName} ${user.lastName} - ${user.email} (${user._id})`}
                                description={`Last Login: ${moment(user.lastLogin).format(
                                    'Do MMM YYYY'
                                )} | ${user.locale.city}`}
                            >
                                <Card.List.Item.Avatar size={50} src={returnProfilePicture(user)} />
                                <Card.List.Item.Content.Right>
                                    <Link to={`/admin/users/${user._id}`}>
                                        <Card.List.Item.Icon icon={faChevronRight} />
                                    </Link>
                                </Card.List.Item.Content.Right>
                            </Card.List.Item>
                        ))
                    )}
                </Card.List>
            </Card>
        </Grid>
    );
};

function returnIconFromPermission(permission) {
    switch (permission) {
        case 'emailVerified':
        case 'phoneVerified':
        case 'isAdmin':
        case 'twoFactorEnabled':
        case 'termsAgreed':
            return <Card.List.Item.Icon icon="envelope-open" />;
    }
}

export default AdminUsers;
