import { USER_PROFILE, USER_REFRESH } from './api';
import { updateUser } from '@actions/user';
import { Axios } from '@helpers/requests';
import store from '../store';

export function getUserFromNewToken() {
    const user = store.getState().user;
    const refreshToken = user && user.refreshToken;
    let token = null;
    return new Promise((resolve, reject) => {
        Axios.post(
            USER_REFRESH,
            { email: user.email, userId: user._id },
            { headers: { Authorization: 'Bearer ' + refreshToken } }
        )
            .then(response => {
                token = response.data.access_token;
                return Axios.get(USER_PROFILE, { headers: { Authorization: 'Bearer ' + token } });
            })
            .then(response => {
                const user = response.data;
                store.dispatch(updateUser({ user, token, refreshToken }));
                resolve({ user, token, refreshToken });
            })
            .catch(err => {
                reject(err);
            });
    });
}

export function getUserFromToken(data) {
    const user = store.getState().user;
    let token = (data && data.access_token) || (user && user.token);
    let refreshToken = (data && data.refresh_token) || (user && user.refreshToken);

    // A token has been returned from the server, we now get user information using said token
    return new Promise((resolve, reject) => {
        Axios.get(USER_PROFILE, { headers: { Authorization: 'Bearer ' + token } })
            .then(response => {
                // Store this response data in a object and resolve it
                resolve({
                    user: response.data,
                    token,
                    refreshToken,
                });
            })
            .catch(error => {
                if (!error) {
                    reject(false);
                }

                reject(false);
            });
    });
}
