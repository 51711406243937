import React from 'react';
import PropTypes from 'prop-types';
import { subcomponent } from '@helpers';

// card sub components
import Image from './Image';
import Title from './Title';
import Description from './Description';
import Content from './Content';
import Actions from './Actions';
import List from './List';
import Position from './Position';
import Avatar from './Avatar';

import styles from './styles.module.scss';

const Card = ({
    onClick = null,
    hoverable = false,
    depth = 0,
    children = null,
    center = false,
    className = '',
    inverse = false,
    disabled = false,
    loading = false,
    divider = true,
    style,
}) => {
    const cardDescription = subcomponent(children, Description, true);
    const cardTitle = subcomponent(children, Title, true);
    const cardAvatar = subcomponent(children, Avatar, true);
    const cardImage = subcomponent(children, Image, true);
    const cardContent = subcomponent(children, Content, true, { loading });
    const cardActions = subcomponent(children, Actions, true);
    const cardList = subcomponent(children, List, false, { loading });
    const cardTopLeft = subcomponent(children, Position.Top.Left, true);
    const cardTopRight = subcomponent(children, Position.Top.Right, true);
    const cardBottomLeft = subcomponent(children, Position.Bottom.Left, true);
    const cardBottomRight = subcomponent(children, Position.Bottom.Right, true);
    const depthClass =
        (depth === 0 && styles.cardDepthNone) ||
        (depth === 1 && styles.cardDepthBottom) ||
        (depth === 2 && styles.cardDepthMiddle) ||
        (depth === 3 && styles.cardDepthTop) ||
        '';
    const centerClass = center ? styles.cardCenter : '';
    const inverseClass = inverse ? styles.cardInverse : '';
    const hoverableClass = !disabled && (hoverable || onClick) ? styles.cardHoverable : '';
    const disabledClass = disabled ? styles.cardDisabled : '';
    const loadingClass = loading ? styles.cardIsLoading : '';
    const dividerClass = divider ? styles.cardDivider : '';

    return (
        <div
            style={{
                gridTemplateRows: `auto auto auto auto 1fr auto auto`,
                gridTemplateColumns: `${
                    cardImage?.props?.position === 'left' ? '1fr' : 'auto'
                } auto 1fr ${cardImage?.props?.position === 'right' ? '1fr' : 'auto'}`,
                ...(style ?? {}),
            }}
            className={`${styles.card} ${loadingClass} ${hoverableClass} ${disabledClass} ${centerClass} ${depthClass} ${inverseClass} ${dividerClass} ${className}`}
            onClick={disabled ? undefined : onClick}
            tabIndex={!disabled && (hoverable || onClick) ? 0 : null}
            onKeyPress={e =>
                !disabled && onClick && (e.keyCode || e.charCode) === 13 ? onClick() : undefined
            }
        >
            {cardImage}
            {cardAvatar}
            {cardTopLeft || cardTopRight ? (
                <div className={`${styles.cardPositionEnd} ${styles.cardPositionTop}`}>
                    {cardTopLeft} {cardTopRight}
                </div>
            ) : null}
            {cardTitle}
            {cardDescription}
            {cardContent}
            {cardList}

            {cardActions}
            {cardBottomLeft || cardBottomRight ? (
                <div className={`${styles.cardPositionEnd} ${styles.cardPositionBottom}`}>
                    {cardBottomLeft} {cardBottomRight}
                </div>
            ) : null}
        </div>
    );
};

Card.propTypes = {
    onClick: PropTypes.func,
    hoverable: PropTypes.bool,
    depth: PropTypes.number,
};

Card.Avatar = Avatar;
Card.Image = Image;
Card.Title = Title;
Card.Description = Description;
Card.Content = Content;
Card.Actions = Actions;
Card.List = List;
Card.Position = Position;

export default Card;
