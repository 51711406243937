import React from 'react';
import { Grid as ReactGrid, Row as ReactRow, Col as ReactCol } from 'react-flexbox-grid';

import styles from './styles.module.scss';

export const Grid = ({
    children,
    noMargin,
    noPadding,
    fullHeight,
    small,
    className = '',
    ...props
}) => (
    <ReactGrid
        className={`${styles.grid} ${small ? styles.gridSmall : ''} ${
            noMargin ? styles.gridNoMargin : ''
        } ${noPadding ? styles.gridNoPadding : ''} ${
            fullHeight ? styles.gridFullHeight : ''
        } ${className}`}
        {...props}
    >
        {children}
    </ReactGrid>
);

export const Row = ({ children, column, row, ...props }) => {
    const renderClassNames = () => {
        const classNames = [];

        switch (row) {
            case 'xs': {
                classNames.push(styles.rowDirectionRowXs);
                break;
            }
            case 'sm': {
                classNames.push(styles.rowDirectionRowSm);
                break;
            }
            case 'md': {
                classNames.push(styles.rowDirectionRowMd);
                break;
            }
            case 'lg': {
                classNames.push(styles.rowDirectionRowLg);
                break;
            }
            case 'xl': {
                classNames.push(styles.rowDirectionRowXl);
                break;
            }
        }

        switch (column) {
            case 'xs': {
                classNames.push(styles.rowDirectionColumnXs);
                break;
            }
            case 'sm': {
                classNames.push(styles.rowDirectionColumnSm);
                break;
            }
            case 'md': {
                classNames.push(styles.rowDirectionColumnMd);
                break;
            }
            case 'lg': {
                classNames.push(styles.rowDirectionColumnLg);
                break;
            }
            case 'xl': {
                classNames.push(styles.rowDirectionColumnXl);
                break;
            }
        }

        return classNames.join(' ');
    };

    return (
        <ReactRow className={`${styles.row} ${renderClassNames()}`} {...props}>
            {children}
        </ReactRow>
    );
};

export const Col = ({
    children,
    shrink,
    xsShrink,
    smShrink,
    mdShrink,
    lgShrink,
    xlShrink,
    grow,
    xsGrow,
    smGrow,
    mdGrow,
    lgGrow,
    xlGrow,
    ...props
}) => {
    const renderClassNames = () => {
        const classNames = [];

        if (shrink) classNames.push(styles.colShrink);
        if (xsShrink) classNames.push(styles.colXsShrink);
        if (smShrink) classNames.push(styles.colSmShrink);
        if (mdShrink) classNames.push(styles.colMdShrink);
        if (lgShrink) classNames.push(styles.colLgShrink);
        if (xlShrink) classNames.push(styles.colXlShrink);

        if (grow) classNames.push(styles.colGrow);
        if (xsGrow) classNames.push(styles.colXsGrow);
        if (smGrow) classNames.push(styles.colSmGrow);
        if (mdGrow) classNames.push(styles.colMdGrow);
        if (lgGrow) classNames.push(styles.colLgGrow);
        if (xlGrow) classNames.push(styles.colXlGrow);

        return classNames.join(' ');
    };

    return (
        <ReactCol className={`${styles.col} ${renderClassNames()}`} {...props}>
            {children}
        </ReactCol>
    );
};

// Builds margin spacer (responsive)
export const Divider = ({
    margin = 3,
    xsMargin,
    smMargin,
    mdMargin,
    lgMargin,
    xlMargin,
    children,
}) => {
    const renderClassNames = () => {
        if (xsMargin || smMargin || mdMargin || lgMargin || xlMargin) {
            margin = null;
        }

        const breakpoints = [
            { name: 'Xs', size: xsMargin },
            { name: 'Sm', size: smMargin },
            { name: 'Md', size: mdMargin },
            { name: 'Lg', size: lgMargin },
            { name: 'Sm', size: xlMargin },
        ];

        const classNames = [];

        // base margins (not responsive)
        if (margin === 0) classNames.push(styles.dividerNone);
        if (margin === 1) classNames.push(styles.dividerExtraSmall);
        if (margin === 2) classNames.push(styles.dividerSmall);
        if (margin === 3) classNames.push(styles.dividerMedium);
        if (margin === 4) classNames.push(styles.dividerLarge);
        if (margin === 5) classNames.push(styles.dividerExtraLarge);

        breakpoints.forEach(breakpoint => {
            switch (breakpoint.size) {
                case 0: {
                    classNames.push(styles[`divider${breakpoint.name}None`]);
                    break;
                }
                case 1: {
                    classNames.push(styles[`divider${breakpoint.name}ExtraSmall`]);
                    break;
                }
                case 2: {
                    classNames.push(styles[`divider${breakpoint.name}Small`]);
                    break;
                }
                case 3: {
                    classNames.push(styles[`divider${breakpoint.name}Medium`]);
                    break;
                }
                case 4: {
                    classNames.push(styles[`divider${breakpoint.name}Large`]);
                    break;
                }
                case 5: {
                    classNames.push(styles[`divider${breakpoint.name}ExtraLarge`]);
                    break;
                }
            }
        });

        return classNames.join(' ');
    };

    return <div className={`${styles.divider} ${renderClassNames()}`}>{children}</div>;
};
