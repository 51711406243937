import React, { Fragment, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { navigate } from 'gatsby-link';
import queryString from 'query-string';

import { Grid } from '@components/Grid';

import { EDITOR_EXPLORER, EDITOR_NAVIGATION } from '@helpers/api';
import { makeGetRequest } from '@helpers/requests';

import Folder from './Folder';
import File from './File';
import Command from '@components/Command';
import Empty from '@components/Empty';
import { setWebsiteEditorNavigation } from '@actions/website';

const Editor = ({ user, website, location, folder, file, path }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                setData(null);

                if (
                    !folder &&
                    !file &&
                    website?.editor &&
                    website?.editor?.navigation &&
                    Array.isArray(website.editor.navigation) &&
                    website.editor.navigation.length
                ) {
                    navigate(
                        `/editor?${
                            website.editor.navigation[0].folder
                                ? `folder=${website.editor.navigation[0].id}`
                                : `file=${website.editor.navigation[0].id}`
                        }`,
                        {
                            replace: true,
                        }
                    );

                    return;
                }

                setError(null);

                if (file || folder) {
                    const { data } = await makeGetRequest(EDITOR_EXPLORER, {
                        folder,
                        file,
                    });

                    setData(data);
                }

                setLoading(false);
            } catch (error) {
                if (!error || error === 'cancelled') return;
                setError(error);
                setLoading(false);
            }
        })();
    }, [folder, file]);

    useEffect(() => {
        if (
            !folder &&
            !file &&
            website?.editor &&
            website?.editor?.navigation &&
            Array.isArray(website.editor.navigation) &&
            website.editor.navigation.length
        ) {
            navigate(
                `/editor?${
                    website.editor.navigation[0].folder
                        ? `folder=${website.editor.navigation[0].id}`
                        : `file=${website.editor.navigation[0].id}`
                }`
            );
        }
    }, [website?.editor?.navigation]);

    const handleQueryChange = (query = {}) => {
        setLoading(true);
        navigate(
            `${location.pathname}?${queryString.stringify({
                ...queryString.parse(location.search),
                ...query,
            })}`
        );
    };

    const onFolderSelect = ({ folder, file }) => {
        handleQueryChange({ folder: folder.id, file: file.id });
    };

    const handleChange = data => {
        setData(data);
    };

    if (!loading && !file && !folder) {
        return (
            <Fragment>
                <Command>
                    <Command.Breadcrumbs>
                        <Command.Breadcrumbs.Breadcrumb text="Editor" />
                    </Command.Breadcrumbs>
                </Command>
                <Empty title="Hold tight" text="You don't have any editable content yet..." />
            </Fragment>
        );
    }

    if (!!file) {
        return (
            <File loading={loading} data={data} error={error} path={path} onChange={handleChange} />
        );
    }

    if (!!folder) {
        return <Folder loading={loading} data={data} error={error} onSelect={onFolderSelect} />;
    }

    return null;
};

export default connect(({ user, website }, ownProps) => {
    const params = queryString.parse(ownProps.location.search);
    const folder = params.folder;
    const file = params.file;
    const path = [].concat(params.path);

    return { user, website, folder, file, path };
})(Editor);
