import React, { Fragment, useEffect, useState } from 'react';
import Input from '@components/Input';
import { Col, Divider, Row } from '@components/Grid';
import Alert from '@components/Alert';
import Select from '@components/Select';
import Label from '@components/Label';
import Switch from '@components/Switch';
import Checkboxes from '@components/Checkboxes';
import Button from '@components/Button';
import { useSelector } from 'react-redux';
import List from '@components/List';
import Text from '@components/Text';
import Modal from '@components/Modal';
import { useSnackbar } from '@components/Snackbar';
import usePrevious from '@helpers/hooks/usePrevious';
import Options from '@components/CustomField/Options';

const Form = ({
	nested,
	allCategories,
	types,
	field,
	values,
	errors,
	touched,
	setFieldValue,
	handleChange,
	handleSubmit,
	isSubmitting,
	creatingCollectionItem,
	onActiveCollectionItem,
	onDelete,
}) => {
	const [openSnackbar] = useSnackbar();
	const [deleting, setDeleting] = useState(false);
	const [processingDeletion, setProcessingDeletion] = useState(false);

	const user = useSelector(({ user }) => user);
	const {
		id,
		label,
		type,
		hint,
		categories,
		multiple,
		globalApplicability,
		disabled,
		optional,
		options,
		items,
	} = values;
	const prevType = usePrevious(type);

	useEffect(() => {
		// if previous type and current type exists and the previous type multiple support is
		// different from the current type ensure multiple is undefined (reset)
		if (
			!!prevType &&
			!!type &&
			types?.find(({ value }) => value === prevType)?.supports?.multiple !==
				types?.find(({ value }) => value === type)?.supports?.multiple
		) {
			setFieldValue('multiple', undefined);
		}
	}, [type]);

	const handleDelete = async () => {
		try {
			setProcessingDeletion(true);
			await onDelete(values);
			setDeleting(false);
		} catch (error) {
			throw error;
		}
	};

	return (
		<Fragment>
			{!!user?.permissions?.isAdmin && (
				<Fragment>
					<Input
						optional
						name="id"
						label="Id"
						hint="Id is automatically generated if not specified"
						onChange={e =>
							setFieldValue(
								'id',
								e.target.value
									.toLowerCase()
									.split(' ')
									.join('-')
							)
						}
						value={id}
					/>
					{!!field?.id && (
						<Fragment>
							<Divider margin={2} />
							<Alert
								title="Editing an ID is dangerous!"
								type="warning"
								message="Make sure you know what you're doing, the corresponding website may be using this id to render a specific layout, changing this id may stop it from appearing."
							/>
						</Fragment>
					)}
					{touched.id && errors.id && (
						<Fragment>
							<Divider margin={2} />
							<Alert type="error" message={errors.id} />
						</Fragment>
					)}
					<Divider />
				</Fragment>
			)}

			<Input name="label" label="Label" onChange={handleChange} value={label} />
			{touched.label && errors.label && (
				<Fragment>
					<Divider margin={2} />
					<Alert type="error" message={errors.label} />
				</Fragment>
			)}
			<Divider />

			<Input name="hint" label="Hint" optional onChange={handleChange} value={hint} />
			{touched.hint && errors.hint && (
				<Fragment>
					<Divider margin={2} />
					<Alert type="error" message={errors.hint} />
				</Fragment>
			)}
			<Divider />

			<Select
				disabled={!user?.permissions?.isAdmin && field?.disabled}
				name="type"
				label="Type"
				onChange={handleChange}
				value={type}
			>
				{types.map(type => (
					<Select.Option key={type?.value} {...type} />
				))}
			</Select>
			{touched.type && errors.type && (
				<Fragment>
					<Divider margin={2} />
					<Alert type="error" message={errors.type} />
				</Fragment>
			)}
			<Divider />

			{(type === 'dropdown' || type === 'checkbox' || type === 'radio') && (
				<Fragment>
					<Label text="Options" />
					<Options
						type={type}
						options={options}
						onChange={options => setFieldValue('options', options)}
					/>
					<Divider />
				</Fragment>
			)}

			{type === 'collection' && (
				<Fragment>
					<Label text="Items" hint="Create and manage items within this collection" />
					{!!items && (
						<Fragment>
							<List>
								{items?.map((item, index) => (
									<List.Item
										key={item?.collectionId || index}
										onClick={() => onActiveCollectionItem(item)}
									>
										<List.Item.Column>
											<Text bold>{item?.label}</Text>
											<Text faded>{item?.type}</Text>
										</List.Item.Column>
									</List.Item>
								))}
							</List>
							{touched.items && errors.items && (
								<Fragment>
									<Divider margin={2} />
									<Alert type="error" message={errors.id} />
								</Fragment>
							)}

							<Divider margin={2} />
						</Fragment>
					)}

					<Button
						icon="plus"
						text="Create item"
						onClick={() => onActiveCollectionItem()}
						disabled={creatingCollectionItem}
					/>

					<Divider />
				</Fragment>
			)}

			{types?.find(({ value }) => value === type)?.supports?.multiple && (
				<Fragment>
					<Switch
						disabled={!user?.permissions?.isAdmin && field?.disabled}
						label="Multiple"
						hint="Allow multiple entries for this field"
						name="multiple"
						checked={multiple}
						onChange={(e, checked) => setFieldValue('multiple', checked)}
					/>
					{touched.multiple && errors.multiple && (
						<Fragment>
							<Divider margin={2} />
							<Alert type="error" message={errors.multiple} />
						</Fragment>
					)}
					<Divider />
				</Fragment>
			)}

			{!!user?.permissions?.isAdmin && (
				<Fragment>
					<Switch
						disabled={!user?.permissions?.isAdmin && field?.disabled}
						label="Disabled"
						hint="Prevent non-admins from editing fundamental fields."
						name="disabled"
						checked={disabled}
						onChange={(e, checked) => setFieldValue('disabled', checked)}
					/>
					<Divider />
				</Fragment>
			)}

			<Switch
				disabled={!user?.permissions?.isAdmin && field?.disabled}
				label="Optional"
				hint="Allow this field to be empty."
				id="optional"
				name="optional"
				checked={optional}
				onChange={(e, checked) => setFieldValue('optional', checked)}
			/>
			<Divider />

			{!nested && (
				<Fragment>
					<Switch
						allowDisabledOnClick
						disabled={
							(!user?.permissions?.isAdmin && field?.disabled) ||
							!allCategories.length
						}
						label="Global Applicability"
						hint="Is this product type applicable to all products, or specific categories?"
						name="globalApplicability"
						checked={globalApplicability}
						onChange={(e, checked) => setFieldValue('globalApplicability', checked)}
						onClick={
							!allCategories.length
								? e => {
										openSnackbar(
											`You don't have any categories yet, try creating one in order to set category applicability.`
										);
								  }
								: undefined
						}
					/>
					<Divider />

					{!globalApplicability && (
						<Fragment>
							<Checkboxes
								disabled={!user?.permissions?.isAdmin && field?.disabled}
								label="Applicable Categories"
								hint="This field will display on only the checked categories below."
								onChange={({ active }) => setFieldValue('categories', active)}
							>
								{allCategories?.map(({ _id, name }) => (
									<Checkboxes.Checkbox
										key={_id}
										value={_id}
										label={name}
										checked={categories}
									/>
								))}
							</Checkboxes>
						</Fragment>
					)}
				</Fragment>
			)}

			<Divider />
			<Row end="xs">
				<Col xs={12}>
					{!!field && (user?.permissions?.isAdmin || !field?.disabled) && (
						<Button
							submitting={processingDeletion}
							danger
							onClick={() => setDeleting(true)}
						>
							Delete
						</Button>
					)}
					<Button submitting={isSubmitting} success onClick={handleSubmit}>
						{!!field ? 'Save' : 'Create'}
					</Button>
				</Col>
			</Row>

			{!!field && (
				<Modal isOpen={deleting} onClose={() => setDeleting(false)}>
					<Modal.Content>
						<p>{`Are you sure you want to delete "${field?.label ?? ''}" field?`}</p>
						<Button loading={processingDeletion} danger onClick={handleDelete}>
							Delete
						</Button>
						<Button onClick={() => setDeleting(false)}>Cancel</Button>
					</Modal.Content>
				</Modal>
			)}
		</Fragment>
	);
};

export default Form;
