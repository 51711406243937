import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { subcomponent } from '@helpers';
import Label from '@components/Label';

import styles from './styles.module.scss';

const Select = ({
    placeholder,
    label,
    labelOnClick,
    hint,
    optional,
    icon,
    value,
    fullWidth = true,
    border = true,
    name,
    onChange,
    children,
    secondary,
    tertiary,
    disabled,
}) => {
    const fullWidthClass = fullWidth ? styles.selectFullWidth : '';
    const placeholderClass = !value ? styles.selectPlaceholder : '';
    const noBorderClass = !border ? styles.selectNoBorder : '';
    const disabledClass = disabled ? styles.selectDisabled : '';
    const svgIcon = icon ? (
        <FontAwesomeIcon icon={icon} />
    ) : (
        <FontAwesomeIcon icon={'caret-down'} />
    );

    let themeClass = styles.selectPrimary;

    if (secondary) {
        themeClass = styles.selectSecondary;
    } else if (tertiary) {
        themeClass = styles.selectTertiary;
    }

    return (
        <div
            className={`${styles.select} ${themeClass} ${fullWidthClass} ${placeholderClass} ${noBorderClass} ${disabledClass}`}
        >
            {label ? (
                <Label onClick={labelOnClick} text={label} hint={hint} optional={optional} />
            ) : null}

            <select name={name} onChange={onChange} value={value || ''} disabled={disabled}>
                {!value && (
                    <option value="" disabled>
                        {placeholder || 'Please choose an option...'}
                    </option>
                )}
                {subcomponent(children, Option)}
            </select>
            {svgIcon}
        </div>
    );
};

const Option = ({ value, label, disabled }) => (
    <option disabled={disabled} value={value}>
        {label}
    </option>
);

Select.Option = Option;
Option.displayName = 'Option';

export default Select;
